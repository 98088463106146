/* eslint-disable */
import React from 'react';
import { Graph } from '../../generated/graph';
import { MutationFunction, MutationResult, gql } from '@apollo/client';
import { Mutation } from '@apollo/react-components';

const MUTATION_REMOVE = gql`
  mutation RemoveComment($id: Int!) {
    handledComment(id: $id)
  }
`;

const MUTATION_MARK_TYPE = gql`
  mutation UpdatePostType($postID: String!, $type: FacebookPostType!) {
    markFacebookPost(postID: $postID, type: $type)
  }
`;

interface CommentListPostGroupProps {
  comments: Graph.Comment[];
  postID: string;
  type: 'LIVE' | 'NONE';
}

export class CommentListPostGroup extends React.Component<CommentListPostGroupProps> {
  state: {
    ids: number[];
    removed: boolean;
  } = {
    ids: [],
    removed: false,
  };

  render() {
    if (this.state.removed) return <div></div>;

    return (
      <div style={{ padding: 10, border: '1px solid #eee', marginBottom: 30, borderRadius: 10 }}>
        <div className="row">
          <div className="col-6">
            <a href={`https://www.facebook.com/${this.props.postID}`} target="_blank" rel="noopener noreferrer">
              <strong>{this.props.postID}</strong>
            </a>
          </div>
          <div className="col-6 text-right">
            <Mutation mutation={MUTATION_MARK_TYPE} onCompleted={() => this.setState({ removed: true })}>
              {(
                update: (arg0: { variables: { postID: string; type: string } }) => void,
                { loading }: MutationResult,
              ) => {
                return (
                  <button
                    disabled={loading}
                    className="btn btn-warning"
                    onClick={() =>
                      update({
                        variables: { postID: this.props.postID, type: this.props.type === 'LIVE' ? 'NONE' : 'LIVE' },
                      })
                    }
                  >
                    MARKED AS <strong>{this.props.type === 'LIVE' ? 'NOT LIVE' : 'LIVE'}</strong>
                  </button>
                );
              }}
            </Mutation>
          </div>
        </div>

        {this.renderRows(this.props.comments)}
      </div>
    );
  }

  renderRows = (rows: Graph.Comment[]) => {
    return (
      <table className="table table-lightborder dataTable" style={{ tableLayout: 'fixed' }}>
        <tbody>
          {rows
            .filter(x => !this.state.ids.includes(x.id!))
            .map(comment => (
              <tr key={comment.id!}>
                <td style={{ width: '200px' }}>
                  <Mutation
                    mutation={MUTATION_REMOVE}
                    onCompleted={(data: Graph.Mutation) => {
                      if (data.handledComment) this.removeComment(comment.id!);
                    }}
                  >
                    {(update: MutationFunction, { loading }: MutationResult) =>
                      this.renderMutation(update, loading, comment)
                    }
                  </Mutation>
                </td>
                <td style={{ width: '70px' }}>{this.formatTime(comment!.createdAt!)}</td>
                <td>
                  <strong>{comment.name}</strong>
                  <p>{comment.content}</p>
                </td>
                <td>
                  {comment.handledBy != null ? (
                    <div>
                      Removed by <b>{comment.handledBy}</b> <br /> {this.formatTime(comment!.handledAt!)} ago
                    </div>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  renderMutation = (update: any, loading: boolean, comment: Graph.Comment) => {
    if (loading) return <span>Removing...</span>;

    return (
      <div>
        <a href={`https://www.facebook.com/${comment.facebookID!}`} target="_blank" rel="noopener noreferrer">
          <button className="btn btn-sm btn-success" onClick={() => this.setState({ id: comment.id })}>
            View Conversation
          </button>
        </a>
        &nbsp;
        <button
          className="btn btn-sm btn-danger"
          onClick={() => {
            update({ variables: { id: comment.id! } });
          }}
        >
          Remove
        </button>
      </div>
    );
  };

  removeComment = (id: number) => {
    this.setState({ ids: [id, ...this.state.ids] });
  };

  formatTime(timestamp: string) {
    const diff = Date.now() - new Date(timestamp).getTime();

    console.log(diff.toLocaleString());

    if (diff >= 86400000) {
      return ((diff / 86400000) | 0) + ' days';
    } else if (diff >= 3600000) {
      return ((diff / 3600000) | 0) + ' hours';
    } else if (diff >= 60000) {
      return ((diff / 60000) | 0) + ' min';
    } else if (diff >= 1000) {
      return ((diff / 1000) | 0) + ' sec';
    } else {
      return 'now';
    }
  }
}
