import { ApolloProvider } from '@apollo/client';
import { PropsWithChildren, useMemo } from 'react';
import createApolloClient from 'src/libs/createApolloClient';
import { useToken } from './TokenContext';

export default function ApolloClientProvider({ children }: PropsWithChildren<unknown>) {
  const { token } = useToken();
  const client = useMemo(() => {
    return createApolloClient(token);
  }, [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
