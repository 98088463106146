import React, { PropsWithChildren } from 'react';
import styles from './form.module.scss';

export default function FormSelectInputRaw(props: PropsWithChildren<React.HTMLProps<HTMLSelectElement>>) {
  return (
    <select {...props} className={styles.input}>
      {props.children}
    </select>
  );
}
