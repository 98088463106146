/* eslint-disable @typescript-eslint/no-explicit-any */
import cookie from 'js-cookie';
import { Link, useHistory } from 'react-router-dom';
import { Graph } from '../../generated/graph';
import useDebounce from 'src/hooks/useDebounce';
import { gql, useMutation } from '@apollo/client';
import { useState, useEffect, useCallback } from 'react';
import MoreAction from 'src/components/layout/MoreAction';
import PolarisContent from 'src/components/PolarisContent';
import {
  Pagination,
  Card,
  FilterInterface,
  Filters,
  Page,
  Text,
  TextField,
  Button,
  ChoiceList,
  IndexTable,
  EmptySearchResult,
} from '@shopify/polaris';
import useQuerySuppilerList from 'src/hooks/useQuerySupplierList';

const MUTATION_ADMIN_FOLLOW_STORE = gql`
  mutation AdminFollowStore($id: Int!, $followed: Boolean!) {
    adminFollowStore(id: $id, followed: $followed)
  }
`;

const styles = {
  overflow: 'hidden',
  width: '200px',
  margin: '0',
  padding: '0 0 0 1rem',
  fontSize: '0.8rem',
  color: ' #95a5a6',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  webkitBoxOrient: 'vertical',
  webkitLineClamp: 1,
};

function FollowByAdmin({ id, followedBy, followed }: { id: number; followedBy?: Graph.Admin[]; followed: boolean }) {
  const [hasFollow, setHasFollow] = useState<boolean>();
  const [followAdmin, { loading }] = useMutation(MUTATION_ADMIN_FOLLOW_STORE, {
    refetchQueries: ['supplierList'],
  });
  const onFollow = () => {
    followAdmin({
      variables: { id, followed: !hasFollow },
      onCompleted: () => {
        setHasFollow(!hasFollow);
      },
    });
  };

  useEffect(() => {
    setHasFollow(followed);
  }, [followedBy]);
  return (
    <>
      {followedBy?.map((x, i) => (
        <Text as={'p'} variant={'headingXs'} key={i}>
          {x?.name}
        </Text>
      ))}
      <div style={{ marginTop: '10px' }}>
        <Button size="slim" onClick={onFollow} loading={loading} disabled={loading}>
          {hasFollow ? 'Followed' : 'Follow'}
        </Button>
      </div>
    </>
  );
}

function list(data: Graph.Supplier[], supplierName: string, phoneNumber: string, loading: boolean, limit: number) {
  const history = useHistory();
  const emptyStateMarkup = (
    <EmptySearchResult
      title="Not found"
      description={
        phoneNumber
          ? `We cannot find seller with phone number starting in ${phoneNumber}`
          : ` We cannot find seller with name starting in ${supplierName}`
      }
      withIllustration
    />
  );

  const suppliers = data ? data : [];

  return (
    <IndexTable
      resourceName={{
        singular: 'seller',
        plural: 'sellers',
      }}
      headings={[
        {
          title: 'Profile',
        },
        { title: 'Location' },
        { title: 'Type&role' },
        { title: 'Contact' },
        { title: 'Monitor By' },
        { title: 'Verify By' },
        { title: 'Control' },
      ]}
      itemCount={!data || loading ? 0 : data.length}
      selectable={false}
      loading={loading}
      emptyState={emptyStateMarkup}
    >
      {suppliers.map((row, i) => {
        if (i >= limit) return;
        return (
          <IndexTable.Row key={row?.id + ''} position={i} id={row.id + ''}>
            <IndexTable.Cell>
              <div style={{ display: 'flex' }}>
                <div>
                  {row.logo ? (
                    <img src={row.logo} alt="" style={{ width: 50, height: 50 }} />
                  ) : (
                    <div style={{ width: 50, height: 50, background: '#eee' }}></div>
                  )}
                </div>
                <div
                  // style={{ overflow: 'hidden', maxWidth: '200px', paddingLeft: '1rem' }}
                  style={styles}
                >
                  <div>
                    <Link key={row.id} to={`/supplier/detail/${row.id}#information`}>
                      {row.name}
                    </Link>
                    <div style={{ maxWidth: '300px', whiteSpace: 'normal' }}>
                      <Text as={'span'} variant={'headingXs'} breakWord>
                        {row.emails}
                      </Text>
                    </div>
                    <Text as={'p'} variant={'headingXs'} color="critical">
                      ID: {row.id}
                    </Text>
                    {row.subscription && (
                      <div
                        className={`${
                          row.subscription.planName === 'BOOST'
                            ? 'bg-red-500'
                            : row.subscription.planName === 'PRO'
                            ? 'bg-green-500'
                            : 'bg-neutral-500'
                        } text-white rounded-full w-[50px] text-center border-dotted border-[0.025rem]`}
                      >
                        <small>{row.subscription?.planName}</small>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div style={{ width: '150px', wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
                <Text as={'p'} variant={'headingXs'} breakWord color="subdued">
                  {row.address}
                </Text>
              </div>
              <Text as={'p'} variant={'headingXs'} color="critical">
                {row.origin}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as={'p'} variant={'headingXs'}>
                {row.role}
              </Text>
              <Text as={'p'} variant={'headingXs'}>
                {row.sellerType}
              </Text>
              {row?.isSeller && (
                <Text as={'p'} variant={'headingXs'} color="success">
                  L192&apos;s seller
                </Text>
              )}
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div style={{ width: '100px', wordBreak: 'break-all', whiteSpace: 'break-spaces' }}>
                <Text as={'p'} variant={'headingXs'} breakWord>
                  {row.contact || ''}
                </Text>
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <FollowByAdmin id={Number(row?.id)} followedBy={row.followedBy || []} followed={!!row.followed} />
            </IndexTable.Cell>
            <IndexTable.Cell>
              {row.verifiedBy ? (
                <Text as={'p'} variant={'headingXs'} key={i}>
                  {row.verifiedBy.name}
                </Text>
              ) : (
                <Text as={'p'} variant={'headingXs'} key={i}>
                  --
                </Text>
              )}
            </IndexTable.Cell>
            <IndexTable.Cell>
              <MoreAction key="" isVertical marginTop="-85px" marginRight="32px">
                <MoreAction.Link href={`http://adm.l192.com/supplier/edit/${row.id}`} target="_blank">
                  Edit (Old Admin)
                </MoreAction.Link>
                <MoreAction.Button
                  onClick={async () => {
                    const isProduction = process.env.NODE_ENV !== 'development';

                    await cookie.set('lf_merchant_token', `${'mx' + row?.token?.toString()}`, {
                      domain: '.l192.com',
                      expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
                    });
                    await window.open(
                      isProduction ? `https://sellers.l192.com` : `https://sellers-stage.l192.com`,
                      '_blank',
                    );
                  }}
                >
                  Login to Merchant Admin Panel
                </MoreAction.Button>
                <MoreAction.Button
                  onClick={async () => {
                    history.push(`/supplier/detail/${row.id}#punishment_Fee`);
                  }}
                >
                  Add Fee
                </MoreAction.Button>
              </MoreAction>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      })}
    </IndexTable>
  );
}

export function SupplierListScreen() {
  const LIMIT = 50;
  const [offset, setOffset] = useState(0);
  const [origin, setOrigin] = useState<[]>([]);
  const [onlyL192, setOnlyL192] = useState<[]>([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const debounceName = useDebounce(supplierName, 800);
  const [sellerRole, setSellerRole] = useState<[]>([]);
  const [sellerType, setSellerType] = useState<[]>([]);
  const [myFollowwing, setMyFollowwing] = useState<[]>([]);
  const [isVerified, setIsVerified] = useState<[]>([]);
  const debouncePhoneNumber = useDebounce(phoneNumber, 500);
  const [supplierList, setSupplierList] = useState<Graph.Supplier[]>([{}]);
  const [sellerStatus, setSellerStatus] = useState<string[] | []>(['true']);
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);

  const { data: supplierData, loading } = useQuerySuppilerList({
    fetchPolicy: 'no-cache',
    variables: {
      name: debounceName,
      limit: LIMIT,
      offset: offset,
      phoneNumber: debouncePhoneNumber,
      active: sellerStatus?.find(x => x === 'true') ? true : sellerStatus?.find(x => x === 'false') ? false : undefined,
      roles: sellerRole?.length > 0 ? sellerRole : undefined,
      origin: origin.find(x => x === 'local') ? 1 : origin.find(x => x === 'china') ? 2 : undefined,
      sellerType: sellerType?.length > 0 ? sellerType : undefined,
      myFollowwing: myFollowwing.find(x => x === 'true') ? true : false,
      isSeller:
        onlyL192.length < 1 || onlyL192.length > 1
          ? undefined
          : (onlyL192 as string[])[0] === 'L192_SELLER'
          ? true
          : false,
      isVerified: isVerified.find(x => x === 'true') ? true : false,
      subscriptionPlan: subscriptionPlan.length > 0 ? subscriptionPlan : ([] as any),
    },
  });
  const handleOrignChange = useCallback(value => setOrigin(value), []);
  const handleRoleChange = useCallback(value => setSellerRole(value), []);
  const handleMyFollowingChange = useCallback(value => setMyFollowwing(value), []);
  const handleIsVerifiedChange = useCallback(value => setIsVerified(value), []);
  const handleSellerStatusChange = useCallback(value => setSellerStatus(value), []);
  const handleSubcriptionChange = useCallback(value => setSubscriptionPlan(value), []);

  const handleTypeChange = useCallback(value => {
    const sellerTypeString = value.filter((x: string) => x.split('_')[0] !== 'L192');
    const isSellerString = value.filter((x: string) => x.split('_')[0] === 'L192');
    setSellerType(sellerTypeString);
    setOnlyL192(isSellerString);
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    setSupplierName('');
    setSellerStatus(['true']);
    setSellerRole([]);
    setSellerType([]);
    setOrigin([]);
    setMyFollowwing([]);
    setOnlyL192([]);
    setOffset(0);
  }, []);

  useEffect(() => {
    if (supplierData?.supplierList) {
      setSupplierList(supplierData?.supplierList);
    }
  }, [supplierData]);

  useEffect(() => {
    setOffset(0);
  }, [supplierName, sellerStatus, sellerRole, sellerType, origin, myFollowwing, onlyL192]);

  const filters: FilterInterface[] = [
    {
      label: 'Phone',
      key: 'phone',
      filter: <TextField value={phoneNumber} onChange={setPhoneNumber} autoComplete="off" label="" />,
    },
    {
      key: 'seller roles',
      label: 'Seller Roles',
      filter: (
        <ChoiceList
          title="Seller Role"
          titleHidden
          choices={[
            { label: 'Store', value: 'STORE' },
            { label: 'Vendor', value: 'VENDOR' },
            { label: 'FBL Vendor', value: 'FBL_VENDOR' },
            { label: 'SHIPPER', value: 'SHIPPER' },
            { label: 'Seller', value: 'SELLER' },
          ]}
          selected={sellerRole || []}
          onChange={handleRoleChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'seller type',
      label: 'Seller Type',
      filter: (
        <ChoiceList
          title="Seller type"
          titleHidden
          choices={[
            { label: 'Normal', value: 'NORMAL' },
            { label: 'IS', value: 'IS' },
            { label: 'ISD', value: 'ISD' },
            { label: 'L192 Seller', value: 'L192_SELLER' },
            { label: 'L192 Delivery', value: 'L192_DELIVERY' },
          ]}
          selected={[...sellerType, ...onlyL192] || []}
          onChange={handleTypeChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'origin',
      label: 'Origin',
      filter: (
        <ChoiceList
          title="Origin"
          titleHidden
          choices={[
            { label: 'Local', value: 'local' },
            { label: 'China', value: 'china' },
          ]}
          selected={origin || []}
          onChange={handleOrignChange}
        />
      ),
    },
    {
      key: 'seller status',
      label: 'Seller Status',
      filter: (
        <ChoiceList
          title="Seller Status"
          titleHidden
          choices={[
            { label: 'Active', value: 'true' },
            { label: 'Inactive', value: 'false' },
          ]}
          selected={sellerStatus || []}
          onChange={handleSellerStatusChange}
        />
      ),
    },
    {
      key: 'My Following',
      label: 'My Following',
      filter: (
        <ChoiceList
          title="My following"
          titleHidden
          choices={[{ label: 'My following', value: 'true' }]}
          selected={myFollowwing || []}
          onChange={handleMyFollowingChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'Verified',
      label: 'Verified',
      filter: (
        <ChoiceList
          title="Is Verified"
          titleHidden
          choices={[{ label: 'Is Verified', value: 'true' }]}
          selected={isVerified || []}
          onChange={handleIsVerifiedChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'subscriptionPlan',
      label: 'Subscription Plan',
      filter: (
        <ChoiceList
          title="Is Verified"
          titleHidden
          choices={[
            { label: 'Pro', value: 'PRO' },
            { label: 'Boost', value: 'BOOST' },
            { label: 'Elite', value: 'ELITE' },
            { label: 'Feature', value: 'FEATURE' },
          ]}
          selected={subscriptionPlan || []}
          onChange={handleSubcriptionChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (phoneNumber.length > 0) {
    appliedFilters.push({
      key: 'phone',
      label: 'Phone: ' + phoneNumber,
      onRemove: () => setPhoneNumber(''),
    });
  }
  if (sellerRole.length > 0) {
    appliedFilters.push({
      key: 'seller roles',
      label: 'Seller Role: ' + sellerRole,
      onRemove: () => setSellerRole([]),
    });
  }
  if (sellerType.length > 0) {
    appliedFilters.push({
      key: 'seller type',
      label: 'Seller Type: ' + sellerType,
      onRemove: () => setSellerType([]),
    });
  }
  if (origin.length > 0) {
    appliedFilters.push({
      key: 'origin',
      label: 'Origin: ' + origin,
      onRemove: () => setOrigin([]),
    });
  }
  if (sellerStatus.length > 0) {
    appliedFilters.push({
      key: 'seller status',
      label: 'Seller Status: ' + (!!sellerStatus?.find(x => x === 'true') ? 'active' : 'inactive'),
      onRemove: () => setSellerStatus([]),
    });
  }

  if (myFollowwing.length > 0) {
    appliedFilters.push({
      key: 'My Following',
      label: 'My Following',
      onRemove: () => setMyFollowwing([]),
    });
  }
  if (onlyL192.length > 0) {
    appliedFilters.push({
      key: 'Only L192 seller',
      label: 'Only L192 seller',
      onRemove: () => setOnlyL192([]),
    });
  }

  if (subscriptionPlan.length > 0) {
    appliedFilters.push({
      key: 'subscriptionPlan',
      label: 'Subcription Plan',
      onRemove: () => setSubscriptionPlan([]),
    });
  }

  return (
    <PolarisContent>
      <Page fullWidth>
        <Card>
          <Card.Section>
            <Filters
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={setSupplierName}
              queryValue={supplierName}
              onQueryClear={() => handleFiltersClearAll()}
              onClearAll={() => handleFiltersClearAll()}
            />
          </Card.Section>
          <Card.Section flush>{list(supplierList, supplierName, phoneNumber, loading, LIMIT)}</Card.Section>
          <Card.Section>
            <Pagination
              label={offset > 0 ? offset / LIMIT + 1 : 1}
              hasPrevious={offset === 0 ? false : true}
              onPrevious={() => {
                setOffset(offset - LIMIT);
              }}
              hasNext={supplierList.length === LIMIT ? true : false}
              onNext={() => {
                setOffset(offset + LIMIT);
              }}
            />
          </Card.Section>
        </Card>
      </Page>
    </PolarisContent>
  );
}
