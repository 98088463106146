/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { QUERY_PACKAGE_LIST } from '../query';
import { Link } from 'react-router-dom';
import { BlockLoading } from '../../../components/loading/BlockLoading';

import { Table } from 'src/components/layout/tables/Table';
import XForm from 'src/components/layout/xform/XForm';
import Button from 'src/components/layout/xform/Button';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';

interface Schedule {
  title: string;
  items: Graph.Package[];
}

function Schedule(props: Schedule) {
  const [toggle, setToggle] = useState(false);
  if (props.items.length > 0) {
    return (
      <div className="mb-3">
        <Table stripe={true} border={true}>
          <thead>
            <tr style={{ background: '#ced6e0' }} onClick={() => setToggle(!toggle)}>
              <th colSpan={7}>
                {props.title}
                <span style={{ fontWeight: 'lighter' }}> ({props.items.length})</span>
              </th>
              <th style={{ textAlign: 'right' }}>
                <XForm.Button>
                  {toggle ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </XForm.Button>
              </th>
            </tr>
            {toggle && (
              <tr>
                <th style={{ width: 75 }}>Type</th>
                <th style={{ width: 150 }}>Status</th>
                <th style={{ width: 180 }}>Date</th>
                <th>Package</th>
                <th style={{ width: 120 }}>Created By</th>
                <th style={{ width: 120 }}>Claimed By</th>
                <th style={{ width: 200 }}>Additional Instruction</th>
                <th style={{ width: 200 }}>Route</th>
              </tr>
            )}
          </thead>
          {toggle && (
            <tbody>
              {props.items.map(item => (
                <tr
                  key={item.id}
                  style={{
                    borderBottom: '1px solid #eeeeee',
                  }}
                >
                  <td className="text-center">
                    <i
                      className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
                      style={{ fontSize: 18, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
                    ></i>
                  </td>
                  <td>{item.status}</td>
                  <td>
                    {item?.createdAt ? (
                      <div>
                        <strong>Created At</strong>
                        <br /> {moment(item?.createdAt?.toString() || '').format('YYYY-MM-DD hh:mm A')}
                      </div>
                    ) : (
                      ''
                    )}
                    {item?.requestPickupAt ? (
                      <div>
                        <strong>Requested At</strong>
                        <br />
                        {moment(item?.requestPickupAt?.toString() || '').format('YYYY-MM-DD hh:mm A')}
                      </div>
                    ) : (
                      ''
                    )}
                    {item?.appointedAt ? (
                      <div>
                        <strong>Appointed At</strong>
                        <br />
                        {moment(item?.appointedAt?.toString() || '').format('YYYY-MM-DD hh:mm A')}
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <div>
                      <Link className="h4" to={`/package/detail/${item.id}`}>
                        {item.id.toString().padStart(8, '0')}
                      </Link>
                    </div>
                    <div>
                      Cash to collect: <strong>${item.cash?.toFixed(2)}</strong> | Barcode:{' '}
                      <strong>{item.barcode}</strong>
                    </div>
                  </td>
                  <td>
                    <strong>{item.createdBy?.name}</strong>
                  </td>
                  <td>
                    <strong>{item.claimDeliveryBy?.name}</strong>
                  </td>
                  <td>{item.additionalInstruction}</td>
                  <td>
                    <strong style={{ fontSize: 16 }}>
                      <small>
                        {item.currentWarehouse?.shortName}
                        &nbsp;&nbsp;
                        <i className="fas fa-arrow-right" />
                        &nbsp;&nbsp; {item.nextWarehouse?.shortName ?? 'Unknown'}
                      </small>
                    </strong>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </div>
    );
  }
  return null;
}

function renderPackage(items: Graph.Package[]) {
  const today = moment(Date.now()).format('YYYY-MM-DD');

  const yesterdays: Graph.Package[] = [];
  const tomorrow: Graph.Package[] = [];
  const morning: Graph.Package[] = [];
  const afternoon: Graph.Package[] = [];
  const evening: Graph.Package[] = [];

  items.map(x => {
    if (x.appointedAt && !moment(x.appointedAt).isBefore(moment(`${today} 00:00:00`))) {
      if (moment(x.appointedAt).isAfter(moment(`${today} 23:59:59`))) {
        tomorrow.push(x);
      } else if (moment(x.appointedAt).isBefore(`${today} 12:00:00`)) {
        morning.push(x);
      } else if (moment(x.appointedAt).isBefore(`${today} 17:00:00`)) {
        afternoon.push(x);
      } else {
        evening.push(x);
      }
    } else {
      yesterdays.push(x);
    }
    return null;
  });
  const list = [
    { title: 'Yesterday', items: yesterdays },
    { title: '00AM-12PM', items: morning },
    { title: '12PM-5PM', items: afternoon },
    { title: '05PM-12AM', items: evening },
    { title: 'Tomorrow', items: tomorrow },
  ];
  return list.map((l, i) => <Schedule {...l} key={i} />);
}

function PackageButtonFilter({ items }: { items: Graph.Package[] }) {
  const [tabName, setTabName] = useState<{ current: string; next: string }>({ current: 'All', next: '' });
  const tabs: { current: string; next: string; total: number }[] = [{ current: 'All', next: '', total: items.length }];
  items.map(x => {
    const current = x?.currentWarehouse?.shortName || '';
    const next = x?.nextWarehouse?.shortName || '';
    const index = tabs.findIndex(h => h.current === current && h.next === next);
    if (index < 0) {
      tabs.push({ current, next, total: 1 });
    } else {
      const hub = tabs[index];
      tabs[index] = { current, next, total: hub.total + 1 };
    }
    return null;
  });
  return (
    <>
      <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {tabs.map((t, i) => {
          const tab = !!t.next ? `${t.current}${t.next}` : t.current;
          return (
            <div key={i}>
              <Button
                theme={t.current === tabName.current && t.next === tabName.next ? 'primary' : 'secondary'}
                style={{ margin: '5px 10px 5px 0' }}
                value={tab}
                onClick={() => setTabName(t)}
              >
                {t.current}
                {t.next !== '' ? (
                  <>
                    &nbsp;
                    <i className="fas fa-arrow-right" />
                    &nbsp;
                  </>
                ) : (
                  ''
                )}
                {t.next} {t.current === '' && t.next === '' && 'L192'} ({t.total})
              </Button>
            </div>
          );
        })}
      </div>
      {renderPackage(
        items.filter(
          x =>
            tabName.current === 'All' ||
            (!x.nextWarehouse && x.currentWarehouse?.shortName === tabName.current) ||
            (x.currentWarehouse?.shortName === tabName.current && x.nextWarehouse?.shortName === tabName.next) ||
            (!x.nextWarehouse && !x.currentWarehouse && tabName.current === ''),
        ),
      )}
    </>
  );
}

export function PackageInternalListScreen() {
  const history = useHistory();
  const { pathname, search } = useLocation();

  useEffect(() => {
    history.replace(pathname + '#internal');
  }, [search, history, pathname]);
  const { data } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, {
    variables: {
      type: 'INTERNAL',
      status: [
        'DRAFT',
        'REQUEST_PICKUP',
        'PICKUP',
        'ARRIVED',
        'TRANSIT',
        'DELIVERING',
        'CLAIM_PICKUP',
        'CLAIM_DELIVERY',
        'MARK_RETURNING',
        'ATTEMPT',
        'SCHEDULE_REQUEST_PICKUP',
        'WITH_AGENT',
        'WITH_TAXI',
        'ABANDONED',
      ],
    },
  });

  if (!data?.packageList) return <BlockLoading />;
  return (
    <>
      <div>
        <PackageButtonFilter items={data?.packageList?.filter(x => x.status !== 'DELIVERED')} />
      </div>
    </>
  );
}
