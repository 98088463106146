export type PurchaseOrderCostItemDescription = {
  id: number;
  price: string;
  priceDescription: string;
  complete: boolean;
};

export function purchaseOrderCostReader(costs: string, exchangeRate?: number): PurchaseOrderCostItemDescription[] {
  const lines: string[] = costs.split(/\r?\n/);
  const result: PurchaseOrderCostItemDescription[] = [];

  for (const line of lines) {
    const t = line.trim().split(/[\s,]+/);
    if (t.length < 3) {
      result.push({
        id: Number(t[0]),
        price: t[1],
        priceDescription: '',
        complete: true,
      });
    } else if (t.length === 3) {
      const cogs = Number(t[1]);
      const cogsInOrigianlCurrency = Number(t[2]);
      const cogsConversion = cogsInOrigianlCurrency / (exchangeRate ? exchangeRate : 1);
      const correctFormat =
        Math.max(cogsConversion * 1.25, cogsConversion + 0.85) >= cogs && cogsConversion * 0.8 <= cogs;

      result.push({
        id: Number(t[0]),
        price: t[1],
        priceDescription: t[2],
        complete: exchangeRate ? correctFormat : false,
      });
    }
  }

  return result;
}
