import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import moment from 'moment';

const QUERY_DELIVERY = gql`
  query notificationDeliveryList {
    notificationDeliveryList {
      id
      segmentName
      title
      heading
      total
      sent
      createdAt
      data
      delivery {
        converted
        sent
        failed
      }
    }
  }
`;

function renderProgress(data: Graph.NotificationDelivery) {
  if (!data.delivery) return <div></div>;

  if (data.delivery)
    return (
      <div>
        <div className="os-progress-bar primary">
          <div className="bar-labels mb-1">
            <span>
              {Math.floor(((data.delivery.converted || 0) / (data.delivery.sent || 1)) * 10000) / 100}% Conversion
            </span>
          </div>

          <div className="bar-level-1" style={{ width: '100%', background: '#e74c3c' }}>
            <div className="bar-level-3" style={{ width: ((data.delivery.sent || 0) / (data.sent || 0)) * 100 + '%' }}>
              <div
                className="bar-level-3"
                style={{
                  width: Math.floor(((data.delivery.converted || 0) / (data.delivery.sent || 1)) * 10000) / 100 + '%',
                  background: '#27ae60',
                }}
              ></div>
            </div>
          </div>

          <div className="bar-labels mt-1">
            <span className="positive">
              {data.delivery.sent} / {data.sent}
            </span>
          </div>
        </div>
      </div>
    );
}

export function NotificationDeliveryPartial(): React.ReactElement {
  const { loading, data } = useQuery<Graph.Query>(QUERY_DELIVERY);

  if (loading || !data) return <div>Loading ...</div>;

  return (
    <div>
      <div className="controls-above-table">
        <Link to="/notification" className="btn btn-primary">
          Create Notification
        </Link>
        <a
          href="https://adm.l192.com/training/29"
          target={'_blank'}
          style={{
            fontSize: '2rem',
            display: 'inline-block',
            verticalAlign: 'middle',
            marginLeft: '10px',
            marginBottom: '4px',
          }}
          rel="noreferrer"
        >
          <strong style={{ fontSize: '2rem' }}>?</strong>
        </a>
      </div>
      <div>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Content</th>
              <th>Segment</th>
              <th style={{ width: 150 }}>Delivery</th>
              <th style={{ width: 100 }}>Created At</th>
              <th style={{ width: 60 }}></th>
            </tr>
          </thead>
          <tbody>
            {(data.notificationDeliveryList || []).map(
              (x): ReactElement => (
                <tr key={x.id?.toString()}>
                  <td>
                    <div>
                      <strong>{x.heading}</strong>
                    </div>
                    <div>{x.title}</div>
                  </td>
                  <td>{x.segmentName}</td>
                  <td>{renderProgress(x)}</td>
                  <td>{x.createdAt}</td>
                  <td>
                    {x.data.data.screen === 'Product' && (
                      <Link
                        className="btn btn-primary"
                        to={`/product/analytics?ids=${x.data.data.payload.id}&start=${moment(x.createdAt as string)
                          .add(-15, 'day')
                          .format('YYYY-MM-DD')}&end=${moment(x.createdAt as string)
                          .add(15, 'day')
                          .format('YYYY-MM-DD')}&annotation=${moment(x.createdAt as string).format('YYYY-MM-DD')}`}
                      >
                        Product Analytics
                      </Link>
                    )}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
