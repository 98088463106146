import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { TagComplete } from '../../../components/TagComplete';

interface Props {
  value: string[];
  onChanged: (tags: string[]) => void;
}

const QUERY = gql`
  query tagList {
    tagList(type: PURCHASE_ORDER) {
      id
      name
    }
  }
`;

export const PurchaseOrderTags: React.SFC<Props> = (props: Props) => {
  const { loading, data } = useQuery<Graph.Query>(QUERY);

  if (loading) {
    return <div className="lf-form-control">...</div>;
  }

  return (
    <TagComplete
      label="Tags"
      items={data?.tagList ? data.tagList.map(x => x.name) : []}
      itemBack={props.value ? props.value : []}
      onAppend={(val: string): void => {
        props.onChanged(props.value ? [...props.value, val] : [val]);
      }}
      onRemove={(val: string[]): void => {
        props.onChanged(val);
      }}
    />
  );
};
