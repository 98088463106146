import React from 'react';
import { ApolloClient, gql } from '@apollo/client';
import { Loading } from './Loading';
import { SectionBlock } from './light/SectionBlock';

type Props = {
  apollo: ApolloClient<unknown>;
  title?: string;
  onValidInventorySlot: (slot: InventorySlot) => void;
};

export type InventorySlot = {
  id: number;
  name: string;
};

export class ScanningInventorySlot extends React.Component<Props> {
  state: {
    isLoading: boolean;
    barcode: string;
  } = {
    isLoading: false,
    barcode: '',
  };

  input: HTMLInputElement | null = null;

  componentDidMount() {
    if (this.input !== null) {
      this.input.focus();
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return (
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <SectionBlock title={this.props.title ? this.props.title : 'Scanning Inventory Slot'}>
            <form onSubmit={this.onInventorySlotEntered}>
              <div className="form-group">
                <label>Inventory Barcode</label>
                <input
                  ref={input => {
                    this.input = input;
                  }}
                  type="text"
                  className="form-control"
                  value={this.state.barcode}
                  onChange={this.onInventorySlotBarcodeChanged}
                />
              </div>
            </form>
          </SectionBlock>
        </div>
      </div>
    );
  }

  private onInventorySlotEntered = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({ isLoading: true }, () => {
      this.checkInventorySlot();
    });
  };

  private checkInventorySlot = async () => {
    if (this.state.barcode.includes('_LOC_')) {
      const result = await this.props.apollo.query({
        query: gql`
          query getInventorySlot($id: Int!) {
            inventorySlot(id: $id) {
              id
              name
            }
          }
        `,
        variables: {
          id: parseInt(this.state.barcode.replace('_LOC_', '')),
        },
        fetchPolicy: 'cache-first',
      });

      this.clearInput();

      if (result.data.inventorySlot !== null) {
        this.props.onValidInventorySlot(result.data.inventorySlot);
      } else {
        this.setState({ isLoading: false });
        alert('Inventory slot not found');
      }
    } else {
      this.clearInput();
      this.setState({ isLoading: false });
      alert('Inventory slot not found');
    }
  };

  private clearInput = () => {
    this.setState({ barcode: '' });
    if (this.input !== null) this.input.focus();
  };

  private onInventorySlotBarcodeChanged = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      barcode: e.currentTarget.value,
    });
  };
}
