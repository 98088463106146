import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { QUERY_PACKAGE_LIST } from '../query';
import { BlockLoading } from '../../../components/loading/BlockLoading';
import { Table } from 'src/components/layout/tables/Table';
import XForm from 'src/components/layout/xform/XForm';
import { Link } from 'react-router-dom';
import { MerchantPicker } from 'src/components/pickers/MerchantPicker';
import Formatter from 'src/libs/Formatter';
function renderHistoryTable(item: Graph.Package, idx: number) {
  return (
    <tr key={item.id}>
      <td>{idx + 1}</td>
      <td>
        {item.type === 'ORDER_PICKUP' && (
          <div style={{ marginBottom: 2 }}>
            <span style={{ fontSize: 10, background: '#f1c40f', display: 'inline-block', padding: '2px 5px' }}>
              {Formatter.beautifyUglyName(item.type)}
            </span>
          </div>
        )}
        <Link to={`/package/detail/${item.id}`}>{item.id.toString().padStart(8, '0')}</Link>
      </td>
      <td>
        <strong>{item.supplier?.name}</strong>
      </td>
      <td>
        <div>
          <strong>{item?.customer?.name}</strong>
          <br />
          <span>{item.customer?.phoneNumber}</span>
        </div>
      </td>
      <td>
        <strong style={{ fontSize: 16 }}>{item.createdAt?.slice(0, 10)}</strong>
      </td>
      <td>
        <strong style={{ fontSize: 16 }}>{item.createdAt?.slice(10)}</strong>
      </td>
    </tr>
  );
}
function RenderHistoryTableList({ items }: { items: Graph.Package[] }) {
  const [toggle, setToggle] = useState(false);
  return (
    <Table stripe={true} border={true}>
      <thead>
        <tr style={{ background: '#ced6e0' }} onClick={() => setToggle(!toggle)}>
          <th colSpan={5}>
            {items[0].status || 'Little Fashion'}
            <span style={{ fontWeight: 'lighter' }}> ({items.length})</span>
          </th>
          <th style={{ textAlign: 'right' }}>
            <XForm.Button>{toggle ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</XForm.Button>
          </th>
        </tr>
        {toggle && (
          <tr>
            <th style={{ width: 50 }}>#</th>
            <th style={{ width: 150 }}>Package ID</th>
            <th>Merchant</th>
            <th style={{ width: 150 }}>Customer</th>
            <th style={{ width: 150 }}>CreatedAt</th>
            <th style={{ width: 150 }}>Time</th>
          </tr>
        )}
      </thead>
      {toggle && <tbody>{items.map(renderHistoryTable)}</tbody>}
    </Table>
  );
}

function renderPackage(packageList: Graph.Package[]) {
  const uniqueStatus = new Set(packageList.map(x => (x.status ? x.status : null)));
  const StatusList = [...uniqueStatus];
  return StatusList.map((statusName, i) => {
    const orders = packageList.filter(x => {
      if (!statusName) return x.status === null;
      return x.status === statusName;
    });

    return (
      <div key={i} className="mb-3">
        <RenderHistoryTableList items={orders} />
      </div>
    );
  });
}

export function PackageDeliveryHistory() {
  const newDate = new Date();
  const today = newDate
    .getDate()
    .toString()
    .padStart(2, '0');

  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  // const lastDayofmonth = new Date(year, month, 0).toString().slice(8, 10);
  const startDate = year + '-' + month.toString().padStart(2, '0') + '-' + today;
  const currentDate = year + '-' + month.toString().padStart(2, '0') + '-' + today;

  const [pageId, setPageId] = useState<number | null>(null);
  const [createFrom, setCreateFrom] = useState<undefined | string>(startDate.toString());
  const [createTo, setCreateTo] = useState<undefined | string>(currentDate.toString());
  const { data, loading } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, {
    variables: {
      supplierId: pageId,
      createdAtTo: createTo + ' 23:59:59',
      createdAtFrom: createFrom + ' 00:00:00',
    },
    skip: pageId ? false : true,
  });
  return (
    <>
      <XForm.Division>
        <MerchantPicker label="Sellers" value={pageId} onChange={setPageId} />
        <XForm.Text
          type="date"
          label="Created At From"
          placeholder={startDate || undefined}
          value={createFrom}
          onChange={e => setCreateFrom(e.currentTarget.value)}
        />
        <XForm.Text
          type="date"
          label="Created At To"
          placeholder={currentDate || undefined}
          value={createTo}
          onChange={e => setCreateTo(e.currentTarget.value)}
        />
      </XForm.Division>

      <div>
        {data?.packageList ? (
          renderPackage(data?.packageList.map(x => x))
        ) : loading ? (
          <BlockLoading />
        ) : (
          <span>Please select seller to view all their delivery</span>
        )}
      </div>
    </>
  );
}
