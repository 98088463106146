import React from 'react';
import styles from './form.module.scss';

interface Props extends React.PropsWithChildren<React.HTMLProps<HTMLInputElement>> {
  label?: string;
  caption?: string;
  right?: boolean;
}

export default React.forwardRef(function FormTextInput(props: Props, ref: React.Ref<HTMLInputElement>) {
  const { label, caption, right, ...restProps } = props;

  if (label === undefined) {
    return (
      <input
        type="text"
        {...restProps}
        ref={ref}
        className={styles.input}
        style={right ? { textAlign: 'right' } : {}}
      />
    );
  }

  return (
    <div className={styles.form_group}>
      <label className={styles.label}>{props.label}</label>
      <input
        type="text"
        {...restProps}
        ref={ref}
        className={styles.input}
        style={right ? { textAlign: 'right' } : {}}
      />
      {caption && <small>{caption}</small>}
    </div>
  );
});
