import { PropsWithChildren, useContext, createContext } from 'react';
import { useUser } from 'src/contexts/AuthProvider';
import useLocalStorageObject from 'src/hooks/useLocalStorageObject';
import LegacyNavigationContainer from './LegacyNavigationContainer';
import PolarisNavigationContainer from './PolarisNavigationContainer';

const NavigationThemeContext = createContext<{
  legacy: boolean;
  setLegacyNavigation: (v: { legacy: boolean }) => void;
}>({
  legacy: true,
  setLegacyNavigation: () => {
    throw 'Not implemented';
  },
});

export function useLegacyNavigation() {
  return useContext(NavigationThemeContext);
}

export default function NavigationContainer(props: PropsWithChildren<unknown>) {
  const [legacy, setLegacy] = useLocalStorageObject<{ legacy: boolean }>('navigation_options', { legacy: true });
  const user = useUser();

  let finalLegacy = legacy.legacy;
  if (user.groupID === 6 || user.groupID === 8) {
    finalLegacy = false;
  }

  return (
    <NavigationThemeContext.Provider value={{ legacy: finalLegacy, setLegacyNavigation: setLegacy }}>
      {finalLegacy ? <LegacyNavigationContainer {...props} /> : <PolarisNavigationContainer {...props} />}
    </NavigationThemeContext.Provider>
  );
}
