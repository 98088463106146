/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { Layout, IndexTable, Avatar, useIndexResourceState, EmptyState, Button, Card } from '@shopify/polaris';
import { useMutation, gql, useQuery } from '@apollo/client';
import { Graph } from 'src/generated/graph';
import XForm from 'src/components/layout/xform/XForm';
import { MerchantPicker } from 'src/components/pickers/MerchantPicker';
import { DeliverPicker } from 'src/components/pickers/DeliverPicker';
import { Link } from 'react-router-dom';
import styles from './tasks.module.scss';
import { Checkbox } from 'src/components/form/FormComponent';
import { PolarisConfirm } from 'src/components/polaris/PolarisModal';
import { useCustomToast } from 'src/contexts/ToastProvider';
import PolarisPagination from 'src/components/polaris/PolarisPagination';

const QUERY = gql`
  query packageList(
    $claimDeliveryBy: Int
    $offset: Int!
    $limit: Int!
    $claimPickupBy: Int
    $status: [PackageStatus]
    $supplierId: Int
    $reachFinal: Boolean
  ) {
    packageList(
      claimDeliveryBy: $claimDeliveryBy
      offset: $offset
      limit: $limit
      claimPickupBy: $claimPickupBy
      status: $status
      supplierId: $supplierId
      reachFinal: $reachFinal
    ) {
      id
      supplier {
        id
        name
        logo
        contact
      }
      claimDeliveryBy {
        id
        name
        image
      }
      claimPickupBy {
        id
        name
        image
      }
      createdAt
      customerName
      customer {
        id
        name
        image
        phoneNumber
      }
    }
  }
`;

const CLAIM_PACKAGE = gql`
  mutation claimPackage($claimPackageId: Int!, $adminId: Int) {
    claimPackage(id: $claimPackageId, adminId: $adminId)
  }
`;

const CLAIM_DELIVERY = gql`
  mutation claimDeliveryPackage($claimDeliveryPackageId: Int!, $adminId: Int) {
    claimDeliveryPackage(id: $claimDeliveryPackageId, adminId: $adminId)
  }
`;

function ClaimPackageItem({
  item: x,
  selectedResources,
  status,
}: {
  item: Graph.Package;
  selectedResources: string[];
  status: string | null;
}) {
  return (
    <IndexTable.Row
      selected={selectedResources.map(x => String(x)).includes(String(x.id))}
      id={String(x.id)}
      key={x.id}
      position={x.id}
    >
      <IndexTable.Cell>
        <Link to={`/package/detail/${x.id}`} target={'_blink'}>
          <strong>{x.id.toString().padStart(8, '0')}</strong>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {x.supplier && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              shape="square"
              size="small"
              source={x.supplier?.logo || ''}
              name={x.supplier?.name?.charAt(0)}
              customer={false}
              initials={x.supplier?.name?.substring(0, 2).toUpperCase()}
            />
            <div className="ml-2">
              <small>
                <b>{x.supplier?.name}</b>
              </small>
              <br />
              <small>{x.supplier.contact}</small>
            </div>
          </div>
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {x.customer && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              shape="square"
              size="small"
              source={x.customer.image || ''}
              name={x.customerName?.charAt(0)}
              initials={x.customerName?.substring(0, 2).toUpperCase()}
            />
            <div className="ml-2">
              <small>
                <b>{x.customerName}</b>
              </small>
              <br />
              <small>{x.customer.phoneNumber}</small>
            </div>
          </div>
        )}
      </IndexTable.Cell>
      {status === 'REQUEST_PICKUP' ? (
        <IndexTable.Cell>
          {x.claimPickupBy && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                source={x.claimPickupBy.image || ''}
                shape="square"
                size="small"
                name={x.claimPickupBy?.name?.charAt(0)}
                initials={x.claimPickupBy.name?.substring(0, 2).toUpperCase()}
              />
              <div className="ml-2">
                <small>{x.claimPickupBy?.name}</small>
                <br />
                <small>{x.claimPickupAt}</small>
              </div>
            </div>
          )}
        </IndexTable.Cell>
      ) : (
        <IndexTable.Cell>
          {x.claimDeliveryBy && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                source={x.claimPickupBy?.image || ''}
                shape="square"
                size="small"
                name={x.claimDeliveryBy?.name?.charAt(0)}
                initials={x.claimDeliveryBy.name?.substring(0, 2).toUpperCase()}
              />
              <div className="ml-2">
                <small>{x.claimDeliveryBy?.name}</small>
                <br />
                <small>{x.claimDeliveryAt}</small>
              </div>
            </div>
          )}
        </IndexTable.Cell>
      )}
      <IndexTable.Cell>
        <div className="text-right">
          <small>{x.createdAt}</small>
        </div>
      </IndexTable.Cell>
    </IndexTable.Row>
  );
}

export function ClaimPackageScreen() {
  const PAGE_SIZE = 10;
  const [offset, setOffset] = useState(0);
  const { setToasts, toasts, onToast } = useCustomToast();
  const [supplierId, setSupplierId] = useState<number | null>(null);
  const [deliverId, setDeliverId] = useState<number | null>(null);
  const [status, setStatus] = useState<string | null>(null);
  const { data, loading, refetch } = useQuery<Graph.Query>(QUERY, {
    skip: !deliverId || !supplierId || !status,
    variables:
      status === 'ARRIVED'
        ? {
            offset: 0,
            limit: 100000,
            status: [status],
            supplierId,
            reachFinal: status === 'ARRIVED',
            claimDeliveryBy: deliverId,
          }
        : {
            offset: 0,
            limit: 100000,
            status: [status],
            supplierId,
            reachFinal: status === 'ARRIVED',
            claimPickupBy: deliverId,
          },
  });

  const [claimPackage] = useMutation(CLAIM_PACKAGE, {
    refetchQueries: ['packageList'],
    onError: err => {
      setToasts([...toasts, { content: err.message, status: 'error' }]);
    },
  });
  const [claimDeliveryPackage] = useMutation(CLAIM_DELIVERY, {
    refetchQueries: ['packageList'],
    onError: err => {
      setToasts([...toasts, { content: err.message, status: 'error' }]);
    },
  });

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
    removeSelectedResources,
  } = useIndexResourceState(
    (data?.packageList?.map(x => ({ ...x, id: String(x.id) })) as unknown[]) as { [key: string]: unknown }[],
  );

  const handleFilter = useCallback(() => {
    clearSelection();
    if (!deliverId || !supplierId || !status) {
      const t = [...toasts];
      if (!status) {
        onToast({ content: 'Please select status.', status: 'error' });
      }
      if (!supplierId) {
        onToast({ content: 'Please select seller.', status: 'error' });
      }
      if (!deliverId) {
        onToast({ content: 'Please select driver.', status: 'error' });
      }
      setToasts(t);
    } else {
      const variables: any = {
        offset: 0,
        limit: 100000,
        status: [status],
        supplierId,
        reachFinal: status === 'ARRIVED',
      };
      if (status === 'ARRIVED') {
        variables.claimDeliveryBy = deliverId;
      } else {
        variables.claimPickupBy = deliverId;
      }
      refetch(variables);
    }
  }, [deliverId, supplierId, status]);

  const handleClaimPickup = useCallback(() => {
    if (selectedResources.length > 0) {
      PolarisConfirm.dialog({
        title: 'Confirmation',
        body: [<div key={0}>You are unclaim request pickup {selectedResources.length} packages.</div>],
        buttons: [
          {
            title: 'Confirm',
            class: 'primary',
            onPress: async () => {
              for (const packageId of selectedResources) {
                const r = await claimPackage({
                  variables: {
                    claimPackageId: Number(packageId),
                    adminId: 0,
                  },
                });
                if (r.errors) {
                  onToast({
                    content: `${r.errors[0].message} #${String(packageId).padStart(8, '0')}`,
                    status: 'error',
                  });
                } else {
                  if (r.data.claimPackage) {
                    onToast({
                      content: `Unclaimed request pickup package #${String(packageId).padStart(8, '0')}`,
                      status: 'success',
                    });
                    removeSelectedResources([String(packageId)]);
                  } else {
                    onToast({
                      content: `Fail unclaimed request pickup package #${String(packageId).padStart(8, '0')}`,
                      status: 'error',
                    });
                  }
                }
              }
            },
          },
          {
            title: 'Cancel',
            class: 'danger',
          },
        ],
      });
    }
  }, [selectedResources, toasts, setToasts, PolarisConfirm, claimPackage]);

  const handleClaimDeliveryPickup = useCallback(() => {
    if (selectedResources.length > 0) {
      PolarisConfirm.dialog({
        title: 'Confirmation',
        body: [<div key={0}>You are unclaim delivery {selectedResources.length} packages.</div>],
        buttons: [
          {
            title: 'Confirm',
            class: 'primary',
            onPress: async () => {
              for (const packageId of selectedResources) {
                const r = await claimDeliveryPackage({
                  variables: {
                    claimDeliveryPackageId: Number(packageId),
                    adminId: 0,
                  },
                });
                if (r.errors) {
                  onToast({
                    content: `${r.errors[0].message} #${String(packageId).padStart(8, '0')}`,
                    status: 'error',
                  });
                } else {
                  if (r.data.claimDeliveryPackage) {
                    onToast({
                      content: `Unclaimed delivery package #${String(packageId).padStart(8, '0')}`,
                      status: 'success',
                    });
                    removeSelectedResources([String(packageId)]);
                  } else {
                    onToast({
                      content: `Fail unclaimed delivery package #${String(packageId).padStart(8, '0')}`,
                      status: 'error',
                    });
                  }
                }
              }
            },
          },
          {
            title: 'Cancel',
            class: 'danger',
          },
        ],
      });
    }
  }, [selectedResources, toasts, setToasts, PolarisConfirm, claimDeliveryPackage]);

  const promotedBulkActions = [];

  if (status === 'REQUEST_PICKUP') {
    promotedBulkActions.push({
      content: `Unclaim Pickup Package`,
      onAction: handleClaimPickup,
    });
  }

  if (status === 'ARRIVED') {
    promotedBulkActions.push({
      content: `Unclaim Delivery Package`,
      onAction: handleClaimDeliveryPickup,
    });
  }

  const EmptyStateTable = (
    <EmptyState
      heading="No package list"
      image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
    >
      <p>Try changing the filters.</p>
    </EmptyState>
  );

  const handleChangeStatus = async (checked: boolean, s: string) => {
    await setStatus(checked ? s : null);
    await clearSelection();
  };

  const totalRow = data?.packageList?.length || 0;
  const maxCount = Math.min(PAGE_SIZE + offset, totalRow);
  const currentTotalCount = totalRow < PAGE_SIZE ? totalRow : maxCount;

  return (
    <div>
      <Layout>
        <Layout.Section fullWidth>
          <div className={styles.CheckboxWrap}>
            <div
              className={styles.CheckboxWrapCheck}
              style={{ color: '#3E4B5B', backgroundColor: '#fff', border: '1px solid #555' }}
            >
              <div>
                <Checkbox
                  label="Request Pickup"
                  checked={status === 'REQUEST_PICKUP'}
                  onChange={e => {
                    handleChangeStatus(e.target.checked, 'REQUEST_PICKUP');
                  }}
                />
              </div>
            </div>
            <div
              className={styles.CheckboxWrapCheck}
              style={{ color: '#3E4B5B', backgroundColor: '#fff', border: '1px solid #555' }}
            >
              <div>
                <Checkbox
                  label="Delivery"
                  checked={status === 'ARRIVED'}
                  onChange={e => {
                    handleChangeStatus(e.target.checked, 'ARRIVED');
                  }}
                />
              </div>
            </div>
          </div>
        </Layout.Section>
        <Layout.Section oneHalf>
          <XForm.Division>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: 250 }} className="mr-3">
                <MerchantPicker label="Sellers" value={supplierId} onChange={setSupplierId} />
              </div>
              <div style={{ width: 250 }} className="mr-3">
                <DeliverPicker label="Driver" value={deliverId} onChange={setDeliverId} />
              </div>
              <div className="mt-1">
                <Button onClick={handleFilter}>Filter</Button>
              </div>
            </div>
          </XForm.Division>
        </Layout.Section>
        <Layout.Section oneHalf></Layout.Section>
        <Layout.Section>
          <Card>
            <Card.Section flush>
              {data && totalRow > 0 && (
                <PolarisPagination
                  type="table"
                  hasNext={currentTotalCount < totalRow}
                  hasPrevious={offset >= PAGE_SIZE}
                  label={`${offset + 1}-${currentTotalCount} of ${totalRow} packages`}
                  onNext={() => setOffset(offset + PAGE_SIZE)}
                  onPrevious={() => setOffset(offset - PAGE_SIZE)}
                />
              )}
            </Card.Section>
            <Card.Section flush>
              <IndexTable
                headings={[
                  { title: 'Package Id' },
                  { title: 'Supplier' },
                  { title: 'Customer' },
                  { title: 'Claim By' },
                  { title: (<div className="text-right">Created At</div>) as any },
                ]}
                selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
                onSelectionChange={handleSelectionChange}
                itemCount={data?.packageList?.length || 0}
                loading={loading}
                promotedBulkActions={promotedBulkActions}
                emptyState={EmptyStateTable}
                resourceName={{
                  plural: 'package',
                  singular: 'packages',
                }}
              >
                {data &&
                  data.packageList
                    ?.filter((_, i) => i >= offset && i < currentTotalCount)
                    .map(x => {
                      return (
                        <ClaimPackageItem key={x.id} item={x} selectedResources={selectedResources} status={status} />
                      );
                    })}
              </IndexTable>
            </Card.Section>
            <Card.Section flush>
              {data && totalRow > 0 && (
                <PolarisPagination
                  type="table"
                  hasNext={currentTotalCount < totalRow}
                  hasPrevious={offset >= PAGE_SIZE}
                  label={`${offset + 1}-${currentTotalCount} of ${totalRow} packages`}
                  onNext={() => setOffset(offset + PAGE_SIZE)}
                  onPrevious={() => setOffset(offset - PAGE_SIZE)}
                />
              )}
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </div>
  );
}
