import moment from 'moment';

export class DateTime {
  static today(): string {
    return moment().format('YYYY-MM-DD');
  }

  static now(): string {
    return moment().format('YYYY-MM-DD HH:mm:ss');
  }

  static diff(a: string, b: string) {
    return moment(a).diff(moment(b), 'day');
  }

  static forwardWithTime(n: number, unit: 'day' | 'hour' = 'day'): string {
    return moment()
      .add(n, unit)
      .format('YYYY-MM-DD HH:mm:ss');
  }

  static firstDayOfCurrentMonth(): string {
    return moment().format('YYYY-MM-01');
  }

  static yesterday(): string {
    return moment()
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
  }

  static tomorrow(): string {
    return moment()
      .add(1, 'day')
      .format('YYYY-MM-DD');
  }

  static lastMonth(): string {
    return moment()
      .subtract(30, 'day')
      .format('YYYY-MM-DD');
  }

  static elapsed(date: string): string {
    const duration = moment.duration(moment().diff(moment(date)));

    const minutes = duration.asMinutes() | 0;
    if (minutes < 60) return `${minutes} min${minutes > 1 ? 's' : ''}`;

    const hours = duration.asHours() | 0;
    if (hours < 24) return `${hours} hour${hours > 1 ? 's' : ''}`;

    const days = duration.asDays() | 0;
    return `${days} day${days > 1 ? 's' : ''}`;
  }

  static formatUnix(unix: number): string {
    return moment(unix * 1000).format('YYYY-MM-DD HH:mm');
  }

  static elapsedUnix(unix: number): string {
    const duration = moment.duration(moment().diff(moment(unix * 1000)));
    const later = duration.asSeconds() < 0 ? ' later' : '';

    const minutes = Math.abs(duration.asMinutes() | 0);
    if (minutes < 60) return `${minutes} min${minutes > 1 ? 's' : ''}${later}`;

    const hours = Math.abs(duration.asHours() | 0);
    if (hours < 24) return `${hours} hour${hours > 1 ? 's' : ''}${later}`;

    const days = Math.abs(duration.asDays() | 0);
    return `${days.toLocaleString()} day${days > 1 ? 's' : ''}${later}`;
  }

  static last(n: number, unit: 'day'): string {
    return moment()
      .subtract(n, unit)
      .format('YYYY-MM-DD');
  }

  static lastMonthPayrollRange() {
    const day = Number(moment().format('D'));

    if (day >= 26) {
      return [
        moment()
          .add(-1, 'month')
          .format('YYYY-MM-26'),
        moment().format('YYYY-MM-26'),
      ];
    }

    return [
      moment()
        .add(-2, 'month')
        .format('YYYY-MM-26'),
      moment()
        .add(-1, 'month')
        .format('YYYY-MM-26'),
    ];
  }

  static currentMonthPayrollRange() {
    const day = Number(moment().format('D'));

    if (day >= 26) {
      return [
        moment().format('YYYY-MM-26'),
        moment()
          .add(1, 'month')
          .format('YYYY-MM-26'),
      ];
    }

    return [
      moment()
        .add(-1, 'month')
        .format('YYYY-MM-26'),
      moment().format('YYYY-MM-26'),
    ];
  }
}
