import { ReactElement, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Layout } from '@shopify/polaris';
import styles from './style.module.scss';
import { Graph } from 'src/generated/graph';
import moment from 'moment';
import { BlockLoading } from 'src/components/loading/BlockLoading';
import {
  MUTATION_RANDOM_WINNER,
  MUTATION_SEND_CUSTOMER_NOTIFICATION_BY_REST,
  QUERY_LUCKYDRAW,
  QUERY_LUCKYDRAW_PARTICIPANT,
} from './query';
import { useMiniMenu } from 'src/components/layout/MenuContext';

const icons = [
  { src: '/icons/Confetti.png' },
  { src: '/icons/Gift.png' },
  { src: '/icons/Applause.png' },
  { src: '/icons/Party Balloon.png' },
  { src: '/icons/Circus Cannon.png' },
  { src: '/icons/Circus Cannon-reverse.png' },
  { src: '/icons/Party Balloon-reverse.png' },
  { src: '/icons/Applause-reverse.png' },
  { src: '/icons/Gift-reverse.png' },
  { src: '/icons/Confetti-reverse.png' },
];

interface CustomerInfo {
  id: number;
  phone: string;
}

interface Props {
  id: number;
  data: Graph.LuckyDraw | null;
  phonelists?: CustomerInfo[] | [];
}

function ProductInfo({ id, data }: Props) {
  const [DrawHeight] = useState(40);
  const [speed, setSpeed] = useState(5);
  const [spin, setSpin] = useState(false);
  const [position, setPosition] = useState(0);
  const [whereToStop, setWhereToStop] = useState(-1);
  const [winnerNumber, setWinnerNumber] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [phonelists, setPhoneLists] = useState<CustomerInfo[] | []>();

  const { loading } = useQuery<Graph.Query>(QUERY_LUCKYDRAW_PARTICIPANT, {
    variables: { id },
    onCompleted: r =>
      setPhoneLists(r?.luckyDrawParticipant?.flatMap(x => [{ id: x?.id, phone: '0' + x?.phoneNumber }] || [{}])),
  });

  const [random] = useMutation(MUTATION_RANDOM_WINNER, {
    onCompleted: r => {
      setWinnerNumber(phonelists?.find(x => x?.id === r?.randomLuckyDrawWinner)?.phone || '');
      setShowLoading(true);
    },
  });

  const [sendNotification, { loading: loadingSend }] = useMutation(MUTATION_SEND_CUSTOMER_NOTIFICATION_BY_REST, {
    variables: {
      customerIds: [Number(data?.winner?.id)],
      data: {
        screen: 'LuckyDrawDetail',
        payload: { luckyDrawId: { id } },
      },
      heading: 'Lucky draw winner!!!',
      notificationType: 'INFORMATION',
      title: `Congrats! Bong ${data?.winner?.name}, You have won our lucky draw: ${data?.name}. We will contact you later.`,
    },
    onCompleted: () => {
      window?.alert('Notification have sent');
    },
  });

  const onStartSpin = () => {
    setSpin(true);
    setPosition(0);
    setWhereToStop(-1);
    setWinnerNumber('');
    setSpeed(5);
  };

  const onWhereTotstop = () => {
    random({
      variables: { id },
    }).finally(() => setWhereToStop(Math.floor(position + 30)));
  };

  useEffect(() => {
    if (spin) {
      const timerId = setInterval(() => {
        setPosition(prev => {
          let newPosition = prev + 0.1;
          if (whereToStop > 0) {
            setSpeed(prev => prev + 0.05);
            if (newPosition > whereToStop) {
              newPosition = whereToStop;
              setSpin(false);
              setShowLoading(false);
            }
          }
          return newPosition;
        });
      }, speed);
      return () => clearInterval(timerId);
    }
  }, [spin, setPosition, speed, phonelists, whereToStop, setSpin]);

  useMemo(() => {
    if (!winnerNumber) {
      setWinnerNumber((data?.winner?.phoneNumber && '0' + data?.winner?.phoneNumber?.toString()) || '');
    }
  }, [data]);

  return (
    <div className={styles.thumbnail_info_wrap}>
      <div className={styles.thumbnail_info_wrap_inner}>
        <div className={styles.thumbnail}>
          <img alt="" src={data?.cover || ''} />
          <div className={styles.avatar}>
            <span>
              <img src={data?.thumbnail || ''} alt="" />
            </span>
          </div>
          <div className={styles.thumbnail_saving_point}>
            <strong>{data?.cost} saving point</strong>
          </div>
        </div>

        <h5 style={{ padding: '0.5rem 0' }}>{data?.name}</h5>
        <p>
          <small>Starts: {moment(data?.startedAt?.toString()).format('MMMM DD, YYYY')}</small>
          <small style={{ marginLeft: '20px' }}>
            Ends: {moment(data?.endedAt?.toString()).format('MMMM DD, YYYY')}
          </small>
        </p>
        <p style={{ padding: '0.5rem 0' }}>{data?.description}</p>

        <div className={styles.lucky_draw_wrap}>
          <p style={{ padding: '0.5rem 0' }}>Total participants: {data?.participants}</p>
          <div className={styles.lucky_draw_wrap_inner}>
            <div className={styles.lucky_draw}>
              <ul>
                {icons?.map((x, i) => (
                  <li key={i}>
                    <img src={x?.src} alt="" />
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.lucky_draw_random_area}>
              <div className={styles.lucky_draw_random_area_title}>
                <p>Winner is:</p>
              </div>
              <div className={styles.lucky_draw_random_area_input}>
                <ul
                  style={{
                    top: -DrawHeight * (position % (phonelists ? phonelists?.length : 0) || 0),
                    filter: spin ? 'blur(0.6px)' : 'blur(0)',
                  }}
                >
                  <li>{!loading ? (winnerNumber ? winnerNumber : 'XXXXXXXX') : 'Loading'}</li>
                  {phonelists?.map((x, i) => (
                    <li key={i}>{x?.phone}</li>
                  ))}
                </ul>{' '}
                {whereToStop > 0 && (
                  <p className={styles.winner} style={{ top: DrawHeight * (whereToStop - position) || 0 }}>
                    {winnerNumber}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.lucky_draw_footer}>
            <div className={styles.notification}>
              <button onClick={() => sendNotification()} disabled={loadingSend}>
                <img src="/icons/megaphone.png" alt="" width={25} />
                {/* <i className="fas fa-spinner fa-spin"></i> */}
              </button>
            </div>

            <div className={styles.btn_wrap}>
              {!spin ? (
                <button onClick={() => onStartSpin()}>DRAW</button>
              ) : (
                <button onClick={() => onWhereTotstop()}>STOP{showLoading ? 'ING' : ''}</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function LuckyDrawScreen(props: RouteComponentProps<{ id: string }>): ReactElement {
  useMiniMenu();
  const id = Number(props?.match?.params?.id);
  const { data, loading } = useQuery<Graph.Query>(QUERY_LUCKYDRAW, {
    variables: { id },
  });

  if (loading) {
    <BlockLoading />;
  }

  return (
    <Layout>
      <ProductInfo data={data?.luckyDraw || null} id={id} />
    </Layout>
  );
}
