import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  title?: string;
  body?: boolean;
  transparent?: boolean;
  width?: number;
  link?: string;
};

export function SectionBlock(props: Props): ReactElement {
  props = {
    transparent: false,
    body: true,
    ...props,
  };

  return (
    <div className="element-wrapper">
      {props.title && (
        <h6 className="element-header">{props.link ? <Link to={props.link}>{props.title}</Link> : props.title}</h6>
      )}
      <div
        style={{ maxWidth: props.width }}
        className={props.body ? (props.transparent ? 'element-box-tp' : 'element-box') : ''}
      >
        {props.children}
      </div>
    </div>
  );
}
