/* eslint-disable */
import React from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { gql, OperationVariables } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Query, QueryResult } from '@apollo/react-components';
import moment from 'moment';
import { useQueryString } from '../../libs/QueryString';

const QUERY_DELIVERY_PERSON = gql`
  query getDeliveryPersonList($start: String!, $end: String!) {
    deliveryPersonKPI(start: $start, end: $end)
  }
`;

interface GraphResponseKPI {
  id: number;
  name: string;
  accuracy: number;
  trips: {
    id: number;
    way: number;
    created_at: string;
    valid: 0 | 1;
  }[];
}

interface GraphResponse {
  deliveryPersonKPI: GraphResponseKPI[];
}

interface State {
  startDate: string;
  endDate: string;
}

export class DeliveryDashboardScreen extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);

    const { start, end } = useQueryString<{ start?: string; end?: string }>();

    this.state = {
      startDate: start
        ? start
        : moment()
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
      endDate: end ? end : moment().format('YYYY-MM-DD'),
    };
  }

  renderQuery = ({ data, loading }: QueryResult<GraphResponse>) => {
    if (loading) return <div>Loading...</div>;
    if (!data) return <div>Loading ...</div>;

    return (
      <table className="table table-lightborder">
        <thead>
          <tr>
            <th style={{ width: 250 }}>Name</th>
            <th></th>
            <th style={{ width: 150 }}>Accuracy</th>
          </tr>
        </thead>
        <tbody>
          {data.deliveryPersonKPI
            .sort((a, b) => b.accuracy - a.accuracy)
            .filter(x => x.trips.length > 0)
            .map(this.renderRow)}
        </tbody>
      </table>
    );
  };

  renderInput = () => {
    return (
      <form action="" method="GET" className="controls-above-table">
        <input
          type="text"
          name="start"
          placeholder="YYYY-MM-DD"
          className="lf-form-control"
          defaultValue={this.state.startDate}
          style={{ marginRight: 7, width: 150 }}
        />
        to &nbsp;
        <input
          type="text"
          name="end"
          placeholder="YYYY-MM-DD"
          className="lf-form-control"
          defaultValue={this.state.endDate}
          style={{ marginRight: 7, width: 150 }}
        />
        <button className="lf-btn btn-primary">Filter</button>
      </form>
    );
  };

  renderTripHeatMap = (x: GraphResponseKPI) => {
    return (
      <div>
        {x.trips.map(y => (
          <a
            key={y.id}
            href={`/report/delivery/tracker/${x.id}?date=${y.created_at.substr(0, 10)}#${y.way}`}
            style={{
              width: 10,
              height: 10,
              background: y.valid === 1 ? '#27ae60' : '#e74c3c',
              display: 'inline-block',
              margin: 2,
            }}
            title={`${y.created_at.substr(0, 10)} (#${y.way})`}
          >
            &nbsp;
          </a>
        ))}
      </div>
    );
  };

  renderRow = (x: GraphResponseKPI) => {
    return (
      <tr key={x.id}>
        <td>
          <Link to={`/report/delivery/tracker/${x.id}`}>{x.name}</Link>
        </td>
        <td>{this.renderTripHeatMap(x)}</td>
        <td>
          <strong className="h5">{Math.round(x.accuracy * 100)}%</strong>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <Content>
        <SectionBlock title={'Delivery'}>
          {this.renderInput()}
          <Query
            query={QUERY_DELIVERY_PERSON}
            variables={({ start: this.state.startDate, end: this.state.endDate } as unknown) as OperationVariables}
          >
            {this.renderQuery}
          </Query>
        </SectionBlock>
      </Content>
    );
  }
}
