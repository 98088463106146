import { AdminGroupListScreen } from '../screens/AdminGroup/AdminGroupListScreen';
import { AdminGroupFormScreen } from '../screens/AdminGroup/AdminGroupFormScreen';

export const AdminGroupRoutes = [
  { path: '/admingroup/list', exact: true, component: AdminGroupListScreen },
  { path: '/admingroup/create', exact: true, component: AdminGroupFormScreen },
  { path: '/admingroup/edit/:id', exact: true, component: AdminGroupFormScreen },
];

/*
renderAdminGroupRouter() {
  return [
    <Route key="admin_group_list" path="/admingroup/list" exact component={withTracker(AdminGroupListScreen)}/>,
    <Route key="admin_group_create" path="/admingroup/create" exact render={
      (props) => <AdminGroupFormScreen {...props}/>
    }/>,
    <Route key="admin_group_edit" path="/admingroup/edit/:id" exact render={
      (props) => <AdminGroupFormScreen {...props}/>
    }/>
  ]
}
*/
