/* eslint-disable */
import React, { ReactNode, ReactElement } from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { googleMapStyle } from '../../MapStyle';
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { gql } from '@apollo/client';
import { Query } from '@apollo/react-components';
import { DateTime } from '../../libs/DateTime';
import queryString from 'query-string';
import { Setting } from '../../libs/Setting';
import { Graph } from '../../generated/graph';

const QUERY_ROUTES = gql`
  query deliveryTripList($id: Int!, $date: String!) {
    deliveryTripList(deliveryID: $id, date: $date) {
      id
      scanOutAt
      routes {
        id
        lat
        lng
        deliveredAt
        customerID
      }
    }
  }
`;

const colorList = ['#34495e', '#f1c40f', '#e67e22', '#9b59b6', '#2ecc71', '#1abc9c', '#30336b', '#22a6b3', '#535c68'];

function dateFormating(ms: number): string {
  if (ms < 60000) {
    return ((ms / 1000) | 0) + 's';
  } else if (ms < 60000 * 60) {
    return Math.floor(ms / 60000) + 'm';
  } else {
    const hour = Math.floor(ms / (60000 * 60));
    const rmin = ((ms - hour * (60000 * 60)) / 60000) | 0;

    return hour + 'h ' + rmin.toString().padStart(2, '0') + 'm';
  }
}

interface Props {
  match: {
    params: {
      id: number;
    };
  };
  history: any;
  location: {
    search: any;
  };
}

export class DeliveryTrackerScreen extends React.Component<Props> {
  state: {
    trips?: Graph.DeliveryTrip[];
    date: string;
    tripId?: number;
    zoom: number;
    center: {
      lat: number;
      lng: number;
    };
  };

  inputDateRef = React.createRef<HTMLInputElement>();
  mapRef = React.createRef<GoogleMap>();

  constructor(props: Props) {
    super(props);
    const queryUrl = queryString.parse(this.props.location.search);

    this.state = {
      date: queryUrl.date ? (queryUrl.date as string) : DateTime.yesterday(),
      tripId: window.location.hash !== '' ? Number(window.location.hash.substr(1)) : undefined,
      zoom: 12.75,
      center: {
        lat: 11.562108,
        lng: 104.888535,
      },
    };
  }

  renderMap = (): ReactNode => {
    if (!this.state.trips) return <div></div>;

    const fn = (trip: Graph.DeliveryTrip): boolean => {
      if (!this.state.tripId) return true;
      if (this.state.tripId === trip.id) return true;
      return false;
    };

    return (
      <LoadScript id="script-loader" googleMapsApiKey={Setting.googleMapKey}>
        <GoogleMap
          ref={this.mapRef}
          id="script-loader"
          mapContainerStyle={{
            height: 500,
            width: '100%',
          }}
          zoom={this.state.zoom}
          onZoomChanged={(): void => {
            if (this.mapRef.current?.state.map) {
              this.setState({ zoom: this.mapRef.current.state.map.getZoom() });
            }
          }}
          onCenterChanged={(): void => {
            if (this.mapRef.current?.state.map) {
              this.setState({ center: this.mapRef.current.state.map.getCenter() });
            }
          }}
          center={this.state.center}
          options={{
            styles: googleMapStyle,
          }}
        >
          {this.state.trips.filter(fn).map(this.renderTripRoute)}
          {this.state.trips
            .filter(fn)
            .map((x: Graph.DeliveryTrip, idx: number) => this.renderTripLines(x, colorList[idx]))}
        </GoogleMap>
      </LoadScript>
    );
  };

  renderTripRoute = (trip: Graph.DeliveryTrip): ReactNode[] => {
    const fn = (x: Graph.DeliveryTripRoute, idx: number): ReactNode => (
      <Marker key={idx} label={(idx + 1).toString()} position={{ lat: Number(x.lat), lng: Number(x.lng) }} />
    );

    return (trip.routes || []).filter(x => x.lat !== null).map(fn);
  };

  renderTripLines = (trip: Graph.DeliveryTrip, color: string): ReactNode => {
    return (
      <Polyline
        key={'poly_' + trip.id}
        options={{ strokeColor: color }}
        path={(trip.routes || [])
          .filter(x => x.lat !== null)
          .map((x: Graph.DeliveryTripRoute) => ({ lat: Number(x.lat), lng: Number(x.lng) }))}
      />
    );
  };

  renderTripDetail = (trip: Graph.DeliveryTrip): ReactNode => {
    if (!trip.routes) return undefined;
    if (trip.id !== this.state.tripId) return undefined;

    const routes = trip.routes;

    return (
      <div key={trip.id} style={{ marginTop: -20, marginBottom: 30 }}>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th colSpan={4} style={{ fontSize: 18 }}>
                <strong>Trip #{trip.id}</strong>
                <br />
                Scanout: {trip.scanOutAt}
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Time</th>
              <th>Note</th>
              <th>Customer</th>
            </tr>
          </thead>
          <tbody>
            {routes.map((x: Graph.DeliveryTripRoute, idx: number) => {
              const prevTrip = routes[idx - 1];
              const prevDeliveryAt = idx > 0 && prevTrip.deliveredAt ? prevTrip.deliveredAt.toString() : '';
              const currentDeliveryAt = x.deliveredAt ? x.deliveredAt : '';
              const scanOutAt = trip.scanOutAt ? trip.scanOutAt : '';
              const prev = new Date(idx === 0 ? scanOutAt : prevDeliveryAt);
              const current = new Date(x.deliveredAt ? x.deliveredAt : '');
              const diff = current.getTime() - prev.getTime();

              return (
                <tr key={x.id}>
                  <td style={{ width: 95 }}>
                    <strong>{dateFormating(diff)}</strong>
                  </td>
                  <td style={{ width: 75 }}>{currentDeliveryAt.split(' ')[1]}</td>
                  <td>#{x.id}</td>
                  <td>#{x.customerID}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  renderFilter = (): ReactNode => {
    return (
      <div className="controls-above-table">
        <form
          onSubmit={(): void => {
            if (this.inputDateRef.current) {
              this.props.history.push(`?date=${this.inputDateRef.current.value}`);
            }
          }}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div style={{ width: 200 }} className="mr-1">
            <input
              ref={this.inputDateRef}
              name="date"
              placeholder="YYYY-MM-DD"
              defaultValue={this.state.date}
              className="lf-form-control"
              type="text"
            />
          </div>
          <div>
            <button className="lf-btn btn-primary">Filter</button>
          </div>
        </form>
      </div>
    );
  };

  renderTab = (trip: Graph.DeliveryTrip, idx: number): ReactNode => {
    return (
      <div
        key={trip.id}
        className={this.state.tripId === trip.id ? 'step-trigger active complete' : 'step-trigger'}
        onClick={(): void => this.setState({ tripId: trip.id })}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              height: 10,
              width: 10,
              background: colorList[idx],
              borderRadius: '50%',
              marginRight: 10,
            }}
          ></span>
          <span>#{trip.id}</span>
        </div>
      </div>
    );
  };

  renderTripList = (): ReactNode => {
    if (!this.state.trips || this.state.tripId) return undefined;

    return (
      <table className="table table-striped table-bordered" style={{ marginTop: -20, marginBottom: 30 }}>
        <thead>
          <tr>
            <th>Trip</th>
            <th style={{ width: 50 }}>Points</th>
          </tr>
        </thead>
        <tbody>
          {this.state.trips.map((trip: Graph.DeliveryTrip) => (
            <tr key={trip.id}>
              <td>Trip #{trip.id}</td>
              <td>{(trip.routes || []).length}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderQuery = (): ReactNode => {
    if (!this.state.trips) return <div>Loading</div>;

    return (
      <SectionBlock title="Tracking">
        <div>{this.renderFilter()}</div>

        <div className="row">
          <div className="col-6">{this.renderMap()}</div>
          <div className="col-6">
            <div className="step-triggers">
              <div
                className={this.state.tripId ? 'step-trigger' : 'step-trigger active complete'}
                onClick={(): void => this.setState({ tripId: undefined })}
              >
                All Trips
              </div>
              {this.state.trips.map(this.renderTab)}
            </div>
            {this.renderTripList()}
            {this.state.trips.map((x: Graph.DeliveryTrip) => this.renderTripDetail(x))}
          </div>
        </div>
      </SectionBlock>
    );
  };

  render(): ReactElement {
    return (
      <Content>
        <Query
          query={QUERY_ROUTES}
          variables={{ id: Number(this.props.match.params.id), date: this.state.date }}
          onCompleted={(data: { deliveryTripList: any }): void => this.setState({ trips: data.deliveryTripList })}
        >
          {(): ReactElement => <div></div>}
        </Query>
        {this.renderQuery()}
      </Content>
    );
  }
}
