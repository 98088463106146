import React from 'react';
import { AppPageMarkup, AppMarkupButton } from '../../../types/common';
import { NavigationBuilder } from '../../../components/NavigationBuilder';
import { UploadImage } from '../../../components/UploadImage';
import { AppPageTabEditor } from './AppPageTabEditor';
import { Checkbox } from '../../../components/form/FormComponent';

interface Props {
  value: AppPageMarkup;
  onChange: (v: AppPageMarkup) => void;
}

function AppPageMarkupButton(value: AppMarkupButton, change: (v: AppMarkupButton) => void) {
  return (
    <>
      <div className="form-group">
        <label>Title</label>
        <input
          value={value.text || ''}
          className="lf-form-control"
          placeholder="Please write block title"
          onChange={e => {
            change({ ...value, text: e.target.value } as AppMarkupButton);
          }}
        />
      </div>
      <div className="form-group">
        <UploadImage
          value={value?.image}
          width={50}
          height={50}
          onChange={v => {
            change({ ...value, image: v } as AppMarkupButton);
          }}
        />
      </div>
      <NavigationBuilder
        value={value.navigate}
        onChange={v => {
          change({ ...value, navigate: v } as AppMarkupButton);
        }}
      />
    </>
  );
}

export function AppPageMarkupButtonList(props: Props) {
  return (
    <div>
      <div className="form-group">
        <label>Title</label>
        <input
          value={props.value?.title || ''}
          className="lf-form-control"
          placeholder="Please write block title"
          onChange={e => {
            props.onChange({ ...props.value, title: e.target.value } as AppPageMarkup);
          }}
        />
      </div>

      <div className="form-group">
        <Checkbox
          label="Random Shuffle"
          checked={props.value.shuffle}
          onChange={e => props.onChange({ ...props.value, shuffle: e.target.checked } as AppPageMarkup)}
        />
      </div>

      <div className="form-group">
        <label>Size</label>
        <br />
        <input
          value={props.value?.width || '0'}
          style={{ width: 50 }}
          className="lf-inline lf-form-control mr-1"
          placeholder="Width"
          onChange={e => {
            props.onChange({ ...props.value, width: Number(e.target.value) } as AppPageMarkup);
          }}
        />
        &nbsp; x &nbsp;
        <input
          value={props.value?.height || '0'}
          style={{ width: 50 }}
          className="lf-inline lf-form-control"
          placeholder="Height"
          onChange={e => {
            props.onChange({ ...props.value, height: Number(e.target.value) } as AppPageMarkup);
          }}
        />
      </div>

      <AppPageTabEditor
        value={props.value.buttons || []}
        onChange={v => {
          if (props.onChange) {
            props.onChange({ ...props.value, buttons: v });
          }
        }}
        titleExtract={v => v.text || 'Untitled'}
        defaultNewItemValue={() => ({
          navigate: {},
          image: '',
          text: 'Untitled',
        })}
        render={AppPageMarkupButton}
      />
    </div>
  );
}
