import React, { useState, ReactElement } from 'react';
import { useQuery, gql } from '@apollo/client';

interface GraphReportSaleByCategoryItem {
  id: number;
  name: string;
  uniqueCustomer: number;
  qty: number;
  total: number;
  totalApp: number;
  margin: number;
}

interface QueryCompareItem {
  current: GraphReportSaleByCategoryItem;
  compared?: GraphReportSaleByCategoryItem;
}

interface GraphQueryResult {
  reportSaleByCategory: GraphReportSaleByCategoryItem[];
}

const QUERY = gql`
  query ReportSalesBreakdownByCategory($category: Int!, $start: String!, $end: String!) {
    reportSaleByCategory(categoryID: $category, start: $start, end: $end)
  }
`;

function useSalesQuery({
  skip,
  categoryId,
  start,
  end,
  compareStart,
  compareEnd,
}: {
  skip: boolean;
  categoryId: number;
  start: string;
  end: string;
  compareStart: string;
  compareEnd: string;
}): { loading: boolean; data: QueryCompareItem[] | undefined } {
  const { loading: currentLoading, data: currentData } = useQuery<GraphQueryResult>(QUERY, {
    variables: { category: categoryId, start, end },
    skip: skip,
  });

  const { loading: compareLoading, data: compareData } = useQuery<GraphQueryResult>(QUERY, {
    variables: { category: categoryId, start: compareStart, end: compareEnd },
    skip: skip,
  });

  // Merging data
  let result: QueryCompareItem[] | undefined = undefined;
  if (currentData && compareData) {
    result = currentData.reportSaleByCategory.map(
      (x): QueryCompareItem => {
        return {
          current: x,
          compared: compareData.reportSaleByCategory.find(y => y.id === x.id),
        };
      },
    );
  }

  return { loading: currentLoading || compareLoading, data: result };
}

function renderGrowth(current: number, compare?: number): ReactElement {
  const growth = compare && compare > 0 ? (current / compare - 1) * 100 : 0;

  if (growth > 0) {
    return (
      <div style={{ color: '#27ae60' }}>
        <small>{growth.toLocaleString()}%</small>
      </div>
    );
  } else {
    return (
      <div style={{ color: '#e74c3c' }}>
        <small>{Math.abs(growth).toLocaleString()}%</small>
      </div>
    );
  }
}

function ReportSalesByCategoryItem(props: {
  category?: QueryCompareItem;
  defaultCollapsed: boolean;
  depth: number;
  start: string;
  end: string;
  comparedStart: string;
  comparedEnd: string;
}): React.ReactElement {
  const categoryId = props.category === undefined ? 0 : props.category.current.id;
  const [collapsed, setCollapsed] = useState(props.defaultCollapsed);
  const [firstLoading, setFirstLoading] = useState(props.defaultCollapsed);
  const { loading, data } = useSalesQuery({
    categoryId: categoryId,
    start: props.start,
    end: props.end,
    skip: !firstLoading,
    compareEnd: props.comparedEnd,
    compareStart: props.comparedStart,
  });

  const depth = (
    <span style={{ background: '#f1f1f1', height: 20, display: 'inline-block', width: 50 * (props.depth - 1) }}>
      &nbsp;
    </span>
  );

  const collapsedStyle = collapsed ? { boxShadow: '0px 2px 4px rgba(126, 142, 177, 0.5)', background: '#f1f1f1' } : {};

  const loadingElement = firstLoading ? (
    <>
      <tr key={categoryId}>
        <td colSpan={6}>
          {depth}
          {depth}
          <span>Loading...</span>
        </td>
      </tr>
    </>
  ) : (
    <></>
  );

  const dataElement =
    data && collapsed ? (
      data
        .sort((a, b) => b.current.total - a.current.total)
        .map(item => (
          <ReportSalesByCategoryItem
            key={item.current.id}
            category={item}
            defaultCollapsed={false}
            depth={props.depth + 1}
            start={props.start}
            end={props.end}
            comparedStart={props.comparedStart}
            comparedEnd={props.comparedEnd}
          />
        ))
    ) : (
      <></>
    );

  return (
    <>
      {props.category !== undefined && (
        <tr>
          <td style={collapsedStyle}>
            {depth}
            <button
              className="btn btn-link mr-1"
              onClick={(): void => {
                setFirstLoading(true);
                setCollapsed(!collapsed);
              }}
            >
              <i className="fas fa-arrow-circle-down"></i>
            </button>
            <span>{props.category.current.name}</span>
          </td>
          <td style={collapsedStyle} className="text-right">
            <div>{props.category.current.total.toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
            <div className="text-muted">
              {props.category.compared?.total.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </div>
            <div>{renderGrowth(props.category.current.total, props.category.compared?.total)}</div>
          </td>
          <td style={collapsedStyle} className="text-right">
            <div>{props.category.current.totalApp.toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
            <div className="text-muted">
              {props.category.compared?.totalApp.toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </div>
            <div>{renderGrowth(props.category.current.totalApp, props.category.compared?.totalApp)}</div>
          </td>
          <td style={collapsedStyle} className="text-right">
            <div>{props.category.current.qty.toLocaleString()}</div>
            <div className="text-muted">{props.category.compared?.qty.toLocaleString()}</div>
            <div>{renderGrowth(props.category.current.qty, props.category.compared?.qty)}</div>
          </td>
          <td style={collapsedStyle} className="text-right">
            <div>{props.category.current.uniqueCustomer.toLocaleString()}</div>
            <div className="text-muted">{props.category.compared?.uniqueCustomer.toLocaleString()}</div>
            <div>{renderGrowth(props.category.current.uniqueCustomer, props.category.compared?.uniqueCustomer)}</div>
          </td>
          <td style={collapsedStyle} className="text-right">
            <div>
              {(props.category.current.margin * 100).toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
              %
            </div>
            {props.category.compared && (
              <div className="text-muted">
                {(props.category.compared?.margin * 100).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
                %
              </div>
            )}
          </td>
        </tr>
      )}
      {data === undefined || loading ? loadingElement : dataElement}
    </>
  );
}

export function ReportSalesByCategoryList(props: {
  start: string;
  end: string;
  comparedStart: string;
  comparedEnd: string;
}): React.ReactElement {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th style={{ width: 100 }} className="text-right">
            Sales ($)
          </th>
          <th style={{ width: 100 }} className="text-right">
            App Sales ($)
          </th>
          <th style={{ width: 100 }} className="text-right">
            Qty
          </th>
          <th style={{ width: 100 }} className="text-right">
            Unique Customer
          </th>
          <th style={{ width: 70 }} className="text-right">
            Margin
          </th>
        </tr>
      </thead>
      <tbody>
        <ReportSalesByCategoryItem
          defaultCollapsed={true}
          depth={0}
          start={props.start}
          end={props.end}
          comparedStart={props.comparedStart}
          comparedEnd={props.comparedEnd}
        />
      </tbody>
    </table>
  );
}
