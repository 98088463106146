/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from '@apollo/client';
import { ChoiceList, Divider } from '@shopify/polaris';
import React, { useState } from 'react';
import { PolarisTextField } from 'src/components/polaris/PolarisTextField';
import { Graph } from 'src/generated/graph';

const QUERY = gql`
  query brandList($merchantId: Int, $verified: Boolean, $status: BrandStatus, $name: String, $limit: Int!) {
    brandList(merchantId: $merchantId, verified: $verified, status: $status, name: $name, limit: $limit) {
      id
      alias
      name
    }
  }
`;

interface Props {
  selected: any[];
  setSelected: (v: any[]) => void;
  supplierId?: number;
}

export function FilterBrand(props: Props) {
  const [brandInput, setBrandInput] = useState('');
  const [brandSearch, setBrandSearch] = useState('');

  const { data, loading } = useQuery<Graph.Query>(QUERY, {
    variables: {
      merchantId: props.supplierId ? Number(props.supplierId) : undefined,
      verified: undefined,
      status: 'APPROVED',
      name: brandSearch ? brandSearch : undefined,
      limit: 10,
    },
  });

  const brands = data
    ? data.brandList?.map(brand => {
        return {
          label: <small>{brand.name}</small>,
          value: brand.id,
        };
      })
    : [];

  return (
    <React.Fragment>
      <div
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setBrandSearch(brandInput);
          }
        }}
      >
        <PolarisTextField
          value={brandInput}
          onChange={v => {
            setBrandInput(v);
            if (v === '') {
              setBrandSearch('');
            }
          }}
          height={'25x'}
          placeholder="Search brand name"
          autoComplete="off"
          label
          labelHidden
          disabled={loading}
          readOnly={loading}
        />
      </div>
      <Divider />
      <ChoiceList
        allowMultiple={true}
        choices={brands as any}
        selected={props.selected}
        onChange={props.setSelected}
        title
        titleHidden
      />
    </React.Fragment>
  );
}
