import React from 'react';
import { CreateBarcodeScreen } from '../screens/Inventory/CreateBarcodeScreen';
import { SKUScreen } from '../screens/Inventory/SKUScreen';
import { PurchaseOrderLogScreen } from '../screens/PurchaseOrder/PurchaseOrderLogScreen';
import { ProductListScreen } from '../screens/Inventory/ProductListScreen';
import { EditInventoryScreen } from '../screens/Inventory/EditInventoryScreen';
import { StockTransationListScreen } from '../screens/Stock/StockTransationListScreen';
import { RouteComponentProps } from 'react-router';
import { InventoryMovementScreen } from '../screens/InventoryMovementScreen';
import { useApolloClient } from '@apollo/client';
import { ProductListPolarisScreen } from 'src/screens/Inventory/ProductListPolarisScreen';

function createInventoryScreen(type: 'STOCK_IN_FBL' | 'STOCK_IN' | 'RECYCLE' | 'TRANSFER' | 'ADJUST' | 'FIXED') {
  return function HOC(props: RouteComponentProps) {
    const client = useApolloClient();
    return <InventoryMovementScreen {...props} apollo={client} type={type} />;
  };
}

export const InventoryRoutes = [
  { path: '/barcode/create', exact: true, component: CreateBarcodeScreen },
  { path: '/sku/:id', exact: true, component: SKUScreen },
  { path: '/purchase/logs', exact: true, component: PurchaseOrderLogScreen },
  { path: '/product/search', exact: true, component: ProductListScreen },
  { path: '/productv2/search', exact: true, component: ProductListPolarisScreen },
  { path: '/inventory/create', exact: true, component: EditInventoryScreen },
  { path: '/inventory/edit/:id', exact: true, component: EditInventoryScreen },
  { path: '/inventory/stock_transation', exact: true, component: StockTransationListScreen },
  { path: '/inventory/stockin', exact: true, component: createInventoryScreen('STOCK_IN') },
  { path: '/inventory/stockin_fbl', exact: true, component: createInventoryScreen('STOCK_IN_FBL') },
  { path: '/inventory/transfer', exact: true, component: createInventoryScreen('TRANSFER') },
  { path: '/inventory/recycle', exact: true, component: createInventoryScreen('RECYCLE') },
  { path: '/inventory/adjust', exact: true, component: createInventoryScreen('ADJUST') },
  { path: '/inventory/fixed', exact: true, component: createInventoryScreen('FIXED') },
];
