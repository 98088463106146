import { GMVScreen } from '../screens/Report/GMVScreen';
import { StockKPIReportScreen } from '../screens/Report/StockKPIReportScreen';
import { DeliveryTrackerScreen } from '../screens/Report/DeliveryTrackerScreen';
import { PipelineScreen } from '../screens/Report/PipelineScreen';
import { SaleReportScreen } from '../screens/Report/SaleReportScreen';
import { DeliveryDashboardScreen } from '../screens/Report/DeliveryDashboardScreen';
import { RecommendRestockScreen } from '../screens/Report/RecommendRestockScreen';
import { ReportSalesByCategoryScreen } from '../screens/Report/ReportSalesByCategoryScreen';
import { ReportSearchScreen } from '../screens/Report/ReportSearchScreen';
import { ReportLiveProductListScreen } from '../screens/Report/ReportLiveProductListScreen';
import { MerchantTotalSalesScreen } from '../screens/Report/MerchantTotalSalesScreen';
import { KPIOfflineReportScreen } from 'src/screens/Report/KPIOfflineReportScreen';

export const ReportRoutes = [
  { path: '/report/gmv', exact: true, component: GMVScreen },
  { path: '/report/stockkpi', exact: true, component: StockKPIReportScreen },
  { path: '/report/delivery/tracker/:id', exact: true, component: DeliveryTrackerScreen },
  { path: '/report/delivery/dashboard', exact: true, component: DeliveryDashboardScreen },
  { path: '/report/pipeline', exact: true, component: PipelineScreen },
  { path: '/report/sale', exact: true, component: SaleReportScreen },
  { path: '/report/recommend/restock', exact: true, component: RecommendRestockScreen },
  { path: '/report/sales/category', exact: true, component: ReportSalesByCategoryScreen },
  { path: '/report/search', exact: true, component: ReportSearchScreen },
  { path: '/report/product/live', exact: true, component: ReportLiveProductListScreen },
  { path: '/report/merchants/sales', exact: true, component: MerchantTotalSalesScreen },
  { path: '/report/kpi/offline', exact: true, component: KPIOfflineReportScreen },
];
