import React from 'react';
import { Select } from '@shopify/polaris';

interface Props extends React.PropsWithChildren<React.HTMLProps<HTMLSelectElement>> {
  label?: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (x: any) => void;
  items?: { text: string; value: string }[];
}

export default function FormSelectInput(props: Props) {
  const options = (props.items || []).map(item => ({ label: item?.text, value: item?.value }));
  return <Select label={props.label} options={options} onChange={props?.onChange} value={props?.value?.toString()} />;
}
