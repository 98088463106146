import React from 'react';
import { AppNavigation, AppNavigationPayload } from '../types/common';
import { CategoryPicker } from './pickers/CategoryPicker';
import { CustomPagePicker } from './pickers/CustomPagePicker';
import { MerchantPicker } from './pickers/MerchantPicker';
import { LuckyDrawPicker } from './pickers/LuckyDrawPicker';
import { BrandPicker } from './pickers/BrandPicker';

interface Props {
  value?: AppNavigation;
  onChange?: (v: AppNavigation | undefined) => void;
}

interface PayloadProps {
  value?: AppNavigationPayload;
  onChange: (v: AppNavigationPayload) => void;
}

function ProductBuilder(props: PayloadProps) {
  return (
    <div className="form-group">
      <label>Product ID</label>
      <input
        type="text"
        className="lf-form-control"
        value={props.value?.id}
        onChange={e => {
          props.onChange({ id: Number(e.target.value) });
        }}
      />
    </div>
  );
}

function MerchantBuilder(props: PayloadProps) {
  return (
    <div className="form-group">
      <label>Store</label>
      <MerchantPicker
        value={props.value?.id || 0}
        onChange={v => {
          props.onChange({ id: v ? v : undefined });
        }}
      />
    </div>
  );
}

function CustomPageBuilder(props: PayloadProps) {
  return (
    <div className="form-group">
      <label>Custom Page</label>
      <CustomPagePicker
        value={props.value?.id || 0}
        onChanged={v => {
          props.onChange({ id: v });
        }}
        placeholder="Please select custom page"
      />
    </div>
  );
}

function LuckyDrawBuilder(props: PayloadProps) {
  return (
    <div className="form-group">
      <label>Lucky Draw</label>
      <LuckyDrawPicker
        value={props.value?.id || 0}
        onChanged={v => {
          props.onChange({ id: v });
        }}
        placeholder="Please select lucky draw"
      />
    </div>
  );
}

function CategoryBuilder(props: PayloadProps) {
  return (
    <div className="form-group">
      <label>Category</label>
      <CategoryPicker
        value={props.value?.filter?.category?.id || 0}
        onChange={v => {
          props.onChange({ filter: { category: { id: v } } });
        }}
      />
    </div>
  );
}

function BrandBuilder(props: PayloadProps) {
  return (
    <div className="form-group">
      <label>Brand</label>
      <BrandPicker
        value={props.value?.filter?.brand?.id || 0}
        onChange={v => {
          props.onChange({ filter: { brand: { id: v } } });
        }}
      />
    </div>
  );
}

function payloadScreenMapping(nav: AppNavigation): string {
  if (nav.screen === 'Search') {
    if (nav.payload?.filter?.category) {
      return 'Category';
    } else if (nav.payload?.filter?.brand) {
      return 'Brand';
    }
  }
  return nav.screen || '';
}

export function NavigationBuilder(props: Props) {
  const value: AppNavigation = props.value || { screen: '' };

  const onScreenChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const screen = e.target.value;
    if (props.onChange) {
      if (screen === 'None') {
        props.onChange(undefined);
      } else if (screen === 'Home') {
        props.onChange({});
      } else if (screen === 'Category') {
        props.onChange({ screen: 'Search', payload: { filter: { category: { id: 0 } } } });
      } else if (screen === 'Brand') {
        props.onChange({ screen: 'Search', payload: { filter: { brand: { id: 0 } } } });
      } else {
        props.onChange({ screen });
      }
    }
  };

  const onPayloadChange = (v: AppNavigationPayload) => {
    if (props.onChange) {
      props.onChange({ ...value, payload: v });
    }
  };

  const screen = payloadScreenMapping(value);

  return (
    <div>
      <div className="form-group">
        <label>Screen</label>
        <select className="lf-form-control" value={screen} onChange={onScreenChange}>
          <option>None</option>
          <option value="Home">Frontpage</option>
          <option value="Cart">Cart</option>
          <option value="Category">Category</option>
          <option value="Brand">Brand</option>
          <option value="StoreDetail">Store</option>
          <option value="Page">Custom Page</option>
          <option value="ProductDetail">Product</option>
          <option value="LuckyDrawList">Lucky Draw List</option>
          <option value="LuckyDrawDetail">Lucky Draw</option>
        </select>
      </div>

      {screen === 'Category' && <CategoryBuilder value={props.value?.payload} onChange={onPayloadChange} />}
      {screen === 'Brand' && <BrandBuilder value={props.value?.payload} onChange={onPayloadChange} />}
      {screen === 'Page' && <CustomPageBuilder value={props.value?.payload} onChange={onPayloadChange} />}
      {screen === 'StoreDetail' && <MerchantBuilder value={props.value?.payload} onChange={onPayloadChange} />}
      {screen === 'LuckyDrawDetail' && <LuckyDrawBuilder value={props.value?.payload} onChange={onPayloadChange} />}
      {screen === 'ProductDetail' && <ProductBuilder value={props.value?.payload} onChange={onPayloadChange} />}
    </div>
  );
}
