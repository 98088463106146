import React, { useState } from 'react';
import { UploadFileBase } from './UploadFileBase';

interface Props {
  value?: string;
  onChange?: (v: string) => void;
  width: number;
  height: number;
}

export function UploadImage(props: Props) {
  const [uploading, setUploading] = useState(false);

  let display = <div style={{ height: props.height, width: props.width }}></div>;

  if (uploading) {
    display = (
      <div style={{ height: props.height, width: props.width, lineHeight: '50px', textAlign: 'center' }}>
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    );
  } else if (props.value) {
    display = (
      <div>
        <img src={props.value} style={{ width: props.width, height: props.height }} alt="" />
      </div>
    );
  }

  return (
    <div style={{ background: '#f1f1f1', width: props.width, height: props.height }}>
      <UploadFileBase
        value={props.value}
        onStart={() => setUploading(true)}
        onChange={v => {
          if (props.onChange) props.onChange(v);
          setUploading(false);
        }}
      >
        {display}
      </UploadFileBase>
    </div>
  );
}
