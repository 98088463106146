import React, { useRef } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Graph } from '../generated/graph';

const UPLOAD_FILE = gql`
  mutation Upload($file: Upload!) {
    upload(file: $file) {
      filename
      url
    }
  }
`;

interface Props {
  value?: string;
  style?: React.CSSProperties;
  onChange?: (url: string) => void;
  onStart?: () => void;
}

export function UploadFileBase(props: React.PropsWithChildren<Props>) {
  const input = useRef<HTMLInputElement>(null);
  const [upload] = useMutation<Graph.Mutation>(UPLOAD_FILE, {
    onCompleted: data => {
      if (props.onChange) {
        props.onChange(data.upload.url);
      }
    },
  });

  return (
    <div style={props.style}>
      <input
        ref={input}
        type="file"
        className="d-none"
        required
        onChange={(e): void => {
          if (e.target?.validity?.valid && e.target?.files) {
            if (props.onStart) props.onStart();
            upload({ variables: { file: e.target.files[0] } });
          }
        }}
        value=""
      />
      <div
        onClick={() => {
          if (!input) return;
          if (input === null) return;
          if (!input.current) return;
          if (input.current === null) return;
          input.current.click();
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
