import React, { useEffect, useState } from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { Loading } from '../../components/Loading';
import { useMutation, useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { QUERY_ADMIN_LIST } from 'src/query/admin';
import { BlockLoading } from 'src/components/loading/BlockLoading';
import Button from 'src/components/layout/xform/Button';
import XForm from 'src/components/layout/xform/XForm';
import ScanedPackageLists from './components/PackageScanOutTableLists';

const QUERY_SCAN_OUT = gql`
  mutation ScanPackageOut($barcode: String!, $id: Int!) {
    scanPackageOut(barcode: $barcode, enforceDelivererId: $id) {
      message
      success
      package {
        id
        type
        createdAt
        appointedAt
        isReturning
        supplier {
          id
          name
          contact
        }
        customer {
          id
          name
          phoneNumber
        }
        claimPickupBy {
          id
          name
        }
        claimDeliveryBy {
          id
          name
        }
        currentWarehouse {
          id
          name
          shortName
        }
        finalWarehouse {
          id
          name
          shortName
        }
      }
    }
  }
`;

function PackageBarcodeScanner({
  admin,
  onScanOutData,
}: {
  admin: Graph.Admin;
  onScanOutData: (v: Graph.Package[]) => void;
}) {
  const [barcode, setBarcode] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  // const [isOpenCamera, setIsOpenCamera] = useState(false);
  const [update, { loading, data }] = useMutation<Graph.Mutation>(QUERY_SCAN_OUT, {
    fetchPolicy: 'no-cache',
    onCompleted: r => {
      const returnData = r?.scanPackageOut?.package;
      onScanOutData(!!returnData ? [returnData] : []);
      setBarcode('');
    },
  });

  async function onInputKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === 13) {
      update({ variables: { barcode, id: admin.id } })
        .then()
        .catch(console.error);
    }
  }
  const onScanQrCode = () => {
    if (!!barcode) {
      update({ variables: { barcode, id: admin.id } })
        .then()
        .catch(console.error);
    }
  };
  // const previewStyle = {
  //   height: '200px',
  //   width: '200px',
  //   margin: '0 auto',
  // };

  // const handleScan = (result: any) => {
  //   if (result) {
  //     setBarcode(result);
  //   }
  // };

  // const handleError = (error: any) => {
  //   console.log(error);
  // };
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);
  function renderSuccess() {
    if (data !== undefined) {
      if (data?.scanPackageOut?.success) {
        return (
          <div className="alert alert-success" role="alert">
            <strong>Success! </strong>You successfully scan the package out.
          </div>
        );
      } else {
        return (
          <div className="alert alert-danger" role="alert">
            <strong>Oh snap! </strong> {data?.scanPackageOut?.message}
          </div>
        );
      }
    }
  }

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <strong>Deliverer</strong>
      </div>
      <img src={admin.image || ''} style={{ width: 100, height: 100 }} alt="" />
      <div style={{ marginTop: 10 }}>{admin.name}</div>
      <br />
      <div style={{ display: 'flex' }}>
        <div className="form-group" style={{ flexGrow: 1 }}>
          <label>Barcode</label>
          <input
            autoFocus={true}
            type="text"
            onKeyDown={onInputKeydown}
            style={{ fontSize: 20 }}
            className="lf-form-control"
            value={barcode}
            onChange={e => setBarcode(e.target.value)}
          />
        </div>
        {!!isMobile && (
          <XForm.Button
            loading={loading}
            onClick={() => onScanQrCode()}
            style={{ height: '46px', margin: '24px 0 0 10px', width: '80px', display: 'block', borderRadius: '8px' }}
          >
            ADD
          </XForm.Button>
        )}
      </div>
      <div>{loading ? <Loading></Loading> : null}</div>

      {renderSuccess()}

      {/* {!!isMobile && (
        <>
          {!!isOpenCamera && (
            <QrReader delay={500} style={previewStyle} onError={handleError} onScan={handleScan} facingMode={'user'} />
          )}

          <XForm.FilterContainer>
            <XForm.Button
              style={{ width: '100%', display: 'block' }}
              onClick={() => setIsOpenCamera(!isOpenCamera)}
              theme="white"
            >
              {!isOpenCamera ? 'Open Camera' : 'Close camera'} <i className="fas fa-camera"></i>
            </XForm.Button>
          </XForm.FilterContainer>
        </>
      )} */}
    </>
  );
}

function PackageScanOutBody({
  adminList,
  onScanOutData,
}: {
  adminList: Graph.Admin[];
  onScanOutData: (v: Graph.Package[]) => void;
}) {
  const [adminId, setAdminId] = useState(0);
  const [adminQrCode, setAdminQrCode] = useState('');
  const admin = adminList.find(x => x.id === adminId);
  if (admin)
    return (
      <div>
        <PackageBarcodeScanner admin={admin} onScanOutData={onScanOutData} />
        <Button
          onClick={() => {
            setAdminId(0);
          }}
        >
          Cancel
        </Button>
      </div>
    );

  async function onInputKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === 13) {
      setAdminId(Number(e.currentTarget.value) % 100000);
      setAdminQrCode('');
    }
  }

  return (
    <div style={{ display: 'contents' }}>
      <label>Deliverer QR Code</label>
      <input
        autoFocus={true}
        type="text"
        onKeyDown={onInputKeydown}
        style={{ fontSize: 20 }}
        className="lf-form-control"
        value={adminQrCode}
        onChange={e => setAdminQrCode(e.target.value)}
      />
    </div>
  );
}

export function PackageScanOutScreen() {
  const { data } = useQuery<Graph.Query>(QUERY_ADMIN_LIST);
  const [scanOutData, setScanOutData] = useState<Graph.Package[]>([]);
  const onScanOutData = (pksBarcode: Graph.Package[]) => {
    const pkgs: Graph.Package[] = [...scanOutData];
    pkgs.push(...pksBarcode);
    setScanOutData(pkgs);
  };
  const t = 'Package Scan Out (' + scanOutData.length.toString() + ')';
  return (
    <Content>
      <SectionBlock title="Scan Out" width={500}>
        {data?.adminList ? (
          <PackageScanOutBody adminList={data.adminList} onScanOutData={onScanOutData} />
        ) : (
          <BlockLoading />
        )}
      </SectionBlock>
      <XForm.Group label={t}>
        <ScanedPackageLists scanOutData={scanOutData} />
      </XForm.Group>
    </Content>
  );
}
