import React from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { useMutation, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import QRCode from 'qrcode.react';

const MUTATION_GENERATE = gql`
  mutation {
    generateTOTP
  }
`;

export function TwoFactorAuthScreen() {
  const [generate, { data, loading }] = useMutation<Graph.Mutation>(MUTATION_GENERATE);

  const onGenerateClicked = () => {
    if (!loading) {
      generate()
        .then()
        .catch(console.error);
    }
  };

  return (
    <Content>
      <SectionBlock title="Setup Two Factor" width={600}>
        <div>
          Please click below to generate QR for two factor authentication. If you have setup it before, the old code
          will no longer work. You have the new generated code.
          {data && (
            <div className="mt-3">
              <QRCode value={data.generateTOTP as string} size={200} />
            </div>
          )}
          <button className="btn btn-primary mt-3" onClick={onGenerateClicked}>
            Generate
          </button>
        </div>
      </SectionBlock>
    </Content>
  );
}
