import { Modal, TextContainer } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { UploadImage } from 'src/components/UploadImage';

interface MenuProps {
  icon: string;
  caption: string;
  navigate: string;
}
interface ModalProps {
  idx: number;
  open: boolean;
  superAdmin: boolean;
  menuItem?: MenuProps;
  handleChange: (x?: number) => void;
  onUpdateMenuLists: (idx: number, item: MenuProps) => void;
}
export default function ModalToUpdate({
  idx,
  open,
  menuItem,
  superAdmin,
  handleChange,
  onUpdateMenuLists,
}: ModalProps) {
  const [icon, setIcon] = useState('');
  const [caption, setCaption] = useState('');
  const [navigate, setNavigate] = useState('');

  useEffect(() => {
    if (menuItem) {
      setIcon(menuItem.icon);
      setCaption(menuItem?.caption);
      setNavigate(menuItem?.navigate);
    }
  }, [menuItem]);
  return (
    <>
      <Modal
        open={open}
        onClose={() => handleChange()}
        title="Update Menu"
        primaryAction={{
          content: 'Update',
          onAction: () => {
            onUpdateMenuLists(idx, { ...menuItem, caption, icon, navigate });
            handleChange();
          },
        }}
      >
        <Modal.Section>
          <TextContainer>
            <UploadImage value={icon} width={80} height={80} onChange={v => setIcon(v)} />
            {/* <TextField label="Caption" value={caption} onChange={handleCaptionChange} autoComplete="off" /> */}
            {/* <TextField label="Navigate" value={navigate} onChange={handleNavigateChange} autoComplete="off" />{' '} */}
            {/* <NavigationBuilder
              value={toScreen}
              onChange={v => {
                setToScreen(v);
              }}
            /> */}
            {/* <NavigationBuilder value={toScreen} onChange={setToScreen} /> */}

            {superAdmin && (
              <>
                <h5>Navigate</h5>
                <textarea
                  rows={8}
                  disabled={!superAdmin}
                  className="form-control"
                  defaultValue={navigate}
                  onChange={e => setNavigate(e.currentTarget.value)}
                ></textarea>
              </>
            )}
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
}
