import React from 'react';
import styles from './form.module.scss';

interface Props
  extends React.PropsWithChildren<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  > {
  theme?: 'primary' | 'secondary' | 'white' | 'danger' | 'success' | 'warning';
  loading?: boolean;
}

export default function Button(props: Props) {
  const classNameList = [styles.btn];

  if (props.theme) {
    if (props.theme === 'primary') classNameList.push(styles.btn_primary);
    if (props.theme === 'secondary') classNameList.push(styles.btn_secondary);
    if (props.theme === 'danger') classNameList.push(styles.btn_danger);
    if (props.theme === 'success') classNameList.push(styles.btn_success);
    if (props.theme === 'warning') classNameList.push(styles.btn_warning);
  } else {
    classNameList.push(styles.btn_primary);
  }

  const { loading, ...btnProps } = props;

  return (
    <button {...btnProps} className={classNameList.join(' ')}>
      {loading && <i className="fas fa-spinner fa-spin mr-1"></i>}
      {props.children}
    </button>
  );
}
