import React from 'react';

const CheckboxInternal = React.forwardRef(
  (props: React.InputHTMLAttributes<HTMLInputElement> & { label?: string }, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label className={['lf-checkbox', props.className].join(' ')} style={props.style}>
        <input ref={ref} type="checkbox" {...props} />
        <span>{props.label || ''}</span>
      </label>
    );
  },
);

CheckboxInternal.displayName = 'Checkbox';
export const Checkbox = CheckboxInternal;
