import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { TabGutter } from './TabGutter';

interface TabProps {
  usingPathForAlias?: boolean;
  children: React.ReactElement<TabItemProps> | React.ReactElement<TabItemProps>[];
}

interface TabItemProps {
  title: string;
  alias?: string;
  component?: JSX.Element;
  link?: string;
}

interface TabItemState extends TabItemProps {
  loaded: boolean;
}

function getParentPath(name: string) {
  const token = name.split('/');
  token.pop();
  return token.join('/');
}

function findAlias(usingPathForAlias: boolean) {
  if (usingPathForAlias) {
    return window.location.pathname.split('/').pop();
  }

  return window.location.hash;
}

export function Tab(props: TabProps) {
  const history = useHistory();
  const usingPathForAlias = props.usingPathForAlias || false;
  const children = useMemo(() => React.Children.toArray(props.children), [props.children]) as React.ReactElement<
    TabItemProps
  >[];
  const [selectedIndex, setSelectedIndex] = useState(
    Math.max(
      children.findIndex(tab => tab.props.alias === findAlias(usingPathForAlias)),
      0,
    ),
  );
  const [tabHeaderList, setTabHeaderList] = useState<TabItemState[]>(
    children.map((tab, tabIndex) => ({
      ...tab.props,
      loaded: tabIndex === selectedIndex,
    })),
  );

  useEffect(() => {
    return history.listen(() => {
      const index = Math.max(
        tabHeaderList.findIndex(tab => tab.alias === findAlias(usingPathForAlias)),
        0,
      );

      setSelectedIndex(index);
      tabHeaderList[index].loaded = true;
      setTabHeaderList([...tabHeaderList]);
    });
  }, [setSelectedIndex, setTabHeaderList, tabHeaderList, usingPathForAlias, history]);

  const onTabHeaderClick = (tabIndex: number) => {
    setSelectedIndex(tabIndex);
    tabHeaderList[tabIndex].loaded = true;
    setTabHeaderList([...tabHeaderList]);

    if (usingPathForAlias) {
      if (tabHeaderList[tabIndex].alias) {
        const parentPath = getParentPath(window.location.pathname);
        history.push(parentPath + '/' + (tabHeaderList[tabIndex].alias || ''));
      }
    } else {
      if (tabHeaderList[tabIndex].link) {
        history.push(tabHeaderList[tabIndex].link || '');
      } else if (tabHeaderList[tabIndex].alias) {
        history.push(window.location.pathname + tabHeaderList[tabIndex].alias || '');
      }
    }
  };

  return (
    <div>
      <div>
        <TabGutter>
          {tabHeaderList.map((tabHeader, tabIndex) => (
            <TabGutter.Item
              key={tabIndex}
              active={tabIndex === selectedIndex}
              onClick={() => onTabHeaderClick(tabIndex)}
            >
              {tabHeader.title}
            </TabGutter.Item>
          ))}
          {selectedIndex >= 0 &&
            tabHeaderList &&
            tabHeaderList[selectedIndex] &&
            tabHeaderList[selectedIndex].component && (
              <TabGutter.Actions>{tabHeaderList[selectedIndex].component}</TabGutter.Actions>
            )}
        </TabGutter>
      </div>

      {children.map((node, idx) => {
        if (tabHeaderList[idx].loaded) {
          return (
            <div key={idx} style={{ display: idx === selectedIndex ? 'block' : 'none' }}>
              {node}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

Tab.Item = function TabItem(props: React.PropsWithChildren<TabItemProps>) {
  return <div>{props.children}</div>;
};
