import React, { ReactElement } from 'react';
import { Content } from '../../components/light/Content';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { useQueryString } from '../../libs/QueryString';
import { DateTime } from '../../libs/DateTime';
import { Link } from 'react-router-dom';

interface GraphSearchResponse {
  keywords: {
    keyword: string;
    total: number;
    totalDevice: number;
  }[];
  latest: {
    keyword: string;
    createdAt: string;
  }[];
  summary: {
    total: number;
    totalSearchByKeyword: number;
    totalSearchByFilter: number;
  };
}

const QUERY = gql`
  query ReportSearchBreakdown($start: String!, $end: String!) {
    reportSearchBreakdown(start: $start, end: $end)
  }
`;

export function ReportSearchScreen(): ReactElement {
  const { start, end } = useQueryString<{ start: string; end: string }>({
    start: DateTime.last(2, 'day'),
    end: DateTime.yesterday(),
  });

  const { data, loading } = useQuery<Graph.Query>(QUERY, {
    variables: { start: `${start} 00:00:00`, end: `${end} 23:59:59` },
  });
  if (loading || !data) return <div>Loading ...</div>;

  const report: GraphSearchResponse = data.reportSearchBreakdown;

  return (
    <Content>
      <div className="control-header">
        <div className="row align-items-center">
          <div className="col-8 col-lg-7">
            <form action="" className="form-inline" method="get">
              <div className="form-group mr-4">
                <label className="mr-2" htmlFor="">
                  Start
                </label>
                <input name="start" type="date" className="lf-form-control lf-inline" defaultValue={start} />
              </div>
              <div className="form-group mr-2 d-none d-md-flex">
                <label className="mr-2" htmlFor="">
                  End
                </label>
                <input name="end" type="date" className="lf-form-control lf-inline" defaultValue={end} />
              </div>
              <div className="form-group d-none d-md-flex">
                <button className="lf-btn btn-primary">
                  <i className="os-icon os-icon-ui-22"></i>
                  <span>Filter</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="element-wrapper">
        <div className="row">
          <div className="col-3">
            <div className="element-box el-tablo centered trend-in-corner padded bold-label">
              <div className="value">{report.summary.total}</div>
              <div className="label">Total Search</div>
            </div>
          </div>
          <div className="col-3">
            <div className="element-box el-tablo centered trend-in-corner padded bold-label">
              <div className="value">{report.summary.totalSearchByKeyword}</div>
              <div className="label">Total Search by Keyword</div>
            </div>
          </div>
          <div className="col-3">
            <div className="element-box el-tablo centered trend-in-corner padded bold-label">
              <div className="value">{report.summary.totalSearchByFilter}</div>
              <div className="label">Total Search by Filter</div>
            </div>
          </div>
          <div className="col-3">
            <div className="element-box el-tablo centered trend-in-corner padded bold-label">
              <div className="value">
                {report.summary.total - report.summary.totalSearchByKeyword - report.summary.totalSearchByFilter}
              </div>
              <div className="label">Total Search by Keyword + Filter</div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="element-box">
              <h5>Top Search Keywords</h5>
              <table className="mt-4 table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Keyword</th>
                    <th>Count</th>
                    <th>Unique Device</th>
                  </tr>
                </thead>
                <tbody>
                  {report.keywords.map(x => (
                    <tr key={x.keyword}>
                      <td>
                        <Link to={`/app/preview/search?keyword=${x.keyword}`}>{x.keyword}</Link>
                      </td>
                      <td>{x.total}</td>
                      <td>{x.totalDevice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6">
            <div className="element-box">
              <h5>Latest Search Keywords</h5>
              <table className="mt-4 table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Keyword</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {report.latest.map((x, idx) => (
                    <tr key={idx}>
                      <td>
                        <Link to={`/app/preview/search?keyword=${x.keyword}`}>{x.keyword}</Link>
                      </td>
                      <td>{x.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
}
