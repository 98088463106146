import React from 'react';

import { Table } from 'src/components/layout/tables/Table';
import Formatter from 'src/libs/Formatter';
import { Link } from 'react-router-dom';
import { Graph } from 'src/generated/graph';

function renderPackage(item: Graph.Package, idx: number) {
  return (
    <tr key={item.id}>
      <td>{idx + 1}</td>
      <td className="text-center">
        <i
          className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
          style={{ fontSize: 18, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
        ></i>
      </td>
      <td>
        {item.type === 'ORDER_PICKUP' && (
          <div style={{ marginBottom: 2 }}>
            <span style={{ fontSize: 10, background: '#f1c40f', display: 'inline-block', padding: '2px 5px' }}>
              {Formatter.beautifyUglyName(item.type)}
            </span>
          </div>
        )}
        <Link to={`/package/detail/${item.id}`}>{item.id.toString().padStart(8, '0')}</Link>
      </td>
      <td>
        <div style={{ display: 'flex' }}>
          {item?.useInsurance === true && (
            <div>
              <span
                style={{
                  fontSize: 10,
                  background: '#F94892',
                  display: 'inline-block',
                  margin: '6px 0 0 4px',
                  padding: '3px 5px 1px 5px',
                  color: '#fff',
                }}
              >
                {Formatter.beautifyUglyName('Insurance')}
              </span>
            </div>
          )}
        </div>
        <strong>{item.supplier?.name}</strong>
        <br />
        <span>{item.supplier?.contact}</span>
      </td>
      <td>{item?.requestPickupAt}</td>
      <td>
        {item.customer && (
          <div>
            <strong>{item.customer.name}</strong>
            <br />
            <span>{item.customer.phoneNumber}</span>
          </div>
        )}
      </td>
      <td>
        {item.claimPickupBy && (
          <div>
            <strong>{item.claimPickupBy.name}</strong>
            <br />
            <span>{item.claimPickupAt}</span>
          </div>
        )}
      </td>
      <td>{item.appointedAt?.toString()}</td>
      <td>
        <strong style={{ fontSize: 16 }}>{item.nextWarehouse?.shortName}</strong>
      </td>
    </tr>
  );
}
export default function ScanedPackageLists({ scanOutData }: { scanOutData: Graph.Package[] }) {
  return (
    <Table stripe={true} border={true}>
      <thead>
        <tr>
          <th style={{ width: 40 }}>#</th>
          <th style={{ width: 50 }}>Type</th>
          <th style={{ width: 200 }}>Package ID</th>
          <th>Merchant</th>
          <th style={{ width: 180 }}>Request Pickup At</th>
          <th style={{ width: 200 }}>Customer</th>
          <th style={{ width: 200 }}>Claimed By</th>
          <th style={{ width: 150 }}>Appointment</th>
          <th style={{ width: 200 }}>Pickup To</th>
        </tr>
      </thead>
      <tbody>{scanOutData?.map(renderPackage)}</tbody>
    </Table>
  );
}
