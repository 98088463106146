import { useDefaultValue } from '../../../libs/Common';
import { Graph } from '../../../generated/graph';

interface Props {
  value: Graph.PurchaseOrderInput;
  onChange: (v: Partial<Graph.PurchaseOrderInput>) => void;
}

export function PurchaseOrderCreditNotePartial({ value, onChange }: Props) {
  return (
    <fieldset>
      <legend>
        <span>Credit Note</span>
      </legend>

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>Unit with issue</label>
            <div className="lf-input-group">
              <select
                defaultValue={useDefaultValue(value.creditType, 'MISSING')}
                onChange={e =>
                  onChange({ ...value, creditType: e.currentTarget.value as Graph.PurchaseOrderCreditType })
                }
                className="lf-form-control"
                style={{ width: 150 }}
              >
                <option value="MISSING">Missing</option>
                <option value="SURPLUS">Surplus</option>
              </select>
              <input
                className="lf-form-control"
                type="text"
                defaultValue={useDefaultValue(value.creditUnit?.toString(), '0')}
                onChange={e => onChange({ ...value, creditUnit: Number(e.currentTarget.value) })}
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label>Credit Amount</label>
            <div className="lf-input-group">
              <input
                className="lf-form-control"
                type="number"
                defaultValue={useDefaultValue(value.creditAmount, '0')}
                onChange={e => onChange({ ...value, creditAmount: e.currentTarget.value })}
              />
              <div className="lf-form-control">{useDefaultValue(value.creditAmountCurrency, 'USD')}</div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
}
