import React from 'react';
import styles from './form.module.scss';

interface Props extends React.PropsWithChildren<React.HTMLProps<HTMLTextAreaElement>> {
  label: string;
}

export default React.forwardRef(function FormTextAreaInput(props: Props, ref: React.Ref<HTMLTextAreaElement>) {
  return (
    <div className={styles.form_group}>
      <label className={styles.label}>{props.label}</label>
      <textarea {...props} ref={ref} className={styles.input} />
    </div>
  );
});
