import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useCurrentStore } from '../../hooks/Store';
import { ProfileSideMenuItem } from './ProfileSideMenu';

type Props = {
  title: string;
  name: string;
  role: string;
  menu: ProfileSideMenuItem[];
  image: string;
};

export function Topbar(props: Props) {
  const store = useCurrentStore();
  const history = useHistory();

  function renderItem(item: ProfileSideMenuItem) {
    return (
      <li key={item.name}>
        <Link to={item.to ? item.to : '#'} onClick={item.onClick}>
          <i className={item.icon}></i>
          <span>{item.name}</span>
        </Link>
      </li>
    );
  }

  function renderMenuItem() {
    const switchStore = (n: number) => {
      localStorage.setItem('store', n.toString());
      window.location.reload();
    };

    return (
      <ul>
        {props.menu.map(e => renderItem(e))}
        <li style={{ padding: '1rem' }}>
          <div style={{ color: '#fff' }}>Switching Store</div>
          <div className="mt-2">
            <button className="btn btn-white m-1" onClick={() => switchStore(1)}>
              Store 1
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(2)}>
              Store 2
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(3)}>
              Store 3
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(4)}>
              TK
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(5)}>
              Store 5
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(6)}>
              PH
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(7)}>
              Chom Chao
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(8)}>
              Russey Keo
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(9)}>
              Chamka Dong
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(10)}>
              CV
            </button>
            <button className="btn btn-white m-1" onClick={() => switchStore(11)}>
              SHV
            </button>
          </div>
        </li>
      </ul>
    );
  }

  function renderTopMenu() {
    return (
      <div className="top-menu-controls">
        <div className="element-search autosuggest-search-activator">
          <input
            placeholder="Please search customer"
            type="text"
            onKeyPress={e => {
              if (e.which === 13) {
                history.push({
                  pathname: '/customers',
                  search: `phone=${e.currentTarget.value}`,
                });
              }
            }}
          />
        </div>
        <div className="messages-notifications os-dropdown-trigger os-dropdown-position-left">
          <Link to="/chat" style={{ color: 'inherit' }}>
            <i className="os-icon os-icon-mail-14"></i>
          </Link>
        </div>
        <div className="logged-user-w avatar-inline">
          <div className="logged-user-i" style={{ display: 'inline-flex' }}>
            <div className="avatar-w">
              <img
                src={
                  props.image === undefined
                    ? 'https://s6.kh1.co/8f/8f49b1bbdfefbb5fd2701c4b31c5b61010e402db.jpg'
                    : props.image
                }
                alt=""
                style={{
                  width: 40,
                  height: 40,
                }}
              />
            </div>
            <div className="logged-user-info-w">
              <div className="logged-user-name" style={{ color: '#fff' }}>
                {props.name}
              </div>
              <div className="logged-user-role" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                Store {store}
              </div>
            </div>
            <div className="logged-user-menu color-style-bright">
              <div className="logged-user-avatar-info">
                <div className="avatar-w">
                  <img
                    src={
                      props.image === undefined
                        ? 'https://s6.kh1.co/8f/8f49b1bbdfefbb5fd2701c4b31c5b61010e402db.jpg'
                        : props.image
                    }
                    alt=""
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  />
                </div>
                <div className="logged-user-info-w">
                  <div className="logged-user-name">{props.name}</div>
                  <div className="logged-user-role">{props.role}</div>
                  <div className="logged-user-role" style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                    Store {store}
                  </div>
                </div>
              </div>
              <div className="bg-icon">
                <i className="os-icon os-icon-wallet-loaded"></i>
              </div>
              {renderMenuItem()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div className="top-bar color-scheme-bright">{renderTopMenu()}</div>;
}
