import { gql, useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { Graph } from 'src/generated/graph';

const SettingContext = React.createContext<{ supplierSetting?: Graph.SupplierSetting | null }>({});

const QUERY_SETTING = gql`
  query supplierSetting {
    supplierSetting {
      exchangeRate
      minimumDeliverTime
      packageValueLimit
    }
  }
`;

export function SettingProvider(props: React.PropsWithChildren<unknown>) {
  const { data } = useQuery<Graph.Query>(QUERY_SETTING);

  return (
    <SettingContext.Provider value={{ supplierSetting: data?.supplierSetting }}>
      {props.children}
    </SettingContext.Provider>
  );
}

export function useSetting() {
  return useContext(SettingContext);
}
