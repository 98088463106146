import { gql, useLazyQuery, useSubscription } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Graph } from 'src/generated/graph';

const SUBSCRIPTION_PACKAGE_IMAGE = gql`
  subscription ImagePackageCreated {
    imagePackageCreated {
      total
    }
  }
`;

const QUERY_PACKAGE_IMAGE = gql`
  query ImagePackages($status: ImagePackageStatus) {
    imagePackages(status: $status) {
      id
    }
  }
`;

export const imagePackageMenu = () => {
  const [count, setCount] = useState(0);
  useSubscription<Graph.Subscription>(SUBSCRIPTION_PACKAGE_IMAGE, {
    onData: t => setCount(Number(t?.data?.data?.imagePackageCreated?.total) || 0),
  });
  const [getImagePkg] = useLazyQuery<Graph.Query>(QUERY_PACKAGE_IMAGE, {
    variables: { status: 'PENDING' },
    onCompleted: r => setCount(Number(r?.imagePackages?.length)),
  });
  useEffect(() => {
    getImagePkg();
  }, []);

  return [
    {
      name: `Image Package`,
      to: '/package/image_package',
      icon: 'fas fa-box-open',
      badge: `${count === 0 ? '' : count}`,
    },
  ];
};
