import { LegacyCard, LegacyCardProps } from '@shopify/polaris';
import { LegacyCardSectionProps } from '@shopify/polaris/build/ts/latest/src/components/LegacyCard/components';
import React from 'react';

function Card(props: React.PropsWithChildren<LegacyCardProps>) {
  return (
    <LegacyCard {...props} key={Math.pow(28, 3)}>
      {props.children}
    </LegacyCard>
  );
}

function CardSection(props: React.PropsWithChildren<LegacyCardSectionProps>) {
  return <LegacyCard.Section {...props}>{props.children}</LegacyCard.Section>;
}

Card.Section = CardSection;

export const PolarisCard = Card;
