import React from 'react';
import { PlaygroundScreen } from '../PlaygroundScreen';

export function CustomControlCollectionScreen() {
  return (
    <div className="p-5" style={{ width: 400 }}>
      {/* <MultipleColorProgressBar values={[5, 4, 2, 6, 7]} colors={['#f00', '#00f', '#aaa', '#eee', '#ddd']} /> */}
      <div>Hello</div>
      <PlaygroundScreen />
    </div>
  );
}
