/* eslint-disable */
import React from 'react';
import { OperationVariables, Query, QueryResult } from '@apollo/react-components';
import { gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { CommentListPostGroup } from './CommentListPostGroup';
import { BlockLoading } from 'src/components/loading/BlockLoading';

const QUERY = gql`
  query CommentList(
    $id: String
    $type: FacebookPostType
    $sort: CommentSortType
    $handled: Boolean
    $replied: Boolean
    $date: String
  ) {
    commentList(pageID: $id, type: $type, sort: $sort, handled: $handled, replied: $replied, date: $date) {
      id
      name
      commentID
      facebookID
      postID
      profilePicture
      content
      createdAt
      handledBy
      handledAt
    }
  }
`;

type CommentListTabProps = {
  pageId: string;
  handled: boolean;
  replied: boolean;
  date: string | undefined;
  type: 'LIVE' | 'NONE';
  sort: 'EARLIEST' | 'LATEST';
};

export class CommentListContent extends React.Component<CommentListTabProps> {
  state: {
    id: number;
    data: {
      comments: Graph.Comment[];
      postID: string;
    }[];
  } = {
    id: 0,
    data: [],
  };

  refetch: any;

  refresh = () => {
    this.refetch();
  };

  onCompleteTransformData = (data: Graph.Query) => {
    // Grouping the post by ID
    const posts: {
      [key: string]: {
        postID: string;
        comments: Graph.Comment[];
      };
    } = {};

    for (const comment of data.commentList || []) {
      if (comment.postID) {
        if (!posts[comment.postID])
          posts[comment.postID] = {
            postID: comment.postID,
            comments: [],
          };

        posts[comment.postID].comments.push(comment);
      }
    }

    this.setState({ data: Object.values(posts) });
  };

  render() {
    return (
      <Query
        query={QUERY}
        variables={
          ({
            id: this.props.pageId && this.props.pageId.length > 2 ? this.props.pageId : undefined,
            type: this.props.type,
            sort: this.props.sort,
            handled: this.props.handled,
            replied: this.props.replied,
            date: this.props.date,
          } as unknown) as OperationVariables
        }
        onCompleted={this.onCompleteTransformData}
      >
        {this.renderQuery}
      </Query>
    );
  }

  renderQuery = ({ loading, refetch }: QueryResult<Graph.Query>) => {
    this.refetch = refetch;

    if (loading) return <BlockLoading />;

    return (
      <div>
        {this.state.data.map(x => (
          <CommentListPostGroup key={x.postID} {...x} type={this.props.type} />
        ))}
      </div>
    );
  };
}
