/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEvent } from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { gql } from '@apollo/client';
import { Permission } from '../../components/Permission';
import { Query } from '@apollo/react-components';
import { Loading } from '../../components/Loading';
import { useQueryString } from '../../libs/QueryString';

const QUERY = gql`
  query reportGMV($year: Int!, $month: Int!) {
    reportGMV(year: $year, month: $month) {
      day
      total
      shop1
      shop2
      shop3
      app
    }
  }
`;

type Props = {
  location: {
    search: any;
  };
  history: any;
};

export class GMVScreen extends React.Component<Props> {
  state: {
    year: number;
    month: number;
    compareYear: number;
    compareMonth: number;

    inputYear: string;
    inputMonth: string;
    inputCompareYear: string;
    inputCompareMonth: string;
  };

  graphs: any = [];

  constructor(props: Props) {
    super(props);

    const currentDate = new Date();
    const previousDate = new Date();
    previousDate.setMonth(previousDate.getMonth() - 1);

    const { year, month, cyear, cmonth } = useQueryString<{
      year: number;
      month: number;
      cyear: number;
      cmonth: number;
    }>({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      cyear: previousDate.getFullYear(),
      cmonth: previousDate.getMonth() + 1,
    });

    this.state = {
      year: year,
      month: month,
      compareYear: cyear,
      compareMonth: cmonth,

      inputYear: year.toString(),
      inputMonth: month.toString().padStart(2, '0'),
      inputCompareYear: cyear.toString(),
      inputCompareMonth: cmonth.toString().padStart(2, '0'),
    };
  }

  onClickReport = (e: FormEvent) => {
    e.preventDefault();
    this.setState({
      year: Number(this.state.inputYear),
      month: Number(this.state.inputMonth),
      compareYear: Number(this.state.inputCompareYear),
      compareMonth: Number(this.state.inputCompareMonth),
    });

    this.props.history.push(
      `/report/gmv?year=${this.state.inputYear}&month=${this.state.inputMonth}&cyear=${this.state.inputCompareYear}&cmonth=${this.state.inputCompareMonth}`,
    );
  };

  onChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Content>
        <Permission superAdmin={true}>
          <SectionBlock title="Net Merchandise Value">
            {this.renderInput()}
            <div className="row">
              <div className="col-sm-12">{this.renderQuery()}</div>
            </div>
          </SectionBlock>
        </Permission>
      </Content>
    );
  }

  renderInput() {
    return (
      <form className="controls-above-table" onSubmit={this.onClickReport}>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-inline justify-content-sm-start">
              <input
                type="text"
                placeholder="Year"
                className="form-control form-control-sm rounded bright"
                style={{ marginRight: 7, width: 75 }}
                value={this.state.inputYear}
                onChange={this.onChange}
                name="inputYear"
              />
              <input
                type="text"
                placeholder="Month"
                className="form-control form-control-sm rounded bright"
                style={{ marginRight: 7, width: 75 }}
                value={this.state.inputMonth}
                onChange={this.onChange}
                name="inputMonth"
              />
              <label style={{ marginRight: 7 }}>Compare to</label>
              <input
                type="text"
                placeholder="Year"
                className="form-control form-control-sm rounded bright"
                style={{ marginRight: 7, width: 75 }}
                value={this.state.inputCompareYear}
                onChange={this.onChange}
                name="inputCompareYear"
              />
              <input
                type="text"
                placeholder="Month"
                className="form-control form-control-sm rounded bright"
                style={{ marginRight: 7, width: 75 }}
                value={this.state.inputCompareMonth}
                onChange={this.onChange}
                name="inputCompareMonth"
              />

              <button className="btn btn btn-secondary" type="submit">
                Report
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderQuery = () => {
    return (
      <Query query={QUERY} variables={{ year: this.state.year, month: this.state.month }}>
        {this.renderQueryCompare}
      </Query>
    );
  };

  renderQueryCompare = ({ loading, data }: any) => {
    return (
      <Query
        query={QUERY}
        variables={{ year: this.state.compareYear, month: this.state.compareMonth }}
        skip={this.state.compareYear === 0 || this.state.compareMonth === 0}
      >
        {({ loading: loadingCompare, data: dataCompare }: any) => {
          return this.renderTable(loading || loadingCompare, data, dataCompare);
        }}
      </Query>
    );
  };

  renderTable = (loading: boolean, data: any, dataCompare: any) => {
    if (loading)
      return (
        <div>
          <Loading />
        </div>
      );

    const { rows, summary } = dataCompare
      ? this.transformDataToTable(data.reportGMV, dataCompare.reportGMV)
      : this.transformDataToTable(data.reportGMV, []);

    this.graphs = [];

    for (const row of rows) {
      this.graphs.push({
        name: row.current.day,
        default: Number(row.current.acc).toFixed(2),
        compare: Number(row.compare.acc).toFixed(2),
        amt:
          Number(row.current.acc) > Number(row.compare.acc) ? Number(row.current.acc) | 0 : Number(row.compare.acc) | 0,
      });
    }

    return (
      <div style={{ position: 'relative', overflow: 'hidden', maxWidth: '100%' }}>
        <table className="table table-lightborder">
          <thead>
            <tr>
              <th style={{ width: '150px' }}>Day</th>
              <th className="text-right" style={{ width: '100px' }}>
                Accumulate
              </th>
              <th className="text-right" style={{ width: '100px' }}>
                Total
              </th>
              <th className="text-right" style={{ width: '100px' }}>
                App
              </th>
              <th className="text-right" style={{ width: '100px' }}>
                Other
              </th>
              <th className="text-right" style={{ width: '100px' }}>
                Shop 1
              </th>
              <th className="text-right" style={{ width: '100px' }}>
                Shop 2
              </th>
              <th className="text-right" style={{ width: '100px' }}>
                Shop 3
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ fontWeight: 'bold' }}>
              <td>Total</td>
              <td></td>
              {this.renderCell(summary, 'total')}
              {this.renderCell(summary, 'app')}
              {this.renderCell(summary, 'other')}
              {this.renderCell(summary, 'shop1')}
              {this.renderCell(summary, 'shop2')}
              {this.renderCell(summary, 'shop3')}
              <td></td>
            </tr>

            {this.renderTableBody(rows)}
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    );
  };

  transformDataToTable(data: any, dataCompare: any) {
    const rows = new Array(31).fill({}).map(() => ({
      current: {
        day: '',
        shop1: 0,
        shop2: 0,
        shop3: 0,
        app: 0,
        total: 0,
        other: 0,
        acc: 0,
      },

      compare: {
        day: '',
        shop1: 0,
        shop2: 0,
        shop3: 0,
        app: 0,
        total: 0,
        other: 0,
        acc: 0,
      },
    }));

    const summary = {
      current: {
        shop1: 0,
        shop2: 0,
        shop3: 0,
        app: 0,
        total: 0,
        other: 0,
        acc: 0,
      },
      compare: {
        shop1: 0,
        shop2: 0,
        shop3: 0,
        app: 0,
        total: 0,
        other: 0,
        acc: 0,
      },
    };

    for (const row of data) {
      const day = Number(row.day.split('-').pop()) - 1;
      rows[day].current = {
        day: row.day,
        acc: 0,
        shop1: Number(row.shop1),
        shop2: Number(row.shop2),
        shop3: Number(row.shop3),
        app: Number(row.app),
        total: Number(row.total),
        other: row.total - row.shop1 - row.shop2 - row.shop3 - row.app,
      };

      summary.current.shop1 += rows[day].current.shop1;
      summary.current.shop2 += rows[day].current.shop2;
      summary.current.shop3 += rows[day].current.shop3;
      summary.current.app += rows[day].current.app;
      summary.current.total += rows[day].current.total;
      summary.current.other += rows[day].current.other;
    }

    for (const row of dataCompare) {
      const day = Number(row.day.split('-').pop()) - 1;
      rows[day].compare = {
        day: row.day,
        acc: 0,
        shop1: Number(row.shop1),
        shop2: Number(row.shop2),
        shop3: Number(row.shop3),
        app: Number(row.app),
        total: Number(row.total),
        other: row.total - row.shop1 - row.shop2 - row.shop3 - row.app,
      };

      summary.compare.shop1 += rows[day].compare.shop1;
      summary.compare.shop2 += rows[day].compare.shop2;
      summary.compare.shop3 += rows[day].compare.shop3;
      summary.compare.app += rows[day].compare.app;
      summary.compare.total += rows[day].compare.total;
      summary.compare.other += rows[day].compare.other;
    }

    rows[0].current.acc = rows[0].current.total;
    rows[0].compare.acc = rows[0].compare.total;

    for (let i = 1; i < 31; i++) {
      rows[i].current.acc = rows[i - 1].current.acc + rows[i].current.total;
      rows[i].compare.acc = rows[i - 1].compare.acc + rows[i].compare.total;
    }

    // console.log(rows);
    return { rows, summary };
  }

  renderTableBody(rows: any[]) {
    return rows.map((x, idx) => {
      return (
        <tr key={idx}>
          <td>{`${this.state.year}-${(this.state.month + '').padStart(2, '0')}-${(idx + 1 + '').padStart(2, '0')}`}</td>
          {this.renderCell(x, 'acc', { backgroundColor: '#f1e48f' })}
          {this.renderCell(x, 'total', { backgroundColor: '#ecf0f1' })}
          {this.renderCell(x, 'app')}
          {this.renderCell(x, 'other')}
          {this.renderCell(x, 'shop1')}
          {this.renderCell(x, 'shop2')}
          {this.renderCell(x, 'shop3')}
          <td></td>
        </tr>
      );
    });
  }

  renderCell(value: any, field: string, style = {}) {
    return (
      <td className="align-top text-right" style={style}>
        <div>{value.current[field].toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
        {value.compare.day !== '' && (
          <div style={{ color: '#AAA' }}>
            {value.compare[field].toLocaleString('en-US', { minimumFractionDigits: 2 })}
          </div>
        )}
        {this.renderGrowthRate(value.current[field], value.compare[field])}
      </td>
    );
  }

  renderGrowthRate(value1: number, value2: number) {
    if (value1 === 0 || value2 === 0) return undefined;

    const ratio = value1 / value2;
    const sign = ratio > 1 ? 1 : 0;
    const change = ratio > 1 ? ratio - 1 : 1 - ratio;

    return (
      <div>
        <small style={{ color: sign ? '#27ae60' : '#e74c3c' }}>{(change * 100).toFixed(2)}%</small>
      </div>
    );
  }
}
