import './scss/index.css';
import './scss/main.scss';
import { createRoutes } from './routes';
import 'react-quill/dist/quill.snow.css';
import '@shopify/polaris/build/esm/styles.css';
import { AppProvider } from '@shopify/polaris';
import { PropsWithChildren, Suspense } from 'react';
import { AuthProvider } from './contexts/AuthProvider';
import { TokenProvider } from './contexts/TokenContext';
import translations from '@shopify/polaris/locales/en.json';
import { MenuContext } from './components/layout/MenuContext';
import ApolloClientProvider from './contexts/ApolloClientProvider';
import { SettingProvider } from './components/context/SettingContext';
import { BrowserRouter as Router, Link, Switch } from 'react-router-dom';
import NavigationContainer from './components/layout/NavigationContainer';
import { GeneralEventAdd } from './components/context/GeneralEventContext';
import { PolarisModal, PolarisConfirm } from './components/polaris/PolarisModal';
import { PolarisDialogPreview, PolarisPreview } from './components/polaris/PolarisDialog';
import { CustomToastMultiple } from './contexts/ToastProvider';

const CustomLinkComponent = (props: PropsWithChildren<{ url: string; external?: boolean }>) => {
  const { children, url, external, ...rest } = props;

  if (external) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
};

export default function App() {
  const routers = createRoutes();

  return (
    <AppProvider i18n={translations} linkComponent={CustomLinkComponent}>
      <TokenProvider>
        <ApolloClientProvider>
          <AuthProvider>
            <CustomToastMultiple>
              <SettingProvider>
                <GeneralEventAdd>
                  <PolarisModal ref={t => PolarisConfirm.setModal(t)} />
                  <PolarisDialogPreview ref={t => PolarisPreview.setModal(t)} />
                  <MenuContext>
                    <Router>
                      <NavigationContainer>
                        <Suspense fallback={<div></div>}>
                          <Switch>{routers}</Switch>
                        </Suspense>
                      </NavigationContainer>
                    </Router>
                  </MenuContext>
                </GeneralEventAdd>
              </SettingProvider>
            </CustomToastMultiple>
          </AuthProvider>
        </ApolloClientProvider>
      </TokenProvider>
    </AppProvider>
  );
}
