import React, { useEffect, useState } from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { useLazyQuery } from '@apollo/client';
import { QUERY_PACKAGE_WITHOUT_LOG } from './query';
import { useHistory } from 'react-router';
import { Graph } from '../../generated/graph';

export function PackageScanBarcodeScreen() {
  const history = useHistory();
  const [barcode, setBarcode] = useState('');
  const [fetch, { data, loading }] = useLazyQuery<Graph.Query>(QUERY_PACKAGE_WITHOUT_LOG, {
    variables: { barcode },
    onCompleted: data => {
      if (data && data.package && isNaN(Number(barcode)) === true) {
        history.push(`/package/detail/${data.package?.id}`);
      } else {
        history.push(`/package/detail/${barcode}`);
      }
    },
  });

  useEffect(() => {
    if (barcode) {
      fetch({ variables: { barcode } });
    }
  }, [barcode, fetch]);

  let status = <div></div>;
  if (loading) {
    status = (
      <div>
        <strong>
          <i>Searching...</i>
        </strong>
      </div>
    );
  } else if (data && !data.package) {
    status = (
      <div>
        <strong>
          <i>Cannot find any package with the given barcode</i>
        </strong>
      </div>
    );
  }

  const onKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      setBarcode(e.currentTarget.value);
      e.currentTarget.value = '';
    }
  };

  return (
    <Content>
      <SectionBlock title="Scan Package Barcode" width={500}>
        <div className="form-group">
          <label>Scan Package Barcode</label>
          <input
            onKeyDown={onKeyEnter}
            autoFocus
            style={{ fontSize: 20 }}
            className="lf-form-control"
            placeholder="Enter Barcode..."
          />
        </div>
        {status}
      </SectionBlock>
    </Content>
  );
}
