/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import {
  AlphaTabProps,
  AppliedFilterInterface,
  FilterInterface,
  IndexFilters,
  IndexFiltersMode,
} from '@shopify/polaris';
import {
  IndexFiltersCancelAction,
  IndexFiltersProps,
} from '@shopify/polaris/build/ts/latest/src/components/IndexFilters';
import React, { useState } from 'react';
import { ProductListArguments } from './useQueryProductList';

interface Props {
  filters: FilterInterface[];
  appliedFilters: AppliedFilterInterface[];
  filterValue: ProductListArguments;
  queryValue: string;
  handleQueryChange?: (v: string) => void;
  handleQueryEnter?: () => void;
  handleClearAll?: () => void;
  handleQueryClear?: () => void;
  handleSort?: (v: any) => void;
  handleSelectedTab?: (key: string) => void;
  loading?: boolean;
}

export function FilterProduct(props: Props) {
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const [selected, setSelected] = useState(0);

  const [mode, setMode] = useState(IndexFiltersMode.Default);
  const [tabs, setTabs] = useState<string[]>([
    'Default',
    ...(localStorage.getItem('productviewtab')
      ? (JSON.parse(localStorage.getItem('productviewtab') || '[]') as any[])
      : []),
  ]);

  const sortOptions: IndexFiltersProps['sortOptions'] = [
    { label: 'Date', value: 'date asc', directionLabel: 'Ascending' },
    { label: 'Date', value: 'date desc', directionLabel: 'Descending' },
    { label: 'Stock', value: 'stock asc', directionLabel: 'Ascending' },
    { label: 'Stock', value: 'stock desc', directionLabel: 'Descending' },
    { label: 'Sales', value: 'sales asc', directionLabel: 'Ascending' },
    { label: 'Sales', value: 'sales desc', directionLabel: 'Descending' },
    { label: 'New Stock', value: 'new_stock asc', directionLabel: 'Ascending' },
    { label: 'New Stock', value: 'new_stock desc', directionLabel: 'Descending' },
    { label: 'Rating', value: 'rating asc', directionLabel: 'Ascending' },
    { label: 'Rating', value: 'rating desc', directionLabel: 'Descending' },
  ];

  const cancelAction: IndexFiltersCancelAction = {
    onAction: () => {
      // code here
    },
  };

  const onCreateNewView = async (value: string, type_create: 'NEW' | 'SAVE_AS') => {
    await sleep(500);
    const tab = [...tabs, value.trim()];
    const filters = localStorage.getItem('filtersProduct')
      ? JSON.parse(localStorage.getItem('filtersProduct') || '{}')
      : {};

    filters[value.trim()] = type_create === 'NEW' ? {} : props.filterValue;

    setTabs([...tabs, value.trim()]);

    localStorage.setItem('productviewtab', JSON.stringify(tab.filter(f => f !== 'Default')));
    localStorage.setItem('filtersProduct', JSON.stringify(filters));
    return true;
  };

  const onSaveView = async () => {
    await sleep(500);
    const tab = tabs[selected];
    const filters = localStorage.getItem('filtersProduct')
      ? JSON.parse(localStorage.getItem('filtersProduct') || '{}')
      : {};
    filters[tab] = props.filterValue;
    localStorage.setItem('filtersProduct', JSON.stringify(filters));
    // code here
    return true;
  };

  const deleteView = (index: number) => {
    const tab = [...tabs];
    const filters = localStorage.getItem('filtersProduct')
      ? JSON.parse(localStorage.getItem('filtersProduct') || '{}')
      : {};
    delete filters[tab[index]];

    tab.splice(index, 1);
    setTabs(tab);
    localStorage.setItem('productviewtab', JSON.stringify(tab.filter(f => f !== 'Default')));
    localStorage.setItem('filtersProduct', JSON.stringify(filters));
    setSelected(0);
  };

  const renameView = (index: number, value: string) => {
    const tab = [...tabs];
    const filters = localStorage.getItem('filtersProduct')
      ? JSON.parse(localStorage.getItem('filtersProduct') || '{}')
      : {};
    filters[value.trim()] = filters[tab[index]];
    delete filters[tab[index]];
    tab[index] = value;

    setTabs(tab);
    localStorage.setItem('productviewtab', JSON.stringify(tab.filter(f => f !== 'Default')));
    localStorage.setItem('filtersProduct', JSON.stringify(filters));
  };

  return (
    <div
      onKeyDown={e => {
        if (e.keyCode === 13) {
          props.handleQueryEnter && props.handleQueryEnter();
        }
      }}
    >
      <IndexFilters
        sortOptions={sortOptions}
        sortSelected={
          props.filterValue.orderBy
            ? [`${props.filterValue.orderBy.field?.toLowerCase()} ${props.filterValue.orderBy.sortBy?.toLowerCase()}`]
            : ['date asc']
        }
        onSort={props.handleSort}
        filters={props.filters}
        tabs={
          tabs.map((x, i) => {
            return {
              content: x,
              id: `${x}-${i}`,
              isLocked: i === 0,
              disabled: !!props.loading,
              actions:
                i === 0
                  ? []
                  : [
                      {
                        type: 'rename',
                        onPrimaryAction: async (value: string) => {
                          await sleep(1);
                          renameView(i, value);
                          return true;
                        },
                      },
                      {
                        type: 'delete',
                        onPrimaryAction: async () => {
                          await sleep(1);
                          deleteView(i);
                          return true;
                        },
                      },
                    ],
            };
          }) as AlphaTabProps[]
        }
        cancelAction={cancelAction}
        mode={mode}
        setMode={setMode}
        selected={selected}
        onClearAll={() => props.handleClearAll && props.handleClearAll()}
        queryPlaceholder="ស្វែងរកលេខកូដផលិតផល"
        queryValue={props.queryValue}
        onQueryChange={v => props.handleQueryChange && props.handleQueryChange(v)}
        onQueryClear={() => props.handleQueryClear && props.handleQueryClear()}
        onSelect={v => {
          if (!props.loading) {
            setSelected(v);
            props.handleSelectedTab && props.handleSelectedTab(tabs[v]);
          }
        }}
        appliedFilters={props.appliedFilters}
        canCreateNewView={true}
        onCreateNewView={v => onCreateNewView(v, 'NEW')}
        primaryAction={
          selected === 0
            ? { type: 'save-as', onAction: v => onCreateNewView(v, 'SAVE_AS') }
            : { type: 'save', onAction: onSaveView }
        }
      />
    </div>
  );
}
