/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon, Popover } from '@shopify/polaris';
import { SearchMinor, ChevronRightMinor, ChevronLeftMinor, CircleTickMinor } from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState } from 'react';
// import { useCategoryListQuery, useRecommendProductCategoryListLazyQuery } from 'src/graphql/codegen_graphql';
import style from './style.module.scss';
import { useQueryCategoryList } from 'src/hooks/useQueryCategoryList';
import { PolarisTextField } from './PolarisTextField';

const CategoryCallbackContext = React.createContext<{
  value?: number[];
  onChange?: (v: number) => void;
}>({});

interface CategoryTreeNode {
  id: number;
  name: string;
  path: string;
  children: CategoryTreeNode[];
  subCategory: string;
}

interface CategoryPrebuild {
  root: CategoryTreeNode;
  hash: { [key: number]: CategoryTreeNode };
}

// interface PropsSuggest {
//   value: any;
//   // onClick: (e: number) => void;
//   onChange: (v: any) => void;
// }

interface Props {
  value: number[];
  onChange: (v: any) => void;
}

function buildPath(path: string, children: CategoryTreeNode[]) {
  for (const child of children) {
    child.path = path;
    buildPath(path + child.name + ' > ', child.children);
  }
}

function buildTree(data: any) {
  const hash: { [key: number]: CategoryTreeNode } = {
    0: {
      children: [],
      id: 0,
      name: '',
      path: '',
      subCategory: '',
    },
  };

  // Create all the hash
  for (const item of data) {
    const node: CategoryTreeNode = {
      id: item.id,
      name: item.name?.en as string,
      path: '',
      children: [],
      subCategory: item.subCategory,
    };

    hash[item.id] = node;
  }

  // Attach all the children
  for (const item of data) {
    if (item.parentID) {
      if (hash[item.parentID]) {
        hash[item.parentID].children.push(hash[item.id]);
      }
    } else {
      hash[0].children.push(hash[item.id]);
    }
  }

  buildPath('', hash[0].children);

  return {
    root: hash[0],
    hash,
  };
}

export function PolarisCategory(props: Props) {
  // const context = useContext(FormContext);
  const [active, setActive] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [category, setCategory] = useState<CategoryPrebuild>({
    root: { children: [], id: 0, name: '', path: '', subCategory: '' },
    hash: {},
  });
  const [treeNode, setTreeNode] = useState<CategoryTreeNode[]>([]);
  const [selectTree, setSelectTree] = useState<CategoryTreeNode | null>(null);
  const [previousTreeId, setPreviousTreeId] = useState<number[]>([0]);
  const [showAll, setShowAll] = useState(false);
  const { data, loading } = useQueryCategoryList({
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (data && data?.categoryList) {
      const builds = buildTree(data?.categoryList);
      setCategory(builds);
      setTreeNode(builds.root.children);
    }
  }, [data, setCategory, setTreeNode]);

  const filterSearch = useCallback(
    (value: string) => {
      if (value.length >= 2) {
        const filterRegex = new RegExp(value, 'i');
        const filter = data?.categoryList?.filter((x: any) => x?.name?.en?.match(filterRegex));

        if ((filter || []).length > 0) {
          const builds = buildTree(filter);
          setCategory(builds);
          // eslint-disable-next-line no-console
          console.log(builds);
          setTreeNode(
            Object.keys(builds.hash)
              .filter(x => x !== '0')
              .map((x: any) => builds.hash[x]),
          );
        }
      }
    },
    [setCategory, setTreeNode, data],
  );

  const handleQuery = useCallback(
    async (value: string) => {
      await setKeyword(value);
      if (value === '') {
        if (data && data?.categoryList) {
          const builds = buildTree(data?.categoryList);
          await setCategory(builds);
          await setTreeNode(builds.root.children);
        }
        return;
      }
      await setTimeout(() => filterSearch(value), 500);
    },
    [filterSearch, data, setCategory, setTreeNode],
  );

  const handleSelectChildTree = useCallback(
    (treeId: number) => {
      const tree = category.hash[treeId];
      setSelectTree(tree);
      setTreeNode(tree.children);
      setPreviousTreeId([...previousTreeId, tree.id]);
      setShowAll(false);
    },
    [category, setTreeNode, setSelectTree, setPreviousTreeId, previousTreeId, setShowAll],
  );

  const handlePreviousSelectChildTree = useCallback(() => {
    const arr = [...previousTreeId];
    const newArr = arr.filter(x => x !== arr[arr.length - 1]);
    const tree = category.hash[newArr[newArr.length - 1]];
    setSelectTree(tree);
    setTreeNode(tree.children);
    setPreviousTreeId(newArr);
    setShowAll(false);
  }, [category, setTreeNode, setSelectTree, setPreviousTreeId, previousTreeId, setShowAll]);

  // const cat =
  //   data && category.hash
  //     ? (category.hash as any)[String(props.value || 0)] || { path: '', name: '' }
  //     : { path: '', name: '' };

  return (
    <div>
      {!loading && category && (
        <Popover
          activator={
            <PolarisTextField
              prefix={<Icon source={SearchMinor as any} color="base" />}
              label
              labelHidden
              // label={`Category${(props.value || 0) > 0 ? `: ${cat.path || ''}${cat.name || ''}` : ''}`}
              autoComplete="off"
              placeholder="Search"
              onFocus={() => setActive(true)}
              value={keyword}
              onChange={handleQuery}
              height={30}
              // error={
              //   context.error?.name === 'category_id' && 'Please search category or select category from suggestion.'
              // }
            />
          }
          active={active}
          onClose={() => {
            setActive(false);
          }}
        >
          <Popover.Section>
            <CategoryCallbackContext.Provider
              value={{
                value: props.value,
                onChange: v => {
                  if (props.onChange)
                    props.onChange({
                      ...props.value,
                      category_id: v,
                    });
                },
              }}
            >
              {selectTree !== null && previousTreeId.length > 1 && !keyword && (
                <div className={style.tree_item_reverse}>
                  <div onClick={handlePreviousSelectChildTree}>
                    <Icon source={ChevronLeftMinor} color="base" />
                  </div>
                  <div
                    className={props.value.includes(selectTree.id) ? style.active : ''}
                    onClick={() => {
                      const dummy = [...props.value];
                      const child = selectTree.subCategory ? selectTree.subCategory.split(',').map(x => Number(x)) : [];
                      if (props.value.includes(selectTree.id)) {
                        const removes = dummy.filter(x => x !== selectTree.id).filter(x => !child.includes(x));
                        props.onChange(removes);
                        return;
                      }
                      dummy.push(selectTree.id);
                      props.onChange([...dummy, ...child]);
                    }}
                  >
                    <div className={style.text}>{selectTree.name}</div>
                    {(props.value as any[]).includes(selectTree.id) && <Icon source={CircleTickMinor} color="base" />}
                  </div>
                </div>
              )}
              {(treeNode.length > 6 && showAll === false ? treeNode.slice(0, 6) : treeNode).map(tree => {
                return (
                  <div key={tree.id} className={style.tree_item}>
                    <div
                      className={props.value.includes(tree.id) ? style.active : ''}
                      onClick={() => {
                        const dummy = [...props.value];
                        const child = tree.subCategory ? tree.subCategory.split(',').map(x => Number(x)) : [];
                        if (props.value.includes(tree.id)) {
                          const removes = dummy.filter(x => x !== tree.id).filter(x => !child.includes(x));
                          props.onChange(removes);
                          return;
                        }
                        dummy.push(tree.id);
                        props.onChange([...dummy, ...child]);
                      }}
                    >
                      {props.value.includes(tree.id) && <Icon source={CircleTickMinor} color="base" />}
                      <div className={style.text}>{tree.name}</div>
                    </div>
                    {tree.children.length > 0 && !keyword ? (
                      <div onClick={() => handleSelectChildTree(tree.id)}>
                        <Icon source={ChevronRightMinor} color="base" />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                );
              })}
              {treeNode.length > 6 && showAll === false && (
                <div onClick={() => setShowAll(true)} className={style.tree_item_loading}>
                  <div>Show all {treeNode.length}</div>
                </div>
              )}
            </CategoryCallbackContext.Provider>
          </Popover.Section>
        </Popover>
      )}
    </div>
  );
}

// export function PolarisSuggestCategory(props: PropsSuggest) {
//   const [open, setOpen] = useState(false);
//   const [invisible, setInvisible] = useState(true);
//   const [recommendProductCategoryList, { data, loading }] = useRecommendProductCategoryListLazyQuery();

//   useEffect(() => {
//     if (props.value.title) {
//       const suggestion = props.value.title ? JSON.parse(props.value.title) : { km: '', en: '', zh: '' };
//       recommendProductCategoryList({
//         variables: {
//           keyword: suggestion.en,
//         },
//       });
//       setInvisible(!!suggestion.en ? false : true);
//     }
//   }, [props, recommendProductCategoryList]);

//   const handleToggle = useCallback(() => setOpen(open => !open), []);

//   if (!!invisible) {
//     return <div></div>;
//   }

//   return (
//     <div>
//       <Button onClick={handleToggle}>
//         {
//           (
//             <Text as="h6" variant="headingSm">
//               {open ? 'Hide' : 'Show'} Suggest Category
//             </Text>
//           ) as any
//         }
//       </Button>
//       <br />
//       <br />
//       <Collapsible
//         open={open}
//         id="basic-collapsible"
//         transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
//         expandOnPrint
//       >
//         <Listbox
//           onSelect={v => {
//             props.onChange({
//               ...props.value,
//               category_id: Number(v),
//             });
//             handleToggle();
//           }}
//         >
//           {data &&
//             data.recommendProductCategoryList.map((x: any) => {
//               return (
//                 <Listbox.Option
//                   selected={props.value === x.categoryId}
//                   value={x.categoryId}
//                   key={x.categoryId}
//                 >
//                   {x.categoryName}
//                 </Listbox.Option>
//               );
//             })}
//           {/* {loading && <Listbox.Loading accessibilityLabel="Suggestion cateogry for you..." />} */}
//         </Listbox>
//       </Collapsible>
//     </div>
//   );
// }
