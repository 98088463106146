import React, { ReactElement, useState, useEffect } from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { AdminPicker } from '../../components/AdminPicker';
import { useUser } from 'src/contexts/AuthProvider';

interface RecommendProductRestockItem {
  code: string;
  itemid: number;
  sku_id: number;
  picture: string;
  size: string;
  color: string;
  qty: number;
  sold: number;
  itemurl: string;
  stall_link: string;
  contact: string;
  supplier_name: string;
  followed_by: number;
  followed_by_name: string;
}

const QUERY = gql`
  query recommendProductRestock($offset: Int, $limit: Int, $followedBy: Int) {
    recommendProductRestock(offset: $offset, limit: $limit, followedBy: $followedBy)
  }
`;

const MUTATION_UNFOLLOWED = gql`
  mutation unfollowProduct($id: Int!, $input: ProductInput!) {
    updateProduct(id: $id, data: $input)
  }
`;

function RestockFollowButton(props: { data: RecommendProductRestockItem }): ReactElement {
  const user = useUser();
  const [unfollow, { loading, data }] = useMutation(MUTATION_UNFOLLOWED);

  const unfollowedButton = (
    <button
      className="btn btn-primary mt-2"
      disabled={loading}
      onClick={(): void => {
        unfollow({ variables: { id: props.data.itemid, input: { followedBy: null } } });
      }}
    >
      Unfollow
    </button>
  );

  if (props.data.followed_by && !data) {
    return (
      <div>
        <strong>Followed By</strong>
        <br />
        {props.data.followed_by_name}
        <br />
        {(user.superAdmin || user.id === props.data.followed_by) && unfollowedButton}
      </div>
    );
  }

  return <div></div>;
}

function renderRestockTableRow(item: RecommendProductRestockItem): ReactElement {
  return (
    <tr key={item.sku_id}>
      <td>
        <img src={item.picture} alt="" />
      </td>
      <td>
        {item.code}
        <br />
        {item.itemurl && (
          <div>
            <a href={item.itemurl} target="_blank" rel="noopener noreferrer">
              Product Link
            </a>
          </div>
        )}
        {item.itemurl && (
          <div>
            <a href={item.stall_link} target="_blank" rel="noopener noreferrer">
              Store Link
            </a>
          </div>
        )}
      </td>
      <td>{item.color}</td>
      <td>{item.size}</td>
      <td>{item.qty}</td>
      <td>{item.sold}</td>
      <td>
        <div>
          <strong>Supplier</strong>
          <br />
          <div>{item.supplier_name}</div>
          <div>{item.contact}</div>
        </div>
      </td>
      <td>
        <RestockFollowButton data={item} />
      </td>
    </tr>
  );
}

function renderRestockTable(items: RecommendProductRestockItem[]): ReactElement {
  return (
    <table className="table table-bordered table-lg table-v2 table-striped">
      <thead>
        <tr>
          <th style={{ width: 120 }}></th>
          <th>Code</th>
          <th>Color</th>
          <th>Size</th>
          <th style={{ width: 75 }}>Stock Qty</th>
          <th style={{ width: 75 }}>Sold</th>
          <th>Contact</th>
          <th style={{ width: 150 }}>Followed</th>
        </tr>
      </thead>
      <tbody>{items.map(renderRestockTableRow)}</tbody>
    </table>
  );
}

function renderLoading(): ReactElement {
  return (
    <table className="table table-bordered table-lg table-v2 table-striped">
      <thead>
        <tr>
          <th style={{ width: 120 }}></th>
          <th>Code</th>
          <th>Color</th>
          <th>Size</th>
          <th>Stock Qty</th>
          <th>Sold</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={6}>
            <div className="bg-loading ph-big" />
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <div className="bg-loading ph-big" />
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <div className="bg-loading ph-big" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function renderContent(loading: boolean, data?: Graph.Query): ReactElement {
  if (loading || !data) return renderLoading();
  return renderRestockTable(data.recommendProductRestock);
}

function renderBelow(
  n: number,
  next: boolean,
  offset: number,
  limit: number,
  setOffset: React.Dispatch<React.SetStateAction<number>>,
  bottom = false,
): ReactElement {
  let prevElement = <button className="lf-btn">Prev</button>;
  let nextElement = <button className="lf-btn">Next</button>;

  if (offset > 0) {
    prevElement = (
      <div
        className="lf-btn btn-primary"
        onClick={(): void => {
          setOffset(offset - limit);
        }}
      >
        Prev
      </div>
    );
  }

  if (next) {
    nextElement = (
      <div
        className="lf-btn btn-primary"
        onClick={(): void => {
          setOffset(offset + limit);
        }}
      >
        Next
      </div>
    );
  }

  return (
    <div className={bottom ? 'controls-below-table' : 'controls-below-table mb-3'}>
      <div className="lf-input-group">
        {prevElement}
        <input className="lf-form-control text-center" disabled value={n} type="text" style={{ width: 40 }} />
        {nextElement}
      </div>
    </div>
  );
}

export function RecommendRestockScreen(): ReactElement {
  const pageSize = 20;
  const user = useUser();
  const [pageOffset, setPageOffset] = useState<number>(0);
  const pageNo = Math.round(pageOffset / pageSize) + 1;
  const [followedBy, setFollowedBy] = useState<number>(0);

  useEffect(() => {
    setFollowedBy(user.id);
  }, [user.id]);

  const { loading, data } = useQuery<Graph.Query>(QUERY, {
    variables: { offset: pageOffset, limit: pageSize, followedBy: followedBy === 0 ? undefined : followedBy },
  });

  const hasNext = pageSize === (data ? data.recommendProductRestock.length : 0);

  return (
    <Content>
      <SectionBlock title="Restock Recommendation">
        <div className="controls-above-table" style={{ maxWidth: 300 }}>
          <AdminPicker
            value={followedBy}
            onChange={(v): void => {
              setFollowedBy(v);
              setPageOffset(0);
            }}
            placeholder="All User"
          />
        </div>
        {renderBelow(pageNo, hasNext, pageOffset, pageSize, setPageOffset, false)}
        {renderContent(loading, data)}
        {renderBelow(pageNo, hasNext, pageOffset, pageSize, setPageOffset, true)}
      </SectionBlock>
    </Content>
  );
}
