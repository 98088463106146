import { Button, IndexTable, LegacyCard, Text, TextField } from '@shopify/polaris';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { PolarisConfirm } from 'src/components/polaris/PolarisModal';
import { useCustomToast } from 'src/contexts/ToastProvider';
import useMutationAddSupplierFee from 'src/hooks/useMutationAddSupplierFee';

const manual_fees = [
  {
    fees: 'Product correcting fee',
    charge: '$2',
    details:
      'Actual product does not match with the product picture. The actual product does not match with the product title, description, colors and sizes. The mismatch has caused a return and/or refund. Operators have to check all the units at the warehouse and correct the product information.',
    note: 'Product correcting {product code}',
  },
  {
    fees: 'Delivery charge',
    charge:
      'Charge is based on the package fee calculator on app L192 Delivery. Key the weight and dimensions in the fee calculator.',
    details:
      'For heavy product whose weights and dimensions were declared by sellers. This has caused the platform to cover the delivery cost.',
    note: 'Delivery charge {product code} {order ID}',
  },
];

export function AddSupplierFeeScreen({ id }: { id: number }) {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const [fee, setFee] = useState('0');
  const [reason, setReason] = useState(`Punishment fee  ${query.get('code') ? `{${query.get('code')}}` : ''}`);
  const { toasts, setToasts } = useCustomToast();
  const [addSupplierFee] = useMutationAddSupplierFee();
  const handleFeeChange = useCallback((value: string) => {
    if (Number(value) < 0) value = '0';
    setFee(Number(value).toString());
  }, []);

  const handleReasonChange = useCallback((value: string) => {
    setReason(value);
  }, []);
  return (
    <div>
      <LegacyCard>
        <LegacyCard.Section>
          <TextField label="Amount[$]" type="number" value={fee} autoComplete="off" onChange={handleFeeChange} />
          <br></br>
          <TextField
            label="Fee Description"
            type="text"
            value={reason}
            autoComplete="off"
            onChange={handleReasonChange}
            multiline={5}
          />
          <br />
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              disabled={Number(fee) === 0}
              onClick={() => {
                PolarisConfirm.dialog({
                  title: 'Confirmation',
                  body: [<div key={'0'}>Do you want to add seller fee?</div>],
                  buttons: [
                    {
                      title: 'Ok',
                      class: 'primary',
                      onPress: () => {
                        if (Number(fee) > 0) {
                          addSupplierFee({
                            variables: {
                              supplierId: id,
                              amount: fee,
                              description: reason,
                            },
                          }).then(() => {
                            setToasts([...toasts, { content: 'Seller fee added', status: 'success' }]);
                            setFee('0');
                          });
                        }
                      },
                    },
                  ],
                });
              }}
            >
              Add
            </Button>
          </div>
        </LegacyCard.Section>
        <LegacyCard.Section flush>
          <IndexTable
            headings={[{ title: 'Fees' }, { title: 'Charge' }, { title: 'Details' }, { title: 'Notes for sellers' }]}
            itemCount={2}
            selectable={false}
          >
            {manual_fees.map((row, index) => {
              return (
                <IndexTable.Row key={index} id={index + ''} position={index}>
                  <IndexTable.Cell>
                    <Text as="p" variant="bodySm">
                      {row.fees}
                    </Text>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div style={{ width: 150, whiteSpace: 'initial' }}>
                      <Text as="p" variant="bodySm" breakWord>
                        {row.charge}
                      </Text>
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div style={{ width: 250, whiteSpace: 'initial' }}>
                      <Text as="p" variant="bodySm" breakWord>
                        {row.details}
                      </Text>
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div style={{ width: 250, whiteSpace: 'initial' }}>
                      <Text as="p" variant="bodySm" breakWord>
                        {row.note}
                      </Text>
                    </div>
                  </IndexTable.Cell>
                </IndexTable.Row>
              );
            })}
          </IndexTable>
        </LegacyCard.Section>
      </LegacyCard>
    </div>
  );
}
