import React, { ReactElement, useState } from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { CreateUpdateForm, CreateUpdateProps } from '../../components/form/CreateUpdateForm';
import { Graph } from '../../generated/graph';
import { useForm } from 'react-hook-form';
import { gql } from '@apollo/client';
import { RouteComponentProps } from 'react-router';
import { Checkbox } from '../../components/form/FormComponent';
import { CategoryPicker } from '../../components/pickers/CategoryPicker';

interface NotificationSegmentCondition {
  categoryId?: number;
  spend?: {
    operation: string;
    value?: number;
    from?: number;
    to?: number;
  };
  saving?: {
    operation?: string;
    value?: number;
    from?: number;
    to?: number;
  };
  hasPlayedLuckyDraw?: boolean;
  hasBuyOffline?: boolean;
}

const QUERY = gql`
  query NotificationSegment($id: Int!) {
    notificationSegment(id: $id) {
      id
      name
      order
      schedule
      condition
    }
  }
`;

const UPDATE = gql`
  mutation UpdateNotificationSegment($id: Int!, $data: NotificationSegmentInput!) {
    updateNotificationSegment(id: $id, data: $data)
  }
`;

const CREATE = gql`
  mutation CreateNotificationSegment($data: NotificationSegmentInput!) {
    createNotificationSegment(data: $data)
  }
`;

interface NotificationConditionBuilder {
  category: number;
  spentOperator: string;
  spentFrom: string | number;
  spentTo: string | number;
  savingOperator: string;
  savingFrom: string | number;
  savingTo: string | number;
  hasPlayedLuckyDraw: boolean;
  hasBuyOffline: boolean;
}

function buildNotificationCondition(condition: NotificationConditionBuilder) {
  let result: NotificationSegmentCondition = {};
  const { category, spentOperator, spentTo, spentFrom, savingOperator, savingFrom, savingTo } = condition;

  if (category > 0) result = { ...result, categoryId: category };

  if (spentOperator === 'BETWEEN') {
    result = {
      ...result,
      spend: {
        operation: spentOperator,
        from: Number(spentFrom),
        to: Number(spentTo),
      },
    };
  } else if (spentOperator !== 'NONE') {
    result = {
      ...result,
      spend: {
        operation: spentOperator,
        value: Number(spentFrom),
      },
    };
  }

  if (savingOperator === 'BETWEEN') {
    result = {
      ...result,
      saving: {
        operation: spentOperator,
        from: Number(savingFrom),
        to: Number(savingTo),
      },
    };
  } else if (savingOperator !== 'NONE') {
    result = {
      ...result,
      saving: {
        operation: savingOperator,
        value: Number(savingFrom),
      },
    };
  }

  if (condition.hasBuyOffline) {
    result = {
      ...result,
      hasBuyOffline: true,
    };
  }

  if (condition.hasPlayedLuckyDraw) {
    result = {
      ...result,
      hasPlayedLuckyDraw: true,
    };
  }

  return result;
}

function NotificationSegmentConditionInput(props: {
  value: NotificationSegmentCondition;
  onChange(t: NotificationSegmentCondition): void;
}): ReactElement {
  const [condition, setCondition] = useState<NotificationConditionBuilder>({
    category: props.value.categoryId || 0,
    hasBuyOffline: props.value.hasBuyOffline || false,
    hasPlayedLuckyDraw: props.value.hasPlayedLuckyDraw || false,
    spentOperator: props.value.spend?.operation || 'NONE',
    spentFrom: (props.value.spend?.operation === 'BETWEEN' ? props.value.spend?.from : props.value.spend?.value) || '',
    spentTo: props.value.spend?.to || '',
    savingOperator: props.value.saving?.operation || 'NONE',
    savingFrom:
      (props.value.saving?.operation === 'BETWEEN' ? props.value.saving?.from : props.value.saving?.value) || '',
    savingTo: props.value.saving?.to || '',
  });

  const onConditionChanged = (newCondition: NotificationConditionBuilder) => {
    setCondition(newCondition);
    props.onChange(buildNotificationCondition(newCondition));
  };

  return (
    <div>
      <div className="form-group">
        {
          <CategoryPicker
            value={condition.category}
            onChange={(t): void =>
              onConditionChanged({
                ...condition,
                category: t,
              })
            }
          />
        }
      </div>

      <div className="form-group">
        <label>Spent</label>

        <div className="lf-input-group">
          <select
            className="lf-form-control"
            value={condition.spentOperator}
            onChange={(t): void => onConditionChanged({ ...condition, spentOperator: t.currentTarget.value })}
          >
            <option value="NONE">None</option>
            <option value=">">{'>'}</option>
            <option value=">=">{'>='}</option>
            <option value="<">{'<'}</option>
            <option value="<=">{'<='}</option>
            <option value="BETWEEN">Between</option>
          </select>

          <input
            value={condition.spentFrom}
            className="lf-form-control"
            onChange={(t): void => onConditionChanged({ ...condition, spentFrom: t.target.value })}
          />
          <input
            value={condition.spentTo}
            className="lf-form-control"
            onChange={(t): void => onConditionChanged({ ...condition, spentTo: t.target.value })}
          />
        </div>
      </div>

      <div className="form-group">
        <label>Saving Point</label>

        <div className="lf-input-group">
          <select
            className="lf-form-control"
            value={condition.savingOperator}
            onChange={(t): void => onConditionChanged({ ...condition, savingOperator: t.currentTarget.value })}
          >
            <option value="NONE">None</option>
            <option value=">">{'>'}</option>
            <option value=">=">{'>='}</option>
            <option value="<">{'<'}</option>
            <option value="<=">{'<='}</option>
            <option value="BETWEEN">Between</option>
          </select>

          <input
            value={condition.savingFrom}
            className="lf-form-control"
            onChange={(t): void => onConditionChanged({ ...condition, savingFrom: t.target.value })}
          />
          <input
            value={condition.savingTo}
            className="lf-form-control"
            onChange={(t): void => onConditionChanged({ ...condition, savingTo: t.target.value })}
          />
        </div>
      </div>

      <div>
        <Checkbox
          label="Lucky Draw Player"
          checked={condition.hasPlayedLuckyDraw}
          onChange={t => onConditionChanged({ ...condition, hasPlayedLuckyDraw: t.target.checked })}
        />
        <Checkbox
          label="Offline Customer"
          checked={condition.hasBuyOffline}
          onChange={t => onConditionChanged({ ...condition, hasBuyOffline: t.target.checked })}
        />
      </div>
    </div>
  );
}

function NotificationSegmentScheduleInput(props: {
  value: string | null | undefined;
  onChange: (v: string | null) => void;
}): ReactElement {
  const hour = props.value ? props.value.split(' ')[2] : '';
  const minute = props.value ? props.value.split(' ')[1] : '';

  return (
    <div>
      <Checkbox
        label="Daily Build Schedule"
        checked={props.value ? true : false}
        onChange={(t): void => {
          if (t.currentTarget.checked) props.onChange('0 0 0 * * *');
          else props.onChange(null);
        }}
      />
      <div className="form-group mt-2">
        <input
          className="lf-form-control lf-inline"
          style={{ width: 50 }}
          placeholder="HH"
          onChange={(t): void => {
            props.onChange(`0 ${minute} ${t.currentTarget.value} * * *`);
          }}
          value={hour}
          disabled={props.value ? false : true}
        />{' '}
        &nbsp;:&nbsp;
        <input
          className="lf-form-control lf-inline"
          style={{ width: 50 }}
          placeholder="MM"
          onChange={(t): void => {
            props.onChange(`0 ${t.currentTarget.value} ${hour} * *`);
          }}
          value={minute}
          disabled={props.value ? false : true}
        />
      </div>
    </div>
  );
}

function NotificationSegmentScreenForm({
  update,
  defaultValue,
}: CreateUpdateProps<Graph.NotificationSegmentInput>): React.ReactElement {
  const { register, handleSubmit } = useForm<Graph.NotificationSegmentInput>({
    defaultValues: { order: 0, ...defaultValue },
  });
  const [schedule, setSchedule] = useState(defaultValue.schedule);
  const [condition, setCondition] = useState<NotificationSegmentCondition>(
    defaultValue.condition ? defaultValue.condition : {},
  );

  const onSubmit = handleSubmit(data => {
    update({
      ...data,
      order: Number(data.order),
      schedule,
      condition,
    });
  });

  return (
    <form onSubmit={onSubmit} noValidate={true}>
      <div className="form-group">
        <label>Name</label>
        <input name="name" className="lf-form-control" ref={register} />
      </div>

      <div className="form-group">
        <label>Order</label>
        <input name="order" className="lf-form-control" ref={register} />
      </div>

      <fieldset className="form-group">
        <legend>Condition</legend>
        <NotificationSegmentConditionInput value={condition} onChange={(t): void => setCondition(t)} />
      </fieldset>

      <fieldset className="form-group">
        <legend>Schedule</legend>
        <NotificationSegmentScheduleInput value={schedule} onChange={(t): void => setSchedule(t)} />
      </fieldset>

      <div className="form-buttons-w">
        <button name="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
}

export function NotificationSegmentScreen(props: RouteComponentProps<{ id: string }>): React.ReactElement {
  return (
    <Content>
      <SectionBlock title="Segments" width={600}>
        <CreateUpdateForm
          query={QUERY}
          create={CREATE}
          update={UPDATE}
          body={NotificationSegmentScreenForm}
          updateReturned="/notification/list#segment"
          id={props.match.params.id}
        />
      </SectionBlock>
    </Content>
  );
}
