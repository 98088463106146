import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { InlineTab, InlineTabContent } from '../../components/light/InlineTab';
import { NotificationDeliveryPartial } from './NotificationDeliveryPartial';
import { NotificationStatusPartial } from './NotificationStatusPartial';
import { NotificationSegmentPartial } from './NotificationSegmentPartial';
import { NotificationSchedulePartial } from './NotificationSchedulePartial';
import { NotificationAnnouncementPartial } from './NotificationAnnouncementPartial';

export function NotificationListScreen(): React.ReactElement {
  return (
    <Content>
      <SectionBlock title="Notification">
        <InlineTab lazy={true}>
          <InlineTabContent name="Notification" alias="notification">
            <NotificationSchedulePartial />
          </InlineTabContent>
          <InlineTabContent name="Delivery" alias="delivery">
            <NotificationDeliveryPartial />
          </InlineTabContent>
          <InlineTabContent name="Segments" alias="segments">
            <NotificationSegmentPartial />
          </InlineTabContent>
          <InlineTabContent name="Status" alias="status">
            <NotificationStatusPartial />
          </InlineTabContent>
          <InlineTabContent name="Announcement" alias="announcement">
            <NotificationAnnouncementPartial />
          </InlineTabContent>
        </InlineTab>
      </SectionBlock>
    </Content>
  );
}
