/* eslint-disable */
export class Field {
  public obj: {
    query: string;
    columns: object[];
  };

  constructor(obj: { query: any; columns: object[] }) {
    this.obj = obj;
  }
}
