import { gql, useMutation } from '@apollo/client';
import { Graph } from 'src/generated/graph';

const MUTATION = gql`
  mutation massUpdateProduct($massUpdateProductId: [Int]!, $data: MassProductInput!) {
    massUpdateProduct(id: $massUpdateProductId, data: $data)
  }
`;

export function useMutationMassUpdateProduct() {
  return useMutation<Graph.Mutation>(MUTATION, {
    refetchQueries: ['productListV2'],
  });
}
