/* eslint-disable */

import { SectionBlock } from './light/SectionBlock';
import { InventoryStockInProductType, InventoryStockInProduct } from './InventoryStockInProduct';
import { ApolloClient } from '@apollo/client';
import { CachedProductBarcodeQuery } from '../libs/CachedProductBarcodeQuery';
import { PurchaseOrder } from '../types/PurchaseOrderType';
import { purchaseOrderCostReader, PurchaseOrderCostItemDescription } from '../libs/PurchaseOrderCostReader';
import React from 'react';

type Props = {
  title: string;
  items: InventoryStockInProductType[];
  apollo: ApolloClient<{}>;
  purchaseOrder: PurchaseOrder;
  requiredCost: boolean;
  onValueChanged: (items: InventoryStockInProductType[]) => void;
  onAddTransaction: () => void;
};

export class ScanningTable extends React.Component<Props> {
  public state: {
    barcode: string;
  } = {
    barcode: '',
  };

  private productInput: HTMLInputElement | null;
  private cachedBarcodeScanner: CachedProductBarcodeQuery;
  private costTable: PurchaseOrderCostItemDescription[];

  constructor(props: Props) {
    super(props);
    this.productInput = null;
    this.cachedBarcodeScanner = new CachedProductBarcodeQuery(props.apollo);

    if (this.props.requiredCost) {
      this.costTable = purchaseOrderCostReader(props.purchaseOrder.orderDetail);
    } else {
      this.costTable = [];
    }
  }

  public render() {
    console.log(this.props);
    return (
      <SectionBlock title={this.props.title}>
        <div className="form-group">
          <input
            autoFocus
            placeholder="Enter Product Barcode"
            className="form-control"
            ref={(input: HTMLInputElement) => {
              this.productInput = input;
            }}
            type="text"
            value={this.state.barcode}
            onChange={this.onProductBarcodeChanged}
            onKeyPress={this.onProductBarcodeKeypress}
          />
        </div>

        {this.renderTable()}

        <div className="form-buttons-w">
          <button className="btn btn-primary" onClick={this.props.onAddTransaction}>
            Add Transactions
          </button>
        </div>
      </SectionBlock>
    );
  }

  private renderTable = () => {
    let countQty = this.props.items.reduce((a, b) => a + b.qty, 0);

    return (
      <table className="table table-lightborder">
        <thead>
          <tr>
            <th>Code</th>
            <th>Color</th>
            <th>Size</th>
            <th>Shipment</th>
            <th style={{ width: '100px' }}>Qty</th>
            {this.props.requiredCost && <th style={{ width: '130px' }}>Cost</th>}
            {this.props.requiredCost && <th style={{ width: '130px' }}>Cost</th>}
            <th style={{ width: '70px' }}></th>
            <th style={{ width: '130px' }}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4} className="text-right">
              <strong>Total Qty</strong>
            </td>
            <td colSpan={5}>{countQty}</td>
          </tr>
          {this.props.items.length > 0 ? (
            this.props.items.map((item: InventoryStockInProductType, key: number) => {
              return (
                <InventoryStockInProduct
                  requiredCost={this.props.requiredCost}
                  onUpdate={this.onItemUpdate}
                  onRemove={this.onItemRemoved}
                  key={item.skuID}
                  item={item}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={7} style={{ textAlign: 'center' }}>
                There's nothing here
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} className="text-right">
              <strong>Total Qty</strong>
            </td>
            <td colSpan={5}>{countQty}</td>
          </tr>
        </tfoot>
      </table>
    );
  };

  private getProduct = async (barcode: string) => {
    this.setState({ loading: true });
    const product = await this.cachedBarcodeScanner.scan(barcode);
    this.setState({ loading: false, product_barcode: '' });

    if (product) {
      const copy = this.props.items;
      let product_list = [];
      const existed_index = copy.findIndex(o => o.skuID === product.skuID);

      if (existed_index >= 0) {
        copy[existed_index].qty += 1;
        product_list = copy;
      } else {
        let new_product = { ...product };

        new_product.qty = 1;

        if (this.props.requiredCost) {
          [new_product.cost, new_product.costDescription] = this.getProductPreviousCost(Number(product.itemID));
        }

        product_list = [new_product, ...copy];
      }

      this.props.onValueChanged(product_list);
    }
  };

  private getProductPreviousCost(productID: number): string[] {
    for (const line of this.costTable) {
      if (productID === line.id) return [line.price, line.priceDescription];
    }
    return ['', ''];
  }

  public onItemUpdate = () => {
    this.props.onValueChanged([...this.props.items]);
  };

  public onItemRemoved = (item: InventoryStockInProductType) => {
    this.props.onValueChanged(this.props.items.filter(x => x !== item));
  };

  private onProductBarcodeKeypress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (this.productInput) {
        await this.getProduct(this.productInput.value);
        this.setState({ barcode: '' });
      }
    }
  };

  private onProductBarcodeChanged = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      barcode: e.currentTarget.value,
    });
  };
}
