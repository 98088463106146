import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { SectionLoading } from '../../../components/loading/SectionLoading';
import { Content } from '../../../components/light/Content';
import { SectionBlock } from '../../../components/light/SectionBlock';
import { Link } from 'react-router-dom';

const QUERY = gql`
  query {
    customPageList {
      id
      name
      priority
    }
  }
`;

export function CustomPageListScreen() {
  const { data } = useQuery<Graph.Query>(QUERY);

  if (!data || !data.customPageList) {
    return <SectionLoading title="Custom Pages" />;
  }

  return (
    <Content>
      <SectionBlock title="Custom Pages">
        <div className="controls-above-table">
          <Link className="btn btn-primary" to="/app/page/create">
            New Custom Page
          </Link>
        </div>

        <table className="table table-lightborder">
          <thead>
            <tr>
              <th style={{ width: 20 }}></th>
              <th>Page</th>
            </tr>
          </thead>
          <tbody>
            {data.customPageList.map(x => {
              return (
                <tr key={x.id?.toString()}>
                  <td>
                    <span style={{ display: 'inline-block', width: 30 }}>
                      {x.priority === 2 && <i className="fas fa-star" style={{ color: '#f1c40f' }}></i>}
                      {x.priority === 1 && <i className="fas fa-star" style={{ color: '#95a5a6' }}></i>}
                    </span>
                  </td>
                  <td>
                    <Link to={`/app/page/${x.id}`}>{x.name}</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </SectionBlock>
    </Content>
  );
}
