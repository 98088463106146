import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Graph } from 'src/generated/graph';
import Layout from 'src/components/layout/Layout';
import { Table } from 'src/components/layout/tables/Table';
import Button from 'src/components/layout/xform/Button';
import Modal from 'src/components/layout/Modal';
import { useState } from 'react';
import XForm from 'src/components/layout/xform/XForm';
import { Tab } from 'src/components/layout/tabs/Tab';
import { useEffect } from 'react';
import moment from 'moment';
import { LinkButton } from 'src/components/layout/xform/LinkButton';
import { rebuildQueryString, useQueryStringContext } from 'src/libs/QueryString';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

const QUERY = gql`
  query sellerLiveCampaignList(
    $offset: Int
    $limit: Int
    $status: [SellerAdsCampaignItemStatus]
    $deliveryStatus: [SellerAdsCampaignDeliveryStatus]
    $orderBy: SortOrder
    $productCode: String
  ) {
    sellerLiveCampaignList(
      status: $status
      orderBy: $orderBy
      offset: $offset
      limit: $limit
      deliveryStatus: $deliveryStatus
      productCode: $productCode
    ) {
      page {
        current
        size
        total
      }
      data {
        campaignId
        supplier {
          name
        }
        product {
          id
          code
          price
          discount
          thumbnail
          stock
          ratingScore
          ratingCount
        }
        appliedDate
        approvedDate
        biding
        budget
        historyReach
        historyClicks
        historySoldQty
        historyRevenue
        historyLastSold
        settledAmount
        deliveryStatus
        activity
        basedLineSalesQty
        appSalesQty
        liveSalesQty
        campaignReach
        campaignClicks
        saleQtyToPay
        deliveryStatus
        settledAmount
      }
    }
  }
`;

const MUTATION_APPROVE = gql`
  mutation approveSellerAdsItem($id: Int!, $activity: String) {
    approveSellerAdsItem(id: $id, activity: $activity)
  }
`;
const MUTATION_REJECT = gql`
  mutation rejectSellerAdsItem($id: Int!, $reason: String) {
    rejectSellerAdsItem(id: $id, reason: $reason)
  }
`;

function AdsRow({
  data,
  index,
  onSearch,
}: {
  data: Graph.SellerLiveCampaign;
  index: number;
  onSearch: (v: string) => void;
}) {
  const status = !window.location.hash ? 'APPROVE' : window.location.hash.substring(1);
  const [show, setShow] = useState(false);
  const [type, setType] = useState<'ACTIVITY' | 'REJECTED'>('ACTIVITY');
  const [live, setLive] = useState(0);
  const [notification, setNotication] = useState(0);
  const [social, setSocial] = useState(0);
  const [rejectInput, setRejectInput] = useState('');
  const [approve] = useMutation(MUTATION_APPROVE, { refetchQueries: ['sellerLiveCampaignList'] });
  const [reject] = useMutation(MUTATION_REJECT, { refetchQueries: ['sellerLiveCampaignList'] });

  const price: number = Number(data.product?.price || 0) * (1 - Number(data.product?.discount || 0) / 100);
  const rating: number =
    Number(data.product?.ratingCount) > 0
      ? Number(data.product?.ratingScore || 0) / Number(data.product?.ratingCount || 0)
      : 0;

  const onApproveClicked = () => {
    approve({
      variables: {
        id: Number(data.campaignId),
        activity: `${live}${notification}${social}`,
      },
    });
    setShow(false);
  };

  const onRejectClicked = () => {
    reject({
      variables: {
        id: Number(data.campaignId),
        reason: rejectInput,
      },
    });
    setShow(false);
  };

  const onClickApproved = () => {
    setType('ACTIVITY');
    setShow(true);
  };

  const onClickReject = () => {
    setType('REJECTED');
    setShow(true);
  };

  return (
    <tr>
      <td>{index}</td>
      <td>
        <small>{data.supplier?.name}</small>
        <br />
        <small className={data.deliveryStatus === 'COMPLETE' ? 'text-success' : 'text-danger'}>
          {data.deliveryStatus}
        </small>
      </td>
      <td>
        <small>Live At: {data.appliedDate}</small>
        <br />
        {status === 'APPROVE' && <small>Update At: {data.approvedDate}</small>}
      </td>
      <td>
        <img src={data.product?.thumbnail || ''} style={{ width: 75, height: 75 }} alt="" />
      </td>
      <td>
        <a href={`https://www.l192.com/product/${data.product?.id}`} rel="noopener noreferrer" target="_blank">
          {data.product?.code}
        </a>
        <br />
        <small>
          Price:{' '}
          {Number(data.product?.discount || 0) > 0 && (
            <span className="text-danger" style={{ textDecoration: 'line-through' }}>
              ${data.product?.price}
            </span>
          )}
          ${price.toFixed(2)}
        </small>
        <br />
        <small>Discount: {data.product?.discount?.toPrecision(2)}%</small>
        <br />
        <small>Stock: {data.product?.stock}</small>
      </td>
      <td>
        <strong>${data.biding?.toFixed(2)}</strong>
        <br />
        <small>{(Number((data.biding || 0) / price) * 100).toPrecision(2)}%</small>
      </td>
      <td>
        <strong>${data.budget?.toFixed(2)}</strong>
      </td>
      <td>
        {status === 'PENDING' ? (
          <div>
            <small>Sales QTY: {data.historySoldQty || 0} units</small>
            <br />
            <small>Sales: ${data.historyRevenue || 0}</small>
            <br />
            <small>Rating: {rating || 0}</small>
            <br />
            <small>Clicks: {data.historyClicks}</small>
            <br />
            <small>
              Last sold data:{' '}
              {data.historyLastSold && moment(new Date(Number(data.historyLastSold) * 1)).format('YYYY-MM-DD')}
            </small>
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <small>
                <strong style={{ borderRadius: 4, padding: 3 }} className="bg-info">
                  Sales QTY: {data.saleQtyToPay}
                </strong>
                <Link
                  to={`/supplier/ads/order/${data.campaignId}`}
                  style={{ borderRadius: 4, backgroundColor: '#343a40', marginLeft: 5, padding: 3, color: '#fff' }}
                >
                  <i className="os-icon os-icon-eye"></i>
                </Link>
              </small>{' '}
              <small>
                <strong style={{ borderRadius: 4, padding: 3 }} className="bg-warning">
                  Sales: ${Number((data.saleQtyToPay || 0) * price).toFixed(2)}
                </strong>
              </small>
            </div>
            <small>
              <Table>
                <thead>
                  <tr>
                    <th className="text-center">App</th>
                    <th className="text-center">Live</th>
                    <th className="text-center">Baseline</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{data.appSalesQty}</td>
                    <td className="text-center">{data.liveSalesQty}</td>
                    <td className="text-center">{data.basedLineSalesQty}</td>
                  </tr>
                </tbody>
              </Table>
            </small>
            <small>
              <Table>
                <thead>
                  <tr>
                    <th className="text-center">Reach</th>
                    <th className="text-center">Clicks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">{data.campaignReach}</td>
                    <td className="text-center">{data.campaignClicks}</td>
                  </tr>
                </tbody>
              </Table>
            </small>
          </div>
        )}
      </td>
      {status === 'APPROVE' && (
        <td>
          <small>
            <strong style={{ borderRadius: 4, padding: 3, backgroundColor: '#1abc9c', fontSize: 14 }}>
              Spent: ${Number((data.saleQtyToPay || 0) * (data.biding || 0)).toFixed(2)}
            </strong>
          </small>
          <br />
          <small>Settled: ${Number(data.settledAmount || 0).toFixed(2)}</small>
        </td>
      )}
      {status === 'APPROVE' && (
        <td colSpan={2}>
          {data.activity?.charAt(0) === '1' && <small>Live</small>}
          <br />
          {data.activity?.charAt(1) === '1' && <small>Notification</small>}
          <br />
          {data.activity?.charAt(2) === '1' && <small>Social</small>}
        </td>
      )}
      {status === 'PENDING' && (
        <td>
          <Button onClick={onClickApproved}>Approve</Button>
          <Modal show={show} onClose={() => setShow(false)}>
            <Modal.Header>{type === 'ACTIVITY' ? 'Select Activity' : 'Reject Reason'}</Modal.Header>
            <Modal.Body style={{ padding: 10 }}>
              {type === 'ACTIVITY' ? (
                <div>
                  <XForm.Check label="Live" value={live} onChange={e => setLive(e.currentTarget.checked ? 1 : 0)} />
                  <XForm.Check
                    label="Notification"
                    value={notification}
                    onChange={e => setNotication(e.currentTarget.checked ? 1 : 0)}
                  />
                  <XForm.Check
                    label="Social Media Post"
                    value={social}
                    onChange={e => setSocial(e.currentTarget.checked ? 1 : 0)}
                  />
                </div>
              ) : (
                <div>
                  <XForm.TextArea
                    placeholder="say somthing..."
                    label=""
                    value={rejectInput}
                    onChange={e => setRejectInput(e.currentTarget.value)}
                  />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={type === 'ACTIVITY' ? onApproveClicked : onRejectClicked}>Submit</Button>
            </Modal.Footer>
          </Modal>
        </td>
      )}
      {status === 'PENDING' && (
        <td>
          <Button onClick={onClickReject} theme="danger">
            Reject
          </Button>
        </td>
      )}
      <td>
        <i
          className="os-icon os-icon-search text-primary"
          style={{ cursor: 'pointer' }}
          onClick={() => onSearch(data.product?.code + '')}
        ></i>
      </td>
    </tr>
  );
}

function RenderTable({
  status,
  completed,
  delivering,
  props,
}: {
  status: string;
  completed?: boolean;
  delivering?: boolean;
  props: RouteComponentProps;
}) {
  const [deliveringStatus, setDeliveringStatus] = useState<string[]>([]);
  const qs = useQueryStringContext<{ page: string; search: string }>({ page: '1', search: '' });
  const page = Number(qs.page);
  const search = String(qs.search);
  const [searchInput, setSearchInput] = useState(search);
  const size = 20;

  useEffect(() => {
    if (completed === true) {
      setDeliveringStatus(res => [...res, 'COMPLETE']);
    }

    if (delivering === true) {
      setDeliveringStatus(res => [...res, 'DELIVERING']);
    }
  }, [completed, setDeliveringStatus, delivering]);

  const { data } = useQuery<Graph.Query>(QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      offset: (page - 1) * size,
      limit: size,
      status: [status],
      deliveryStatus: deliveringStatus.length > 0 ? deliveringStatus : undefined,
      orderBy: status === 'PENDING' ? 'ASC' : 'DESC',
      productCode: search,
    },
  });

  return (
    <>
      <XForm.Footer>
        <XForm.Division>
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search product code"
            style={{ width: 150 }}
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                props.history.push(rebuildQueryString({ page: '1', search: searchInput }));
              }
            }}
          />
          <div>
            {data && (
              <span>
                {data.sellerLiveCampaignList?.page?.current} to{' '}
                {Number(data.sellerLiveCampaignList?.page?.size) +
                  Number(data.sellerLiveCampaignList?.page?.current) -
                  1 >
                Number(data.sellerLiveCampaignList?.page?.total)
                  ? data.sellerLiveCampaignList?.page?.total
                  : Number(data.sellerLiveCampaignList?.page?.size) +
                    Number(data.sellerLiveCampaignList?.page?.current) -
                    1}{' '}
                of {data.sellerLiveCampaignList?.page?.total}
              </span>
            )}
            <LinkButton to={rebuildQueryString({ page: page - 1 })} disabled={Number(qs.page) === 1}>
              Back
            </LinkButton>
            {data && (
              <LinkButton
                to={rebuildQueryString({ page: page + 1 })}
                disabled={Number(data.sellerLiveCampaignList?.data?.length) < size}
              >
                Next
              </LinkButton>
            )}
          </div>
        </XForm.Division>
      </XForm.Footer>
      <Table seperator={true} border={true} stripe={true}>
        <thead>
          <tr>
            <th style={{ width: 50 }}>No</th>
            <th>Seller</th>
            <th>Date</th>
            <th style={{ width: 50 }}>Image</th>
            <th style={{ width: 150 }}>Product Info</th>
            <th style={{ width: 100 }}>Biding</th>
            <th style={{ width: 100 }}>Budget</th>
            <th>{window.location.hash === '#PENDING' ? 'Product History' : 'Campiagn Result'}</th>
            {window.location.hash === '#APPROVE' && <th>Settlment</th>}
            <th style={{ width: 100 }}>{window.location.hash === '#PENDING' ? '' : 'Activities'}</th>
            <th style={{ width: 120 }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.sellerLiveCampaignList?.data?.map((x, i) => (
              <AdsRow
                data={x}
                key={i}
                index={Number(data.sellerLiveCampaignList?.page?.current) + i}
                onSearch={v => {
                  setSearchInput(v);
                  props.history.push(rebuildQueryString({ page: '1', search: v }));
                }}
              />
            ))}
        </tbody>
      </Table>
      <XForm.Footer>
        {data && (
          <span>
            {data.sellerLiveCampaignList?.page?.current} to{' '}
            {Number(data.sellerLiveCampaignList?.page?.size) + Number(data.sellerLiveCampaignList?.page?.current) - 1 >
            Number(data.sellerLiveCampaignList?.page?.total)
              ? data.sellerLiveCampaignList?.page?.total
              : Number(data.sellerLiveCampaignList?.page?.size) +
                Number(data.sellerLiveCampaignList?.page?.current) -
                1}{' '}
            of {data.sellerLiveCampaignList?.page?.total}
          </span>
        )}
        <LinkButton to={rebuildQueryString({ page: page - 1 })} disabled={Number(qs.page) === 1}>
          Back
        </LinkButton>
        {data && (
          <LinkButton
            to={rebuildQueryString({ page: page + 1 })}
            disabled={Number(data.sellerLiveCampaignList?.data?.length) < size}
          >
            Next
          </LinkButton>
        )}
      </XForm.Footer>
    </>
  );
}

export function SupplierAdsCampaignScreen(props: RouteComponentProps) {
  const [completed, setCompleted] = useState(true);
  const [delivering, setDelivering] = useState(true);
  const [loading, setLoading] = useState(false);
  const status = !window.location.hash ? 'PENDING' : window.location.hash.substring(1);

  useEffect(() => {
    window.location.hash = '#' + status;
  }, [status]);

  return (
    <Layout full>
      <Layout.Header>Review Seller Ads</Layout.Header>
      <Tab>
        <Tab.Item title="Approved" alias="#APPROVE">
          <XForm.Division column={[1]}>
            <XForm.Check
              label="Completed"
              checked={completed}
              onChange={e => {
                setLoading(true);
                setCompleted(e.currentTarget.checked);
                setTimeout(() => {
                  setLoading(false);
                }, 500);
              }}
            />
            <XForm.Check
              label="Delivering"
              checked={delivering}
              onChange={e => {
                setLoading(true);
                setDelivering(e.currentTarget.checked);
                setTimeout(() => {
                  setLoading(false);
                }, 500);
              }}
            />
          </XForm.Division>
          <br />
          {!loading && <RenderTable status={status} completed={completed} delivering={delivering} props={props} />}
        </Tab.Item>
        <Tab.Item title="Pending" alias="#PENDING">
          <RenderTable status={status} completed={false} delivering={false} props={props} />
        </Tab.Item>
      </Tab>
    </Layout>
  );
}
