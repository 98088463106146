import styles from './LoadingRow.module.scss';

export function LoadingRow() {
  return (
    <>
      {/* {topPlaceHolder && (
        <div className={styles.header}>
          <div></div>
        </div>
      )} */}
      <div className={styles.container}>
        <div>
          <div className={styles.item}>
            <div className={styles.img}></div>
            <div className={styles.cnt}>
              <div className={styles.title} style={{ width: '95%' }}></div>
              <div className={styles.txt} style={{ width: '45%' }}></div>
              <div className={styles.txt3} style={{ width: '30%' }}></div>
              <div className={styles.txt2} style={{ width: '25%' }}></div>
              <div className={styles.txt2} style={{ width: '20%' }}></div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.img}></div>
            <div className={styles.cnt}>
              <div className={styles.title} style={{ width: '95%' }}></div>
              <div className={styles.txt} style={{ width: '45%' }}></div>
              <div className={styles.txt3} style={{ width: '30%' }}></div>
              <div className={styles.txt2} style={{ width: '25%' }}></div>
              <div className={styles.txt2} style={{ width: '20%' }}></div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.img}></div>
            <div className={styles.cnt}>
              <div className={styles.title} style={{ width: '95%' }}></div>
              <div className={styles.txt} style={{ width: '45%' }}></div>
              <div className={styles.txt3} style={{ width: '30%' }}></div>
              <div className={styles.txt2} style={{ width: '25%' }}></div>
              <div className={styles.txt2} style={{ width: '20%' }}></div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.img}></div>
            <div className={styles.cnt}>
              <div className={styles.title} style={{ width: '95%' }}></div>
              <div className={styles.txt} style={{ width: '45%' }}></div>
              <div className={styles.txt3} style={{ width: '30%' }}></div>
              <div className={styles.txt2} style={{ width: '25%' }}></div>
              <div className={styles.txt2} style={{ width: '20%' }}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
