/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useLazyQuery } from '@apollo/client';
import { Avatar, Icon, Modal, Text, Thumbnail } from '@shopify/polaris';
import { StarFilledMinor } from '@shopify/polaris-icons';
import React, { useState } from 'react';
import { Graph } from 'src/generated/graph';

const QUERY = gql`
  query productReviewList($productId: Int, $merchantId: Int) {
    productReviewList(productId: $productId, merchantId: $merchantId) {
      comment
      rating
      customer {
        id
        image
        name
      }
      id
      createdAt
      updatedAt
      attachments {
        src
        thumbnail
      }
    }
  }
`;

export function checkProperties(obj: any) {
  for (const key in obj) {
    if (key !== '__typename' && obj[key] !== null && obj[key] !== '') {
      return obj[key];
    }
  }
  return '';
}

export function RatingProduct({ item }: { item: any }) {
  const [open, setOpen] = useState(false);
  const [productQuery, { data, loading }] = useLazyQuery<Graph.Query>(QUERY);

  const activator = (
    <small
      style={{ display: 'flex' }}
      onClick={() => {
        setOpen(!open);
        productQuery({
          variables: {
            productId: Number(item.id),
          },
        });
      }}
    >
      <b style={{ color: '#5E5E5E' }}>{item.productReview ? item.productReview.reviews : 0} reviews</b>
      {'|'}
      <span style={{ display: 'flex', alignItems: 'center' }} className={'text-danger'}>
        <span style={{ marginTop: -5 }}>
          <Icon source={StarFilledMinor} color="critical" />
        </span>
        <span>{item.productReview ? item.productReview.rating : 0}</span>
      </span>
    </small>
  );

  return (
    <Modal
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Thumbnail alt="" source={item.picture} size="medium" />
          <div className="ml-2">
            <Text as="h4" variant="bodyMd">
              {checkProperties(item.title)}
            </Text>
            <small>
              {Number(item.discount) > 0 ? (
                <>
                  <b className="text-danger">
                    ${(Number(item.price) - Number(item.discount / 100) * Number(item.price)).toFixed(2)}
                  </b>
                  <b className="ml-1" style={{ color: '#5E5E5E', textDecoration: 'line-through' }}>
                    ${item.price}
                  </b>{' '}
                </>
              ) : (
                <b style={{ color: '#5E5E5E' }}>${item.price}</b>
              )}
            </small>
          </div>
        </div>
      }
      open={open}
      onClose={() => setOpen(!open)}
      activator={activator}
      loading={loading}
    >
      {data &&
        data.productReviewList?.map((item, index) => {
          return (
            <Modal.Section key={index}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Avatar size="medium" customer name="" initials={item?.customer?.name?.charAt(0)} />
                <div className="ml-2">
                  <div>
                    <small>Reviewed: {item?.createdAt}</small>
                  </div>
                  <div>
                    <small>Updated: {item?.updatedAt}</small>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className="mt-1">
                {[...new Array(item?.rating)].map((_, i) => {
                  return (
                    <div key={i}>
                      <Icon source={StarFilledMinor} color="critical" />
                    </div>
                  );
                })}
              </div>
              <div className="mt-2">
                <small>{item?.comment}</small>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}
                className="mt-3"
              >
                {item?.attachments?.map((x, i) => {
                  return (
                    <div key={i} className="mr-2 mb-2">
                      <Thumbnail source={x?.thumbnail + ''} alt="" key={x?.thumbnail} />
                    </div>
                  );
                })}
              </div>
            </Modal.Section>
          );
        })}
    </Modal>
  );
}
