import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Barcode from 'react-barcode';
import { createPortal } from 'react-dom';
import { useSetting } from 'src/components/context/SettingContext';
import Button from 'src/components/layout/xform/Button';
import { Graph } from 'src/generated/graph';
import Formatter from 'src/libs/Formatter';
import QRCode from 'qrcode.react';

export const QUERY_CUSTOMER_ADDRESS_LIST = gql`
  query CustomerAddressList($customerID: Int) {
    customerAddressList(customerID: $customerID) {
      id
      note
      default
      label
      dropOff {
        id
        name
        provinceName
      }
    }
  }
`;

function PrintReceiptInternal(
  { item, onCallBack }: { item: Graph.Package | null; onCallBack?: () => void },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mainRef: any,
) {
  const setting = useSetting();

  const appointedAtTime = moment(item?.appointedAt || '').format('hh:mm A');
  const appointedAtDate = moment(item?.appointedAt || '').format('YYYY/MM/DD');
  const [iframeBody, setIframeBody] = useState<HTMLElement | null | undefined>(null);
  const [khmerRiel] = useState<number>(setting?.supplierSetting?.exchangeRate || 4150);
  const [customerAD, { data }] = useLazyQuery<Graph.Query>(QUERY_CUSTOMER_ADDRESS_LIST);
  const customerArea = data?.customerAddressList?.filter(y => !!y.default);
  const ConvertToRiel = (value: number) => {
    const toRiel = khmerRiel * value;
    return toRiel.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ',') + '៛';
  };

  const BarcodeDash = (code: string | number | null | undefined) => {
    const barcode =
      code?.toString().substring(0, 2) +
      '-' +
      code
        ?.toString()
        .slice(2)
        .split('')
        .reverse()
        .join('')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '-')
        .split('')
        .reverse()
        .join('');
    return barcode;
  };

  const ref = useRef<HTMLIFrameElement>(null);

  useImperativeHandle(mainRef, () => ({
    print: () => setting?.supplierSetting?.exchangeRate && ref?.current?.contentWindow?.print(),
  }));
  const itemId = item?.customer?.id;
  useEffect(() => {
    customerAD({
      variables: {
        customerID: Number(itemId),
      },
    });
  }, [itemId, customerAD]);
  useEffect(() => {
    setIframeBody(ref.current?.contentWindow?.document?.body);
  }, [ref]);

  useEffect(() => {
    if (item && onCallBack) {
      onCallBack();
    }
  }, [item, onCallBack]);

  const dom = (
    <div className="wrapFrame">
      <link type="text/css" rel="Stylesheet" href="/printing.css" />
      <div style={{ textAlign: 'center', marginBottom: '5px' }}>
        <h3>L192​</h3>
        <p>Online Fulfilment Services</p>
        {item?.type === 'THIRD_PARTY' && !!item?.isDropbox && (
          <small style={{ fontSize: '11px' }}>
            {appointedAtDate} | {appointedAtTime}
          </small>
        )}
        {((item?.type !== 'THIRD_PARTY' && !item?.isDropbox) ||
          (item?.type !== 'THIRD_PARTY' && item?.isDropbox) ||
          (item?.type === 'THIRD_PARTY' && !item?.isDropbox)) && (
          <>
            <Barcode value={`${item?.barcode}`} width={2} height={30} margin={0} displayValue={false} />
            <p style={{ marginTop: '5px', fontSize: '16px' }}>
              {item?.barcodeInitial} | {BarcodeDash(item?.barcode)} ​
            </p>
          </>
        )}
      </div>
      {item?.type === 'THIRD_PARTY' && !!item?.isDropbox && (
        <div style={{ display: 'flex', padding: '10px' }}>
          <QRCode value={`https://app.l192.com/package?id=${item?.uuid}`} size={60} />
          <div style={{ fontSize: '0.685rem', paddingLeft: '10px' }}>
            <p style={{ marginTop: '5px', fontSize: '16px', fontWeight: 'bold' }}>
              {item?.barcodeInitial} | {BarcodeDash(item?.barcode)} ​
            </p>
            <span style={{ fontSize: '0.685rem' }}>
              Third Party
              <br />
              {item?.additionalInstruction}
            </span>
          </div>
        </div>
      )}
      <small>
        <table>
          <thead>
            <tr>
              <th style={{ width: '60px', textAlign: 'left' }}></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {item?.type !== 'THIRD_PARTY' && !item?.isDropbox && (
              <tr>
                <td style={{ display: 'block', fontSize: '13px' }}>
                  <small>ពេលដឹក</small>
                </td>
                <td>
                  <small style={{ fontSize: '11px' }}>
                    {appointedAtDate} | {appointedAtTime}
                  </small>
                </td>
              </tr>
            )}
            <tr>
              <td style={{ display: 'block', fontSize: '13px' }}>
                <small>អ្នកផ្ញើ</small>
              </td>
              <td>
                <small style={{ fontSize: '11px' }}>
                  {item?.supplier && `(${item?.supplier?.contact})`} {item?.supplier?.name || 'L192'}&nbsp;
                </small>
              </td>
            </tr>
            <tr>
              <td style={{ display: 'block', fontSize: '13px' }}>
                <small>អ្នកទទួល</small>
              </td>
              <td>
                <small style={{ fontSize: '11px' }}>
                  ({item?.customer?.phoneNumber}) {item?.customer?.name}{' '}
                  {item?.telegram && <span style={{ display: 'block' }}>Telegram {item?.telegram}</span>}
                  {item?.addressName}
                  {customerArea?.filter(x => x.id === item?.addressId).map(x => x.note && ', (' + x.note + ')')}
                  <br />
                  {item?.additionalInstruction}
                </small>
              </td>
            </tr>
            {(!!item?.supplier || (item?.type !== 'THIRD_PARTY' && !item?.isDropbox)) && (
              <tr>
                <td style={{ fontSize: '13px' }}>
                  <small>ត្រូវយក:</small>
                </td>
                <td>
                  <small style={{ fontSize: '11px' }}>
                    {Formatter.currency(item?.cash)} | KHR ({ConvertToRiel(Number(item?.cash))}) : 1$=({khmerRiel}៛)
                  </small>
                </td>
              </tr>
            )}
            {(!!item?.supplier || (item?.type !== 'THIRD_PARTY' && !item?.isDropbox)) && (
              <tr>
                <td style={{ display: 'block', fontSize: '13px' }}>
                  <small>យកបាន:</small>
                </td>
                <td>
                  <small>__________________</small>
                </td>
              </tr>
            )}
            <tr>
              <td style={{ display: 'block', fontSize: '13px' }}>
                <small>Note:</small>
              </td>
              <td>
                <small style={{ fontSize: '11px' }}>
                  {item?.legacyDeliveryNote?.returnedItems && item?.legacyDeliveryNote?.returnedItems?.length > 0 && (
                    <div>
                      <p>មានទំនិញយកមកវិញ:​</p>
                      {item?.legacyDeliveryNote?.returnedItems?.map((hasItem, key) => (
                        <small key={key}>
                          <em>{hasItem?.sku?.product?.code}</em> &nbsp;
                        </small>
                      ))}
                    </div>
                  )}
                </small>
                <small style={{ fontSize: '11px' }}>
                  {!!item?.isDropbox && (
                    <>
                      ដឹកដាក់ (CTD):
                      <span>
                        {item?.streetNumber && (
                          <>
                            ផ្លូវ:<span> {item.streetNumber}</span>
                          </>
                        )}
                        {item?.houseNumber && (
                          <>
                            , ផ្ទះលេខ:<span> {item?.houseNumber ? item?.houseNumber : ''}</span>
                          </>
                        )}
                        <p> {item?.dropboxInstruction && <i>({item?.dropboxInstruction})</i>}</p>
                      </span>
                      <p>
                        {customerArea
                          ?.filter(x => x.id === item.addressId)
                          .map((x, i) => (
                            <span
                              key={i}
                              style={{
                                display: 'block',
                              }}
                            >
                              <strong>({x?.label})</strong>
                              {x?.label === 'FRIEND' && (
                                <p>
                                  អ្នកទទួលជំនួស :
                                  <span>
                                    {x?.receiver}({x?.phoneNumber})
                                  </span>
                                </p>
                              )}
                            </span>
                          ))}
                      </p>
                    </>
                  )}
                </small>
              </td>
            </tr>
          </tbody>
        </table>
      </small>
      <br />

      <span style={{ display: 'block', textAlign: 'center' }}>*****************************</span>
    </div>
  );

  return (
    <div>
      <Button
        onClick={() => {
          if (ref.current?.contentWindow && setting?.supplierSetting?.exchangeRate) {
            ref.current.contentWindow.print();
          }
        }}
      >
        Print Receipt
      </Button>
      <iframe title={'title' + item?.id} ref={ref} style={{ width: '100%', display: 'none' }}>
        {iframeBody && createPortal(dom, iframeBody)}
      </iframe>
    </div>
  );
}

const PrintReceipt = forwardRef(PrintReceiptInternal);
export default PrintReceipt;
