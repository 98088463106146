import { useState } from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { Printing } from '../../libs/Printing';
import { useMutation, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { Checkbox } from 'src/components/form/FormComponent';

const MUTATION = gql`
  mutation generatePackageBarcode($n: Int!) {
    generatePackageBarcode(amount: $n)
  }
`;

export function GeneratePackageBarcodeScreen() {
  const [generate] = useMutation<Graph.Mutation>(MUTATION);
  const [largeSize, setLargeSize] = useState(false);
  const [amount, setAmount] = useState('10');

  async function print() {
    const printer = new Printing();
    const { data } = await generate({ variables: { n: Number(amount) } });

    if (data?.generatePackageBarcode) {
      for (const code of data.generatePackageBarcode) {
        largeSize ? printer.packageBarcode(code, 1) : printer.packageBarcode(code);
        await new Promise(r => window.setTimeout(r, 1000));
      }
    }
  }

  return (
    <Content>
      <SectionBlock title="Generate Barcode" width={300}>
        <div className="form-input">
          <label>Number of Barcode to Print</label>
          <input
            type="number"
            value={amount}
            onChange={e => setAmount(e.target.value)}
            className="lf-form-control text-right"
          />
          <br />
          <br />
          <Checkbox label="Large Size" checked={largeSize} onChange={e => setLargeSize(e.currentTarget.checked)} />
        </div>

        <div className="form-buttons-w">
          <button onClick={print} className="btn btn-primary">
            Print
          </button>
        </div>
      </SectionBlock>
    </Content>
  );
}
