import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useApolloClient, gql } from '@apollo/client';
import { URLQueryStringContext } from './context/URLQueryStringContext';

const MUTATION_LOG = gql`
  mutation LogActivity($activity: String!) {
    logActivity(activity: $activity)
  }
`;

const tracking_code = 'UA-22913152-18';
ReactGA.initialize(tracking_code);

type Props = {
  location?: {
    pathname: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withTracker(BaseComponent: React.FunctionComponent<any>) {
  const trackPage = (page: string) => {
    ReactGA.set({
      page,
    });
    ReactGA.pageview(page);
  };

  return function HOC(props: Props) {
    const client = useApolloClient();
    const page = props.location?.pathname;

    useEffect(() => {
      if (page) {
        trackPage(page);
        client
          .mutate({ mutation: MUTATION_LOG, variables: { activity: page } })
          .then()
          .catch(console.error);
      }
    }, [page, client]);

    return (
      <URLQueryStringContext.Provider value={{ value: window.location.search }}>
        <BaseComponent {...props} />
      </URLQueryStringContext.Provider>
    );
  };
}

export default withTracker;
