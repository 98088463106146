import React, { useState } from 'react';
import { CreateUpdateForm, CreateUpdateProps } from '../../../components/form/CreateUpdateForm';
import { gql } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { Content } from '../../../components/light/Content';
import { SectionBlock } from '../../../components/light/SectionBlock';
import { CategoryPicker } from '../../../components/pickers/CategoryPicker';
import { RouteComponentProps } from 'react-router-dom';

const QUERY = gql`
  query DiscountCampaign($id: Int!) {
    discountCampaign(id: $id) {
      id
      name
      discount
      startedAt
      endedAt
      category
    }
  }
`;

const MUTATION_CREATE = gql`
  mutation CreateDiscountCampaign($data: DiscountCampaignInput) {
    createDiscountCampaign(data: $data)
  }
`;

const MUTATION_UPDATE = gql`
  mutation UpdateDiscountCampaign($id: Int!, $data: DiscountCampaignInput!) {
    updateDiscountCampaign(id: $id, data: $data)
  }
`;

function DiscountCampaignForm(props: CreateUpdateProps<Partial<Graph.DiscountCampaign>>) {
  const [name, setName] = useState(props.defaultValue.name || '');
  const [discount, setDiscount] = useState((props.defaultValue.discount || '') + '');
  const [startedAt, setStartedAt] = useState(props.defaultValue.startedAt || '');
  const [endedAt, setEndedAt] = useState(props.defaultValue.endedAt || '');
  const [category, setCategory] = useState(props.defaultValue.category || 0);

  return (
    <>
      <div className="form-group">
        <label>Name</label>
        <input
          autoFocus={true}
          className="lf-form-control"
          value={name || ''}
          onChange={e => setName(e.target.value)}
        />
      </div>

      <div className="row">
        <div className="form-group col-6">
          <label>Started At</label>
          <input
            type="date"
            className="lf-form-control"
            value={startedAt || ''}
            onChange={e => setStartedAt(e.target.value)}
          />
        </div>

        <div className="form-group col-6">
          <label>Ended At</label>
          <input
            type="date"
            className="lf-form-control"
            value={endedAt || ''}
            onChange={e => setEndedAt(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group">
        <label>Category</label>
        <CategoryPicker value={category || 0} onChange={v => setCategory(v)} />
      </div>

      <div className="form-group">
        <label>Discount</label>
        <input className="lf-form-control" value={discount || '0'} onChange={e => setDiscount(e.target.value)} />
      </div>

      <div className="form-buttons-w">
        <button
          className="btn btn-lg btn-primary"
          onClick={() => {
            props.update({
              startedAt,
              endedAt,
              category,
              discount: Number(discount),
              name,
            });
          }}
        >
          Save
        </button>
      </div>
    </>
  );
}

export function DiscountCampaignScreen(props: RouteComponentProps<{ id: string }>) {
  return (
    <Content>
      <SectionBlock title="Discount Campaign" width={600}>
        <CreateUpdateForm
          query={QUERY}
          update={MUTATION_UPDATE}
          create={MUTATION_CREATE}
          updateReturned={`/discount_campaigns`}
          transform={(data: Graph.DiscountCampaign) => ({
            ...data,
            startedAt: data.startedAt?.split(' ')[0],
            endedAt: data.endedAt?.split(' ')[0],
          })}
          body={DiscountCampaignForm}
          id={props.match.params.id}
        />
      </SectionBlock>
    </Content>
  );
}
