import React, { ReactElement, useCallback, useState } from 'react';
import { UploadFileBase } from './UploadFileBase';

interface Props {
  value?: string;
  isCancel?: boolean;
  onChange?: (t: string) => void;
  uploadTitle?: string;
  bg_color?: string;
}

export function UploadInlineFile({ value, uploadTitle, onChange, bg_color, isCancel }: Props) {
  const [loading, setLoading] = useState(false);

  const onStartUpload = useCallback(() => {
    setLoading(true);
  }, []);

  const onComplete = useCallback(
    (uploadUrl: string) => {
      if (onChange) onChange(uploadUrl);
      setLoading(false);
    },
    [onChange],
  );

  let display: ReactElement = (
    <div style={{ cursor: 'pointer' }}>
      <i className="fal fa-file-upload" />
      &nbsp;&nbsp;
      <span>{uploadTitle ?? 'Upload'}</span>
    </div>
  );

  if (loading) {
    display = (
      <div>
        <i className="fas fa-spinner fa-spin"></i>
        &nbsp;&nbsp;
        <span>Uploading ...</span>
      </div>
    );
  } else if (value) {
    const finalURL = value.length > 2 && value.substring(0, 2) === '//' ? 'https:' + value : value;

    display = (
      <div style={{ display: 'flex' }}>
        <div
          style={{ flex: 1 }}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          &nbsp;
          <span>
            <i className="fal fa-paperclip"></i>
          </span>
          &nbsp;&nbsp;
          <a target="_blank" href={finalURL} rel="noopener noreferrer">
            <span>Attachment</span>
          </a>
        </div>

        <i className="fal fa-file-upload" />

        {isCancel && (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (onChange) onChange('');
            }}
          >
            <i
              className="fas fa-times"
              style={{
                width: '20px',
                height: '20px',
                marginLeft: '10px',
                backgroundColor: '#df4141',
                color: '#fff',
                textAlign: 'center',
                lineHeight: '20px',
              }}
            ></i>
          </div>
        )}
      </div>
    );
  }

  return (
    <UploadFileBase value={value} onChange={onComplete} onStart={onStartUpload}>
      <div className="lf-form-control" style={{ backgroundColor: bg_color ?? '' }}>
        {display}
      </div>
    </UploadFileBase>
  );
}
