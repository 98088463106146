import { CreatePurchaseOrderScreen } from '../screens/PurchaseOrder/CreatePurchaseOrderScreen';
import { PurchaseOrderScreen } from '../screens/PurchaseOrder/PurchaseOrderScreen';
import { CommentListScreen } from '../screens/FacebookComment/CommentListScreen';
import { NoteTemplateListScreen } from '../screens/Note/NoteTemplateListScreen';
import { PlaygroundScreen } from '../screens/PlaygroundScreen';
import { CustomControlCollectionScreen } from '../screens/Playbook/CustomControlCollectionScreen';
import { AppSearchPreviewScreen } from '../screens/App/AppSearchPreviewScreen';
import { TwoFactorAuthScreen } from '../screens/Security/TwoFactorAuthScreen';
import { AppPageMarkupScreen } from '../screens/App/AppPageMarkupScreen';
import { CustomPageListScreen } from '../screens/App/page/CustomPageListScreen';
import { LoginlessOrderScreen } from 'src/screens/LoginlessOrder/LoginlessOrderScreen';
import { LoginlessOrderReviewScreen } from 'src/screens/LoginlessOrder/LoginlessOrderReviewScreen';
import SearchPreview from 'src/screens/Search';
import { AppMenuListScreen } from 'src/screens/App/AppMenuListScreen';

export const OtherRoutes = [
  { path: '/purchase/create', exact: true, component: CreatePurchaseOrderScreen },
  { path: '/purchase/edit/:id', exact: true, component: PurchaseOrderScreen },
  { path: '/comments', exact: true, component: CommentListScreen },
  { path: '/note/templates', exact: true, component: NoteTemplateListScreen },
  { path: '/playground', exact: true, component: PlaygroundScreen },
  { path: '/playbook/custom', exact: true, component: CustomControlCollectionScreen },
  { path: '/app/preview/search', exact: true, component: AppSearchPreviewScreen },
  { path: '/security/two-factor', exact: true, component: TwoFactorAuthScreen },
  { path: '/app/page/create', exact: true, component: AppPageMarkupScreen },
  { path: '/app/page/:id', exact: true, component: AppPageMarkupScreen },
  { path: '/app/pages', exact: true, component: CustomPageListScreen },
  { path: '/app/menu', exact: true, component: AppMenuListScreen },
  { path: '/loginless/orders', exact: true, component: LoginlessOrderScreen },
  { path: '/loginless/orders/:id', exact: true, component: LoginlessOrderReviewScreen },
  { path: '/search/preview', exact: true, component: SearchPreview },
];
