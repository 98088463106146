import React, { useState } from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { useCurrentStore } from '../../hooks/Store';
import { useMutation, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { Loading } from '../../components/Loading';
import styles from './style.module.scss';

const QUERY_SCAN_IN = gql`
  mutation scanPackageIn($warehouseId: Int!, $barcode: String!) {
    scanPackageIn(warehouseId: $warehouseId, barcode: $barcode) {
      final
      type
      package {
        id
        status
        isFinal
        isReturning
        returningFromPackageId
        thirdPartyProvider
        supplier {
          id
          name
        }
        sender {
          name
          phoneNumber
        }
        customer {
          id
          name
          phoneNumber
        }
      }
      messageHeader
      messageBody
      next {
        id
        name
      }
    }
  }
`;
export function PackageScanInScreen() {
  const [barcode, setBarcode] = useState('');
  const warehouseId = useCurrentStore();
  const [update, { data }] = useMutation<Graph.Mutation>(QUERY_SCAN_IN, { variables: { barcode, warehouseId } });
  const [loading, setLoading] = useState(false);
  async function onInputKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === 13) {
      setLoading(true);
      await update();
      setLoading(false);
      setBarcode('');
    }
  }

  function renderNextDestination() {
    if (!data || !data?.scanPackageIn?.package) return <div></div>;

    const d = data.scanPackageIn;
    return (
      <div className={styles.scan_wrap} style={{ background: '#eee' }}>
        <h3>Next Destination</h3>
        {d?.type === 'THIRD_PARTY' && d?.package?.thirdPartyProvider !== null && (
          <h5 style={{ color: '#e74c3c' }}>
            Shipping Agency: <i className="fa fad fa-shipping-fast"></i>&nbsp;
            {d?.package?.thirdPartyProvider}
          </h5>
        )}
        <h3>
          {!!d?.package?.isReturning && !!d.final ? (
            <span style={{ fontSize: '90%', color: '#e74c3c' }}>
              <img src="/assets/return.png" alt="" width="40px" /> To Return to Sender ({d?.package?.sender?.name})
            </span>
          ) : (
            d.messageHeader
          )}
          {/* {d.messageHeader}&nbsp; */}
          {d?.type === 'THIRD_PARTY' && '(Third Party)'}
        </h3>

        {d?.type === 'THIRD_PARTY' && (
          <a href={`/package/detail/` + d?.package?.id.toString()} target="_blink">
            Go to package detail
          </a>
        )}
        <hr />
        <div className={styles.scan_messageBody}>
          <p className="mt-2">{d.messageBody}</p>
        </div>
      </div>
    );
  }

  return (
    <Content>
      <SectionBlock title="Scan In" width={800}>
        <div>
          <div className="form-group">
            <label>Barcode</label>
            <input
              autoFocus={true}
              type="text"
              onKeyDown={onInputKeydown}
              style={{ fontSize: 20 }}
              className="lf-form-control"
              value={barcode}
              onChange={e => setBarcode(e.target.value)}
            />
          </div>
        </div>
        <div>{loading ? <Loading></Loading> : null}</div>
        {renderNextDestination()}
      </SectionBlock>
    </Content>
  );
}
