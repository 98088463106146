/* eslint-disable */
import React from 'react';
/*
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Loading } from './Loading';
import { Pagination } from './light/Pagination';

type Props = {
  name: string;
  field: any;
  isPage?: boolean;
};

const originClassname = ['/assets/cambodia.svg', '/assets/china.svg', '/assets/vietnam.svg', '/assets/thailand.svg'];

export class TableComponent extends React.Component<Props> {
  state: {
    field: any;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      field: this.props.field,
    };
  }

  render() {
    const query = new URLSearchParams(window.location.search);

    const variables = {
      page: query.has('page') ? Number(query.get('page')) : 1,
      size: 10,
    };

    return (
      <Query
        query={gql`
          ${this.state.field.obj.query}
        `}
        variables={this.props.isPage === true ? variables : { page: 1, size: 1000000 }}
        onError={err => console.log(err)}
      >
        {this.renderQuery}
      </Query>
    );
  }

  renderQuery = ({ data, loading }: QueryResult<any>) => {
    if (loading || data === undefined)
      return (
        <div>
          <Loading />
        </div>
      );

    const res = data[this.props.name].data;
    const page = data[this.props.name].page;

    return (
      <div>
        {this.renderTable(res)}
        <Pagination currentPage={page.current as number} total={page.total as number} size={page.size as number} />
      </div>
    );
  };

  renderTable(res: any) {
    return (
      <table className="table table-lightborder">
        <thead>
          <tr>
            {this.state.field.obj.columns.map((e: any) => {
              return <th key={e.name}>{e.name}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {res.map((e: any) => {
            return (
              <tr key={e.id}>
                {this.state.field.obj.columns.map((d: any) => {
                  return <td key={d.name}>{this.renderField(e, d)}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderField(field: any, column: any) {
    switch (column.type) {
      case 'image':
        return (
          <img
            style={{
              width: 70,
              height: 70,
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            src={field.logo!}
            alt=""
            hidden={field.logo === undefined || field.logo === null}
          />
        );
      case 'link':
        return <Link to={column.to + field.id}>{field[column.field]}</Link>;
      case 'origin':
        return (
          <img
            style={{
              width: 50,
              height: 50,
            }}
            src={originClassname[field[column.field]! - 1]}
            alt=""
            hidden={originClassname[field[column.field]! - 1] === undefined}
          />
        );
      default:
        return field[column.field];
    }
  }
}
*/

type Props = {
  name: string;
  field: any;
  isPage?: boolean;
};

export function TableComponent(props: Props) {
  return <div></div>;
}
