import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { Graph } from 'src/generated/graph';
import { Table } from 'src/components/layout/tables/Table';
import { BlockLoading } from 'src/components/loading/BlockLoading';

const QUERY_APPROVED_ORDER = gql`
  query {
    loginlessOrderList(status: APPROVED, order: DESC) {
      id
      phoneNumber
      customerName
      status
      total
    }
  }
`;

function roundNumber(value: number) {
  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function showTableItem(data: Graph.LoginlessOrder[]) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Total Price</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {data.map((child, idx) => (
          <tr key={idx}>
            <td>{child.customerName}</td>
            <td>0{child.phoneNumber}</td>
            <td>${roundNumber(Number(child.total))}</td>
            <td>{child.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export function LoginlessOrderApprovedScreen() {
  const { data } = useQuery<Graph.Query>(QUERY_APPROVED_ORDER);
  return data?.loginlessOrderList ? showTableItem(data.loginlessOrderList) : <BlockLoading />;
}
