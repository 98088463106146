import React, { ReactElement } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { Link } from 'react-router-dom';

const QUERY_SEGMENTS = gql`
  query notificationSegmentList {
    notificationSegmentList {
      id
      name
      size
      order
      updatedStartedAt
      updatedEndedAt
      schedule
    }
  }
`;

const MUTATION_FORCE = gql`
  mutation ForceNotificationSegmentUpdate($id: Int!) {
    forceNotificationSegmentUpdate(id: $id)
  }
`;

function NotificationSegmentForceButton({ id }: { id: number }): ReactElement {
  const [update, { loading }] = useMutation(MUTATION_FORCE);
  return (
    <button
      className="btn btn-primary"
      disabled={loading}
      onClick={(): void => {
        update({ variables: { id } });
      }}
    >
      Force Build
    </button>
  );
}

export function NotificationSegmentPartial(): ReactElement {
  const { loading, data } = useQuery<Graph.Query>(QUERY_SEGMENTS);
  if (loading || !data) return <div>Loading ...</div>;

  return (
    <div>
      <div className="controls-above-table">
        <Link to="/notification/segment/create" className="btn btn-primary">
          Create Segment
        </Link>
      </div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Segment</th>
            <th className="text-right" style={{ width: 100 }}>
              Size
            </th>
            <th style={{ width: 50 }}>Order</th>
            <th style={{ width: 250 }}>Update</th>
            <th style={{ width: 100 }}></th>
          </tr>
        </thead>
        <tbody>
          {(data.notificationSegmentList || []).map(
            (x): ReactElement => (
              <tr key={x.id?.toString()}>
                <td>
                  <Link to={`/notification/segment/${x.id}`}>{x.name}</Link>
                  <div>{x.schedule}</div>
                </td>
                <td className="text-right">{x.size?.toLocaleString()}</td>
                <td>{x.order}</td>
                <td>
                  {x.updatedStartedAt && (
                    <div>
                      <strong>Start Build At</strong>
                      <br />
                      {x.updatedStartedAt + ' (+0 GMT)'}
                    </div>
                  )}
                  {x.updatedEndedAt && (
                    <div>
                      <strong>Finish Build At</strong>
                      <br />
                      {x.updatedEndedAt + ' (+0 GMT)'}
                    </div>
                  )}
                </td>
                <td>
                  <NotificationSegmentForceButton id={x.id ? x.id : 0} />
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  );
}
