import { gql } from '@apollo/client';
import React, { useState } from 'react';
import Layout from 'src/components/layout/Layout';
import XForm from 'src/components/layout/xform/XForm';
import { CommentListContent } from './CommentListContent';

const QUERY_FACEBOOK_PAGE = gql`
  query facebookPageList {
    facebookPageList {
      id
      name
      unhandled
    }
  }
`;

const POST_TYPE_LIST = [
  { text: 'Normal Post', value: 'NONE' },
  { text: 'Live Video', value: 'LIVE' },
];

const POST_SORT_LIST = [
  { text: 'Earliest', value: 'EARLIEST' },
  { text: 'Latest', value: 'LATEST' },
];

export function CommentListScreen() {
  const [pageId, setPageId] = useState('');
  const [type, setType] = useState<'NONE' | 'LIVE'>('NONE');
  const [sort, setSort] = useState<'EARLIEST' | 'LATEST'>('EARLIEST');
  const [date, setDate] = useState<undefined | string>(undefined);
  const [handled, setHandled] = useState(false);
  const [replied, setReplied] = useState(false);

  return (
    <Layout>
      <Layout.Header>Comments</Layout.Header>

      <div className="mb-4">
        <XForm.Division>
          <XForm.SelectFromQuery
            value={pageId}
            onChange={e => setPageId(e.currentTarget.value)}
            label="Page"
            query={QUERY_FACEBOOK_PAGE}
            transform={query => {
              const items = (query.facebookPageList || []).map(page => ({
                text: page.name?.toString() + `(${page.unhandled})` || '',
                value: page.id?.toString() || '',
              }));

              return [{ text: 'All', value: '' }, ...items];
            }}
          />
          <XForm.Select
            label="Post Type"
            onChange={e => setType(e.currentTarget.value as 'NONE' | 'LIVE')}
            value={type}
            items={POST_TYPE_LIST}
          />

          <XForm.Select
            value={sort}
            onChange={e => setSort(e.currentTarget.value as 'EARLIEST' | 'LATEST')}
            label="Sort"
            items={POST_SORT_LIST}
          />

          <XForm.Text
            type="date"
            label="Date"
            placeholder="YYYY-MM-DD"
            value={date}
            onChange={e => setDate(e.currentTarget.value)}
          />
        </XForm.Division>

        <XForm.Inline>
          <XForm.Check label="Handled" checked={handled} onChange={e => setHandled(e.currentTarget.checked)} />
          <XForm.Check label="Replied" checked={replied} onChange={e => setReplied(e.currentTarget.checked)} />
        </XForm.Inline>
      </div>

      <CommentListContent pageId={pageId} type={type} sort={sort} handled={handled} replied={replied} date={date} />
    </Layout>
  );
}
