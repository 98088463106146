import React from 'react';

export function DropdownShadow(props: React.PropsWithChildren<unknown>) {
  return (
    <div>
      <div
        style={{
          padding: 10,
          borderWidth: '0 0 .5px 0',
          borderColor: 'rgba(0,0,0,0.2)',
          borderStyle: 'solid',
          alignItems: 'left',
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
