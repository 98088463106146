import React, { useEffect, useState } from 'react';
import styles from './tasks.module.scss';

import { Graph } from '../../../generated/graph';
import { BlockLoading } from '../../../components/loading/BlockLoading';
import { Table } from 'src/components/layout/tables/Table';
import Button from 'src/components/layout/xform/Button';
import { QUERY_PACKAGE_LIST } from '../query';
import PackageCustomerPickupScreen from '../components/PackageCustomerPickupTable';
import { useQuery } from '@apollo/client';
import PackageSearchScreen from '../components/PackageSearch';
import { useHistory, useLocation } from 'react-router';
interface SelectedType {
  text: string;
  value: string;
}
function Schedule({ items }: { items: Graph.Package[] }) {
  if (items.length > 0) {
    return (
      <div className="mb-3">
        <Table stripe={true} border={true}>
          <thead>
            <tr>
              <th style={{ width: 50 }}>Type</th>
              <th style={{ width: 180 }}> Date</th>
              <th style={{ width: 120 }}>Services</th>
              <th>Package</th>
              <th style={{ width: 200 }}>Merchant</th>
              <th style={{ width: 120 }}>Claimed By</th>
              <th style={{ width: 200 }}>Customer</th>
              <th style={{ width: 220 }}>Route</th>
              <th style={{ width: 160 }}></th>
            </tr>
          </thead>

          <tbody>
            {items.map((item, key) => (
              <PackageCustomerPickupScreen item={item} key={key} />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
  return null;
}
const type = [
  { text: 'UUID', value: '1' },
  { text: 'Customer Phone Number', value: '2' },
];

function PackageButtonFilter({ items }: { items: Graph.Package[] }) {
  const [hubName, setHubName] = useState('All');

  const hubs: { title: string; total: number; isCtd?: number | undefined }[] = [
    { title: 'All', total: items.length, isCtd: items.filter(x => !!x.isDropbox).length },
  ];

  items.map(x => {
    const title = x?.currentWarehouse?.shortName || 'Little Fashion';
    const index = hubs.findIndex(h => h.title === title);

    if (index < 0) {
      hubs.push({ title, total: 1, isCtd: 0 });
    } else {
      const hub = hubs[index];
      hubs[index] = { title, total: hub.total + 1, isCtd: Number(hub.isCtd) + Number(x.isDropbox || 0) };
    }
    return null;
  });
  return (
    <>
      <div className={styles.btn_filter_wrap}>
        {hubs.map((h, i) => (
          <div key={i}>
            <Button
              theme={hubName === h.title ? 'primary' : 'secondary'}
              value={h.title}
              onClick={() => setHubName(h.title)}
            >
              {h.title} ({h.total})
              {Number(h.isCtd) > 0 && (
                <span style={{ color: '#eb3b5a', fontSize: '12px' }}> &nbsp;CTD : {h.isCtd}</span>
              )}
            </Button>
          </div>
        ))}
      </div>

      <Schedule
        items={items.filter(
          x =>
            hubName === 'All' ||
            (hubName === 'Little Fashion' && x.currentWarehouse === null) ||
            x.currentWarehouse?.shortName === hubName,
        )}
      />

      {/* {renderPackage(
        items.filter(
          x =>
            hubName === 'All' ||
            (hubName === 'Little Fashion' && x.currentWarehouse === null) ||
            x.currentWarehouse?.shortName === hubName,
        ),
      )} */}
    </>
  );
}

export function PackageCustomerPickupListScreen() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { data } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, {
    variables: { status: ['ARRIVED'], type: ['PICKUP'], reachFinal: true },
  });
  const [search, setSearch] = useState('');
  const [drpSelected, setDrpSelected] = useState<SelectedType>({
    text: type[0].text,
    value: type[0].value,
  });
  const query = `${pathname}?`;

  useEffect(() => {
    history.replace(query + '#customer_pickup');
  }, [history, query, search]);
  if (!data?.packageList) return <BlockLoading />;
  const onChangeType = (type: SelectedType) => {
    setDrpSelected(type);
  };
  const onChangeSearch = (txt?: string) => {
    setSearch(txt || '');
  };
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}></div>
        <PackageSearchScreen
          type={type}
          drpSelect={drpSelected}
          onChangeType={onChangeType}
          searchTxt={search}
          onChangeSearch={onChangeSearch}
        />
      </div>
      <div>
        <PackageButtonFilter
          items={data?.packageList?.filter(x => {
            if (drpSelected?.text === 'UUID' && search !== '') {
              return (
                x?.uuid &&
                x?.uuid
                  ?.toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase().substring(1)) > -1
              );
            }
            if (drpSelected?.text === 'Customer Phone Number' && search !== '') {
              return (
                x?.customer?.phoneNumber &&
                x?.customer?.phoneNumber
                  ?.toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase().substring(1)) > -1
              );
            }
            return x;
          })}
        />
      </div>
    </>
  );
}
