import React from 'react';
import { LoginForm } from '../components/light/LoginForm';
import { useMutation, gql } from '@apollo/client';
import { Graph } from '../generated/graph';

type Props = {
  saveToken: (token: string) => void;
};

const MUTATION_LOGIN = gql`
  mutation loginAdministrator($username: String!, $password: String!, $otp: Int) {
    loginAdministrator(username: $username, password: $password, otp: $otp)
  }
`;

export function LoginScreen(props: Props) {
  const onCompleted = (data: Graph.Mutation) => {
    props.saveToken(data.loginAdministrator as string);
  };
  const [login, { error, loading }] = useMutation(MUTATION_LOGIN, { onCompleted: onCompleted });

  const onLoginClicked = async (username: string, password: string, otp: string) => {
    login({
      variables: {
        username: username,
        password: password,
        otp: otp ? Number(otp) : undefined,
      },
    })
      .then()
      .catch(console.error);
  };

  return (
    <LoginForm title="L192" onSubmit={onLoginClicked} loading={loading} error={error ? error.message : undefined} />
  );
}
