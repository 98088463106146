/* eslint-disable */
import React from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { gql } from '@apollo/client';
import { Query } from '@apollo/react-components';
import { Loading } from '../../components/Loading';
import { Graph } from '../../generated/graph';
import queryString from 'query-string';

const QUERY_STOCK_TRANSATION = gql`
  query stockTransactionList($size: Int!, $filter: StockTransactionFilter!) {
    stockTransactionList(size: $size, filter: $filter) {
      id
      slotID
      productID
      skuID
      purchaseOrderID
      color
      size
      qty
      cost
      costDescription
      transactionType
      createdAt
    }
  }
`;

const formatDatetime = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

type Props = {
  history: any;
  location: {
    search: any;
  };
};

export class StockTransationListScreen extends React.Component<Props> {
  state: {
    inputStart: string;
    inputEnd: string;
    inputBatch: string;
    data: any[];
    filter: any;
  };

  queryUrl: any = queryString.parse(this.props.location.search);

  constructor(props: Props) {
    super(props);
    this.state = {
      inputStart: this.props.location.search === '' ? formatDatetime(new Date()) : this.queryUrl.start,
      inputEnd: this.props.location.search === '' ? formatDatetime(new Date()) : this.queryUrl.end,
      inputBatch: '',
      data: [],
      filter: this.props.location.search === '' ? {} : { dateStart: this.queryUrl.start, dateEnd: this.queryUrl.end },
    };
  }

  onQueryComplete = (data: any) => {
    this.setState({ data: data.stockTransactionList });
  };

  inputChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onClickCheck = (e: any) => {
    if (this.state.inputBatch === '') {
      this.setState({
        filter: {
          dateStart: this.state.inputStart,
          dateEnd: this.state.inputEnd,
        },
      });
    } else {
      this.setState({
        filter: {
          dateStart: this.state.inputStart,
          dateEnd: this.state.inputEnd,
          batchID: Number(this.state.inputBatch),
        },
      });
    }

    this.props.history.push(`/inventory/stock_transation?start=${this.state.inputStart}&end=${this.state.inputEnd}`);
  };

  render() {
    console.log(this.state.filter.start);
    return (
      <Content>
        <SectionBlock title="Stock Transation">
          <Query
            query={QUERY_STOCK_TRANSATION}
            variables={{
              size: 10,
              filter: this.state.filter,
            }}
            onCompleted={this.onQueryComplete}
            skip={this.state.filter.dateStart === undefined}
            fetchPolicy="network-only"
          >
            {this.renderQuery}
          </Query>
        </SectionBlock>
      </Content>
    );
  }

  renderTopController(refetch: any) {
    return (
      <div className="controls-above-table">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-inline justify-content-sm-start">
              <input
                type="text"
                placeholder="start"
                className="form-control form-control-sm rounded bright"
                value={this.state.inputStart}
                onChange={this.inputChange}
                name="inputStart"
              />
              <input
                type="text"
                placeholder="end"
                className="form-control form-control-sm rounded bright"
                value={this.state.inputEnd}
                onChange={this.inputChange}
                name="inputEnd"
              />
              <input
                type="text"
                placeholder="Batch ID"
                className="form-control form-control-sm rounded bright"
                value={this.state.inputBatch}
                onChange={this.inputChange}
                name="inputBatch"
              />
              <button className="btn btn btn-secondary" onClick={this.onClickCheck}>
                Check
              </button>
              <button className="btn btn btn-primary" onClick={() => refetch()}>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderQuery = ({ data, loading, refetch }: any) => {
    if (loading)
      return (
        <div>
          <Loading />
        </div>
      );
    return (
      <div>
        {this.renderTopController(refetch)}
        <table className="table table-lightborder">
          <thead>
            <tr>
              <th>sku id</th>
              <th>product id</th>
              <th>slot id</th>
              <th>qty</th>
              <th className="text-center">transaction type</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((e: Graph.StockTransaction) => (
              <tr key={e.id}>
                <td>{e.skuID}</td>
                <td>{e.productID}</td>
                <td>{e.slotID}</td>
                <td>{e.qty}</td>
                <td style={{ width: 200 }}>{this.renderTransationType(e.transactionType)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  renderTransationType(type: any) {
    switch (type) {
      case 'STOCK_IN':
        return this.renderType(type, 'purple');
      case 'ADJUST':
        return this.renderType(type, 'orange');
      case 'INSTORE_PURCHASE':
        return this.renderType(type, 'purple');
      case 'ONLINE_PURCHASE':
        return this.renderType(type, 'purple');
      case 'RECYCLE':
        return this.renderType(type, 'purple');
      case 'TRANSFER_OUT':
        return this.renderType(type, 'purple');
      case 'TRANSFER_IN':
        return this.renderType(type, 'purple');
      case 'DAMAGE':
        return this.renderType(type, 'purple');
      case 'MISSING':
        return this.renderType(type, 'purple');
      case 'STOCK_OUT':
        return this.renderType(type, 'purple');
    }
  }

  renderType(type: string, bg: string) {
    return (
      <div className={`cta-w ${bg} text-center`}>
        <div className="p-2">
          <h4 className="text-white">{type}</h4>
        </div>
      </div>
    );
  }
}
