import React, { ReactElement, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useForm } from 'react-hook-form';
import { Graph } from '../../../generated/graph';
import { Content } from '../../../components/light/Content';
import { SectionBlock } from '../../../components/light/SectionBlock';
import { Checkbox } from '../../../components/form/FormComponent';
import { gql } from '@apollo/client';
import { CreateUpdateForm, CreateUpdateProps } from '../../../components/form/CreateUpdateForm';
import { UploadInlineFile } from '../../../components/UploadInlineFile';

const QUERY = gql`
  query LuckyDraw($id: Int!) {
    luckyDraw(id: $id) {
      id
      name
      description
      startedAt
      endedAt
      cost
      cover
      thumbnail
      winner {
        id
      }
      published
    }
  }
`;

const UPDATE = gql`
  mutation UpdateLuckyDraw($id: Int!, $data: LuckyDrawInput!) {
    updateLuckyDraw(id: $id, data: $data)
  }
`;

const CREATE = gql`
  mutation CreateLuckyDraw($data: LuckyDrawInput!) {
    createLuckyDraw(data: $data)
  }
`;

function LuckyDrawForm({ update, defaultValue }: CreateUpdateProps<Graph.LuckyDrawInput>): ReactElement {
  const { register, handleSubmit } = useForm<Graph.LuckyDrawInput>({
    defaultValues: defaultValue,
  });

  const [thumbnail, setThumbnail] = useState('');
  const [cover, setCover] = useState('');

  const onSubmit = handleSubmit(data => {
    update({
      ...data,
      thumbnail: thumbnail,
      cover: cover,
      cost: Number(data.cost),
      winner: data.winner ? Number(data.winner) : null,
    });
  });

  return (
    <form onSubmit={onSubmit} noValidate={true}>
      <div className="form-group">
        <label>Name</label>
        <input name="name" className="lf-form-control" ref={register} />
      </div>

      <div className="form-group">
        <label>Description</label>
        <textarea name="description" className="lf-form-control" ref={register} rows={4} />
      </div>

      <div className="row">
        <div className="col-6 form-group">
          <label>Started At</label>
          <input type="date" name="startedAt" className="lf-form-control" ref={register} />
        </div>

        <div className="col-6 form-group">
          <label>Ended At</label>
          <input type="date" name="endedAt" className="lf-form-control" ref={register} />
        </div>
      </div>

      <div className="row">
        <div className="col-6 form-group">
          <label>Thumbnail</label>
          <UploadInlineFile value={defaultValue.thumbnail ? defaultValue.thumbnail : ''} onChange={setThumbnail} />
        </div>

        <div className="col-6 form-group">
          <label>Cover</label>
          <UploadInlineFile value={defaultValue.cover ? defaultValue.cover : ''} onChange={setCover} />
        </div>
      </div>

      <div className="form-group">
        <label>Cost</label>
        <input type="number" name="cost" className="lf-form-control" ref={register} />
      </div>

      <div className="form-group">
        <label>Winner (Customer ID)</label>
        <input type="number" name="winner" className="lf-form-control" ref={register} />
      </div>

      <div className="form-group">
        <Checkbox label="Published" name="published" ref={register} />
      </div>

      <div className="form-buttons-w">
        <button name="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
}

export function LuckyDrawEditScreen(props: RouteComponentProps<{ id: string }>): ReactElement {
  return (
    <Content>
      <SectionBlock title="Lucky Draw" width={600} link={'/marketing/lucky_draw/list'}>
        <CreateUpdateForm
          transform={(data: Graph.LuckyDraw): Graph.LuckyDrawInput => {
            return {
              winner: data.winner?.id,
              startedAt: data.startedAt?.split(' ')[0],
              endedAt: data.endedAt?.split(' ')[0],
            };
          }}
          updateReturned="/marketing/lucky_draw/list"
          id={props.match.params.id}
          update={UPDATE}
          create={CREATE}
          query={QUERY}
          body={LuckyDrawForm}
        />
      </SectionBlock>
    </Content>
  );
}
