import React from 'react';
import styles from './Stats.module.scss';

interface Props {
  title: string;
  value: string;
  subtitle?: string;
}

export function Stats(props: Props) {
  return (
    <div className={styles.stats}>
      <div className={styles.title}>{props.title}</div>
      <br />
      <div className={styles.value}>{props.value}&nbsp;</div>
      <br />
      <div className={styles.subtitle}>{props.subtitle}&nbsp;</div>
    </div>
  );
}
