import { MenuItem } from './MainMenu';
import React, { useState } from 'react';
import { QuickSearch } from './QuickSearch';
import { useMenuContext } from '../layout/MenuContext';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

type Props = {
  items: MenuItem[];
};

function MenuGroupItem({ item }: { item: MenuItem }) {
  const history = useHistory();
  const pathname = history.location.pathname + (history.location.hash || '');
  const isSelected = pathname === item.to;
  const isChildrenSelected = !!(item.subs || []).flat().find(x => x.to === pathname);
  const [collapsed, setCollapsed] = useState(isSelected || isChildrenSelected);

  const onMenuClicked = () => {
    if (item.subs) {
      setCollapsed(!collapsed);
    }
  };

  if (item.header) {
    return (
      <div>
        <h3>{item.name}</h3>
      </div>
    );
  }

  return (
    <>
      {item.to && item.to !== '#' ? (
        <Link to={item.to} className={isSelected ? 'side-menu-level-1 selected' : 'side-menu-level-1'}>
          {item.icon && <i className={`side-menu-icon ${item.icon}`} />}
          <span>{item.name}</span>
          {item.subs && <i className={collapsed ? 'far fa-angle-down' : 'far fa-angle-right'} />}
          {item?.badge && <strong style={{ fontSize: '1rem', color: 'red' }}>{item?.badge}</strong>}
        </Link>
      ) : (
        <div onClick={onMenuClicked} className={isSelected ? 'side-menu-level-1 selected' : 'side-menu-level-1'}>
          {item.icon && <i className={`side-menu-icon ${item.icon}`} />}
          <span>{item.name}</span>
          {item.subs && <i className={collapsed ? 'far fa-angle-down' : 'far fa-angle-right'} />}
        </div>
      )}
      {item.subs && collapsed && (
        <ul className="side-menu-level-2">
          {item.subs.flat().map(x => (
            <li key={x.name} className={x.to === pathname ? 'selected' : ''}>
              <Link to={x.to}>{x.name}</Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export function DesktopMenu(props: React.PropsWithChildren<Props>) {
  const { type } = useMenuContext();
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const router = useRouteMatch();

  if (type === 'mini') {
    return <div />;
  }

  return (
    <div className="side-menu">
      <div className="side-menu-content">
        <h2 className="side-menu-logo" onClick={() => history.push('/')}>
          L192
        </h2>

        <QuickSearch />

        {props.items.map((item, idx) => (
          <MenuGroupItem key={item.name + idx} item={item} />
        ))}
      </div>
    </div>
  );
}
