import { useQuery, gql } from '@apollo/client';
import React, { useEffect } from 'react';
import Layout from 'src/components/layout/Layout';
import { Table } from 'src/components/layout/tables/Table';
import { LinkButton } from 'src/components/layout/xform/LinkButton';
import { BlockLoading } from 'src/components/loading/BlockLoading';
import { Graph } from 'src/generated/graph';

const QUARY_LOGINLESS_ORDER = gql`
  query QUARY_LOGINLESS_ORDER {
    loginlessOrderList(status: PENDING, offset: 0, limit: 200) {
      id
      phoneNumber
      total
      status
      customerName
      unreachableCount
      unreachableAt
      lastReviewAt
    }
  }
`;

const SUBSCRIPTION_LOGINLESS_EVENT = gql`
  subscription {
    loginlessEvent {
      type
      id
      payload
    }
  }
`;

function roundNumber(value: number) {
  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function renderRow(data: Graph.LoginlessOrder[]) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Total Price</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.id.toString()}>
            <td>{item.customerName}</td>
            <td>0{item.phoneNumber}</td>
            <td>${roundNumber(Number(item.total))}</td>
            <td className="text-right">
              <LinkButton to={`/loginless/orders/${item.id}`}>Review</LinkButton>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function renderLockRow(data: Graph.LoginlessOrder[]) {
  return (
    <div className="mt-5">
      <Layout.Header>Reviewing</Layout.Header>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Total Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id.toString()}>
              <td>{item.customerName}</td>
              <td>0{item.phoneNumber}</td>
              <td>${roundNumber(Number(item.total))}</td>
              <td className="text-right">
                <LinkButton theme="secondary" to={`#`}>
                  Lock
                </LinkButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function renderUnreachbleRow(data: Graph.LoginlessOrder[]) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Total Price</th>
          <th>Unreachable</th>
          <th>Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.id.toString()}>
            <td>{item.customerName}</td>
            <td>0{item.phoneNumber}</td>
            <td>${roundNumber(Number(item.total))}</td>
            <td>{item.unreachableCount}</td>
            <td>
              {new Date(item.unreachableAt || '').toLocaleDateString() +
                ' ' +
                new Date(item.unreachableAt || '').toLocaleTimeString()}
            </td>
            <td className="text-right">
              <LinkButton to={`/loginless/orders/${item.id}`}>Review</LinkButton>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function renderBody(data: Graph.LoginlessOrder[]) {
  const pendingOrder = data.filter(x => (x.unreachableCount || 0) === 0);
  const unreviewOrder = pendingOrder.filter(x => !x.lastReviewAt || x.lastReviewAt + 60000 < Date.now());
  const reviewOrder = pendingOrder.filter(x => x.lastReviewAt && x.lastReviewAt + 60000 > Date.now());
  const unreachableOrder = data.filter(x => (x.unreachableCount || 0) > 0);

  return (
    <div>
      {renderRow(unreviewOrder)}
      {reviewOrder.length > 0 && renderLockRow(reviewOrder)}
      {unreachableOrder.length > 0 && (
        <div className="mt-5">
          <Layout.Header>Unreachable</Layout.Header>
          {renderUnreachbleRow(unreachableOrder)}
        </div>
      )}
    </div>
  );
}

export default function LoginlessOrderPendingScreen() {
  const { data, subscribeToMore } = useQuery<Graph.Query>(QUARY_LOGINLESS_ORDER);
  const [, setForceUpdate] = React.useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setForceUpdate(Date.now());
    }, 10000);
    return () => clearInterval(timer);
  }, [setForceUpdate]);

  useEffect(() =>
    subscribeToMore<Graph.Subscription>({
      document: SUBSCRIPTION_LOGINLESS_EVENT,
      updateQuery: (prev: Graph.Query, { subscriptionData }) => {
        if (!prev.loginlessOrderList) return prev;
        if (!subscriptionData.data) return prev;

        const event = subscriptionData.data.loginlessEvent;
        if (event?.type === 'ADDED') {
          return {
            loginlessOrderList: [event.payload, ...prev.loginlessOrderList],
          };
        } else if (event?.type === 'UNREACHABLE') {
          const tmp = { loginlessOrderList: prev.loginlessOrderList.map(x => ({ ...x })) };
          const order = tmp.loginlessOrderList.find(x => x.id === event?.id);
          if (order) {
            order.unreachableCount = (order.unreachableCount || 0) + 1;
            order.unreachableAt = event.payload.unreachableAt;
          }
          return tmp;
        } else if (event?.type === 'REVIEW') {
          const tmp = { loginlessOrderList: prev.loginlessOrderList.map(x => ({ ...x })) };
          const order = tmp.loginlessOrderList.find(x => x.id === event?.id);
          if (order) {
            order.lastReviewAt = event.payload.unreachableAt;
          }
          return tmp;
        } else if (event?.type === 'APPROVED' || event?.type === 'REJECTED') {
          return {
            loginlessOrderList: prev.loginlessOrderList.filter(x => x.id !== event?.id),
          };
        }

        return prev;
      },
    }),
  );

  return data?.loginlessOrderList ? renderBody(data.loginlessOrderList) : <BlockLoading />;
}
