import React from 'react';
import { useHistory } from 'react-router';
import { Graph } from 'src/generated/graph';
import styles from './suggested.module.scss';

export interface QsFilter {
  sort: string;
  category_id: null | number;
  brand_id: null | number;
  store_id: null | number;
  keyword: null | string;
  inStockOnly: null | string;
  min_price: null | number;
  max_price: null | number;
}
interface Props {
  category: Graph.Category[];
  qs: QsFilter;
}
export default function SuggestedCategoryScreen(props: Props) {
  const history = useHistory();
  const onFilterCategory = (category_id: number) => {
    const query = { ...props.qs, category_id };
    history.replace(
      `/search?${Object.entries(query)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')}`,
    );
  };

  return (
    <div className={styles.suggested_wrap}>
      <div className={styles.suggested_inner}>
        <ul className={styles.suggested_list}>
          {props.category.map(x => (
            <li key={x.id} value={x?.id} onClick={e => onFilterCategory(e.currentTarget.value)}>
              <div>
                <span>{x.icon !== '' ? <img src={x?.icon?.toString()} alt="" /> : <strong>LF</strong>}</span>
                <p>{x?.currentLanguageName}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
