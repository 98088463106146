import { useState } from 'react';
import getLocalStorageObject from 'src/libs/getLocalStorageObject';

export default function useLocalStorageObject<T>(key: string, defaultValue: T) {
  const [value, setValue] = useState(getLocalStorageObject(key, defaultValue));

  return [
    value,
    (v: T) => {
      setValue(v);
      window.localStorage.setItem(key, JSON.stringify(v));
    },
  ];
}
