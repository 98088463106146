/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ReactElement } from 'react';
import { CustomPagePicker } from '../../components/pickers/CustomPagePicker';
import { LuckyDrawPicker } from '../../components/pickers/LuckyDrawPicker';
import { CategoryPicker } from '../../components/pickers/CategoryPicker';
import { MerchantPicker } from '../../components/pickers/MerchantPicker';
import { BrandPicker } from 'src/components/pickers/BrandPicker';

function NotificationProductPayload({
  onChange,
  productId,
}: {
  onChange: (x: any) => void;
  productId: number;
}): ReactElement {
  const [product, setProduct] = useState(productId ? productId : '');

  const onProductChanged = (id: string) => {
    setProduct(id);
    onChange({
      screen: 'Product',
      payload: {
        id: id,
      },
    });
  };

  return (
    <div className="form-group">
      <label>Product ID</label>
      <input
        type="text"
        className="lf-form-control"
        value={product}
        onChange={(e): void => onProductChanged(e.target.value)}
      />
    </div>
  );
}

function NotificationLuckyDrawPayload({ onChange, id }: { onChange: (x: any) => void; id: number }): ReactElement {
  const [luckyDrraw, setLuckyDraw] = useState(id ? id : 0);

  const onLuckyDrawChanged = (t: number) => {
    setLuckyDraw(t);
    onChange({
      screen: 'LuckyDrawDetail',
      payload: {
        luckyDrawId: t,
      },
    });
  };

  return (
    <div className="form-group">
      <label>Select Lucky Draw</label>
      <LuckyDrawPicker value={luckyDrraw} onChanged={onLuckyDrawChanged} placeholder="Please select lucky draw" />
    </div>
  );
}

function NotificationCustomPagePayload({
  onChange,
  pageId,
}: {
  onChange: (x: any) => void;
  pageId: number;
}): ReactElement {
  const [page, setPage] = useState(pageId ? pageId : 0);

  const onPageChanged = (t: number) => {
    setPage(t);
    onChange({
      screen: 'Page',
      payload: {
        id: t,
      },
    });
  };

  return (
    <div className="form-group">
      <label>Custom Page</label>
      <CustomPagePicker value={page} onChanged={onPageChanged} placeholder="Please select custom page" />
    </div>
  );
}

function NotificationCategoryPayload({
  onChange,
  categoryId,
}: {
  onChange: (x: any) => void;
  categoryId: number;
}): ReactElement {
  const [category, setCategory] = useState(categoryId ? categoryId : 0);

  const onCategoryChange = (categoryId: number) => {
    setCategory(categoryId);
    onChange({
      screen: 'Search',
      payload: {
        filter: {
          category: { id: categoryId },
        },
      },
    });
  };

  return (
    <div className="form-group">
      <label>Category</label>
      <CategoryPicker value={category} onChange={onCategoryChange} />
    </div>
  );
}

function NotificationStorePayload({
  onChange,
  storeId,
}: {
  onChange: (x: any) => void;
  storeId: number;
}): ReactElement {
  const [page, setPage] = useState<number | null>(storeId ? storeId : null);

  const onStoreChanged = (t: number | null) => {
    setPage(t);
    onChange({
      screen: 'StoreDetail',
      payload: {
        id: t,
      },
    });
  };

  return (
    <div className="form-group">
      <label>Store</label>
      <MerchantPicker value={page} onChange={onStoreChanged} />
    </div>
  );
}

function mapScreenType(screen: string, payload?: any) {
  if (screen === 'Cart') {
    return 'CART';
  } else if (!screen) {
    return 'HOME';
  } else if (screen === 'Search') {
    if (payload.filter.brand) {
      return 'BRAND';
    } else {
      return 'CATEGORY';
    }
  } else if (screen === 'StoreDetail') {
    return 'STORE';
  } else if (screen === 'Page') {
    return 'PAGE';
  } else if (screen === 'Product') {
    return 'PRODUCT';
  } else if (screen === 'LuckyDrawDetail') {
    return 'LUCKY_DRAW';
  } else if (screen === 'LuckyDrawList') {
    return 'LUCKY_DRAW_LIST';
  } else if (screen === 'WebView') {
    return 'WEBVIEW';
  }
}

function BrandBuilder(props: any) {
  return (
    <div className="form-group">
      <label>Brand</label>
      <BrandPicker
        value={props.value || 0}
        onChange={(v: any) => {
          props.onChange({ screen: 'Search', payload: { filter: { brand: { id: v } } } });
        }}
      />
    </div>
  );
}

function WebViewBuilder(props: any) {
  return (
    <div className="form-group">
      <label>Url</label>
      <input
        type="text"
        className="lf-form-control"
        value={props.value || ''}
        onChange={e => props.onChange({ screen: 'WebView', payload: { url: e.target.value } })}
      />
    </div>
  );
}

export function NotificationPayloadBuilder({
  onChange,
  payload,
}: {
  onChange: (x: any) => void;
  payload: any;
}): React.ReactElement {
  const [type, setType] = useState(payload ? mapScreenType(payload.screen, payload.payload) : '');

  const onTypeChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value);
    const type: string = e.target.value;

    if (type === 'CART') {
      onChange({
        screen: 'Cart',
      });
    } else if (type === 'HOME') {
      onChange({});
    } else if (type === 'LUCKY_DRAW_LIST') {
      onChange({
        screen: 'LuckyDrawList',
      });
    }
  };

  return (
    <div>
      <div className="form-group">
        <label>Screen</label>
        <select className="lf-form-control" value={type} onChange={onTypeChanged}>
          <option>None</option>
          <option value="HOME">Frontpage</option>
          <option value="CART">Cart</option>
          <option value="CATEGORY">Category</option>
          <option value="BRAND">Brand</option>
          <option value="STORE">Store</option>
          <option value="PAGE">Custom Page</option>
          <option value="PRODUCT">Product</option>
          <option value="WEBVIEW">External Link</option>
          <option value="LUCKY_DRAW_LIST">Lucky Draw List</option>
          <option value="LUCKY_DRAW">Lucky Draw</option>
        </select>
      </div>

      {type === 'CATEGORY' && (
        <NotificationCategoryPayload
          onChange={onChange}
          categoryId={payload?.payload?.filter?.category?.id ? payload.payload.filter.category.id : 0}
        />
      )}
      {type === 'BRAND' && <BrandBuilder value={payload?.payload?.filter?.brand?.id ?? 0} onChange={onChange} />}
      {type === 'PAGE' && (
        <NotificationCustomPagePayload onChange={onChange} pageId={payload?.payload?.id ? payload.payload.id : 0} />
      )}
      {type === 'STORE' && (
        <NotificationStorePayload onChange={onChange} storeId={payload?.payload?.id ? payload.payload.id : null} />
      )}
      {type === 'PRODUCT' && (
        <NotificationProductPayload onChange={onChange} productId={payload?.payload?.id ? payload.payload.id : null} />
      )}
      {type === 'LUCKY_DRAW' && (
        <NotificationLuckyDrawPayload
          onChange={onChange}
          id={payload?.payload?.luckyDrawId ? payload.payload.luckyDrawId : null}
        />
      )}
      {type === 'WEBVIEW' && <WebViewBuilder value={payload?.payload?.url ?? ''} onChange={onChange} />}
    </div>
  );
}
