/* eslint-disable */
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

class QuickSearchBase extends React.Component<RouteComponentProps> {
  state: {
    inputSearch: string;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      inputSearch: '',
    };
  }

  onSearchChange = (e: any) => {
    this.setState({
      inputSearch: e.target.value,
    });
  };

  onSearchClick = () => {
    if (this.state.inputSearch.substring(0, 1) === '#') {
      this.props.history.push(`/customers/edit/${this.state.inputSearch.substring(1)}#transactions`);
    } else {
      this.props.history.push({
        pathname: '/customers',
        search: `phone=${this.state.inputSearch}`,
      });
    }
  };

  render() {
    return (
      <div className="element-search autosuggest-search-activator">
        <input
          autoFocus={true}
          type="text"
          className="form-control"
          placeholder="Customer Phone Number"
          style={{
            borderRightWidth: 0,
          }}
          value={this.state.inputSearch}
          onKeyPress={e => {
            if (e.which === 13) {
              this.onSearchClick();
            }
          }}
          onChange={this.onSearchChange}
        />
      </div>
    );
  }
}

export const QuickSearch = withRouter(QuickSearchBase);
