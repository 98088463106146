/* eslint-disable */
import React from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Query, QueryResult } from '@apollo/react-components';
import { gql } from '@apollo/client';

const QUERY = gql`
  query {
    reportPipeline
  }
`;

interface PipelineDetail {
  waitingToFinding: { average_time: number; average_working_time: number };
  findingToFound: {
    average_time: number;
    average_working_time: number;
    list: {
      description: string;
      average_time: number;
      average_working_time: number;
      count: number;
      percent: number;
    }[];
  };
  foundToReady: { average_time: number; average_working_time: number };
  foundToTransit: { average_time: number; average_working_time: number };
  transitToReady: { average_time: number; average_working_time: number };
  readyToNote: { average_time: number; average_working_time: number };
  noteToDelivered: { average_time: number };
  waitingToPrepared: { average_time: number };
  preparedToArrived: { average_time: number };
  arrivedToReady: { average_time: number };
  promisedToDelivered: {
    average_time: number;
    list: {
      description: string;
      average_time: number;
      count: number;
      percent: number;
    }[];
  };
}

function dateFormating(min: number) {
  if (min >= 60) {
    const hour = Math.floor(min / 60);
    const rmin = (min - hour * 60) | 0;

    return hour + 'h ' + rmin.toString().padStart(2, '0') + 'm';
  }
  return min.toFixed(0) + 'm';
}

function PipelineBlock(props: React.PropsWithChildren<{ title: string; width?: number }>) {
  return (
    <div style={{ padding: 5, border: '2px solid #aaa', margin: '0 auto', width: props.width ? props.width : 200 }}>
      <div className="text-center" style={{ backgroundColor: '#f1f1f1', fontSize: 18, padding: 5 }}>
        <strong>{props.title}</strong>
      </div>
      {props.children}
    </div>
  );
}

function PipelineTime(
  props: React.PropsWithChildren<{ average: number; averageWorkhour?: number; width?: number; target?: number }>,
) {
  let timing = (
    <div style={{ flexDirection: 'column', display: 'flex', textAlign: 'center' }}>
      <span>Avg</span>
      <strong style={{ fontSize: 18 }}>{dateFormating(props.average)}</strong>
    </div>
  );

  if (props.averageWorkhour) {
    timing = (
      <div style={{ display: 'flex' }}>
        <div
          style={{
            flexShrink: 0,
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            borderRight: '1px solid #888',
            padding: 5,
          }}
        >
          <span>Avg</span>
          <strong style={{ fontSize: 18 }}>{dateFormating(props.average)}</strong>
        </div>
        <div
          style={{
            flexShrink: 0,
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            padding: 5,
          }}
        >
          <span>WAvg</span>
          <strong style={{ fontSize: 18 }}>{dateFormating(props.averageWorkhour)}</strong>
        </div>
      </div>
    );
  }

  return (
    <div style={{ padding: 10, border: '2px solid #aaa', margin: '0 auto', width: props.width ? props.width : 250 }}>
      {timing}
      {props.target !== undefined && (
        <div
          style={{
            backgroundColor: '#f6e58d',
            marginBottom: 5,
            color: '#f00',
            padding: 5,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          Target: {dateFormating(props.target)}
        </div>
      )}
      {props.children}
    </div>
  );
}

function PipelineFlow() {
  return (
    <div style={{ lineHeight: 0 }}>
      <div style={{ paddingLeft: '50%', display: 'inline-block', borderRight: '2px solid #aaa', height: 20 }}></div>
    </div>
  );
}

function PipelineFlowFill() {
  return (
    <div style={{ lineHeight: 0, flexGrow: 1 }}>
      <div style={{ paddingLeft: '50%', display: 'inline-block', borderRight: '2px solid #aaa', height: '100%' }}></div>
    </div>
  );
}

function PipelineFlowSplit() {
  return (
    <div style={{ lineHeight: 0, margin: '0 auto', width: '50%' }}>
      <div
        style={{
          height: 30,
          borderTop: '2px solid #aaa',
          borderLeft: '2px solid #aaa',
          borderRight: '2px solid #aaa',
          boxSizing: 'content-box',
          marginRight: -2,
        }}
      ></div>
    </div>
  );
}

function PipelineFlowCombine() {
  return (
    <div style={{ lineHeight: 0, margin: '0 auto', width: '50%' }}>
      <div
        style={{
          height: 30,
          borderBottom: '2px solid #aaa',
          borderLeft: '2px solid #aaa',
          borderRight: '2px solid #aaa',
          boxSizing: 'content-box',
          marginRight: -2,
        }}
      ></div>
    </div>
  );
}

function PipelineSplit(props: React.PropsWithChildren<unknown>) {
  return <div style={{ display: 'flex' }}>{props.children}</div>;
}

function PipelineSplitItem(props: React.PropsWithChildren<unknown>) {
  return <div style={{ flexShrink: 0, width: '50%', display: 'flex', flexDirection: 'column' }}>{props.children}</div>;
}

function renderFoundToReady(data: PipelineDetail) {
  return (
    <PipelineSplit>
      <PipelineSplitItem>
        <PipelineFlowFill />
        <PipelineTime
          average={data.foundToReady.average_time}
          averageWorkhour={data.foundToReady.average_working_time}
          target={10}
        ></PipelineTime>
        <PipelineFlowFill />
      </PipelineSplitItem>
      <PipelineSplitItem>
        <PipelineFlow />
        <PipelineTime
          average={data.foundToTransit.average_time}
          averageWorkhour={data.foundToTransit.average_working_time}
          target={60}
        ></PipelineTime>
        <PipelineFlow />
        <PipelineBlock title="Transit" />
        <PipelineFlow />
        <PipelineTime
          average={data.transitToReady.average_time}
          averageWorkhour={data.transitToReady.average_working_time}
          target={60}
        ></PipelineTime>
        <PipelineFlow />
      </PipelineSplitItem>
    </PipelineSplit>
  );
}

function renderFindingToFoundTime(data: PipelineDetail) {
  return (
    <PipelineTime
      width={400}
      average={data.findingToFound.average_time}
      averageWorkhour={data.findingToFound.average_working_time}
      target={3}
    >
      <table className="table table-bordered table-v2 table-striped">
        <thead>
          <tr>
            <th>Location</th>
            <th>Avg</th>
            <th>W-Avg</th>
            <th>T</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data.findingToFound.list.map(x => (
            <tr key={x.description}>
              <td>{x.description}</td>
              <td className="text-right">{dateFormating(x.average_time)}</td>
              <td className="text-right">{dateFormating(x.average_working_time)}</td>
              <td className="text-right">
                <span style={{ color: '#f00' }}>{dateFormating(x.description[0] === 'S' ? 5 : 1)}</span>
              </td>
              <td className="text-right">{x.percent.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </PipelineTime>
  );
}

function renderDeliveringToDeliveredTime(data: PipelineDetail) {
  return (
    <PipelineTime width={400} average={data.findingToFound.average_time} target={0}>
      <table className="table table-bordered table-v2 table-striped">
        <thead>
          <tr>
            <th>Delivery Staff</th>
            <th>Avg</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          {data.promisedToDelivered.list.map(x => (
            <tr key={x.description}>
              <td>{x.description}</td>
              <td className="text-right">{dateFormating(x.average_time)}</td>
              <td className="text-right">{x.percent.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </PipelineTime>
  );
}

function transform(data: any): PipelineDetail {
  const r: PipelineDetail = {
    waitingToFinding: { average_time: 0, average_working_time: 0 },
    findingToFound: { average_time: 0, average_working_time: 0, list: [] },
    foundToReady: { average_time: 0, average_working_time: 0 },
    foundToTransit: { average_time: 0, average_working_time: 0 },
    transitToReady: { average_time: 0, average_working_time: 0 },
    readyToNote: { average_time: 0, average_working_time: 0 },
    noteToDelivered: { average_time: 0 },
    waitingToPrepared: { average_time: 0 },
    preparedToArrived: { average_time: 0 },
    arrivedToReady: { average_time: 0 },
    promisedToDelivered: { average_time: 0, list: [] },
  };

  for (const row of data) {
    if (row.pipeline_type === 'WAITING_TO_FINDING') {
      r.waitingToFinding = row;
    } else if (row.pipeline_type === 'FINDING_TO_FOUND') {
      r.findingToFound.list.push(row);
    } else if (row.pipeline_type === 'FOUND_TO_READY') {
      r.foundToReady = row;
    } else if (row.pipeline_type === 'FOUND_TO_TRANSIT') {
      r.foundToTransit = row;
    } else if (row.pipeline_type === 'TRANSIT_TO_READY') {
      r.transitToReady = row;
    } else if (row.pipeline_type === 'READY_TO_NOTE') {
      r.readyToNote = row;
    } else if (row.pipeline_type === 'NOTE_TO_DELIVERED') {
      r.noteToDelivered = row;
    } else if (row.pipeline_type === 'PROMISED_TO_DELIVERED') {
      r.promisedToDelivered.list.push(row);
    } else if (row.pipeline_type === 'WAITING_TO_PREPARED') {
      r.waitingToPrepared = row;
    } else if (row.pipeline_type === 'PREPARED_TO_ARRIVED') {
      r.preparedToArrived = row;
    } else if (row.pipeline_type === 'ARRIVED_TO_READY') {
      r.arrivedToReady = row;
    }
  }

  const findingTotal = r.findingToFound.list.map(x => x.count).reduce((a, b) => a + b, 0);
  r.findingToFound.average_time =
    r.findingToFound.list.map(x => x.average_time * x.count).reduce((a, b) => a + b, 0) / findingTotal;
  r.findingToFound.average_working_time =
    r.findingToFound.list.map(x => x.average_working_time * x.count).reduce((a, b) => a + b, 0) / findingTotal;
  r.findingToFound.list = r.findingToFound.list.map(x => ({ ...x, percent: 100 * (x.count / findingTotal) }));

  const promisedToDeliveredTotal = r.promisedToDelivered.list.map(x => x.count).reduce((a, b) => a + b, 0);
  r.promisedToDelivered.average_time =
    r.promisedToDelivered.list.map(x => x.average_time * x.count).reduce((a, b) => a + b, 0) / promisedToDeliveredTotal;
  r.promisedToDelivered.list = r.promisedToDelivered.list.map(x => ({
    ...x,
    percent: 100 * (x.count / promisedToDeliveredTotal),
  }));

  return r;
}

export class PipelineScreen extends React.Component {
  render() {
    return (
      <Query query={QUERY}>
        {({ data, loading }: QueryResult) => {
          if (loading) return <div>Loading</div>;
          return this.renderPipeline(transform(data.reportPipeline));
        }}
      </Query>
    );
  }

  renderPipeline(data: PipelineDetail) {
    return (
      <Content>
        <SectionBlock title="Pipeline">
          <div>
            <PipelineBlock title="Waiting" />
            <PipelineFlow />
            <PipelineFlowSplit />
            <PipelineSplit>
              <PipelineSplitItem>
                <PipelineFlow />
                <PipelineTime
                  average={data.waitingToFinding.average_time}
                  averageWorkhour={data.waitingToFinding.average_working_time}
                  target={10}
                />
                <PipelineFlow />
                <PipelineBlock title="Finding" />
                <PipelineFlow />
                {renderFindingToFoundTime(data)}
                <PipelineFlow />
                <PipelineBlock title="Found" />
                <PipelineFlow />
                <PipelineFlowSplit />
                {renderFoundToReady(data)}
                <PipelineFlowCombine />
              </PipelineSplitItem>

              <PipelineSplitItem>
                <PipelineFlowFill />
                <PipelineTime average={data.waitingToPrepared.average_time} />
                <PipelineFlowFill />
                <PipelineBlock title="Prepared" />
                <PipelineFlowFill />
                <PipelineTime average={data.preparedToArrived.average_time} />
                <PipelineFlowFill />
                <PipelineBlock title="Arrived" />
                <PipelineFlowFill />
                <PipelineTime average={data.arrivedToReady.average_time} />
                <PipelineFlowFill />
              </PipelineSplitItem>
            </PipelineSplit>
            <PipelineFlowCombine />
            <PipelineFlow />
            <PipelineBlock title="Ready" width={300}>
              <ul style={{ marginTop: 10 }}>
                <li>
                  From <i>waiting to ready</i> without transit averagely takes&nbsp;
                  <strong>
                    {dateFormating(
                      data.waitingToFinding.average_time +
                        data.findingToFound.average_time +
                        data.foundToReady.average_time,
                    )}
                  </strong>{' '}
                  or &nbsp;
                  <strong>
                    {dateFormating(
                      data.waitingToFinding.average_working_time +
                        data.findingToFound.average_working_time +
                        data.foundToReady.average_working_time,
                    )}{' '}
                    (working hour)
                  </strong>
                </li>
                <li>
                  From <i>waiting to ready</i> transit averagely takes&nbsp;
                  <strong>
                    {dateFormating(
                      data.waitingToFinding.average_time +
                        data.findingToFound.average_time +
                        data.foundToTransit.average_time +
                        data.transitToReady.average_time,
                    )}
                  </strong>{' '}
                  or &nbsp;
                  <strong>
                    {dateFormating(
                      data.waitingToFinding.average_working_time +
                        data.findingToFound.average_working_time +
                        data.foundToTransit.average_working_time +
                        data.transitToReady.average_working_time,
                    )}{' '}
                    (working hour)
                  </strong>
                </li>
              </ul>
            </PipelineBlock>
            <PipelineFlow />
            <PipelineTime
              average={data.readyToNote.average_time}
              averageWorkhour={data.readyToNote.average_working_time}
              target={60}
            />
            <PipelineFlow />
            <PipelineBlock title="Delivery Note" />
            <PipelineFlow />
            <PipelineTime average={data.noteToDelivered.average_time - data.promisedToDelivered.average_time} />
            <PipelineFlow />
            <PipelineBlock title="Promised Deliver Time" />
            <PipelineFlow />
            {renderDeliveringToDeliveredTime(data)}
            <PipelineFlow />
            <PipelineBlock title="Delivered" />
          </div>
        </SectionBlock>
      </Content>
    );
  }
}
