import { useContext } from 'react';
import { URLQueryStringContext } from 'src/components/context/URLQueryStringContext';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function useQueryStringContext<T = any>(defaultValue?: T): T {
  const context = useContext(URLQueryStringContext);
  const qs = new URLSearchParams(context?.value);
  const entries = Array.from(qs.entries());
  const result: any = { ...defaultValue };

  for (const [key, value] of entries) {
    result[key] = value;
  }

  return (result as unknown) as T;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function useQueryString<T = any>(defaultValue?: T): T {
  const qs = new URLSearchParams(window.location.search);
  const entries = Array.from(qs.entries());
  const result: any = { ...defaultValue };

  for (const [key, value] of entries) {
    result[key] = value;
  }

  return (result as unknown) as T;
}

export function rebuildQueryString(obj: any) {
  const qs = new URLSearchParams(window.location.search);
  for (const key of Object.keys(obj)) {
    qs.set(key, obj[key]);
  }

  return window.location.pathname + '?' + qs.toString() + (window.location.hash ? window.location.hash : '');
}
