import React from 'react';

type Props = {
  children: React.ReactNode;
  breadcrumb?: React.ReactNode;
};

export function Content(props: Props) {
  return (
    <div className="content-w">
      {props.breadcrumb}
      <div className="content-i">
        <div className="content-box">{props.children}</div>
      </div>
    </div>
  );
}
