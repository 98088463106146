/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../generated/graph';
import { DropdownBaseComponent } from './DropdownBaseComponent';
import { HighlightText } from './HighlightText';
import FormInputLike from './layout/xform/FormInputLike';
import XForm from './layout/xform/XForm';
import { TextField } from '@shopify/polaris';

export const QUERY = gql`
  query PackageList($status: [PackageStatus]) {
    adminList(active: true) {
      id
      name
      role
      groupID
      isProductTeam
      zones
      warehouseIds
    }
    adminGroupList {
      id
      name
    }
    packageList(status: $status, offset: 0, limit: 100000) {
      id
      claimDeliveryBy {
        id
        name
      }
    }
  }
`;

interface Props {
  value?: number | string;
  name?: string;
  defaultValue?: number;
  placeholder?: string;
  style?: React.CSSProperties;
  onChange?: (v: number) => void;
  defaultProductTeam?: boolean;
  defaultGroup?: number;
  defaultHubIds?: number;
  label?: string;
  previewToLeft?: boolean;
  polaris?: boolean;
}

export function AdminPicker(props: Props): ReactElement {
  const { data } = useQuery<Graph.Query>(QUERY, {
    variables: { status: ['ARRIVED'] },
    fetchPolicy: 'cache-and-network',
  });

  const [internalValue, setInternalValue] = useState(props.defaultValue || 0);
  const [toggle, setToggle] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<number>(props.defaultGroup || 0);
  const [selectedHubIds] = useState<number | undefined>(props.defaultHubIds);
  const [isSelectedHubIds, setIsSelectedHubIds] = useState(Number(props.defaultHubIds) > 0 || false);
  const [isProductTeam, setIsProductTeam] = useState(props.defaultProductTeam || false);
  const packList = data?.packageList || null || undefined;

  if (!data?.adminList || !data?.adminGroupList) {
    return (
      <FormInputLike label={props.label} dropdown={true} loading={true}>
        Loading
      </FormInputLike>
    );
  }
  const adminGroupList = data.adminGroupList;
  const adminList = data.adminList;

  function preview(data: Graph.Admin[]): ReactElement {
    const item = data.find(x => x.id === (props.value || internalValue));
    const caption = item ? item.name : props.placeholder;

    return <FormInputLike dropdown={true}>{caption}</FormInputLike>;
  }
  function dropdown(data: Graph.Admin[], group: Graph.AdminGroup[]): ReactElement {
    return (
      <div>
        <div
          style={{
            padding: '0.25rem 0.5rem',
            borderWidth: '0 0 .5px 0',
            borderColor: 'rgba(0,0,0,0.2)',
            borderStyle: 'solid',
            alignItems: 'left',
          }}
        >
          <div className="row">
            <div className="col-6 pr-0">
              <input
                className="lf-form-control"
                placeholder="Start typing to search..."
                type="search"
                autoFocus
                name="search"
                onChange={(e): void => setSearch(e.currentTarget.value)}
                autoComplete={'off'}
              />
            </div>
            <div className="col-6 pr-1">
              <select
                className="lf-form-control"
                value={selectedGroup}
                onChange={(e): void => setSelectedGroup(Number(e.currentTarget.value))}
              >
                <option key="all" value={0}>
                  All Group
                </option>
                {group.map(x => (
                  <option key={x.id?.toString()} value={x.id?.toString()}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="mt-2 ml-2">
              <XForm.Check
                checked={isProductTeam}
                label="Product Team"
                onChange={e => setIsProductTeam(e.currentTarget.checked)}
              />
            </div>
            {selectedGroup === 5 && (
              <div className="mt-2 ml-3">
                <XForm.Check
                  checked={isSelectedHubIds}
                  label="Fitler by Hub"
                  onChange={() => setIsSelectedHubIds(!isSelectedHubIds)}
                />
              </div>
            )}
          </div>
        </div>

        <ul className="dropdown-container" style={{ width: 500, maxHeight: 400, overflowY: 'auto', right: 0 }}>
          <li
            key="All"
            onClick={(): void => {
              setToggle(false);
              setInternalValue(0);
              if (props.onChange) {
                props.onChange(0);
              }
            }}
          >
            <strong>No selection </strong>
          </li>

          {data.map(x => (
            <li
              key={x.id}
              onClick={(): void => {
                setToggle(false);
                setInternalValue(x.id);
                if (props.onChange) props.onChange(x.id);
              }}
            >
              <HighlightText text={x.name ? x.name : ''} highlight={search} />
              {x.groupID === 5 ? <> ({packList?.filter(p => p.claimDeliveryBy?.id === x?.id).length})</> : ''}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (props.polaris) {
    return (
      <>
        {props.name && (
          <TextField
            name={props.name}
            value={props.value === undefined ? (internalValue as any) : (props.value as any)}
            readOnly
            autoComplete="off"
            label
            labelHidden
          />
        )}
      </>
    );
  }

  return (
    <>
      {props.name && (
        <input
          name={props.name}
          type="hidden"
          value={props.value === undefined ? internalValue : props.value}
          readOnly
        />
      )}

      <DropdownBaseComponent
        label={props.label}
        style={props.style}
        previewToLeft={props.previewToLeft}
        toggle={toggle}
        preview={(): ReactElement => preview(adminList)}
        dropdownHeight={400}
        dropdown={(): ReactElement =>
          dropdown(
            adminList
              .filter(x => {
                if (selectedGroup === 0) return true;
                if (x.groupID === selectedGroup && !!isSelectedHubIds) {
                  return x.groupID === selectedGroup && x.warehouseIds?.includes(Number(selectedHubIds));
                }
                return x.groupID === selectedGroup;
              })
              .filter(x => {
                if (!isProductTeam) return true;
                return x.isProductTeam;
              })
              .filter(x => {
                if (!x.name) return false;
                return (
                  x.name
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toString().toLowerCase()) !== -1
                );
              }),
            adminGroupList,
          )
        }
        onToggleChanged={(t): void => {
          setToggle(t);
        }}
      />
    </>
  );
}
