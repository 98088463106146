import React from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { ReportSalesByCategoryList } from './partial/ReportSalesByCategoryList';
import { useQueryString } from '../../libs/QueryString';
import { DateTime } from '../../libs/DateTime';

export function ReportSalesByCategoryScreen(): React.ReactElement {
  const { start, end, compare_start, compare_end } = useQueryString<{
    start: string;
    end: string;
    compare_start: string;
    compare_end: string;
  }>({
    start: DateTime.lastMonth(),
    end: DateTime.today(),
    compare_start: DateTime.last(60, 'day'),
    compare_end: DateTime.last(30, 'day'),
  });

  return (
    <Content>
      <SectionBlock title="Report Sales by Category">
        <div className="controls-above-table">
          <form action="" method="GET">
            <div className="mb-1">
              <div style={{ display: 'inline-block', width: 120 }}>Current</div>
              <input type="date" name="start" className="lf-form-control lf-inline" defaultValue={start} />
              <input type="date" name="end" className="lf-form-control lf-inline ml-1" defaultValue={end} />
              <button className="lf-btn btn-primary ml-1">Filter</button>
            </div>
            <div className="mb-1">
              <div style={{ display: 'inline-block', width: 120 }}>Compare to</div>
              <input
                type="date"
                name="compare_start"
                className="lf-form-control lf-inline"
                defaultValue={compare_start}
              />
              <input
                type="date"
                name="compare_end"
                className="lf-form-control lf-inline ml-1"
                defaultValue={compare_end}
              />
            </div>
          </form>
        </div>
        <ReportSalesByCategoryList start={start} end={end} comparedStart={compare_start} comparedEnd={compare_end} />
      </SectionBlock>
    </Content>
  );
}
