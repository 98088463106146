import React, { useState, useEffect } from 'react';

type Props = {
  title: string;
  loading: boolean;
  error?: string;
  onSubmit?: (username: string, password: string, otp: string) => void;
};

export function LoginForm(props: Props) {
  const [store, setStore] = useState('1');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOTP] = useState('');

  useEffect(() => {
    document.body.classList.add('auth-wrapper');
    return () => {
      document.body.classList.remove('auth-wrapper');
    };
  }, []);

  function renderLogo() {
    return (
      <div className="logo-w">
        <h1>{props.title}</h1>
      </div>
    );
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (props.onSubmit) {
      localStorage.setItem('store', store);
      props.onSubmit(username, password, otp);
    }

    e.preventDefault();
  };

  function renderError() {
    if (props.error) {
      return (
        <div className="alert alert-danger" role="alert">
          {props.error}
        </div>
      );
    }
    return null;
  }

  function renderForm() {
    return (
      <form onSubmit={onSubmit}>
        {renderError()}
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Enter your username"
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
            autoFocus
          />
          <div className="pre-icon os-icon os-icon-user-male-circle"></div>
        </div>
        <div className="form-group">
          <input
            className="form-control"
            placeholder="Enter your password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
          <div className="pre-icon os-icon os-icon-fingerprint"></div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label>Store</label>
              <select className="form-control" value={store} onChange={e => setStore(e.target.value)}>
                <option value={1}>Store 1</option>
                <option value={2}>Store 2</option>
                <option value={3}>Store 3</option>
                <option value={4}>Store 4 (TK)</option>
                <option value={5}>Store 5</option>
                <option value={6}>Store 6 (PH)</option>
                <option value={7}>Store 7 (Chom Chao)</option>
                <option value={8}>Store 8 (Russey Keo)</option>
                <option value={9}>Store 9 (Chamka Dong)</option>
                <option value={10}>Store 10 (CV)</option>
                <option value={11}>Store 11 (SHV)</option>
              </select>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label>OTP</label>
              <input
                className="form-control"
                placeholder="OTP"
                value={otp}
                onChange={e => setOTP(e.target.value)}
                type="text"
              />
            </div>
          </div>
        </div>

        <div className="buttons-w">
          <button disabled={props.loading} className="btn btn-primary">
            Log me in
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="all-wrapper menu-side with-pattern">
      <div className="auth-box-w">
        {renderLogo()}
        <h4 className="auth-header">Login Form</h4>
        {renderForm()}
      </div>
    </div>
  );
}
