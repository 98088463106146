import { gql } from '@apollo/client';

export const QUERY_PACKAGE_LIST = gql`
  query PackageList(
    $type: [PackageType]
    $status: [PackageStatus]
    $supplierId: Int
    $overSize: Boolean
    $createdAtTo: String
    $createdAtFrom: String
    $reachFinal: Boolean
    $onlyWithCoordinate: Boolean
  ) {
    packageList(
      offset: 0
      limit: 100000
      type: $type
      status: $status
      overSize: $overSize
      supplierId: $supplierId
      createdAtTo: $createdAtTo
      createdAtFrom: $createdAtFrom
      reachFinal: $reachFinal
      onlyWithCoordinate: $onlyWithCoordinate
    ) {
      x
      y
      z
      id
      type
      uuid
      lng
      lat
      status
      value
      fee
      cash
      weight
      attempt
      isFinal
      barcode
      postFee
      picture
      overSize
      telegram
      isDropbox
      addressId
      createdAt
      provinceId
      addressName
      appointedAt
      isReturning
      houseNumber
      streetNumber
      customerName
      isPrepayment
      useInsurance
      lastTransitAt
      claimPickupAt
      cashCollected
      deliveryNoteId
      barcodeInitial
      requestPickupAt
      hasReturningItem
      thirdPartyTracker
      thirdPartyProvider
      dropboxInstruction
      additionalInstruction
      returningFromPackageId
      customerPayForThirdParty
      legacyDeliveryNote {
        returnedItems {
          id
          sku {
            product {
              code
            }
          }
        }
      }
      lastTransitBy {
        id
        name
        image
      }
      currentWarehouse {
        id
        name
        shortName
      }
      nextWarehouse {
        id
        name
        shortName
      }
      supplier {
        name
        id
        contact
        firstCreatedPackageAt
      }
      createdBy {
        id
        name
      }
      claimDeliveryBy {
        id
        name
      }
      claimPickupBy {
        id
        name
      }
      customer {
        id
        name
        phoneNumber
      }
      notes {
        id
        message
        createdAt
      }
    }
  }
`;

export const QUERY_PACKAGE_WITHOUT_LOG = gql`
  query PackageWithBarcode($barcode: String) {
    package(barcode: $barcode) {
      id
    }
  }
`;

export const QUERY_PACKAGE = gql`
  query Package($id: Int!, $barcode: String) {
    packageItemList(id: $id) {
      id
      code
      size
      color
      slotName
      picture
    }
    package(id: $id, barcode: $barcode) {
      x
      y
      z
      id
      type
      uuid
      lng
      lat
      status
      value
      fee
      cash
      weight
      attempt
      isFinal
      barcode
      postFee
      picture
      overSize
      telegram
      isDropbox
      addressId
      createdAt
      successLat
      successLng
      provinceId
      addressName
      appointedAt
      isReturning
      houseNumber
      streetNumber
      customerName
      isPrepayment
      useInsurance
      cashCollected
      deliveryNoteId
      barcodeInitial
      requestPickupAt
      hasReturningItem
      thirdPartyTracker
      thirdPartyProvider
      dropboxInstruction
      additionalInstruction
      returningFromPackageId
      customerPayForThirdParty
      agent {
        id
        name
      }
      toAgent {
        id
        name
      }
      legacyDeliveryNote {
        returnedItems {
          id
          sku {
            product {
              code
            }
          }
        }
      }
      receiver {
        addressName
        lat
        lng
        name
        phoneNumber
      }

      sender {
        addressName
        lat
        lng
        name
        phoneNumber
      }

      settlementWithSeller {
        id
        attachment
      }
      supplier {
        id
        name
        logo
        address
        origin
        contact
        coordLat
        coordLng
      }
      currentWarehouse {
        id
        name
        shortName
      }
      finalWarehouse {
        id
        name
        shortName
      }
      claimPickupBy {
        id
        name
      }
      claimDeliveryBy {
        id
        name
      }
      customer {
        id
        name
        phoneNumber
      }
      logs {
        id
        status
        createdAt
        attachment
        createdBy {
          name
        }
        warehouse {
          name
          shortName
        }
        verifiedBy {
          id
          name
        }
        verifiedAt
      }
      notes {
        id
        message
        attachment
        createdAt
        createdBy {
          id
          name
          image
        }
      }
      items {
        id
        status
        price
        priceBeforeDiscount
        uuid
        sku {
          id
          option1
          option2
          product {
            id
            code
            originalPicture
            thumbnail(width: 100, height: 100)
          }
        }
        slot {
          id
          name
        }
        customer {
          id
          name
          phoneNumber
        }
      }
      noteFlags {
        unreachable
        locationChanged
        timeChanged
        attempt
        rain
        accident
      }
      changeLogs {
        id
        updatedValue
        originalValue
        createdAt
        createdBy {
          id
          name
        }
      }
      packageWithLabel {
        id
        url
        createdAt
        barcode
      }
    }
  }
`;

export const QUERY_WAREHOUSE_LIST_FILTER = gql`
  query WarehouseList {
    warehouseList {
      id
      name
      shortName
      connectedHubs {
        id
        name
        shortName
      }
    }
  }
`;

export const QUERY_CUSTOMER_NOTE_TEMPLATE = gql`
  query customerNoteTemplateList {
    customerNoteTemplateList {
      id
      content
      isMoneyNote
      type
    }
  }
`;

export const QUERY_CUSTOMER_ADDRESS_LIST = gql`
  query CustomerAddressList($customerID: Int) {
    customerAddressList(customerID: $customerID) {
      id
      address
      lat
      lng
      label
      default
      receiver
      isDropbox
      picture
      phoneNumber
      verifiedPicture
      houseNumber
      streetNumber
      instruction
      note
      verifiedCoordinate {
        lat
        lng
      }
      dropOff {
        id
        name
        provinceName
      }
    }
  }
`;
export const QUERY_SUGGESTION_CUSTOMER_LOCATION = gql`
  query CustomerLocationSuggestion($customerId: Int!) {
    customerLocationSuggestion(customerId: $customerId, checkoutLimit: 5) {
      id
      lat
      lng
      type
    }
  }
`;

export const MUTATION_PACKAGE_ATTACH_BARCODE = gql`
  mutation attachPackageBarcode($id: Int!, $barcode: String!) {
    attachPackageBarcode(id: $id, barcode: $barcode)
  }
`;

export const MUTATION_PACKAGE_ATTACH_TRACKER = gql`
  mutation attachPackageTracker($id: Int!, $tracker: String!) {
    attachPackageTracker(id: $id, tracker: $tracker)
  }
`;

export const MUTATION_PACKAGE_CLAIM_PICKUP = gql`
  mutation claimPackagePickup($id: Int!, $admin: Int!) {
    claimPackage(id: $id, adminId: $admin)
  }
`;

export const MUTATION_PACKAGE_CLAIM_DELIVER = gql`
  mutation claimPackageDeliver($id: Int!, $admin: Int!) {
    claimDeliveryPackage(id: $id, adminId: $admin)
  }
`;

export const MUTATION_PACKAGE_MARK_DELIVERED = gql`
  mutation markPackageDelivered($id: Int!, $cash: Float) {
    markPackageDelivered(id: $id, cash: $cash)
  }
`;

export const MUTATION_PACKAGE_MARK_RETURNING = gql`
  mutation markPackageReturning($id: Int!, $undo: Boolean = false) {
    markPackageReturning(id: $id, undo: $undo)
  }
`;
export const MUTATION_PACKAGE_REVERSE_DRAFT = gql`
  mutation reversePackageStatus($packageId: Int!, $status: PackageStatus) {
    reversePackageStatus(packageId: $packageId, status: $status)
  }
`;
export const MUTATION_VERIFY_PACKAGE_ATTEMPT = gql`
  mutation VerifyPackageAttempt($packageLogId: Int!) {
    verifyPackageAttempt(packageLogId: $packageLogId)
  }
`;

export const MUTATION_PACKAGE_DELIVERY_FEE = gql`
  mutation updatePackage(
    $id: Int!
    $fee: Float
    $lat: String
    $lng: String
    $value: Float
    $cash: Float
    $cashCollected: Float
    $appointedAt: String
  ) {
    updatePackage(
      id: $id
      fee: $fee
      lat: $lat
      lng: $lng
      value: $value
      cash: $cash
      cashCollected: $cashCollected
      appointedAt: $appointedAt
    )
  }
`;

export const MUTATION_PACKAGE_DELIVERY_FEE_V2 = gql`
  mutation updatePackageV2($id: Int!, $data: PackageInput!) {
    updatePackageV2(id: $id, data: $data)
  }
`;

export const MUTATION_PACKAGE_NOTES = gql`
  mutation createPackageNote($packageId: Int!, $data: PackageNoteInput!) {
    createPackageNote(packageId: $packageId, data: $data)
  }
`;
export const MUTATION_VOID_PACKAGE = gql`
  mutation voidPackage($id: Int) {
    voidPackage(id: $id)
  }
`;

export const ESTIMATE_PACKAGE_PRICE = gql`
  query estimatePackagePrice($data: PackageInput) {
    estimatePackagePrice(data: $data) {
      price
    }
  }
`;
export const MUTATION_MARK_PACKAGE_STATUS = gql`
  mutation markPackageStatus($id: Int!, $status: PackageStatus!) {
    markPackageStatus(id: $id, status: $status)
  }
`;
export const MUTATION_MARK_PACKAGE_DELIVERED = gql`
  mutation markPackageDelivered($id: Int, $lat: String, $lng: String, $cash: Float) {
    markPackageDelivered(id: $id, lat: $lat, lng: $lng, cash: $cash)
  }
`;
