import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { QUERY_PACKAGE_LIST } from '../query';
import { Link } from 'react-router-dom';
import { BlockLoading } from '../../../components/loading/BlockLoading';
import { Table } from 'src/components/layout/tables/Table';
import Formatter from 'src/libs/Formatter';
import { useHistory, useLocation } from 'react-router';
import XForm from 'src/components/layout/xform/XForm';

function renderPackage(item: Graph.Package) {
  return (
    <tr key={item.id}>
      <td className="text-center">
        <i
          className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
          style={{ fontSize: 18, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
        ></i>
      </td>
      <td>
        {item.type === 'ORDER_PICKUP' && (
          <div style={{ marginBottom: 2 }}>
            <span style={{ fontSize: 10, background: '#f1c40f', display: 'inline-block', padding: '2px 5px' }}>
              {Formatter.beautifyUglyName(item.type)}
            </span>
          </div>
        )}
        {item.type === 'PICKUP' && (
          <div style={{ marginBottom: 2 }}>
            <span
              style={{
                fontSize: 10,
                background: '#1d82fb',
                display: 'inline-block',
                padding: '2px 5px',
                color: '#fff',
              }}
            >
              {Formatter.beautifyUglyName('Customer Pickup')}
            </span>
          </div>
        )}
        <Link to={`/package/detail/${item.id}`}>{item.id.toString().padStart(8, '0')}</Link>
      </td>
      <td>{item?.lastTransitAt}</td>
      <td>
        <strong>{item.supplier?.name}</strong>
        <br />
        <span>{item.supplier?.contact}</span>
      </td>
      <td>
        {item.customer && (
          <div>
            <strong>{item.customer.name}</strong>
            <br />
            <span>{item.customer.phoneNumber}</span>
          </div>
        )}
      </td>
      <td>
        <strong style={{ fontSize: 16 }}>
          {item.currentWarehouse?.shortName}
          &nbsp;&nbsp;
          <i className="fas fa-arrow-right" />
          &nbsp;&nbsp; {item.nextWarehouse?.shortName ?? 'Customer'}
        </strong>
      </td>
    </tr>
  );
}

function PacakgeList({ items }: { items: Graph.Package[] }) {
  const [toggle, setToggle] = useState(false);
  return (
    <Table stripe={true} border={true}>
      <thead>
        <tr style={{ background: '#ced6e0' }} onClick={() => setToggle(!toggle)}>
          <th colSpan={5}>
            {items[0].lastTransitBy?.name || 'Unknown'}
            <span style={{ fontWeight: 'lighter' }}> ({items.length})</span>
          </th>
          <th style={{ textAlign: 'right' }}>
            <XForm.Button>{toggle ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</XForm.Button>
          </th>
        </tr>
        {toggle && (
          <tr>
            <th style={{ width: 75 }}>Type</th>
            <th style={{ width: 150 }}>Package ID</th>
            <th style={{ width: 250 }}> Last Transit At</th>
            <th>Merchant</th>
            <th style={{ width: 150 }}>Customer</th>
            <th style={{ width: 250 }}>Route</th>
          </tr>
        )}
      </thead>
      {toggle && <tbody>{items.map(renderPackage)}</tbody>}
    </Table>
  );
}
function renderPackageBreakdownByTransitStaff(items: Graph.Package[]) {
  const uniqueTransitStaff = new Set(items.map(x => (x.lastTransitBy ? x.lastTransitBy?.id : null)));
  const TransitStaff = [...uniqueTransitStaff];

  return TransitStaff.map((id, i) => {
    const orders = items.filter(x => {
      if (!id) return x.lastTransitBy === null;
      return x.lastTransitBy?.id === id;
    });
    return (
      <div key={i} className="mb-3">
        <PacakgeList items={orders} />
      </div>
    );
  });
}

export function PackageTransitListScreen() {
  const history = useHistory();
  const { pathname, search } = useLocation();
  useEffect(() => {
    history.replace(pathname + '#transit');
  }, [search, pathname, history]);

  const { data } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, { variables: { status: ['TRANSIT'] } });
  if (!data?.packageList) return <BlockLoading />;
  return (
    <>
      <div>{renderPackageBreakdownByTransitStaff(data.packageList)}</div>
    </>
  );
}
