import styles from './style.module.scss';
import { Graph } from 'src/generated/graph';
import { useApolloClient, gql } from '@apollo/client';
import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import ProductListArrangement from './components/ProductListArrangement';
import { ProductRowItem } from './components/ProductRowItem';
import { ProductColumnItem } from './components/ProductColumnItem';
import { useQueryString } from 'src/libs/QueryString';
import { useHistory } from 'react-router';
import ProductSort from './components/productSort';
import { Loading } from './components/Loading';
import { LoadingRow } from './components/LoadingRow';
import SuggestedCategoryScreen from './components/suggestedCategory';
import Layout from 'src/components/layout/Layout';

const QUERY_SEARCH = gql`
  query productSearch($filter: ProductSearchFilter, $limit: Int, $offset: Int, $sort: ProductSearchSort) {
    productSearch(filter: $filter, offset: $offset, limit: $limit, sort: $sort) {
      items {
        id
        title
        width
        height
        code
        keyword
        price
        is_ads
        price_before_discount
        discount_percentage
        navigate
        l192_choice
        delivery_time
        show_promotion
        promotion_image
        stock
        stock_status
        stock_status_label
        rating_count
        rating
        product_label
        is_brand_product
        is_square_picture
        picture_responsive
        specialProduct {
          id
          text
          image
          navigate
        }
      }
      sponsoredItems {
        id
        title
        picture
        price
        price_before_discount
        navigate
        is_square_picture
        picture_responsive
      }
      suggestedCategory {
        id
        icon
        currentLanguageName
      }
      filter {
        brandList {
          id
          name
        }
        categoryList {
          id
          rootCategory
          secondRoot
          subCategory
          name {
            en
            km
          }
          currentLanguageName
          parentId
        }
      }
    }
  }
`;
function ProductContent({
  show,
  loading,
  displayRow,
  productList,
}: {
  show: boolean;
  loading: boolean;
  displayRow: boolean;
  productList: Graph.ProductRawJson[];
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const productArrangement = useMemo(() => new ProductListArrangement(), [productList]);
  productArrangement.update(productList);
  return (
    <>
      {loading ? (
        !displayRow ? (
          <Loading topPlaceHolder={false} />
        ) : (
          <LoadingRow />
        )
      ) : (
        <>
          {productList.length > 0 && !loading ? (
            displayRow ? (
              <div
                className={styles.containerRow}
                style={
                  show
                    ? {
                        height: '100vh',
                        overflow: 'hidden',
                      }
                    : {}
                }
              >
                <div className={styles.list_container_row}>
                  {productList.map((x, idx) => (
                    <ProductRowItem key={idx} data={x} />
                  ))}
                </div>
              </div>
            ) : (
              <div
                className={styles.container}
                style={
                  show
                    ? {
                        height: '100vh',
                        overflow: 'hidden',
                      }
                    : {}
                }
              >
                <div className={styles.list_container}>
                  {productArrangement.getLeftSide().map((x, idx) => (
                    <ProductColumnItem key={idx} data={x} />
                  ))}
                </div>
                <div className={styles.list_container}>
                  {productArrangement.getRightSide().map((x, idx) => (
                    <ProductColumnItem key={idx} data={x} />
                  ))}
                </div>
              </div>
            )
          ) : (
            <div className={styles.data_empty}>
              <p>
                Your search does not match any products. Try different words if you do not see what you are looking for.
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default function SearchPreview() {
  const [show] = useState(false);
  const [productList, setProductList] = useState<Graph.ProductRawJson[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [displayRow, setDisplayRow] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [suggestedCategory, setSuggestedCategory] = useState<Graph.Category[]>([]);
  const client = useApolloClient();
  const qs = useQueryString<{
    keyword: string;
    sort: string;
    category_id: null | number;
    brand_id: null | number;
    store_id: null | number;
    inStockOnly: string;
    min_price: null | number;
    max_price: null | number;
  }>({
    keyword: '',
    sort: '',
    category_id: null,
    brand_id: null,
    store_id: null,
    inStockOnly: 'false',
    min_price: null,
    max_price: null,
  });
  const [ischeck, setIscheck] = useState<boolean>(qs.inStockOnly === 'false' ? false : true);
  const [popular, setPopular] = useState(qs.sort || 'POPULAR');
  const history = useHistory();
  const [search, setSearch] = useState(qs.keyword || '');
  const query = `?keyword=${qs.keyword}&sort=${(qs.sort = popular)}&category_id=${qs.category_id}&brand_id=${
    qs.brand_id
  }&store_id=${qs.store_id}&inStockOnly=${(qs.inStockOnly = ischeck === true ? 'true' : 'false')}&min_price=${
    qs.min_price
  }&max_price=${qs.max_price}`;

  const variable = {
    filter: {
      keyword: qs.keyword,
      categoryId: Number(qs.category_id),
      brandId: Number(qs.brand_id),
      storeId: Number(qs.store_id),
      inStockOnly: qs.inStockOnly === 'false' ? false : true,
      minPrice: Number(qs.min_price),
      maxPrice: Number(qs.max_price),
    },
  };

  const onToggle = () => [setToggle(!toggle)];
  const onSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      return history.replace(
        `?keyword=${search}&sort=${(qs.sort = popular)}&category_id=${qs.category_id}&brand_id=${
          qs.brand_id
        }&store_id=${qs.store_id}&inStockOnly=${(qs.inStockOnly = ischeck === true ? 'true' : 'false')}&min_price=${
          qs.min_price
        }&max_price=${qs.max_price}`,
      );
    }
  };
  const onListRow = () => {
    setDisplayRow(!displayRow);
  };
  const toggleCheck = () => {
    setIscheck(!ischeck);
  };

  useEffect(() => {
    client
      .query<Graph.Query>({
        query: QUERY_SEARCH,
        variables: {
          ...variable,
          offset: 0,
          limit: 30,
          sort: qs.sort,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setProductList(data?.productSearch?.items || []);
        setSuggestedCategory(data?.productSearch?.suggestedCategory || []);
      })
      .catch(() => setLoading(false));
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, query, setProductList]);

  useEffect(() => {
    history.replace(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (Number(qs.category_id) > 1000000) {
      window.location.replace(`/customPage/${Number(Number(qs.category_id) - 1000000)}`);
    }
  }, [qs.category_id]);
  return (
    <Layout full>
      <div className={styles.wrap}>
        {' '}
        <div className={styles.search_wrap}>
          <span>
            <input
              type="search"
              placeholder="Search product"
              value={search}
              onChange={e => setSearch(e.currentTarget.value)}
              onKeyPress={e => onSearch(e)}
            />
            {loading ? <i className="fa fas fa-solid  fa-spinner fa-spin"></i> : <i className="fa fas fa-search"></i>}
          </span>
        </div>
        <div className={styles.preview_wrap}>
          <div className={styles.preview_wrap_inner}>
            <div className={styles.preview_wrap_content}>
              <div style={{ position: 'relative', backgroundColor: '#fff' }}>
                {suggestedCategory?.length > 0 && <SuggestedCategoryScreen category={suggestedCategory} qs={qs} />}

                <ul className={styles.filter_Wrap}>
                  <li>
                    <ProductSort
                      onToggle={() => onToggle()}
                      toggle={toggle}
                      setPopular={setPopular}
                      popular={popular}
                      qs={qs}
                    />
                  </li>
                  <li style={{ flexGrow: 1 }}>
                    <label className={styles.checkbox}>
                      <p>In stock</p>
                      <div className={styles.inputCheckWrap}>
                        <input type="checkbox" checked={ischeck} onChange={toggleCheck} />
                        <span className={styles.wrapper}>
                          <span className={styles.knob}></span>
                        </span>
                      </div>
                    </label>
                  </li>
                  <li className={styles.list}>
                    <div className={styles.btn_list} onClick={onListRow}>
                      <img src={displayRow ? '/icons/list2.svg' : '/icons/list.svg'} alt="" style={{ width: 22 }} />
                    </div>
                    {/* <div
              style={{ display: "flex", margin: "0 0 0 10px" }}
              onClick={() => onClose()}
            >
              <img
                src="/icons/kitchen-funnel.svg"
                alt=""
                style={{ width: 15 }}
              />
              <p>Filter</p>
            </div> */}
                  </li>
                </ul>
              </div>

              <ProductContent show={show} loading={loading} productList={productList} displayRow={displayRow} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
