import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Graph } from 'src/generated/graph';

const QUERY_SUPPLIER_LIST = gql`
  query supplierList(
    $name: String
    $offset: Int!
    $limit: Int!
    $phoneNumber: String
    $active: Boolean
    $roles: [SupplierRole]
    $sellerType: [SellerType]
    $myFollowwing: Boolean
    $isSeller: Boolean
    $origin: Int
    $isVerified: Boolean
    $subscriptionPlan: [SellerSuscriptionPlan]
  ) {
    supplierList(
      name: $name
      limit: $limit
      offset: $offset
      phoneNumber: $phoneNumber
      active: $active
      roles: $roles
      sellerType: $sellerType
      origin: $origin
      myFollowwing: $myFollowwing
      isSeller: $isSeller
      isVerified: $isVerified
      subscriptionPlan: $subscriptionPlan
    ) {
      id
      name
      logo
      role
      token
      origin
      emails
      address
      contact
      isSeller
      followed
      ownerName
      sellerType
      freePackage
      hasFollowed
      followedBy {
        id
        name
      }
      subscription {
        id
        planName
        expiredDate
        fee
        interval
      }
      verifiedDate
      verifiedBy {
        id
        name
      }
    }
  }
`;

export default function useQuerySuppilerList(
  options: QueryHookOptions<
    Graph.Query,
    {
      name?: string;
      offset: number;
      limit: number;
      phoneNumber?: string;
      active?: boolean;
      roles?: Graph.SupplierRole[];
      sellerType?: Graph.SellerType[];
      myFollowwing?: boolean;
      isSeller?: boolean;
      origin?: number;
      isVerified?: boolean;
      subscriptionPlan?: Graph.SellerSuscriptionPlan[];
    }
  >,
) {
  return useQuery(QUERY_SUPPLIER_LIST, options);
}
