import styles from './tab.module.scss';

export function TabGutter(props: React.PropsWithChildren<unknown>) {
  return <ul className={styles.tab}>{props.children}</ul>;
}

TabGutter.Item = function List(props: React.PropsWithChildren<{ active?: boolean; onClick?: () => void }>) {
  return (
    <li className={props.active ? styles.active : ''} onClick={props.onClick}>
      {props.children}
    </li>
  );
};

TabGutter.Actions = function List(props: React.PropsWithChildren<unknown>) {
  return (
    <li className={styles.actions}>
      <div>{props.children}</div>
    </li>
  );
};
