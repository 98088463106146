import React from 'react';

export type InventoryStockInProductType = {
  code: string;
  color: string;
  size: string;
  skuID: string;
  itemID: string;
  qty: number;
  cost: string;
  costDescription: string;
  shipmentId?: number;
};

type Props = {
  item: InventoryStockInProductType;
  onUpdate: () => void;
  onRemove: (item: InventoryStockInProductType) => void;
  requiredCost: boolean;
};

interface State {
  cost: string;
  prev_cost: string | undefined;
}

export class InventoryStockInProduct extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cost: props.item.cost,
      prev_cost: props.item.cost === '' ? undefined : props.item.cost,
    };
  }

  render() {
    return (
      <tr>
        <td>{this.props.item.code}</td>
        <td>{this.props.item.color}</td>
        <td>{this.props.item.size}</td>
        <td>{this.props.item.shipmentId}</td>
        <td>{this.renderQty()}</td>
        {this.props.requiredCost && (
          <td>
            <input
              className="form-control"
              type="number"
              value={this.state.cost}
              onChange={this.onCostChanged}
              onKeyPress={this.onCostKeyPress}
            />
          </td>
        )}
        {this.props.requiredCost && <td>{this.props.item.costDescription}</td>}
        <td>{this.isIncorrect() ? '🛑' : '✅'}</td>
        <td>
          <button className="btn btn-primary" onClick={this.onRemove}>
            Remove
          </button>
        </td>
      </tr>
    );
  }

  renderQty = () => {
    return (
      <input type="number" className="form-control" value={this.props.item.qty} onChange={this.onEditingQtyChanged} />
    );
  };

  onRemove = () => {
    this.props.onRemove(this.props.item);
  };

  onEditingQtyChanged = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.item.qty = parseInt(e.currentTarget.value);
    this.props.onUpdate();
  };

  private isIncorrect = () => {
    // If user does not require cost, it is always correct
    if (this.props.requiredCost === false) return false;

    // Check if user has inputted correct cost
    return this.state.cost !== this.state.prev_cost || this.state.cost === '';
  };

  private onCostChanged = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      cost: e.currentTarget.value,
    });
  };

  private onCostKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      this.setState({ prev_cost: this.state.cost });
      this.props.item.cost = this.state.cost;
      this.props.onUpdate();
    }
  };
}
