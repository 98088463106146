/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigation } from '@shopify/polaris';
import { useLocation } from 'react-router';
import { useUser } from 'src/contexts/AuthProvider';
import { navigationForAll } from './NavigationSetting/navigationForAll';
import { navigationForOffline } from './NavigationSetting/navigationForOffline';

export function MenuItemIcon({ icon }: { icon: string }) {
  return (
    <div style={{ width: 20, height: 20, lineHeight: '20px', marginRight: '0.75rem' }}>
      <i className={`${icon} ml-1`} />
    </div>
  );
}

export default function PolarisNavigation() {
  const user = useUser();
  const location = useLocation();

  let navigationItems = navigationForAll(user.superAdmin || false, user.groupID || 0);
  if (user.groupID === 6 || user.groupID === 8) {
    navigationItems = navigationForOffline();
  }

  return (
    <Navigation location={location.pathname}>
      {navigationItems.map((menu, index) => (
        <Navigation.Section
          separator={index > 0}
          key={menu.title}
          title={menu.title}
          items={menu.items.map(x => {
            return {
              label: (
                <div style={{ display: 'flex' }}>
                  {x.icon && <MenuItemIcon icon={x.icon} />}
                  {x.label}
                </div>
              ) as any,
              url: x.url,
              selected: x.url === location.pathname,
              exactMatch: true,
              badge: (x as any).badge ? (x as any).badge : undefined,
              subNavigationItems: (x as any).subs ? (x as any).subs : undefined,
            };
          })}
        />
      ))}
    </Navigation>
  );
}
