import moment from 'moment';

export default class Formatter {
  static currency(value: string | number | undefined | null) {
    return (
      '$' +
      Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  static khrCurrency = (khr: number) => {
    const checkLastTwoChar = khr?.toString().slice(-2);
    const getAmount = khr?.toString().slice(0, -2);

    if (Number(checkLastTwoChar) > 0) {
      return (Number(getAmount) + 1 + '00').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return khr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static phoneNumber = (number: any) => {
    if (!!number) {
      const ph = number.toString().substring(0, 1);
      const h = number.toString().substring(0, 3);
      return ph == '0' || ph == '+' || h == '855' ? number : 0 + number;
    }
    return number;
  };

  static number(value: string | number | undefined | null) {
    return Number(value).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  static timestampTimeAgo(ts: number) {
    return moment(ts * 1000).fromNow();
  }

  static beautifyUglyName(name?: string | null) {
    if (!name) return '';

    // Replace ugly dash with space
    let beautyName = name.replaceAll('_', ' ');

    // Upper case first
    beautyName = beautyName
      .toLowerCase()
      .split(' ')
      .map(word => {
        if (word.length === 0) return '';
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

    return beautyName;
  }

  static abbrNum(value: number, decPlaces = 3) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let x: any = value;
    decPlaces = Math.pow(10, decPlaces);

    const abbrev = ['K', 'M', 'B', 'T'];

    for (let i = abbrev.length - 1; i >= 0; i--) {
      const size = Math.pow(10, (i + 1) * 3);
      if (size <= x) {
        x = Math.round((x * decPlaces) / size) / decPlaces;
        if (x == 1000 && i < abbrev.length - 1) {
          x = 1;
          i++;
        }
        x += abbrev[i];
        break;
      }
    }

    return x;
  }
}
