import { PackageScanInScreen } from '../screens/Delivery/PackageScanInScreen';
import { PackageScanOutScreen } from '../screens/Delivery/PackageScanOutScreen';
import { GeneratePackageBarcodeScreen } from '../screens/Delivery/GeneratePackageBarcodeScreen';
import { PackageProgressScreen } from '../screens/Delivery/PackageProgressScreen';
import { PackageScanBarcodeScreen } from '../screens/Delivery/PackageScanBarcodeScreen';
import { withPackageNavigation } from '../screens/Delivery/PackageQuickNavigation';
import { PackageSuccessScreen } from 'src/screens/Delivery/PackageSuccessScreen';

export const DeliveryRoutes = [
  { path: '/package/scanin', exact: true, component: withPackageNavigation(PackageScanInScreen) },
  { path: '/package/scanout', exact: true, component: withPackageNavigation(PackageScanOutScreen) },
  { path: '/package/generate/barcode', exact: true, component: withPackageNavigation(GeneratePackageBarcodeScreen) },
  { path: '/package/progress', exact: true, component: withPackageNavigation(PackageProgressScreen) },
  { path: '/package/success', exact: true, component: withPackageNavigation(PackageSuccessScreen) },
  { path: '/package/scan', exact: true, component: withPackageNavigation(PackageScanBarcodeScreen) },
];
