import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Frame, Layout, Page, Select, Toast } from '@shopify/polaris';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { BlockLoading } from 'src/components/loading/BlockLoading';
import PolarisContent from 'src/components/PolarisContent';

import { useUser } from 'src/contexts/AuthProvider';
import { Graph } from 'src/generated/graph';
import ModalToUpdate from './page/ModalMenuFrontPage';
import styles from './style.module.scss';

const QUERY = gql`
  query frontScreenMenuList {
    frontScreenMenuList
  }
`;
const MUATATION_MENU_LIST = gql`
  mutation UpdateFrontScreenMenuList($menuList: JSON) {
    updateFrontScreenMenuList(menuList: $menuList)
  }
`;

interface MenuProps {
  icon: string;
  caption: string;
  navigate: string;
}

interface Props {
  idx: number;
  open: boolean;
  lang: string;
  menuList: MenuProps;
  onUpdateItem: (x: MenuProps) => void;
  handleChange: (x: number) => void;
}

function UpdateMenu({ idx, menuList, lang, handleChange, onUpdateItem }: Props) {
  const menuItem = Object(JSON.parse(menuList.navigate));
  return (
    <>
      <li className={menuItem?.source === 'discount' ? styles.gradient_active : ''}>
        <div
          onClick={async () => {
            await onUpdateItem(menuList);
            await handleChange(idx);
          }}
        >
          <span className={styles.img_wrap}>{menuList?.icon ? <img src={menuList?.icon} alt="" /> : ''}</span>
          <p>{menuItem?.caption[lang]}</p>
          {/* <p>{menuList?.navigate}</p> */}
        </div>
      </li>
    </>
  );
}

export function AppMenuListScreen(): ReactElement {
  const user = useUser();
  const Ref = useRef<HTMLIFrameElement>(null);
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [isProductionDomain, setIsProductionDomanin] = useState(false);
  const [menuItem, setMenuItem] = useState<MenuProps>();
  const [getData, { loading }] = useLazyQuery<Graph.Query>(QUERY);
  const [menuList, setMenuList] = useState<MenuProps[]>(() => {
    const KEY = 'MENULIST';
    try {
      const item = window.localStorage.getItem(KEY);
      return !!item ? JSON.parse(item) || [] : [];
    } catch (error) {
      return [];
    }
  });

  const [lang, setLang] = useState('en');
  const options = [
    { label: 'EngLish', value: 'en' },
    { label: 'Khmer', value: 'km' },
    { label: 'Chinese', value: 'zh' },
  ];

  const onUpdateItem = (item: MenuProps) => {
    setMenuItem(item);
  };
  const [mutationMenuList, { loading: updateMenuLoading }] = useMutation(MUATATION_MENU_LIST, {
    onError: r => alert(r?.message),
  });
  const handleChange = useCallback(
    (numIndex?: number) => {
      setIndex(numIndex || 0);
      setOpen(!open);
    },
    [open],
  );

  const onUpdateMenuLists = (idx: number, item: MenuProps) => {
    menuList[idx] = item;
  };

  const toggleActive = useCallback(() => setActive(active => !active), []);
  const onMarkChange = () => {
    localStorage.setItem('MENULIST', JSON.stringify(menuList));
  };
  const handleSelectChange = useCallback((value: string) => setLang(value), []);

  const onUpdateMenuList = () => {
    if (menuList?.length > 0) {
      mutationMenuList({
        variables: { menuList },
        onCompleted: () => setActive(active => !active),
      });
      Ref?.current?.contentWindow?.location?.replace(
        `https://${isProductionDomain ? 'view.l192.com/home' : 'view-stage.l192.com/home'}`,
      );
    }
  };

  useEffect(() => {
    const hostName = window.location.hostname;
    localStorage.setItem('MENULIST', JSON.stringify(menuList));

    if ((JSON.parse(localStorage.getItem('MENULIST') || '') || [])?.length > 0) {
      setMenuList(JSON.parse(localStorage.getItem('MENULIST') || '') || []);
    } else {
      getData({ onCompleted: r => setMenuList(r?.frontScreenMenuList) });
    }

    if (hostName === 'spa.l192.com') {
      setIsProductionDomanin(true);
    } else {
      setIsProductionDomanin(false);
    }
  }, []);

  const toastMarkup = active ? <Toast content="Menu Lists Updated" onDismiss={toggleActive} /> : null;
  if (loading) {
    return <BlockLoading />;
  }
  return (
    <PolarisContent>
      <Page>
        <Frame>
          <Layout>
            <Layout.Section>
              <div className="markup-container" style={{ maxWidth: '800px', minHeight: '100%' }}>
                <div className="markup-header">
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 text-right">
                      <Button loading={updateMenuLoading} disabled={updateMenuLoading} onClick={onUpdateMenuList}>
                        Deploy
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="markup-editor">
                  <div className={styles.menu_list_wrap} style={{ position: 'relative', overflowY: 'scroll' }}>
                    <div style={{ width: '300px', padding: '1rem' }}>
                      <Select label="Select language" options={options} onChange={handleSelectChange} value={lang} />
                    </div>
                    <ul>
                      {menuList?.map((x, i) => (
                        <UpdateMenu
                          key={i}
                          idx={i}
                          open={open}
                          menuList={x}
                          lang={lang}
                          handleChange={handleChange}
                          onUpdateItem={onUpdateItem}
                        />
                      ))}
                    </ul>
                    <ModalToUpdate
                      open={open}
                      idx={index}
                      superAdmin={!!user?.superAdmin}
                      menuItem={menuItem}
                      handleChange={handleChange}
                      onUpdateMenuLists={onUpdateMenuLists}
                    />
                    <div className="p-3 form-buttons-w text-right">
                      <button className="btn btn-primary btn-lg" onClick={onMarkChange}>
                        Save
                      </button>
                      <button
                        className="btn btn-warning btn-lg"
                        onClick={() => {
                          localStorage.removeItem('MENULIST');
                          window.location.reload();
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
                <div className="markup-bottom"></div>
              </div>
              <div style={{ zIndex: 100 }}>
                <Draggable axis="both" handle=".handle" defaultPosition={{ x: 0, y: 0 }} grid={[25, 25]} scale={1}>
                  <div className={styles.prview}>
                    <div className="handle">
                      <div className={styles.ic_badge}>
                        <i className="fas fa-arrows-alt"></i>
                      </div>
                    </div>
                    <div className={styles.prview_inner}>
                      <iframe
                        ref={Ref}
                        seamless
                        scrolling="no"
                        src={`https://${isProductionDomain ? 'view.l192.com/home' : 'view-stage.l192.com/home'}`}
                        title={''}
                        height={'102%'}
                        width={'102%'}
                        style={{ margin: '-1% 0 0 -1%' }}
                      ></iframe>
                    </div>
                  </div>
                </Draggable>
              </div>
              {toastMarkup}
            </Layout.Section>
          </Layout>
        </Frame>
      </Page>
    </PolarisContent>
  );
}
