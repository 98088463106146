import { gql, useQuery } from '@apollo/client';
import { IndexTable } from '@shopify/polaris';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import XForm from 'src/components/layout/xform/XForm';
import { Graph } from 'src/generated/graph';

const QUERY_NOTIFICATION_SCHEDULE_LIST = gql`
  query NotificationScheduleList(
    $offset: Int
    $limit: Int
    $notificationScheduleListId: Int
    $type: KindOfNotification
    $mode: ScheduledNotificationType
  ) {
    notificationScheduleList(
      offset: $offset
      limit: $limit
      id: $notificationScheduleListId
      type: $type
      mode: $mode
    ) {
      id
      segmentId
      segmentName
      phoneNumber
      data
      createdAt
      updatedAt
      createdBy {
        name
      }
      updatedBy {
        name
      }
      sent
      deletedBy {
        name
      }
      deletedAt
      scheduleAt
      repeatedValue
      repeatedType
    }
  }
`;

export function NotificationAnnouncementPartial() {
  const [listType, setListType] = useState('NORMAL');
  const { loading, data } = useQuery<Graph.Query>(QUERY_NOTIFICATION_SCHEDULE_LIST, {
    variables: { offset: 0, limit: 100, type: listType, mode: 'ANNOUNCEMENT' },
  });

  return (
    <div>
      <div className="controls-above-table" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Link to="/notification/schedule/create" className="btn btn-primary">
            Create Notification
          </Link>
          <a
            href="https://adm.l192.com/training/29"
            target={'_blank'}
            style={{
              fontSize: '2rem',
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: '10px',
              marginBottom: '4px',
            }}
            rel="noreferrer"
          >
            <strong style={{ fontSize: '2rem' }}>?</strong>
          </a>
        </div>

        <div>
          <XForm.Select
            label=""
            onChange={e => setListType(e.currentTarget.value as 'ALL' | 'NORMAL' | 'REPEATED')}
            value={listType}
            items={[
              { text: 'All notifications', value: 'ALL' },
              { text: 'Normal only', value: 'NORMAL' },
              { text: 'Repeated only', value: 'REPEATED' },
            ]}
          />
        </div>
      </div>
      <IndexTable
        headings={[
          { title: 'Content' },
          { title: 'Receivers' },
          { title: 'Repeat Every' },
          { title: 'Landing Page' },
          { title: 'Notified' },
          { title: 'Created' },
          { title: 'Scheduled at' },
          { title: 'Updated' },
        ]}
        itemCount={data?.notificationScheduleList?.length ?? 0}
        selectable={false}
        loading={loading}
      >
        {data &&
          data.notificationScheduleList?.map((item, index) => {
            return (
              <IndexTable.Row key={item.id} id={item.id + ''} position={index}>
                <IndexTable.Cell>
                  <div>
                    <Link key={item.id} to={`/notification/schedule/${item.id}`}>
                      <strong>{item.data.heading ? item.data.heading : item.id}</strong>
                    </Link>
                  </div>
                  <div>{item.data.title}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {item.segmentName
                    ? item.segmentName
                    : item.phoneNumber?.splice(0, Math.min(3, item.phoneNumber.length)).join(', ')}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  {item.repeatedValue ? item.repeatedValue + ' ' + item.repeatedType : ''}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div>{item.data.data.screen ? 'Screen: ' + item.data.data.screen : ''}</div>
                  <div>{item.data.data.payload ? 'payload: ' + JSON.stringify(item.data.data.payload) : ''}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>{item.sent}</IndexTable.Cell>
                <IndexTable.Cell>
                  <div> {item.createdBy ? 'by ' + item.createdBy.name : ''}</div>
                  <div>{item.createdAt ? 'at ' + item.createdAt : ''}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div>{item.scheduleAt ? item.scheduleAt : ''}</div>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div> {item.updatedBy ? 'by ' + item.updatedBy.name : ''}</div>
                  <div>{item.updatedAt ? 'at ' + item.updatedAt : ''}</div>
                </IndexTable.Cell>
              </IndexTable.Row>
            );
          })}
      </IndexTable>
    </div>
  );
}
