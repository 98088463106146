import React from 'react';
import styles from './table.module.scss';

interface TableProps {
  seperator?: boolean;
  border?: boolean;
  stripe?: boolean;
  grid?: boolean;
  stickyHeader?: boolean;
}

interface TableCellProps {
  template?: 'none' | 'info';
  grow?: boolean;
}

export function Table(props: React.PropsWithChildren<TableProps>) {
  const classNameList: string[] = [styles.table];

  if (props.seperator) {
    classNameList.push(styles.seperator);
  }

  if (props.border) {
    classNameList.push(styles.border);
  }

  if (props.stripe) {
    classNameList.push(styles.stripe);
  }

  if (props.grid) {
    classNameList.push(styles.grid);
  }

  if (props.stickyHeader) {
    classNameList.push(styles.sticky);
  }

  return <table className={classNameList.join(' ')}>{props.children}</table>;
}

Table.Row = function TableCell(
  props: React.PropsWithChildren<
    React.HTMLAttributes<HTMLTableRowElement> & {
      deleted?: boolean;
    }
  >,
) {
  const { deleted, ...rowProps } = props;

  return (
    <tr {...rowProps} style={{ background: props.deleted ? '#ecf0f1' : undefined, ...rowProps?.style }}>
      {props.children}
    </tr>
  );
};

Table.Cell = function TableCell(props: React.PropsWithChildren<TableCellProps>) {
  const classNameList: string[] = [];

  if (props.template) {
    if (props.template === 'info') {
      classNameList.push(styles.template_info);
    }
  }

  if (props.grow) {
    classNameList.push(styles.grow);
  }

  return <td className={classNameList.join(' ')}>{props.children}</td>;
};
