/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from '@apollo/client';
import { Button, Icon, Modal, Thumbnail } from '@shopify/polaris';
import { useState } from 'react';
import { Graph } from 'src/generated/graph';
import { ArrowRightMinor, ArrowLeftMinor } from '@shopify/polaris-icons';

const QUERY = gql`
  query displayProductMediaList($displayProductMediaListId: Int!) {
    displayProductMediaList(id: $displayProductMediaListId) {
      id
      thumbnail
      src
      type
      width
      height
      originalSource
    }
  }
`;

interface Props {
  id: number;
  src: string;
}

export function DisplayProductMedia(props: Props) {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const { data, loading } = useQuery<Graph.Query>(QUERY, {
    skip: open === false,
    variables: {
      displayProductMediaListId: Number(props.id),
    },
  });

  const activator = (
    <div onClick={() => setOpen(true)}>
      <Thumbnail source={props.src} alt="" size="large" />
    </div>
  );

  return (
    <Modal title="Preview" loading={loading} activator={activator} open={open} onClose={() => setOpen(false)}>
      <Modal.Section>
        <div style={{ width: '100%', position: 'relative', overflow: 'hidden', borderRadius: 6 }}>
          {data &&
            data.displayProductMediaList?.map((x, i) => {
              if (x.type === 'PICTURE') {
                return (
                  <img
                    src={x.src || ''}
                    style={{
                      width: 'fit-content',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      visibility: index === i ? 'visible' : 'hidden',
                      display: index === i ? 'block' : 'none',
                    }}
                    alt=""
                    key={i}
                  />
                );
              }
              if (x.type === 'VIDEO') {
                return (
                  <video
                    src={x.src || ''}
                    style={{
                      width: 'fit-content',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      visibility: index === i ? 'visible' : 'hidden',
                      display: index === i ? 'block' : 'none',
                    }}
                    key={i}
                  />
                );
              }
              return (
                <iframe
                  src={x.src || ''}
                  key={i}
                  frameBorder="0"
                  style={{
                    width: 'fit-content',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    visibility: index === i ? 'visible' : 'hidden',
                    display: index === i ? 'block' : 'none',
                  }}
                ></iframe>
              );
            })}
        </div>
      </Modal.Section>
      <Modal.Section>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            icon={<Icon source={ArrowLeftMinor as any} />}
            onClick={() =>
              index === 0 ? setIndex((data?.displayProductMediaList?.length || 0) - 1) : setIndex(index - 1)
            }
          ></Button>
          <small>
            {index + 1}/{data?.displayProductMediaList?.length || 0}
          </small>
          <Button
            icon={<Icon source={ArrowRightMinor as any} />}
            onClick={() =>
              index === (data?.displayProductMediaList?.length || 0) - 1 ? setIndex(0) : setIndex(index + 1)
            }
          ></Button>
        </div>
      </Modal.Section>
    </Modal>
  );
}
