/* eslint-disable */
import moment from 'moment';
import React from 'react';
import { Content } from '../../components/light/Content';
import { gql } from '@apollo/client';
import { OperationVariables, Query, QueryResult } from '@apollo/react-components';
import { Graph } from '../../generated/graph';
import { ResponsiveLine } from '@nivo/line';

interface StockReportKPIRank {
  admin_id: number;
  display: string;
  inventory_score: string;
  sales_score: string;
  supplier_sales_score: string;
  score: string;
}

interface StockReportKPIType {
  ranking: StockReportKPIRank[];
}

const QUERY_REPORT = gql`
  query($startDate: String!, $endDate: String!) {
    reportStockKPI(startDate: $startDate, endDate: $endDate)
  }
`;

export class StockKPIReportScreen extends React.Component {
  state: {
    startDate: string;
    endDate: string;
    ticks: number[];
    allTicks: number[];
  };

  constructor(props: any) {
    super(props);

    this.state = {
      startDate: moment(new Date())
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      ticks: [],
      allTicks: [],
    };
  }

  render() {
    return (
      <Content>
        <div className="element-wrapper compact pt-4">
          <h6 className="element-header">Stock Team KPI</h6>
        </div>

        <div className="mt-3">
          <Query
            query={QUERY_REPORT}
            variables={
              ({ startDate: this.state.startDate, endDate: this.state.endDate } as unknown) as OperationVariables
            }
            onCompleted={(data: Graph.Query) => {
              this.setState({
                ticks: data.reportStockKPI.ranking
                  .filter((x: any) => x.group !== 1 && x.active === 'T')
                  .map((x: any) => x.admin_id),
                allTicks: data.reportStockKPI.ranking.map((x: any) => x.admin_id),
              });
            }}
          >
            {this.renderQuery}
          </Query>
        </div>
      </Content>
    );
  }

  renderLoading = () => {
    return (
      <table className="lf-table-shadow">
        <tbody>
          <tr>
            <td>
              <div className="bg-loading ph-text-normal"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="bg-loading ph-text-normal"></div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="bg-loading ph-text-normal"></div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderTable = (data: Graph.Query) => {
    const kpi: StockReportKPIType = data.reportStockKPI;

    return (
      <table className="lf-table-shadow">
        <thead>
          <tr>
            <th style={{ width: 25 }}>#</th>
            <th style={{ width: 25 }}>
              <input
                type="checkbox"
                checked={this.state.allTicks.length === this.state.ticks.length}
                onChange={e => {
                  if (e.currentTarget.checked) {
                    this.setState({ ticks: this.state.allTicks });
                  } else {
                    this.setState({ ticks: [] });
                  }
                }}
              />
            </th>
            <th>Member</th>
            <th style={{ width: 135 }} className="text-right">
              Inventory Score
            </th>
            <th style={{ width: 135 }} className="text-right">
              Sales Score
            </th>
            <th style={{ width: 135 }} className="text-right">
              Sales Score (Supplier)
            </th>
            <th style={{ width: 135 }} className="text-right">
              Score
            </th>
          </tr>
        </thead>
        <tbody>{kpi.ranking.map(this.renderRow)}</tbody>
      </table>
    );
  };

  renderGraph = (data: Graph.Query) => {
    return (
      <ResponsiveLine
        margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
        data={data.reportStockKPI.raw.filter((x: any) => this.state.ticks.indexOf(x.admin_id) >= 0)}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          stacked: false,
          min: 'auto',
          max: 'auto',
        }}
        axisBottom={{
          format: '%b %d',
          tickValues: 'every 5 days',
        }}
        curve="monotoneX"
        colors={{ scheme: 'dark2' }}
        enableArea={true}
        enablePointLabel={false}
        pointSize={12}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        useMesh={true}
        enableSlices={'x'}
      />
    );
  };

  renderQuery = ({ data, loading }: QueryResult<Graph.Query>) => {
    if (loading) return this.renderLoading();
    if (!data) return <div>Loading fail</div>;

    return (
      <div style={{ marginTop: -50 }}>
        <div className="element-box" style={{ height: 500 }}>
          {this.renderGraph(data)}
        </div>
        <div>{this.renderTable(data)}</div>
      </div>
    );
  };

  renderRow = (data: StockReportKPIRank, idx: number) => {
    return (
      <tr key={data.display}>
        <td>{idx + 1}</td>
        <td>
          <input
            type="checkbox"
            checked={this.state.ticks.indexOf(data.admin_id) >= 0}
            onChange={e => {
              if (e.currentTarget.checked) {
                this.setState({ ticks: [...this.state.ticks, data.admin_id] });
              } else {
                this.setState({ ticks: this.state.ticks.filter((x: number) => x !== data.admin_id) });
              }
            }}
          />
        </td>
        <td>{data.display}</td>
        <td className="text-right text-danger">
          ({Number(data.inventory_score).toLocaleString(undefined, { minimumFractionDigits: 2 })})
        </td>
        <td className="text-right text-success">
          {Number(data.sales_score).toLocaleString(undefined, { minimumFractionDigits: 2 })}
        </td>
        <td className="text-right text-success">
          {Number(data.supplier_sales_score).toLocaleString(undefined, { minimumFractionDigits: 2 })}
        </td>
        <td className="text-right">
          <strong>{Number(data.score).toLocaleString(undefined, { minimumFractionDigits: 2 })}</strong>
        </td>
      </tr>
    );
  };
}
