import React, { ReactElement } from 'react';

export function HighlightText(props: { highlight: string; text: string }): ReactElement {
  const regex = new RegExp('(' + props.highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + ')', 'i');

  return (
    <span
      dangerouslySetInnerHTML={{
        __html:
          props.highlight !== ''
            ? props.text.replace(regex, `<mark style="padding: 0; background-color: #047bf8; color: white">$1</mark>`)
            : props.text,
      }}
    />
  );
}
