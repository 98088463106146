import { useMutation } from '@apollo/client';
import styles from '../partial/tasks.module.scss';
import { Link } from 'react-router-dom';
import { Graph } from '../../../generated/graph';
import { MUTATION_MARK_PACKAGE_DELIVERED } from '../query';
import XForm from 'src/components/layout/xform/XForm';
import Formatter from 'src/libs/Formatter';

export default function PackageCustomerPickupScreen({ item }: { item: Graph.Package }) {
  const [markPackageDelivered, { loading }] = useMutation(MUTATION_MARK_PACKAGE_DELIVERED, {
    refetchQueries: ['PackageList'],
  });
  const OnMarkAsDelivered = () => {
    const randomCode = ((1000 + 8000 * Math.random()) | 0) + '';
    const answer = prompt('Please type ' + randomCode);
    if (answer !== randomCode) {
      if (answer) alert('You type the wrong verification number');
      return;
    }
    markPackageDelivered({
      variables: {
        id: Number(item.id),
        lat: item?.lat?.toString(),
        lng: item?.lng?.toString(),
        cash: Number(item.cash),
      },
    })
      .then()
      .catch(() => console.error);
  };
  return (
    <tr
      key={item.id}
      style={{
        backgroundColor: item.isFinal ? (item.barcode ? '#8ce396' : '#ee8f8f') : '#fff',
        borderBottom: '1px solid #eeeeee',
      }}
    >
      <td className="text-center">
        <i
          className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
          style={{ fontSize: 18, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
        ></i>
      </td>
      <td>
        {!!item.barcode &&
        !item.requestPickupAt &&
        item?.claimPickupBy?.groupID !== 5 &&
        item?.type !== 'INTERNAL' &&
        item?.supplier ? (
          <h5 style={{ color: '#e22f2f', fontWeight: 'bold' }}>Seller Drop</h5>
        ) : (
          ''
        )}
        {item?.requestPickupAt ? (
          <div>
            <strong>Requested At</strong>
            <br /> {item?.requestPickupAt}
          </div>
        ) : (
          ''
        )}
        {item?.appointedAt ? (
          <div>
            <strong>Appointed At</strong>
            <br /> {item?.appointedAt}
          </div>
        ) : (
          ''
        )}
      </td>
      <td>{item.type === 'THIRD_PARTY' ? 'Third Party' : 'L192'}</td>
      <td>
        <div className={styles.badge_wrap}>
          <div>
            <Link className="h4" to={`/package/detail/${item.id}`} target="_blank">
              {item.id.toString().padStart(8, '0')}
            </Link>
          </div>
          {item.type === 'PICKUP' && (
            <div style={{ marginBottom: 2 }}>
              <span
                style={{
                  fontSize: 10,
                  background: '#1d82fb',
                  display: 'inline-block',
                  margin: '6px 0 0 5px',
                  color: '#fff',
                }}
              >
                {Formatter.beautifyUglyName('Customer Pickup')}
              </span>
            </div>
          )}
          {Number(item?.attempt) > 0 && (
            <div>
              <span
                style={{
                  fontSize: 10,
                  background: '#e22f2f',
                  display: 'inline-block',
                  margin: '6px 0 0 5px',
                  padding: '2px 5px',
                }}
              >
                Attempt ({item?.attempt})
              </span>
            </div>
          )}
          {item?.useInsurance === true && (
            <div>
              <span
                style={{
                  fontSize: 10,
                  background: '#F94892',
                  display: 'inline-block',
                  margin: '6px 0 0 4px',
                  padding: '3px 5px 1px 5px',
                  color: '#fff',
                }}
              >
                {Formatter.beautifyUglyName('Insurance')}
              </span>
            </div>
          )}
          {item?.isPrepayment === true && (
            <div>
              <span
                style={{
                  fontSize: 10,
                  background: '#009432',
                  display: 'inline-block',
                  margin: '6px 0 0 5px',
                  padding: '2px 5px',
                }}
              >
                Pre Payment
              </span>
            </div>
          )}
          {!!item?.isDropbox && (
            <div>
              <span
                style={{
                  fontSize: 10,
                  background: '#eb3b5a',
                  display: 'inline-block',
                  margin: '6px 0 0 4px',
                  padding: '3px 5px 0px 5px',
                  color: '#fff',
                }}
              >
                CTD
              </span>
            </div>
          )}
        </div>
        <div>
          Cash to collect: <strong>${item.cash?.toFixed(2)}</strong> | Barcode: <strong>{item.barcode}</strong>
          {item?.barcodeInitial && (
            <strong style={{ color: '#0052cc' }}>
              &nbsp; <i>({item?.barcodeInitial})</i>
            </strong>
          )}
        </div>
        {!!item?.hasReturningItem && (
          <div>
            <span
              style={{
                fontSize: 13,

                display: 'inline-block',

                fontWeight: 'bold',
                color: 'RED',
              }}
            >
              <i className="fas fa-box-alt"></i> CUSTOMER WILL RETURN A PRODUCT
            </span>
          </div>
        )}
        {!!item?.returningFromPackageId && (
          <div>
            <span
              style={{
                fontSize: 13,
                display: 'inline-block',

                fontWeight: 'bold',
                color: '#1C6DD0',
              }}
            >
              <i className="fas fa-hand-holding-box"></i> RETURN FROM PACKAGE #{item?.returningFromPackageId}
            </span>
          </div>
        )}
      </td>
      <td>
        <strong>{item.supplier?.name}</strong>
      </td>
      <td>
        <strong>{item.claimDeliveryBy?.name}</strong>
      </td>
      <td>
        {item.customer && (
          <div>
            <strong>{item.customer.name}</strong>
            <br />
            <span>{item.customer.phoneNumber}</span>
          </div>
        )}
      </td>
      <td>
        <strong style={{ fontSize: 14 }}>
          {item.currentWarehouse?.shortName}
          &nbsp;&nbsp;
          <i className="fas fa-arrow-right" />
          &nbsp;&nbsp; {item.nextWarehouse?.shortName ?? 'Customer'}
        </strong>
      </td>
      <td>
        <XForm.Button
          onClick={() => OnMarkAsDelivered()}
          loading={loading}
          disabled={loading}
          className="btn btn-primary btn-sm"
        >
          <span style={{ fontSize: '11px' }}>Mark as Delivered</span>
        </XForm.Button>
      </td>
    </tr>
  );
}
