/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Checkbox, ChoiceList, Modal } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { useLazyQueryProductList } from '../Filter/useQueryProductList';
import { useMutationMassUpdateProduct } from './useMutationMassUpdateProduct';

interface Props {
  itemIds: number[];
  open: boolean;
  onClose: (open: boolean) => void;
  filterValue: any;
  total: number;
}

export function MassReturnable(props: Props) {
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const [checked, setChecked] = useState(props.itemIds.length === props.total);
  const [enabled, setEnabled] = useState(['T']);
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(0);
  const [query, { loading }] = useLazyQueryProductList({
    variables: {},
    type: 'ID',
  });
  const [massUpdateProduct, { loading: loadingUpdate }] = useMutationMassUpdateProduct();

  const massUpdate = useCallback(
    async (data: any) => {
      const array = [...data];
      const count_loop = Math.ceil(array.length / 100);
      setTotal(count_loop);
      let success = 0;

      if (array.length > 0) {
        while (array.length) {
          const res = await massUpdateProduct({
            variables: {
              massUpdateProductId: array.splice(0, 100).map(x => Number(x.id)),
              data: {
                returnable: enabled[0] === 'T' ? true : false,
              },
            },
          });
          sleep(10);
          if (!loadingUpdate && res.data?.massUpdateProduct) {
            success = success + 1;
            setSuccess(success);
          }
        }
      }

      return success === count_loop;
    },
    [total, success, massUpdateProduct],
  );

  const handleClick = async () => {
    if (props.itemIds.length === props.total) {
      const res = await query({
        variables: {
          ...props.filterValue,
          page: {
            offset: 0,
            limit: props.total,
          },
        },
      });
      sleep(1);
      if (res.data) {
        const update = await massUpdate(res.data?.productListV2?.data || []);
        if (update) {
          setTotal(0);
          setSuccess(0);
          props.onClose(false);
        }
      }
    } else {
      if (checked) {
        const res = await query({
          variables: {
            ...props.filterValue,
            page: {
              offset: 0,
              limit: props.total,
            },
          },
        });
        sleep(1);
        if (res.data) {
          const update = await massUpdate(res.data?.productListV2?.data || []);
          if (update) {
            setTotal(0);
            setSuccess(0);
            props.onClose(false);
          }
        }
      } else {
        massUpdateProduct({
          variables: {
            massUpdateProductId: props.itemIds.map((x: any) => Number(x)),
            data: {
              returnable: enabled[0] === 'T' ? true : false,
            },
          },
        }).then(re => {
          if (re.data?.massUpdateProduct) {
            props.onClose(false);
          }
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        title={`Mass returnable ${checked ? props.total : props.itemIds.length} products selected`}
        open={props.open}
        onClose={() => {
          if (!loading || !loadingUpdate) {
            props.onClose(false);
          }
        }}
      >
        <Modal.Section>
          <Checkbox
            disabled={loading || loadingUpdate}
            label={`Select all filter products (${props.total})`}
            checked={props.itemIds.length === props.total ? true : checked}
            onChange={v => props.itemIds.length !== props.total && setChecked(v)}
          />
        </Modal.Section>
        <Modal.Section>
          <ChoiceList
            title
            titleHidden
            choices={[
              {
                label: 'Enable',
                value: 'T',
              },
              {
                label: 'Disable',
                value: 'F',
              },
            ]}
            selected={enabled}
            onChange={setEnabled}
            disabled={loading || loadingUpdate}
          />
        </Modal.Section>
        <Modal.Section>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {total > 0 && (
                <small>
                  <strong>
                    {success}/{total} batch
                  </strong>
                </small>
              )}
            </div>
            <div>
              <Button
                disabled={loading || loadingUpdate}
                loading={loading || loadingUpdate}
                size="slim"
                primary
                onClick={handleClick}
              >
                Update
              </Button>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
}
