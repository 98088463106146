/* eslint-disable */
import React, { ReactElement } from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { Link } from 'react-router-dom';

const QUERY = gql`
  query PurchaseOrderLogs {
    purchaseOrderLog(offset: 0, limit: 50) {
      id
      before
      after
      updatedBy {
        name
        id
      }
      updatedAt
    }
  }
`;

const mapName: { [key: string]: string } = {
  name: 'Name',
  order_detail: 'Cost Sheet',
  tags: 'Tags',
  stage: 'Stage',
  type: 'PO Type',
  order_file: 'Order File',
  order_qty: 'Order Unit',
  order_amount: 'Order Amount',
  order_currency: 'Order Currency',
  shipping_qty: 'Shipping Unit',
  shipping_amount: 'Product Net Value',
  invoice_amount: 'Invoice Value',
  shipping_currency: 'Invoice Value Currency',
  credit_note_type: 'Credit Note Type',
  credit_note_unit: 'Credit Note Unit',
  credit_note_amount: 'Credit Note Amount',
  shipping_file: 'Shipping File',
  supplier_file: 'Supplier File',
  invoice_file: 'Invoice File',
  invoice_number: 'Invoice Number',
  shipping_invoice_number: 'Shipping Invoice Number',
  created_at: 'Created At',
  shipped_at: 'Shipped At',
  arrived_at: 'Arrived At',
  processing_at: 'Processing At',
  completed_at: 'Completed At',
  payment_at: 'Payment At',
  void_at: 'Void At',
  payment: 'Payment Method',
  payment_proof: 'Payment Proof',
  exchange_rate: 'Exchange Rate',
  invoice_at: 'Invoice Date',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function compare(obj1: any, obj2: any): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ret: any = {};
  for (const i in obj2) {
    if (
      [
        'version',
        'completed_by',
        'processing_by',
        'arrived_by',
        'payment_by',
        'void_by',
        'created_by',
        'shipped_by',
        'total_amount',
        'total_qty',
      ].indexOf(i) >= 0
    )
      continue;
    if (!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
      ret[i] = obj2[i];
    }
  }

  return ret;
}

function renderField(field: string, value: any): ReactElement {
  if (['order_file', 'shipping_file', 'supplier_file', 'invoice_file', 'payment_proof'].indexOf(field) >= 0) {
    // Order attachment
    if (!value) return <span></span>;
    return (
      <a href={value} target="_blank" rel="noopener noreferrer">
        Attachment
      </a>
    );
  } else if (field === 'order_detail') {
    return <span className="btn btn-warning">...</span>;
  } else {
    return <span>{value}</span>;
  }
}

function renderChangeLogRow(row: Graph.PurchaseOrderLog): ReactElement {
  const diff = compare(row.before, row.after);

  if (Object.keys(diff).length === 0) return <div></div>;

  return (
    <div key={row.id?.toString()} className="mb-4">
      <table className="table table-striped table-bordered" style={{ maxWidth: 650 }}>
        <thead>
          <tr>
            <th colSpan={3} style={{ fontSize: 14, fontWeight: 'normal' }}>
              <div>
                <strong style={{ fontSize: 16 }}>
                  Purchase Order <Link to={`/purchase/edit/${row.id}`}>#{row.id}</Link>
                </strong>
              </div>
              <div>
                {row.updatedAt} by <strong style={{ color: '#e74c3c' }}>{row.updatedBy?.name}</strong>
              </div>
            </th>
          </tr>
          <tr>
            <th></th>
            <th style={{ width: 220 }}>After</th>
            <th style={{ width: 220 }}>Before</th>
          </tr>
        </thead>
        {Object.keys(diff).map(field => {
          return (
            <tr key={field}>
              <td>{mapName[field]}</td>
              <td>{renderField(field, row.after[field])}</td>
              <td>{renderField(field, row.before[field])}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export function PurchaseOrderLogScreen(): ReactElement {
  const { data } = useQuery<Graph.Query>(QUERY);

  if (!data?.purchaseOrderLog) {
    return <div>Loading...</div>;
  }

  return (
    <Content>
      <SectionBlock title="Purchase Order Logs">
        <div className="mb-4">
          <i>50 latest purchase order changelog</i>
        </div>
        {data.purchaseOrderLog.map(renderChangeLogRow)}
      </SectionBlock>
    </Content>
  );
}
