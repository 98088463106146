import React from 'react';
import { Content } from '../../../components/light/Content';
import { SectionBlock } from '../../../components/light/SectionBlock';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { SectionLoading } from '../../../components/loading/SectionLoading';
import { Link } from 'react-router-dom';

const QUERY = gql`
  query DiscountCampaignList {
    discountCampaignList {
      id
      name
      discount
      startedAt
      endedAt
      category
      hasAppliedDiscount
    }
  }
`;

const MUTATE_APPLY_DISCOUNT = gql`
  mutation ApplyDiscountCampaign($id: Int!) {
    applyDiscountCampaign(id: $id)
  }
`;

const MUTATE_REVERSE_DISCOUNT = gql`
  mutation reverseDiscountCampaign($id: Int!) {
    reverseDiscountCampaign(id: $id)
  }
`;

function DiscountCampaignApplyButton({ id }: { id: number }) {
  const [update, { loading }] = useMutation(MUTATE_APPLY_DISCOUNT, { refetchQueries: ['DiscountCampaignList'] });

  return (
    <button
      className="btn btn-primary"
      disabled={loading}
      onClick={() => {
        update({ variables: { id } });
      }}
    >
      Apply Discount
    </button>
  );
}

function DiscountCampaignReverseButton({ id }: { id: number }) {
  const [update, { loading }] = useMutation(MUTATE_REVERSE_DISCOUNT, { refetchQueries: ['DiscountCampaignList'] });

  return (
    <button
      className="btn btn-danger"
      disabled={loading}
      onClick={() => {
        update({ variables: { id } });
      }}
    >
      Reverse Discount
    </button>
  );
}

export function DiscountCampaignListScreen() {
  const { data } = useQuery<Graph.Query>(QUERY);

  if (!data?.discountCampaignList) return <SectionLoading title="Discount Campaign List" />;

  return (
    <Content>
      <SectionBlock title="Discount Campaign List">
        <div className="controls-above-table">
          <Link to={`/discount_campaign/create`} className="btn btn-primary">
            Create Discount Campaign
          </Link>
        </div>
        <div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th style={{ width: 50 }}></th>
                <th>Name</th>
                <th style={{ width: 100 }}>Discount</th>
                <th style={{ width: 150 }}></th>
                <th style={{ width: 150 }}></th>
              </tr>
            </thead>
            <tbody>
              {data.discountCampaignList.map(x => (
                <tr key={x.id}>
                  <td className="text-center">
                    {x.hasAppliedDiscount && <i className="fas fa-check-circle" style={{ color: '#27ae60' }}></i>}
                  </td>
                  <td>
                    <Link to={`/discount_campaign/edit/${x.id}`}>{x.name}</Link>
                  </td>
                  <td>{x.discount}%</td>
                  <td>
                    <DiscountCampaignApplyButton id={x.id} />
                  </td>
                  <td>
                    <DiscountCampaignReverseButton id={x.id} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SectionBlock>
    </Content>
  );
}
