import React, { useState, ChangeEvent } from 'react';
import { Graph } from '../../generated/graph';
import { DropdownBaseComponent } from '../DropdownBaseComponent';
import { useQuery } from '@apollo/client';
import { DropdownShadow } from '../DropdownShadow';
import { HighlightText } from '../HighlightText';
import FormInputLike from '../layout/xform/FormInputLike';
import { QUERY_ADMIN_LIST } from 'src/query/admin';

interface Props {
  label?: string;
  value?: number | null;
  onChange?: (v: number | null) => void;
  defaultUseRecentDelivery?: boolean;
}

export function DeliverPicker(props: Props) {
  const { value, onChange } = props;
  const [timer] = useState<{ id?: number }>({});
  const [toggle, setToggle] = useState(false);
  const [search, setSearch] = useState('');
  const [admin, setAdmin] = useState<Graph.Admin | null>(null);

  const { data } = useQuery<Graph.Query>(QUERY_ADMIN_LIST);

  function preview() {
    return (
      <FormInputLike label={props.label} dropdown={true}>
        {admin?.name ? admin.name : 'Please pick driver'}
      </FormInputLike>
    );
  }

  function list() {
    const admin: Graph.Admin[] = data?.adminList ? data?.adminList : [];

    return (
      <ul className="dropdown-container mt-3" style={{ width: 500, right: 0 }}>
        <li
          onClick={(): void => {
            setAdmin(null);
            setToggle(false);
            if (onChange) onChange(null);
          }}
          style={{ borderBottom: '1px solid #f1f1f1' }}
        >
          <i>No Delivery</i>
        </li>
        {admin
          .filter(x => {
            const a = (x.groupID === 23 || x.groupID === 5) && !!x.active;
            return a;
          })
          .filter(x => x.name?.match(new RegExp(search, 'i')))
          .filter((_, i) => i < 10)
          .map(x => (
            <li
              key={x.id?.toString()}
              onClick={(): void => {
                setAdmin(x);
                setToggle(false);
                if (onChange) onChange(x.id as number);
              }}
              className={value === x.id ? 'selected' : ''}
            >
              <HighlightText text={x.name ? x.name : ''} highlight={search} />
            </li>
          ))}
      </ul>
    );
  }

  function dropdown() {
    return (
      <DropdownShadow>
        <div className="element-search autosuggest-search-activator">
          <input
            placeholder="Start typing to search..."
            type="search"
            autoFocus
            name="search"
            autoComplete={'off'}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              const t = e.target.value;
              window.clearTimeout(timer.id);
              timer.id = window.setTimeout(() => setSearch(t), 500);
            }}
          />
        </div>
        <div className="mt-2"></div>
        {list()}
      </DropdownShadow>
    );
  }

  return (
    <DropdownBaseComponent
      dropdownHeight={400}
      preview={preview}
      dropdown={dropdown}
      onToggleChanged={t => setToggle(t)}
      toggle={toggle}
    />
  );
}
