import React, { ReactElement, useState } from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { NotificationPayloadBuilder } from './NotificationPayloadBuilder';
import { useForm } from 'react-hook-form';
import { RouteComponentProps } from 'react-router';
import { Checkbox, TextField } from '@shopify/polaris';
import { DateTime } from 'src/libs/DateTime';
import { CreateUpdateForm, CreateUpdateProps } from 'src/components/form/CreateUpdateForm';
import XForm from 'src/components/layout/xform/XForm';

const QUERY_CUSTOMER_SEGMENT = gql`
  query {
    notificationSegmentList {
      id
      name
      size
    }
  }
`;

const QUERY = gql`
  query NotificationSchedule($id: Int!) {
    notificationSchedule(id: $id) {
      id
      segmentId
      segmentName
      phoneNumber
      data
      scheduleAt
      repeatedValue
      repeatedType
    }
  }
`;

const UPDATE = gql`
  mutation UpdateNotificationSchedule($id: Int!, $data: NotificationScheduleInput!) {
    updateNotificationSchedule(id: $id, data: $data)
  }
`;

const CREATE = gql`
  mutation CreateNotificationSchedule($data: NotificationScheduleInput!) {
    createNotificationSchedule(data: $data)
  }
`;

function NotificationSegment({ value, onChange }: { value: number; onChange: (x: number) => void }): ReactElement {
  const { data, loading } = useQuery<Graph.Query>(QUERY_CUSTOMER_SEGMENT);

  if (loading || !data) {
    return <select className="lf-form-control"></select>;
  }

  return (
    <select className="lf-form-control" value={value} onChange={(e): void => onChange(Number(e.target.value))}>
      {[
        ...(data.notificationSegmentList || []),
        {
          id: -999,
          name: 'All',
          size: 999999,
        },
      ].map(x => (
        <option key={x.id?.toString()} value={x.id?.toString()}>
          {x.name} ({x.size})
        </option>
      ))}
    </select>
  );
}

export function NotificationScreen(props: RouteComponentProps<{ id: string }>): React.ReactElement {
  return (
    <Content>
      <SectionBlock title="Notification Scheduler" width={800}>
        <CreateUpdateForm
          query={QUERY}
          create={CREATE}
          update={UPDATE}
          body={NotificationScreenForm}
          updateReturned="/notification/list"
          createReturned="/notification/list"
          id={props.match.params.id}
        />
      </SectionBlock>
    </Content>
  );
}

function NotificationScreenForm({
  update,
  defaultValue,
}: CreateUpdateProps<Graph.NotificationScheduleInput>): ReactElement {
  const [payload, setPayload] = useState(defaultValue?.data?.data ? defaultValue.data.data : {});
  const [segment, setSegment] = useState(defaultValue?.segmentId ? defaultValue.segmentId : 2);
  const [isSegment, setIsSegment] = useState(defaultValue.phoneNumber ? false : true);
  const [isAnnouncement, setIsAnnouncement] = useState(defaultValue.notificationType === 'ANNOUNCEMENT' ? true : false);
  const [phoneNumberList, setPhoneNumberList] = useState(
    !defaultValue?.segmentId ? defaultValue.phoneNumber?.join('\n') : '',
  );
  const { handleSubmit, register } = useForm<{ title: string; heading: string }>({
    defaultValues: {
      title: defaultValue?.data?.title ? defaultValue.data.title : '',
      heading: defaultValue?.data?.heading ? defaultValue.data.heading : '',
    },
  });
  const [scheduleDate, setScheduleDate] = useState(defaultValue?.scheduleAt ? defaultValue.scheduleAt : DateTime.now());
  const [repeatedValue, setRepeatedValue] = useState(
    defaultValue?.repeatedValue ? defaultValue.repeatedValue.toString() : '0',
  );
  const [repeatedType, setRepeatedType] = useState(defaultValue?.repeatedType ? defaultValue.repeatedType : 'DAY');
  const [isRepeat, setIsRepeat] = useState(defaultValue?.repeatedValue ? true : false);

  const onSubmit = handleSubmit(data => {
    const customerIds = !isSegment
      ? phoneNumberList
          ?.split('\n')
          .filter(y => !!y)
          .map(x => parseInt(x).toString())
      : [];
    update({
      segmentId: isSegment ? segment : null,
      phoneNumber: !isSegment ? customerIds : null,
      data: {
        data: payload,
        title: data.title,
        heading: data.heading,
      },
      scheduleAt: scheduleDate,
      repeatedType: isRepeat ? repeatedType : undefined,
      repeatedValue: isRepeat ? Number(repeatedValue) : 0,
      notificationType: isAnnouncement ? 'ANNOUNCEMENT' : 'NORMAL',
    });
  });

  return (
    <form onSubmit={onSubmit} noValidate={true}>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <Checkbox label="Customer Segment " checked={!!isSegment} onChange={() => setIsSegment(true)} />
            &nbsp;&nbsp;
            <Checkbox label="Customer Phone Number" checked={!isSegment} onChange={() => setIsSegment(false)} />
          </div>
          {!isSegment ? (
            <div className="form-group">
              <label>Phone Number</label>
              <textarea
                name="Phone Number"
                className="lf-form-control"
                placeholder="Phone Number"
                value={phoneNumberList || ''}
                onChange={e => setPhoneNumberList(e.currentTarget.value)}
              ></textarea>
            </div>
          ) : (
            <div className="form-group">
              <label>Segment</label>
              <NotificationSegment value={segment} onChange={(x): void => setSegment(x)} />
            </div>
          )}

          <div className="form-group">
            <label>Heading</label>
            <input name="heading" className="lf-form-control" placeholder="Heading" ref={register} />
          </div>

          <div className="form-group">
            <label>Title</label>
            <textarea name="title" className="lf-form-control" placeholder="Title" ref={register} />
          </div>
        </div>
        <div className="col-6">
          <NotificationPayloadBuilder
            onChange={(x): void => {
              setPayload(x);
            }}
            payload={payload}
          />

          <TextField
            type="datetime-local"
            label="Scheduled date time"
            autoComplete="off"
            value={scheduleDate}
            onChange={setScheduleDate}
          />

          <div className="mt-2">
            <Checkbox label="Repeat Schedule" checked={isRepeat} onChange={setIsRepeat} />
            <div>
              <div>
                <TextField
                  label="Set interval"
                  type="number"
                  value={repeatedValue}
                  onChange={setRepeatedValue}
                  autoComplete="off"
                  disabled={!isRepeat}
                  min={0}
                />
              </div>
              <div className="mt-3">
                <XForm.Select
                  disabled={!isRepeat}
                  label=""
                  onChange={e => setRepeatedType(e.currentTarget.value as 'DAY' | 'MONTH' | 'HOUR')}
                  value={repeatedType}
                  items={[
                    { text: 'Hour', value: 'HOUR' },
                    { text: 'Day', value: 'DAY' },
                    { text: 'Month', value: 'MONTH' },
                  ]}
                />
              </div>
              <div className="mt-2">
                <Checkbox label="Sent as announcement" checked={isAnnouncement} onChange={setIsAnnouncement} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-buttons-w">
        <button name="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
}
