import { MutationHookOptions, gql, useMutation } from '@apollo/client';
import { Graph } from 'src/generated/graph';

const MUTATION = gql`
  mutation AddSupplierFee($supplierId: Int!, $amount: Decimal!, $description: String) {
    addSupplierFee(supplierId: $supplierId, amount: $amount, description: $description)
  }
`;

export default function useMutationAddSupplierFee(
  options?: MutationHookOptions<Graph.Mutation, { supplierId: number; amount: string; description: string }>,
) {
  return useMutation(MUTATION, options);
}
