import React from 'react';

type Props = {
  items: string[];
};

export function BreadCrumbArray(props: Props) {
  return (
    <ul className="breadcrumb">
      {props.items.map(item => (
        <li key={item} className="breadcrumb-item">
          <a href="/#" className="bg-primary text-light" style={{ padding: 5, fontSize: 13 }}>
            {item}
          </a>
        </li>
      ))}
    </ul>
  );
}
