/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useState } from 'react';
import { Content } from '../../../components/light/Content';
import { SectionBlock } from '../../../components/light/SectionBlock';
import { Graph } from '../../../generated/graph';
import { Link } from 'react-router-dom';
import { useMutation, gql, useLazyQuery } from '@apollo/client';
import OffsetPagination from 'src/components/layout/Pagination/OffsetPagination';
import { ViewMinor, HideMinor } from '@shopify/polaris-icons';
import { Button, TextField, TextFieldProps } from '@shopify/polaris';
import { PolarisConfirm } from 'src/components/polaris/PolarisModal';

const QUERY = gql`
  query LuckyDrawList($offset: Int!, $limit: Int!) {
    luckyDrawList(offset: $offset, limit: $limit) {
      id
      name
      cost
      participants
      startedAt
      endedAt
      published
      winner {
        id
        name
        phoneNumber
      }
    }
  }
`;

// const MUTATION_RANDOM_WINNER = gql`
//   mutation RandomLuckyDraw($id: Int!) {
//     randomLuckyDrawWinner(id: $id)
//   }
// `;

const QUERY_LUCKYDRAW = gql`
  query luckyDrawAvailableCustomer($luckyDrawId: Int!) {
    luckyDrawAvailableCustomer(luckyDrawId: $luckyDrawId) {
      avilableCustomers
      notifiedCustomers
    }
  }
`;

const MUTATION_SEND_LUCKYDRAW = gql`
  mutation sendLuckyDrawNotification($luckyDrawId: Int!, $title: String, $heading: String, $limit: Int) {
    sendLuckyDrawNotification(luckyDrawId: $luckyDrawId, title: $title, heading: $heading, limit: $limit)
  }
`;

function InputDialog({ textProps, id, defaultValue }: { textProps?: TextFieldProps; id: string; defaultValue: any }) {
  const [input, setInput] = useState(defaultValue || '');
  const handleChange = useCallback(value => setInput(value), []);

  return (
    <div id={id}>
      <TextField
        {...textProps}
        label=""
        labelHidden
        autoComplete="off"
        placeholder=""
        value={input + ''}
        onChange={handleChange}
      />
    </div>
  );
}

function LimitInput({ avilableCustomers }: { avilableCustomers: number }) {
  const [limit, setLimit] = useState(avilableCustomers);
  const handleChangeLimit = useCallback(value => setLimit(value), []);

  return (
    <div id="limitinput">
      <TextField
        label=""
        labelHidden
        autoComplete="off"
        placeholder=""
        value={limit + ''}
        onChange={handleChangeLimit}
        helpText={`We should not send notification to more than 1000 customers.`}
        type="number"
        inputMode="numeric"
      />
    </div>
  );
}

function ToggleNotification({ id, info }: { id: number; info: { name: string; description: string } }) {
  const [toggle, setToggle] = useState(false);
  const [luckyDrawAvailableCustomer, { called, loading, data }] = useLazyQuery(QUERY_LUCKYDRAW, {
    variables: { luckyDrawId: id },
  });
  const [sendLuckyDrawNotification, { loading: loadingSend }] = useMutation(MUTATION_SEND_LUCKYDRAW, {
    refetchQueries: ['luckyDrawAvailableCustomer', 'LuckyDrawList'],
    onCompleted: res => {
      if (res.sendLuckyDrawNotification) {
        window.alert('Lucky draw notification was send.');
      }
    },
  });

  const handleClick = useCallback(() => {
    luckyDrawAvailableCustomer();
    setToggle(toggle => !toggle);
  }, []);

  const handleSend = () => {
    const docLimitInput = document.getElementById('limitinput');
    const valLimitInput: any = docLimitInput?.getElementsByClassName('Polaris-TextField__Input');

    const docNameInput = document.getElementById('nameinput');
    const valNameInput: any = docNameInput?.getElementsByClassName('Polaris-TextField__Input');

    const docHeadingInput = document.getElementById('headinginput');
    const valHeadingInput: any = docHeadingInput?.getElementsByClassName('Polaris-TextField__Input');

    if (Number(valLimitInput[0].value) > Number(data.luckyDrawAvailableCustomer.avilableCustomers)) {
      return window.alert('Allow value limit equal or less than ' + data.luckyDrawAvailableCustomer.avilableCustomers);
    }

    const input = {
      luckyDrawId: id,
      title: valNameInput[0].value,
      heading: valHeadingInput[0].value,
      limit: Number(valLimitInput[0].value),
    };

    sendLuckyDrawNotification({
      variables: input,
    });
  };

  const handleClickSendLuckyDraw = () => {
    PolarisConfirm.dialog({
      body: [
        <div key={1}>
          <p style={{ display: 'flex', justifyContent: 'space-between' }}>
            <small style={{ width: 100 }}>Name</small>
            <small style={{ marginRight: 'auto' }}>:</small>
            <small style={{ textAlign: 'end', width: '70%' }}>
              <InputDialog
                id="nameinput"
                defaultValue={info.name + ''}
                textProps={{
                  autoComplete: 'off',
                  label: '',
                  labelHidden: true,
                  type: 'text',
                  inputMode: 'text',
                }}
              />
            </small>
          </p>
        </div>,
        <div key={2}>
          <p style={{ display: 'flex', justifyContent: 'space-between' }}>
            <small style={{ width: 100 }}>Heading</small>
            <small style={{ marginRight: 'auto' }}>:</small>
            <small style={{ textAlign: 'end', width: '70%' }}>
              <InputDialog
                id="headinginput"
                defaultValue={info.description + ''}
                textProps={{
                  autoComplete: 'off',
                  label: '',
                  labelHidden: true,
                  type: 'text',
                  inputMode: 'text',
                  multiline: 4,
                }}
              />
            </small>
          </p>
        </div>,
        <div key={3}>
          <p style={{ display: 'flex', justifyContent: 'space-between' }}>
            <small style={{ width: 100 }}>Customers</small>
            <small style={{ marginRight: 'auto' }}>:</small>
            <small style={{ textAlign: 'end' }}>
              <LimitInput avilableCustomers={data.luckyDrawAvailableCustomer.avilableCustomers} />
            </small>
          </p>
        </div>,
      ],
      buttons: [
        {
          title: 'Cancel',
          class: 'danger',
        },
        {
          title: 'Yes',
          class: 'primary',
          onPress: handleSend,
        },
      ],
      title: 'Information from lucky draw',
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: toggle ? 'space-between' : 'center',
        alignItems: 'center',
      }}
    >
      {data && toggle && (
        <small>
          {data.luckyDrawAvailableCustomer.avilableCustomers}/{data.luckyDrawAvailableCustomer.notifiedCustomers}
        </small>
      )}
      {data && toggle && (
        <Button primary size="slim" onClick={handleClickSendLuckyDraw} loading={loadingSend}>
          Notify
        </Button>
      )}
      <Button
        onClick={handleClick}
        icon={called && toggle ? HideMinor : ViewMinor}
        plain
        size="slim"
        loading={loading}
      />
    </div>
  );
}

function LuckyDrawListBody({ data }: { data: Graph.LuckyDraw[] }): React.ReactElement {
  // const [random, { loading }] = useMutation(MUTATION_RANDOM_WINNER, { refetchQueries: ['LuckyDrawList'] });

  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th style={{ width: 25 }}></th>
          <th>Lucky Draw</th>
          <th style={{ width: 200 }}>Notification Available/Notified</th>
          <th style={{ width: 100 }}>Cost</th>
          <th style={{ width: 120 }}>Winner</th>
          <th style={{ width: 200 }}>Started At</th>
          <th style={{ width: 200 }}>Ended At</th>
          <th style={{ width: 75 }}>Participants</th>
          <th style={{ width: 75 }}></th>
        </tr>
      </thead>
      <tbody>
        {(data || []).map(x => (
          <tr key={x.id?.toString()}>
            <td>{x.published ? <div className="status-pill green"></div> : <div className="status-pill red"></div>}</td>
            <td>
              <Link to={`/marketing/lucky_draw/edit/${x.id}`}>{x.name}</Link>
            </td>
            <td className="text-center">
              <ToggleNotification
                id={Number(x.id || 0)}
                info={{ name: x.name || '', description: x.description || '' }}
              />
            </td>
            <td>{x.cost} points</td>
            <td>
              {x.winner && (
                <div>
                  <strong>{x.winner.name}</strong>
                  <br />
                  <span>{x.winner.phoneNumber}</span>
                </div>
              )}
            </td>
            <td>{x.startedAt}</td>
            <td>{x.endedAt}</td>
            <td>
              <Link to={`/marketing/lucky_draw/participant/${x.id}`} className="btn btn-sm btn-primary">
                Participant: {x.participants}
              </Link>
            </td>
            {/* <td>
              <button
                type="button"
                disabled={loading}
                className="btn btn-primary"
                onClick={(): void => {
                  random({ variables: { id: x.id } });
                }}
              >
                Random Winner
              </button>
            </td> */}
            <td>
              <Link to={`/marketing/lucky_draw/${x.id}`} className="btn btn-sm btn-primary" target="blank">
                Random Winner
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function renderBody(data: Graph.LuckyDraw[]): ReactElement {
  return <LuckyDrawListBody data={data} />;
}

function renderHeader(): ReactElement {
  return (
    <div>
      <Link className="btn btn-primary" to="/marketing/lucky_draw/create">
        Create Lucky Draw
      </Link>
    </div>
  );
}

export function LuckyDrawListScreen(): ReactElement {
  return (
    <Content>
      <SectionBlock title="Lucky Draw">
        <div className="mb-3">{renderHeader()}</div>
        <OffsetPagination query={QUERY} body={renderBody} />
      </SectionBlock>
    </Content>
  );
}
