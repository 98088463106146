import { ReactElement, useState, useRef, useEffect } from 'react';
import { AppPageMarkupItem } from './AppPageMarkupItem';
import { AppPageMarkup } from '../../../types/common';
import { Graph } from '../../../generated/graph';
import Draggable from 'react-draggable';
import styles from './style.module.scss';
import { useHistory } from 'react-router';

interface Props {
  id: number | null;
  value: Graph.CustomPageInput;
  loading: boolean;
  onSaved: (v: Graph.CustomPageInput) => void;
}

function move<T>(a: T[], n: number, m: number): T[] {
  if (n < m) {
    return [...a.slice(0, n), ...a.slice(n + 1, m + 1), a[n], ...a.slice(m + 1)];
  } else {
    return [...a.slice(0, m), a[n], a[m], ...a.slice(m + 1, n), ...a.slice(n + 1)];
  }
}

export function AppPageMarkupEditor(props: Props): ReactElement {
  const History = useHistory();
  const Ref = useRef<HTMLIFrameElement>(null);
  const [value, setValue] = useState<AppPageMarkup[]>(props.value.content);
  const nameRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState(0);
  const [hasChanged, setHasChanged] = useState(false);
  const [isProductionDomain, setIsProductionDomanin] = useState(false);

  useEffect(() => {
    const hostName = window.location.hostname;
    if (hostName === 'spa.l192.com') {
      setIsProductionDomanin(true);
    } else {
      setIsProductionDomanin(false);
    }
  }, []);

  return (
    <div className="markup-container">
      <div className="markup-header">
        <div className="row">
          <div className="col-6">
            <input ref={nameRef} autoFocus className="lf-form-control" defaultValue={props.value.name || 'Untitled'} />
          </div>
          <div className="col-6 text-right">
            <button
              disabled={props.loading}
              className="lf-btn btn-primary"
              style={{ borderRadius: 5 }}
              onClick={() => {
                props.onSaved({ content: value, name: nameRef.current?.value });
                Ref?.current?.contentWindow?.location?.replace(
                  `${
                    props?.id
                      ? `https://${isProductionDomain ? 'view.l192.com' : 'view-stage.l192.com'}/customPage/${
                          props?.id
                        }`
                      : ''
                  }`,
                );
              }}
            >
              {props.loading && <i className="fas fa-spinner fa-spin mr-2"></i>}
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="markup-editor">
        <div className="preview">
          <ul>
            {value.map((x, idx) => {
              return (
                <li
                  draggable={true}
                  onDragStart={e => {
                    e.dataTransfer.setData('text/plain', idx.toString());
                  }}
                  onDragOver={e => {
                    e.preventDefault();
                  }}
                  onDrop={e => {
                    e.preventDefault();
                    const from = Number(e.dataTransfer.getData('text/plain'));
                    if (Number(from) !== idx) {
                      setValue(move(value, from, idx));
                      setSelected(idx);
                    }
                  }}
                  key={idx}
                  onClick={() => {
                    if (
                      !hasChanged ||
                      window.confirm('You have made some changes. Do you want to discard your change?')
                    ) {
                      setSelected(idx);
                      setHasChanged(false);
                    }
                    History?.push(`#${x?.title}`);
                  }}
                  className={selected === idx ? 'active' : ''}
                >
                  <strong>{x.title || 'Untitled'}</strong>
                  <div>{x.widget}</div>
                </li>
              );
            })}
            <li
              className="add-new"
              onClick={() => {
                setValue([...value, { widget: 'product_slider', title: 'Untitled' }]);
                if (selected === -1) {
                  setSelected(value.length);
                  setHasChanged(false);
                }
              }}
            >
              <i className="fas fa-plus-circle"></i>
              <span>Add New</span>
            </li>
          </ul>
        </div>
        <div className="options">
          {value[selected] && (
            <AppPageMarkupItem
              hasChanged={hasChanged}
              key={selected + (value[selected].title || '')}
              value={value[selected]}
              onHasChanged={v => setHasChanged(v)}
              onRemove={() => {
                if (window.confirm('Do you want to remove this block?')) {
                  value.splice(selected, 1);
                  setValue([...value]);
                }
              }}
              onSave={v => {
                value[selected] = v;
                setValue([...value]);
              }}
            />
          )}
        </div>
        <div style={{ zIndex: 6 }}>
          <Draggable axis="both" handle=".handle" defaultPosition={{ x: 0, y: 0 }} grid={[25, 25]} scale={1}>
            <div className={styles.prview}>
              <div className="handle">
                <div className={styles.ic_badge}>
                  <i className="fas fa-arrows-alt"></i>
                </div>
              </div>
              <div className={styles.prview_inner}>
                {/* <div className={styles.overlay}></div> */}
                {/* <div dangerouslySetInnerHTML={{ __html: '' }}></div>sadasd */}
                <iframe
                  src={`${
                    props?.id
                      ? `https://${isProductionDomain ? 'view.l192.com' : 'view-stage.l192.com'}/customPage/${
                          props?.id
                        }${History?.location.hash}`
                      : ''
                  }`}
                  title={props?.value?.name || ''}
                  height={'100%'}
                  ref={Ref}
                  className={styles.gg}
                ></iframe>
              </div>
            </div>
          </Draggable>
        </div>
      </div>
    </div>
  );
}
