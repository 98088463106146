import React, { ReactElement } from 'react';
import { Mutation, MutationResult, MutationFunction } from '@apollo/react-components';
import { gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { useDefaultValue } from '../../libs/Common';

const MUTATION = gql`
  mutation UpdatePurchaseOrderStage($stage: PurchaseOrderStage!, $id: Int!) {
    updatePurchaseOrder(order: { stage: $stage }, id: $id)
  }
`;

interface Stage {
  stage: string;
  name: string;
  image: string;
  time: string;
  attachment: string;
}

export class PurchaseOrderStage extends React.Component<{
  purchaseOrder: Graph.PurchaseOrder;
  admin: Graph.Admin;
}> {
  render(): ReactElement {
    if (this.props.purchaseOrder === undefined) return <div></div>;
    if (this.props.purchaseOrder === null) return <div></div>;

    const stages: Stage[] = [
      {
        stage: 'CREATED',
        name: useDefaultValue(this.props.purchaseOrder.createdBy?.name, ''),
        image: useDefaultValue(this.props.purchaseOrder.createdBy?.image, ''),
        time: useDefaultValue(this.props.purchaseOrder.createdAt, ''),
        attachment: '',
      },
    ];

    if (this.props.purchaseOrder.shippedBy !== null) {
      stages.push({
        stage: 'SHIPPED',
        name: useDefaultValue(this.props.purchaseOrder.shippedBy?.name, ''),
        image: useDefaultValue(this.props.purchaseOrder.shippedBy?.image, ''),
        time: useDefaultValue(this.props.purchaseOrder.shippedAt, ''),
        attachment: '',
      });
    }

    if (this.props.purchaseOrder.arrivedBy !== null) {
      stages.push({
        stage: 'ARRIVED',
        name: useDefaultValue(this.props.purchaseOrder.arrivedBy?.name, ''),
        image: useDefaultValue(this.props.purchaseOrder.arrivedBy?.image, ''),
        time: useDefaultValue(this.props.purchaseOrder.arrivedAt, ''),
        attachment: '',
      });
    }

    if (this.props.purchaseOrder.processingBy !== null) {
      stages.push({
        stage: 'PROCESSING',
        name: useDefaultValue(this.props.purchaseOrder.processingBy?.name, ''),
        image: useDefaultValue(this.props.purchaseOrder.processingBy?.image, ''),
        time: useDefaultValue(this.props.purchaseOrder.processingAt, ''),
        attachment: '',
      });
    }

    if (this.props.purchaseOrder.completedBy !== null) {
      stages.push({
        stage: 'COMPLETED',
        name: useDefaultValue(this.props.purchaseOrder.completedBy?.name, ''),
        image: useDefaultValue(this.props.purchaseOrder.completedBy?.image, ''),
        time: useDefaultValue(this.props.purchaseOrder.completedAt, ''),
        attachment: '',
      });
    }

    if (this.props.purchaseOrder.voidBy !== null) {
      stages.push({
        stage: 'VOID',
        name: useDefaultValue(this.props.purchaseOrder.voidBy?.name, ''),
        image: useDefaultValue(this.props.purchaseOrder.voidBy?.image, ''),
        time: useDefaultValue(this.props.purchaseOrder.voidAt, ''),
        attachment: '',
      });
    }

    if (this.props.purchaseOrder.paymentBy !== null) {
      stages.push({
        stage: 'PAID',
        name: useDefaultValue(this.props.purchaseOrder.paymentBy?.name, ''),
        image: useDefaultValue(this.props.purchaseOrder.paymentBy?.image, ''),
        time: useDefaultValue(this.props.purchaseOrder.paymentAt, ''),
        attachment: useDefaultValue(this.props.purchaseOrder.paymentFile, ''),
      });
    }

    stages.sort((a, b) => {
      if (a.time === undefined || b.time === undefined || a.time === null || b.time === null) return 0;

      if (a.time > b.time) return -1;
      else if (a.time === b.time) return 0;
      return 1;
    });

    return (
      <div className="element-box-tp">
        {this.renderTable(stages)}

        <Mutation mutation={MUTATION} refetchQueries={['getPurchasOrder']}>
          {this.renderStageMutation}
        </Mutation>

        <Mutation mutation={MUTATION} refetchQueries={['getPurchasOrder']}>
          {this.renderVoidMutation}
        </Mutation>
      </div>
    );
  }

  renderTable = (stages: Stage[]): ReactElement => {
    return (
      <table className="lf-table-shadow">
        <thead>
          <tr>
            <th>Stages</th>
          </tr>
        </thead>
        <tbody>
          {stages.map(x => (
            <tr key={x.name}>
              <td>
                <div>
                  <strong className="h6">{x.stage}</strong>
                </div>
                <div>
                  <small>{x.name}</small>
                </div>
                {x.attachment && (
                  <div>
                    <small>
                      <a target="_blank" href={x.attachment} rel="noopener noreferrer">
                        <i className="fal fa-paperclip"></i>
                        &nbsp;
                        {x.stage === 'PAID' ? this.props.purchaseOrder.paymentMethod : 'Attachment'}
                      </a>
                    </small>
                  </div>
                )}
                <div>
                  <small>{x.time}</small>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderVoidMutation = (update: MutationFunction, { loading }: MutationResult): ReactElement => {
    if (this.props.purchaseOrder.stage === 'COMPLETED') return <div></div>;
    if (this.props.purchaseOrder.stage === 'VOID') return <div></div>;
    if (!this.props.admin.superAdmin) return <div></div>;

    return (
      <div>
        <div className="mb-2">
          <button
            disabled={loading}
            className="btn btn-success btn-lg btn-block"
            onClick={(): void => {
              update({ variables: { id: this.props.purchaseOrder.id, stage: 'VOID' } });
            }}
          >
            VOID
          </button>
        </div>
      </div>
    );
  };

  renderStageMutation = (update: MutationFunction, { loading, error }: MutationResult): ReactElement => {
    // Don't render the button when the purchase order is completed.
    if (this.props.purchaseOrder.stage === 'COMPLETED') return <div></div>;
    if (this.props.purchaseOrder.stage === 'VOID') return <div></div>;

    let errorAlert = <div></div>;
    if (error) {
      errorAlert = (
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      );
    }

    const currentStage: string = this.props.purchaseOrder.stage as string;
    const nextStageMap: { [key: string]: string } = {
      CREATED: 'SHIPPED',
      SHIPPED: 'ARRIVED',
      ARRIVED: 'PROCESSING',
      PROCESSING: 'COMPLETED',
      COMPLETED: 'COMPLETED',
    };

    const nextStage = nextStageMap[currentStage];

    return (
      <div className="pt-4">
        <div className="mb-3">
          <button
            disabled={loading}
            className="btn btn-warning btn-lg btn-block"
            onClick={(): void => this.onMoveToNextStage(update, nextStage)}
          >
            {nextStage}
          </button>
        </div>
        {errorAlert}
      </div>
    );
  };

  onMoveToNextStage = (update: MutationFunction, nextStage: string): void => {
    const variables = {
      id: this.props.purchaseOrder.id,
      stage: nextStage,
    };

    update({ variables }).catch(console.error);
  };
}
