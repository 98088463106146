import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Graph } from 'src/generated/graph';

const QUERY_ADMIN = gql`
  query admin {
    admin {
      id
      name
      role
      superAdmin
      permissions
      image
      groupID
      recruiterId
    }
  }
`;

export default function useQueryAdmin(options?: QueryHookOptions<Graph.Query>) {
  return useQuery(QUERY_ADMIN, options);
}
