import { useState } from 'react';
import { AppPageMarkupGenricProductList } from './AppPageMarkupGenricProductList';
import { AppPageMarkup } from '../../../types/common';
import { AppPageMarkupButtonList } from './AppPageMarkupButtonList';
import { AppPageMarkupBannerList } from './AppPageMarkupBannerList';
import { AppPageMarkupSponsored } from './AppPageMarkupSponsored';
import { AppPageMarkupTextLink } from './AppPageMarkupTextLink';

interface Props {
  value: AppPageMarkup;
  hasChanged: boolean;
  onHasChanged: (v: boolean) => void;
  onSave?: (v: AppPageMarkup) => void;
  onRemove?: () => void;
}

export function AppPageMarkupItem(props: Props) {
  const [value, setValue] = useState<AppPageMarkup>(props.value);

  const options = [
    { name: 'product_slider', title: 'Product Slider' },
    { name: 'product_column', title: 'Product Column' },
    { name: 'product_list', title: 'Product List' },
    { name: 'banner', title: 'Banner' },
    { name: 'button_list', title: 'Buttons' },
    { name: 'buy_again', title: 'Buy Again' },
    { name: 'sponsored_items', title: 'Sponsored Product' },
    { name: 'text_link', title: 'Text Link' },
  ];

  return (
    <div>
      <div className="p-3">
        <ul className="type-list">
          {options.map(x => (
            <li
              className={value.widget === x.name ? 'active' : ''}
              key={x.name}
              onClick={() => {
                if (value.widget !== x.name) {
                  if (x.name === 'button_list') {
                    setValue({ widget: 'button_list', buttons: [], width: 45, height: 45 });
                  } else {
                    setValue({ widget: x.name });
                  }
                  if (!props.hasChanged) props.onHasChanged(true);
                }
              }}
            >
              {x.title}
            </li>
          ))}
        </ul>

        {['product_slider', 'product_column', 'product_list'].indexOf(value.widget) >= 0 && (
          <AppPageMarkupGenricProductList
            value={value}
            onChange={v => {
              setValue(v);
              if (!props.hasChanged) props.onHasChanged(true);
            }}
          />
        )}

        {value.widget === 'sponsored_items' && (
          <AppPageMarkupSponsored
            value={value}
            onChange={v => {
              setValue(v);
              if (!props.hasChanged) props.onHasChanged(true);
            }}
          />
        )}

        {value.widget === 'button_list' && (
          <AppPageMarkupButtonList
            value={value}
            onChange={v => {
              setValue(v);
              if (!props.hasChanged) props.onHasChanged(true);
            }}
          />
        )}

        {value.widget === 'banner' && (
          <AppPageMarkupBannerList
            value={value}
            onChange={v => {
              setValue(v);
              if (!props.hasChanged) props.onHasChanged(true);
            }}
          />
        )}
        {value.widget === 'text_link' && (
          <AppPageMarkupTextLink
            value={value}
            onChange={v => {
              setValue(v);
              if (!props.hasChanged) props.onHasChanged(true);
            }}
          />
        )}
      </div>
      <div className="p-3 form-buttons-w">
        <button
          className="btn btn-primary btn-lg"
          disabled={!props.hasChanged}
          onClick={() => {
            if (props.onSave) {
              props.onSave(value);
              props.onHasChanged(false);
            }
          }}
        >
          Make Change
        </button>

        <button className="btn btn-warning btn-lg" onClick={props.onRemove}>
          Remove
        </button>
      </div>
    </div>
  );
}
