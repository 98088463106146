import React from 'react';
import { ApolloClient, gql } from '@apollo/client';
import { SectionBlock } from './light/SectionBlock';
import { Loading } from './Loading';
import { PurchaseOrder } from '../types/PurchaseOrderType';

type Props = {
  apollo: ApolloClient<unknown>;
  onValidPurchaseOrder: (order: PurchaseOrder) => void;
};

export class ScanningPurchaseOrder extends React.Component<Props> {
  state: {
    isLoading: boolean;
    barcode: string;
  } = {
    isLoading: false,
    barcode: '',
  };

  input: HTMLInputElement | null = null;

  componentDidMount() {
    if (this.input !== null) {
      this.input.focus();
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return (
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <SectionBlock title="Scanning Purchase Order">
            <form onSubmit={this.onPurchaseOrderEntered}>
              <div className="form-group">
                <label>Purchase Order Barcode</label>
                <input
                  ref={input => {
                    this.input = input;
                  }}
                  type="text"
                  className="form-control"
                  value={this.state.barcode}
                  onChange={this.onPurchaseOrderBarcodeChanged}
                />
              </div>
            </form>
          </SectionBlock>
        </div>
      </div>
    );
  }

  private onPurchaseOrderEntered = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({ isLoading: true }, () => {
      this.checkPurchaseOrder();
    });
  };

  private checkPurchaseOrder = async () => {
    if (this.state.barcode.includes('_PCO_')) {
      const result = await this.props.apollo.query({
        query: gql`
          query getPurchaseOrder($id: Int!) {
            purchaseOrder(id: $id) {
              id
              name
              orderDetail
            }
          }
        `,
        variables: {
          id: parseInt(this.state.barcode.replace('_PCO_', '')),
        },
      });

      this.clearInput();
      if (result.data.purchaseOrder !== null) {
        this.props.onValidPurchaseOrder(result.data.purchaseOrder);
      } else {
        this.setState({ isLoading: false });
        alert('Purchase order not found');
      }
    } else {
      this.clearInput();
      this.setState({ isLoading: false });
      alert('Purchase order not found');
    }
  };

  private clearInput = () => {
    this.setState({ barcode: '' });
    if (this.input !== null) this.input.focus();
  };

  private onPurchaseOrderBarcodeChanged = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      barcode: e.currentTarget.value,
    });
  };
}
