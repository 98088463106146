/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from '@apollo/client';
import { Banner, IndexTable, Loading, Page, Thumbnail } from '@shopify/polaris';
import { IndexTableHeading } from '@shopify/polaris/build/ts/latest/src/components/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/latest/src/types';
import { useState } from 'react';
import PolarisContent from 'src/components/PolarisContent';
import { Graph } from 'src/generated/graph';
import { MenuRowFieldMaker } from './components/MenuRowFieldMaker';

const QUERY = gql`
  query productList(
    $offset: Int!
    $limit: Int!
    $code: String
    $filter: ProductListFilter
    $type: SupplierProductApproved
    $orderBy: SortOrder = DESC
    $sortByField: ProductOrderBy = DATE
    $totalRow: Int
  ) {
    productList(
      offset: $offset
      limit: $limit
      code: $code
      filter: $filter
      type: $type
      orderBy: $orderBy
      sortByField: $sortByField
      totalRow: $totalRow
    ) {
      id
      status
      code
      picture
      price
      productSourceLink
      storeSourceLink
      title {
        en
        km
        zh
      }
      supplier {
        id
        name
      }
      brand {
        id
        name
      }
      category {
        id
        name {
          en
          km
          zh
        }
      }
      weight
      price
      discount
      discountMax
      discountGold
      discountRate
      soldQty
      stockQty
      revenue
      followedBy {
        id
        name
      }
      reach
      clicks
      lastStockIn
      inputDate
      totalRow
      isCore
      isRestock
    }
  }
`;

const headingsTable: NonEmptyArray<IndexTableHeading> = [
  {
    title: 'No.',
  },
  {
    title: 'Item',
  },
  {
    title: 'Title',
  },
  {
    title: 'Info',
  },
  {
    title: 'Status',
  },
  {
    title: 'Weight',
  },
  {
    title: 'Price',
  },
  {
    title: 'Performance',
  },
  {
    title: 'Qty',
  },
  {
    title: '',
  },
];

export function ProductListPolarisScreen() {
  const sortOptions = [
    {
      label: 'Stock',
      value: 'STOCK asc',
      directionLabel: 'Ascending',
    },
    {
      label: 'Stock',
      value: 'STOCK desc',
      directionLabel: 'Descending',
    },
    {
      label: 'Date',
      value: 'DATE asc',
      directionLabel: 'Ascending',
    },
    {
      label: 'Date',
      value: 'DATE desc',
      directionLabel: 'Descending',
    },
    {
      label: 'Sales',
      value: 'SALES asc',
      directionLabel: 'Ascending',
    },
    {
      label: 'Sales',
      value: 'SALES desc',
      directionLabel: 'Descending',
    },
    {
      label: 'New Stock',
      value: 'NEW_STOCK asc',
      directionLabel: 'Ascending',
    },
    {
      label: 'New Stock',
      value: 'NEW_STOCK desc',
      directionLabel: 'Descending',
    },
  ];
  const [sortSelected, setSortSelected] = useState(['order asc']);
  const { data, loading, error } = useQuery<Graph.Query>(QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      offset: 0,
      limit: 25,
    },
  });

  const rowTableMakup = data?.productList?.map((item, index) => {
    const discount = Number(item.price) * (Number(item.discount) / 100);
    return (
      <IndexTable.Row id={item.id + ''} position={index} key={index}>
        <IndexTable.Cell>{index + 1}</IndexTable.Cell>
        <IndexTable.Cell>
          {item.picture && <Thumbnail source={item.picture} alt={item.code + ''} />}
          {item.code && (
            <a href={`https://adm.l192.com/product/edit/basic/${item.id}`} target="_blank" rel="noopener noreferrer">
              {item.code}
            </a>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ width: 200, overflow: 'hidden' }}>
            <small>{item.title?.en ? item.title.en : item.title?.km ? item.title?.km : item.title?.zh}</small>
            {item.storeSourceLink && (
              <div>
                <a href={item.storeSourceLink} target="_blank" rel="noopener noreferrer">
                  Store Link
                </a>
              </div>
            )}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {item.supplier && (
            <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
              <span style={{ width: '6rem', textAlign: 'left' }}>Supplier</span>
              <span style={{ marginRight: 'auto' }}>:</span>
              <span style={{ textAlign: 'end' }}>{item.supplier?.name}</span>
            </p>
          )}
          <hr />
          {(item as any).brand && (
            <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
              <span style={{ width: '6rem', textAlign: 'left' }}>Brand</span>
              <span style={{ marginRight: 'auto' }}>:</span>
              <span style={{ fontWeight: 'bold', textAlign: 'end' }} className="text-success">
                {(item as any).brand.name}
              </span>
            </p>
          )}
          <hr />
          {(item as any).category && (
            <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
              <span style={{ width: '6rem', textAlign: 'left' }}>Category</span>
              <span style={{ marginRight: 'auto' }}>:</span>
              <span style={{ fontWeight: 'bold', textAlign: 'end' }} className="text-primary">
                {(item as any).category.name.en}
              </span>
            </p>
          )}
        </IndexTable.Cell>
        <IndexTable.Cell>{item.status}</IndexTable.Cell>
        <IndexTable.Cell>{item.weight}</IndexTable.Cell>
        <IndexTable.Cell>{item.weight}</IndexTable.Cell>
        <IndexTable.Cell>
          <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
            <span style={{ width: '6rem', textAlign: 'left' }}>Sale Price</span>
            <span style={{ marginRight: 'auto' }}>:</span>
            <span>
              <span style={discount > 0 ? { textDecoration: 'line-through', color: '#e65252', marginRight: 5 } : {}}>
                ${(item as any).price}
              </span>
              {discount > 0 && <span>${(Number((item as any).price) - discount).toFixed(2)}</span>}
            </span>
          </p>
          <hr />
          <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
            <span style={{ width: '6rem', textAlign: 'left' }}>Discount</span>
            <span style={{ marginRight: 'auto' }}>:</span>
            <span>${discount.toFixed(2)}</span>
          </p>
          <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
            <span style={{ width: '6rem', textAlign: 'left' }}></span>
            <span style={{ marginRight: 'auto' }}></span>
            <span className="text-danger">{(item as any).discount}%</span>
          </p>
          <hr />
          <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
            <span style={{ width: '6rem', textAlign: 'left' }}>Gold</span>
            <span style={{ marginRight: 'auto' }}>:</span>
            <span style={{ color: '#dd8210', fontWeight: 'bold' }}>{(item as any).discountGold}%</span>
          </p>
          <hr />
          <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
            <span style={{ width: '6rem', textAlign: 'left' }}>Max</span>
            <span style={{ marginRight: 'auto' }}>:</span>
            <span className="text-danger" style={{ fontWeight: 'bold' }}>
              {(item as any).discountMax}%
            </span>
          </p>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
            <span style={{ width: '6rem', textAlign: 'left' }}>Sold Qty</span>
            <span style={{ marginRight: 'auto' }}>:</span>
            <span>{(item as any).soldQty || 0}</span>
          </p>
          <hr />
          <p style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', marginBottom: 0 }}>
            <span style={{ width: '6rem', textAlign: 'left' }}>Stock</span>
            <span style={{ marginRight: 'auto' }}>:</span>
            <span>{item.stockQty}</span>
          </p>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <MenuRowFieldMaker item={item} />
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  return (
    <PolarisContent>
      <Page title="Product List" fullWidth>
        {loading && <Loading />}
        {error && (
          <Banner
            status="critical"
            title={error?.name}
            action={{ content: 'Reload page', onAction: () => window.location.reload() }}
          >
            <p>{error?.message}</p>
          </Banner>
        )}
        <IndexTable
          selectable={false}
          headings={headingsTable}
          itemCount={data?.productList?.length || 0}
          loading={loading}
        >
          {rowTableMakup}
        </IndexTable>
      </Page>
    </PolarisContent>
  );
}
