import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { RouteComponentProps, useHistory } from 'react-router';
import Layout from 'src/components/layout/Layout';
import { Stats } from 'src/components/layout/Stats';
import { Table } from 'src/components/layout/tables/Table';
import Button from 'src/components/layout/xform/Button';
import XForm from 'src/components/layout/xform/XForm';
import { BlockLoading } from 'src/components/loading/BlockLoading';
import { Graph } from 'src/generated/graph';
import { LinkButton } from 'src/components/layout/xform/LinkButton';

const QUERY_LOGINLESS_ORDER_ITEM = gql`
  query QUERY_LOGINLESS_ORDER_ITEM($id: Int!) {
    loginlessOrder(id: $id) {
      customerName
      id
      status
      phoneNumber
      address
    }
    loginlessOrderItemList(id: $id) {
      id
      productId
      code
      picture
      color
      size
      price
    }
  }
`;

const MUTATION_REVIEW_LOGINLESS = gql`
  mutation reviewLockLoginlessOrder($id: Int!) {
    reviewLockLoginlessOrder(id: $id)
  }
`;

const MUTATION_APPROVE_LOGINLESS_ORDER_ITEM = gql`
  mutation APPROVE_MUTATION_LOGINLESS_ORDER_ITEM($id: Int!) {
    approveLoginlessOrder(id: $id) {
      orderId
      customerId
    }
  }
`;

const MUTATION_UNREACHABLE_LOGINLESS_ORDER = gql`
  mutation increaseLoginlessOrderUnreachable($id: Int!) {
    increaseLoginlessOrderUnreachable(id: $id)
  }
`;

const MUTATION_REJECT_LOGINLESS_ORDER_ITEM = gql`
  mutation REJECT_MUTATION_LOGINLESS_ORDER_ITEM($id: Int!, $reason: LoginlessOrderRejectReason!) {
    rejectLoginlessOrder(id: $id, reason: $reason)
  }
`;

function roundnum(value: number) {
  return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function renderRow(data: Graph.LoginlessOrderItem[]) {
  return (
    <Table seperator={true}>
      <thead>
        <tr>
          <th style={{ width: 120 }}>Picture</th>
          <th>Code</th>
          <th style={{ width: 120 }}>Color</th>
          <th style={{ width: 120 }}>Size</th>
          <th className="text-right" style={{ width: 120 }}>
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.id?.toString()}>
            <td>
              <img
                src={item.picture?.toString()}
                alt={item.code?.toString() || ''}
                style={{ width: 100, height: 100 }}
              />
            </td>
            <td>{item.code}</td>
            <td>{item.color}</td>
            <td>{item.size}</td>
            <td className="text-right">${roundnum(Number(item.price))}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

function renderAfterApprove(approvedData: Graph.LoginlessOrderApproveResponse) {
  return (
    <div>
      <p>Goto this customer panel</p>
      <LinkButton
        to={{ pathname: `http://adm.l192.com/customer/customer/detail/${approvedData.customerId}` }}
        target="_blank"
      >
        Customer Panel
      </LinkButton>
    </div>
  );
}

function LoginOrderItemBody({
  id,
  orderItemList,
  order,
}: {
  orderItemList: Graph.LoginlessOrderItem[];
  order: Graph.LoginlessOrder;
  id: number;
}) {
  const [reason, setReason] = useState('UNREACHABLE');
  const history = useHistory();
  const [approve, { data: approveData }] = useMutation<Graph.Mutation>(MUTATION_APPROVE_LOGINLESS_ORDER_ITEM);
  const [reject] = useMutation<Graph.Mutation>(MUTATION_REJECT_LOGINLESS_ORDER_ITEM);
  const [unreachable] = useMutation<Graph.Mutation>(MUTATION_UNREACHABLE_LOGINLESS_ORDER);
  const [lock] = useMutation(MUTATION_REVIEW_LOGINLESS, { variables: { id } });

  useEffect(() => {
    lock().catch(console.error);
  }, [lock]);

  function unreachableHandler() {
    unreachable({
      variables: { id },
    })
      .then(() => history.push('/loginless/orders'))
      .catch(console.error);
  }

  function approveHandler() {
    approve({
      variables: { id },
    })
      .then()
      .catch(console.error);
  }

  function rejectHandler() {
    if (window.confirm('Are you sure you want to reject this order')) {
      reject({
        variables: { id, reason },
      })
        .then(() => history.push('/loginless/orders'))
        .catch(console.error);
    }
  }

  return (
    <>
      <div>
        <Stats value={order.customerName || 'No Name'} title="Customer Name" />
        <Stats value={`0${order.phoneNumber || ''}`} title="Phone Number" />
      </div>

      <div className="mb-5">
        <strong>Address</strong>
        <br />
        {order.address ? <span>{order.address}</span> : <i>No Address</i>}
      </div>

      {approveData?.approveLoginlessOrder
        ? renderAfterApprove(approveData.approveLoginlessOrder)
        : renderRow(orderItemList)}

      {!approveData?.approveLoginlessOrder && (
        <>
          <div>
            <XForm.Footer>
              <Button theme="primary" onClick={() => approveHandler()}>
                Approve
              </Button>
              <Button theme="danger" onClick={() => unreachableHandler()}>
                Unreachable
              </Button>
            </XForm.Footer>
          </div>

          <div className="mt-5">
            <div className="mb-3">
              <strong>Reject</strong>
            </div>

            <div className="mb-3">
              <XForm.Radio
                label="Unreachable many time"
                value="UNREACHABLE"
                name="reason"
                checked={reason === 'UNREACHABLE'}
                onChange={e => setReason(e.currentTarget.value)}
              />
              <XForm.Radio
                label="Invalid Phone Number"
                value="INVALID_PHONE_NUMBER"
                name="reason"
                checked={reason === 'INVALID_PHONE_NUMBER'}
                onChange={e => setReason(e.currentTarget.value)}
              />
              <XForm.Radio
                label="Wrong Phone Number"
                value="WRONG_PHONE_NUMBER"
                name="reason"
                checked={reason === 'WRONG_PHONE_NUMBER'}
                onChange={e => setReason(e.currentTarget.value)}
              />
              <XForm.Radio
                label="Buy for testing"
                value="TEST_BUYING"
                name="reason"
                checked={reason === 'TEST_BUYING'}
                onChange={e => setReason(e.currentTarget.value)}
              />
              <XForm.Radio
                label="Other"
                value="OTHER"
                name="reason"
                checked={reason === 'OTHER'}
                onChange={e => setReason(e.currentTarget.value)}
              />
            </div>

            <Button theme="danger" onClick={() => rejectHandler()}>
              Reject
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export function LoginlessOrderReviewScreen(props: RouteComponentProps<{ id: string }>) {
  const { data } = useQuery<Graph.Query>(QUERY_LOGINLESS_ORDER_ITEM, {
    variables: { id: Number(props.match.params.id) },
  });

  if (data?.loginlessOrder && data.loginlessOrder.status !== 'PENDING') {
    return (
      <Layout>
        <p>This order cannot be reviewed</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <Layout.Header>Review Loginless Order</Layout.Header>
      {data?.loginlessOrderItemList && data?.loginlessOrder ? (
        <LoginOrderItemBody
          id={Number(props.match.params.id)}
          orderItemList={data.loginlessOrderItemList}
          order={data.loginlessOrder}
        />
      ) : (
        <BlockLoading />
      )}
    </Layout>
  );
}
