import { gql } from '@apollo/client';

export const QUERY_LUCKYDRAW = gql`
  query LuckyDraw($id: Int!) {
    luckyDraw(id: $id) {
      active
      cost
      cover
      createdAt
      createdBy {
        active
        balance
        image
      }
      description
      endedAt
      expired
      id
      joined
      name
      ongoing
      participants
      published
      startedAt
      thumbnail
      winner {
        balance
        id
        image
        loan
        name
        phoneNumber
        stats {
          totalReviewCount
          totalReviewHelpfulCount
        }
      }
    }
  }
`;

export const QUERY_LUCKYDRAW_PARTICIPANT = gql`
  query LuckyDrawParticipant($id: Int!) {
    luckyDrawParticipant(id: $id) {
      balance
      id
      image
      loan
      name
      phoneNumber
      stats {
        totalReviewCount
        totalReviewHelpfulCount
      }
    }
  }
`;

export const MUTATION_RANDOM_WINNER = gql`
  mutation RandomLuckyDraw($id: Int!) {
    randomLuckyDrawWinner(id: $id)
  }
`;

export const MUTATION_SEND_CUSTOMER_NOTIFICATION_BY_REST = gql`
  mutation SendCustomerNotificationByRest(
    $customerIds: [Int]
    $heading: String
    $title: String
    $notificationType: notificationType
    $data: JSON
  ) {
    sendCustomerNotificationByRest(
      customerIds: $customerIds
      heading: $heading
      title: $title
      notificationType: $notificationType
      data: $data
    )
  }
`;
