import React, { ReactElement, CSSProperties, useRef, useEffect, useState } from 'react';
import styles from './layout/xform/form.module.scss';

interface Props {
  label?: string;
  dropdown: () => ReactElement;
  preview: (toggle: boolean) => ReactElement;
  toggle: boolean;
  onToggleChanged: (toggle: boolean) => void;
  dropdownHeight?: number;
  style?: React.CSSProperties;
  previewToLeft?: boolean;
}

function detectPosition(height: number | undefined, ref: React.RefObject<HTMLDivElement>): 'bottom' | 'top' {
  if (height && ref.current && window.innerHeight - ref.current.getBoundingClientRect().bottom < height) {
    return 'top';
  } else {
    return 'bottom';
  }
}

export function DropdownBaseComponent(props: Props) {
  const wrappedRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');

  useEffect(() => {
    const onDocumentClicked = (e: MouseEvent) => {
      if (wrappedRef.current && !wrappedRef.current.contains(e.target as Node)) {
        props.onToggleChanged(false);
      }
    };

    document.addEventListener('click', onDocumentClicked);
    return () => document.removeEventListener('click', onDocumentClicked);
  });

  useEffect(() => {
    const onScroll = () => {
      const currentPosition = detectPosition(props.dropdownHeight, previewRef);
      if (currentPosition !== dropdownPosition) {
        setDropdownPosition(currentPosition);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [dropdownPosition, previewRef, props.dropdownHeight]);

  useEffect(() => {
    setDropdownPosition(detectPosition(props.dropdownHeight, previewRef));
  }, [props.toggle, props.dropdownHeight, previewRef]);

  const position = detectPosition(props.dropdownHeight, previewRef);
  const previewContainerStyle: CSSProperties = {
    zIndex: 2000,
    display: props.toggle ? 'block' : 'none',
    position: 'absolute',
    right: props.previewToLeft ? 0 : undefined,
    bottom: position === 'top' ? (previewRef.current?.clientHeight || 0) + 10 : undefined,
  };

  return (
    <div className={props.label ? styles.form_group : ''}>
      {props.label && <label className={styles.label}>{props.label}</label>}
      <div style={{ position: 'relative', ...props.style }} ref={wrappedRef}>
        <div ref={previewRef} onClick={() => props.onToggleChanged(true)}>
          {props.preview(props.toggle)}
        </div>
        <div style={previewContainerStyle}>
          <div
            style={{
              backgroundColor: '#fff',
              marginTop: 10,
              boxShadow: '0px 15px 125px rgba(0,0,0,0.22)',
              borderRadius: '3px',
            }}
          >
            {props.dropdown()}
          </div>
        </div>
      </div>
    </div>
  );
}
