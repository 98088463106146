/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

interface IState {
  title?: string;
  visible?: boolean;
  url?: string[];
  start?: number;
}

interface PreviewProps {
  state: any;
  setModal: (ref: any) => void;
  dialog: (p: IState) => void;
}

export const PolarisPreview: PreviewProps = {
  state: {
    modal: {},
  },
  setModal: (ref: any) => (PolarisPreview.state.modal = ref),
  dialog: (p: IState) => {
    PolarisPreview.state.modal.onShow(p);
  },
};

function DocumentPDF({ url }: { url: string }) {
  const [, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <Document file={url} options={{ workerSrc: '/pdf.worker.js' }} onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={pageNumber} />
    </Document>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export class PolarisDialogPreview extends React.Component<{}, IState> {
  pressRef: any | null = null;

  state: IState = {
    title: '',
    visible: false,
    url: [],
    start: 0,
  };

  onShow = (p: IState) => {
    this.setState({
      ...p,
      visible: true,
    });
  };

  onPress = (press?: () => void) => {
    if (!!press) {
      this.pressRef = press;
    }
    this.onClose();
  };

  onClose = () => {
    this.setState(
      {
        url: [],
        visible: false,
        title: '',
      },
      () => {
        if (!!this.pressRef) {
          this.pressRef();
          this.pressRef = null;
        }
      },
    );
  };

  render(): React.ReactNode {
    if (this.state.visible) {
      return (
        <div
          style={{
            position: 'fixed',
            backgroundColor: '#f3f3f3',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 99,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.75)',
            }}
            className="p-3"
          >
            <div>
              <h3 style={{ fontSize: '1.5em' }}>{this.state.title}</h3>
              <div className="mt-2">
                Media {(this.state.start || 0) + 1} of {this.state.url?.length}
              </div>
            </div>
            <div>
              <a
                download={this.state.url || ''}
                href={(this.state.url || '')[this.state.start || 0]}
                style={{ marginRight: '1.25rem' }}
              >
                <i className="os-icon os-icon-download" style={{ fontSize: '2em' }}></i>
              </a>
              <a href="#" onClick={this.onClose}>
                <i className="os-icon os-icon-x" style={{ fontSize: '2em' }}></i>
              </a>
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              padding: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              zIndex: 10,
            }}
            onClick={() => {
              this.setState({
                ...this.state,
                start: this.state.start === 0 ? (this.state.url?.length || 0) - 1 : (this.state.start || 0) - 1,
              });
            }}
          >
            <i className="os-icon os-icon-chevron-left" style={{ fontSize: '2em' }}></i>
          </div>
          <div
            style={{
              position: 'absolute',
              right: '20%',
              top: 0,
              bottom: 0,
              padding: '2rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              zIndex: 10,
            }}
            onClick={() => {
              this.setState({
                ...this.state,
                start: this.state.start === (this.state.url?.length || 0) - 1 ? 0 : (this.state.start || 0) + 1,
              });
            }}
          >
            <i className="os-icon os-icon-chevron-right" style={{ fontSize: '2em' }}></i>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              height: '100%',
            }}
          >
            <div
              style={{
                width: '80%',
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {this.state.url?.map((x: string, index) => {
                const exe = x.split('.')[x.split('.').length - 1];
                if (exe === 'pdf') {
                  return <DocumentPDF url={x} key={x} />;
                }
                return (
                  <img
                    src={x}
                    alt=""
                    key={x}
                    style={{ display: index === (this.state.start || 0) ? 'block' : 'none' }}
                  />
                );
              })}
            </div>
            <div
              style={{
                width: '20%',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.75)',
                padding: '1rem',
                overflow: 'hidden',
                overflowWrap: 'break-word',
              }}
            >
              <p>URL: {(this.state.url || [])[this.state.start || 0]}</p>
            </div>
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}
