/* eslint-disable */
import React from 'react';
import { RouterProps } from 'react-router';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { ApolloClient, gql, QueryResult, ApolloConsumer, OperationVariables } from '@apollo/client';
import { Query } from '@apollo/react-components';
import { uuid } from 'uuidv4';
import { Graph } from '../../generated/graph';
import { Printing } from '../../libs/Printing';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../components/form/FormComponent';

interface Props extends RouterProps {
  match: {
    params: {
      id: number;
    };
  };
}

const QUERY_SKU_BY_ID = gql`
  query($id: Int!) {
    sku(id: $id) {
      id
      productID
      color
      size
      barcode
      barcodeOriginal
    }
  }
`;

const QUERY_PRODUCT_BY_ID = gql`
  query($id: Int!) {
    product(id: $id) {
      id
      code
      picture
      discount
      price
    }
  }
`;

const QUERY_SKU_LIST = gql`
  query skuList($productID: Int) {
    skuList(productID: $productID) {
      id
      productID
      size
      color
      barcode
    }
  }
`;

const MUTATION_UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: Int!, $data: ProductInput!) {
    updateProduct(id: $id, data: $data)
  }
`;

const MUTATION_UPDATE_BARCODE = gql`
  mutation UpdateSKU($id: Int!, $data: SKUInput!) {
    updateSKU(id: $id, data: $data)
  }
`;

interface BarcodePrintingMessage {
  skuId: string;
  barcode: string;
  productID: number;
  productCode: string;
  color: string;
  size: string;
  price: number;
  discount: number;
}

type State = {
  printCounter: number;
  isUpdating: boolean;
  isSuccess: boolean;
  isPrintingWithoutPrice: boolean;
};

export class SKUScreen extends React.Component<Props> {
  inputDiscount = React.createRef<HTMLInputElement>();
  inputOriginalBarcode = React.createRef<HTMLInputElement>();

  state: State = {
    printCounter: 0,
    isUpdating: false,
    isSuccess: false,
    isPrintingWithoutPrice: false,
  };

  render() {
    return (
      <Content>
        <SectionBlock title="Stock Keeping Unit" body={false}>
          <Query
            query={QUERY_SKU_BY_ID}
            fetchPolicy={'network-only'}
            variables={({ id: Number(this.props.match.params.id) } as unknown) as OperationVariables}
            onCompleted={() => this.setState({ printCounter: 0 })}
          >
            {this.renderSKUQuery}
          </Query>
        </SectionBlock>
      </Content>
    );
  }

  print = (params: BarcodePrintingMessage | undefined) => {
    if (!params) return;

    const { barcode, productID, productCode, color, size, price, discount } = params;
    const newBarcodeFormat = JSON.stringify({
      Barcode: barcode,
      ItemID: productID,
      Code: productCode,
      Color: color,
      Size: size,
      Price: this.state.isPrintingWithoutPrice ? '' : price.toString(),
      Discount: discount,
      internalQr: JSON.stringify({ x: uuid(), z: Number(params.skuId) }),
    });

    console.log(newBarcodeFormat);

    const print = new Printing();
    console.log('Printing', params);
    print.send('PRINT_BARCODE2 ' + newBarcodeFormat);

    this.setState({ printCounter: this.state.printCounter + 1 });
  };

  printMany = async (params: BarcodePrintingMessage | undefined, qty: number) => {
    if (!params) return;

    for (let i = 0; i < qty; i++) {
      await new Promise(r => window.setTimeout(r, 1000));
      this.print(params);
    }
  };

  renderUpdatingAlert = () => {
    if (this.state.isUpdating) {
      return <div className="alert alert-warning">Please wait. We are updating...</div>;
    }

    if (this.state.isSuccess) {
      return (
        <div className="alert alert-success">
          <strong>Success!</strong> Updated
        </div>
      );
    }
  };

  renderSKUQuery = ({ loading: skuLoading, data: skuData }: QueryResult<Graph.Query>) => {
    return (
      <Query
        query={QUERY_PRODUCT_BY_ID}
        fetchPolicy={'network-only'}
        skip={skuData === undefined || skuData.sku === undefined || skuData.sku === null}
        variables={({ id: skuData && skuData.sku ? skuData.sku!.productID : 0 } as unknown) as OperationVariables}
      >
        {({ loading: productLoading, data: productData }: QueryResult<Graph.Query>) => {
          return this.renderAll(skuLoading, productLoading, skuData, productData);
        }}
      </Query>
    );
  };

  renderImage = (loading: boolean, data?: Graph.Query) => {
    let style: React.CSSProperties = { flexShrink: 0, width: 200, minHeight: 350, backgroundColor: '#f1f1f1' };

    if (!loading && data && data.product) {
      style = { ...style, backgroundImage: `url(${data.product.picture!})`, backgroundSize: 'cover' };
    }

    return <div style={style}></div>;
  };

  renderProduct = (loading: boolean, data?: Graph.Query) => {
    let values = {
      code: 'Loading ...',
      price: 0,
      discount: 0,
    };

    if (!loading && data && data.product) {
      values = {
        ...values,
        ...(data.product as any),
      };
    }

    return (
      <div className="row">
        <div className="col-4">
          <label>Product Code</label>
          <input value={values.code} className="lf-form-control" readOnly />
        </div>

        <div className="col-4">
          <label>Price</label>
          <input value={values.price.toFixed(2)} className="lf-form-control" readOnly />
        </div>

        <div className="col-4">
          <label>Discount</label>
          <input ref={this.inputDiscount} defaultValue={values.discount.toFixed(2)} className="lf-form-control" />
        </div>
      </div>
    );
  };

  createBarcodeMessage = (data?: Graph.Query, productData?: Graph.Query): BarcodePrintingMessage | undefined => {
    if (data && data.sku && productData && productData.product) {
      return {
        skuId: (data!.sku!.id || '0').toString(),
        barcode: data!.sku!.barcode!,
        productID: data!.sku!.productID!,
        productCode: productData!.product!.code!,
        color: data!.sku!.color!,
        size: data!.sku!.size!,
        price: productData!.product!.price!,
        discount: this.inputDiscount.current ? Number(this.inputDiscount.current.value) : 0,
      };
    }
  };

  renderSKU = (loading: boolean, data?: Graph.Query, productData?: Graph.Query) => {
    let values = {
      color: 'Loading ...',
      size: 'Loading ...',
      barcode: 'Loading ...',
      barcodeOriginal: 'Loading ...',
    };

    if (!loading && data && data.sku) {
      values = {
        ...values,
        ...(data.sku as any),
      };
    }

    // Render
    return (
      <div>
        <div className="form-group row">
          <div className="col-6">
            <label>Color</label>
            <input value={values.color} className="lf-form-control" readOnly />
          </div>
          <div className="col-6">
            <label>Size</label>
            <input value={values.size} className="lf-form-control" readOnly />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-6">
            <label>Barcode</label>
            <input value={values.barcode} className="lf-form-control" readOnly />
          </div>
          <div className="col-6">
            <label>Original Barcode</label>
            <input ref={this.inputOriginalBarcode} defaultValue={values.barcodeOriginal} className="lf-form-control" />
          </div>
        </div>

        <div className="form-buttons-w">
          <div className="mb-2">
            <Checkbox
              label="Print without price"
              checked={this.state.isPrintingWithoutPrice}
              onChange={(e): void => this.setState({ isPrintingWithoutPrice: e.target.checked })}
            />
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Printing Counter</div>
            </div>
            <input type="text" className="form-control" readOnly value={this.state.printCounter} />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.print(this.createBarcodeMessage(data, productData));
                }}
              >
                Print Barcode
              </button>
            </div>
            <div className="input-group-append">
              <button
                className="btn btn-warning"
                onClick={async () => {
                  const qty = prompt('How many barcode you want to print?');
                  if (qty === null) return;
                  await this.printMany(this.createBarcodeMessage(data, productData), Number(qty));
                }}
              >
                Print Many
              </button>
            </div>
          </div>
        </div>

        <div className="form-buttons-w text-right">
          <Link to={`/barcode/create?product=${productData!.product!.code!}`} className="btn btn-outline-primary">
            Create Same Product Barcode
          </Link>
          <ApolloConsumer>
            {client => (
              <button
                className="btn btn-primary"
                onClick={async () => await this.update(client, productData!.product!.id!)}
              >
                Update
              </button>
            )}
          </ApolloConsumer>
        </div>
      </div>
    );
  };

  update = async (client: ApolloClient<any>, productID: number) => {
    this.setState({ isUpdating: true });

    if (this.inputDiscount.current) {
      if (this.inputDiscount.current.defaultValue !== this.inputDiscount.current.value) {
        await client.mutate({
          mutation: MUTATION_UPDATE_PRODUCT,
          variables: { id: productID, data: { discount: Number(this.inputDiscount.current.value) } },
        });
      }
    }

    if (this.inputOriginalBarcode.current) {
      if (this.inputOriginalBarcode.current.defaultValue !== this.inputOriginalBarcode.current.value) {
        await client.mutate({
          mutation: MUTATION_UPDATE_BARCODE,
          variables: {
            id: Number(this.props.match.params.id),
            data: { barcodeOriginal: this.inputOriginalBarcode.current.value },
          },
        });
      }
    }

    this.setState({ isUpdating: false, isSuccess: true });
  };

  renderAll = (skuLoading: boolean, productLoading: boolean, skuData?: Graph.Query, productData?: Graph.Query) => {
    if (productData === undefined || productData!.product === undefined) return <div></div>;
    return (
      <div className="bg-white" style={{ display: 'flex', maxWidth: 1200 }}>
        {this.renderImage(productLoading, productData)}
        <div className="p-3 ml-2" style={{ minWidth: 450 }}>
          {this.renderUpdatingAlert()}
          {this.renderProduct(productLoading, productData)}
          <hr />
          {this.renderSKU(skuLoading, skuData, productData)}
        </div>
        <div className="p-3 ml-2 table-responsive">
          <label>
            <strong>Quick Navigation</strong>
          </label>
          <div className="mt-2" style={{ overflowY: 'auto', maxHeight: 400 }}>
            {this.renderSkuList(skuData!, productData!)}
          </div>
        </div>
      </div>
    );
  };

  renderSkuList = (skuData: Graph.Query, products: Graph.Query) => {
    return (
      <Query
        query={QUERY_SKU_LIST}
        fetchPolicy={'network-only'}
        variables={({ productID: Number(products!.product!.id) } as unknown) as OperationVariables}
      >
        {({ loading, data }: QueryResult) => {
          if (loading || data === undefined) return <div>Loading...</div>;
          return (
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Barcode</th>
                  <th>Color</th>
                  <th>Size</th>
                </tr>
              </thead>
              <tbody>
                {data.skuList!.map((x: any) => (
                  <tr key={x.id} style={{ textTransform: 'capitalize' }}>
                    <td>
                      <Link
                        to={
                          x.barcode === ''
                            ? `/barcode/create?product=${products!.product!.code}&color=${x.color}&size=${x.size}`
                            : `/sku/${x.id}`
                        }
                      >
                        {x.barcode === '' ? 'Create' : x.barcode}
                      </Link>
                    </td>
                    <td>{x.color}</td>
                    <td style={{ textTransform: 'uppercase' }}>{x.size}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }}
      </Query>
    );
  };
}
