import { useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { AppPageMarkup } from '../../../types/common';
import Button from 'src/components/layout/xform/Button';
import { formatsQuill, modulesQuill } from './quillStyle';
import ReactQuill from 'react-quill';

interface Props {
  value: AppPageMarkup;
  onChange: (v: AppPageMarkup) => void;
}

export function AppPageMarkupTextLink(props: Props) {
  const [isHtmlCode, setIsHtmlCode] = useState(false);

  return (
    <div>
      <div className="form-group">
        <label>Title</label>
        <input
          value={props.value?.title || ''}
          className="lf-form-control"
          placeholder="Please write block title"
          onChange={e => {
            props.onChange({ ...props.value, title: e.target.value } as AppPageMarkup);
          }}
        />
      </div>
      <div style={{ padding: '1rem 0' }}>
        <Button onClick={() => setIsHtmlCode(!isHtmlCode)} style={{ textAlign: 'center' }}>
          Switch
        </Button>
      </div>
      <div style={{ display: 'flex', position: 'relative' }}>
        {!isHtmlCode ? (
          <>
            <ReactQuill
              style={{ height: '500px', width: '900px' }}
              value={props?.value?.code}
              onChange={e => {
                if (props.onChange) {
                  props.onChange({ ...props.value, code: e });
                }
              }}
              modules={modulesQuill}
              formats={formatsQuill}
            />
          </>
        ) : (
          <>
            <div style={{ width: '900px' }}>
              <CodeEditor
                // language="html"
                value={props?.value?.code}
                placeholder="Please enter html code."
                onChange={e => {
                  if (props.onChange) {
                    props.onChange({ ...props.value, code: e.currentTarget.value });
                  }
                }}
                padding={15}
                minHeight={500}
                style={{
                  fontSize: 12,
                  backgroundColor: '#f5f5f5',
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
