import React, { useEffect, useRef, useState } from 'react';

interface Props {
  isVertical?: boolean;
  marginRight?: string;
  marginTop?: string;
}
export default function MoreAction({ isVertical, marginRight, marginTop, children }: React.PropsWithChildren<Props>) {
  const [show, setShow] = useState(false);
  const wrappedRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onDocumentClicked = (e: MouseEvent) => {
      if (wrappedRef.current && !wrappedRef.current.contains(e.target as Node)) {
        setShow(false);
      }
    };

    document.addEventListener('click', onDocumentClicked);
    return () => document.removeEventListener('click', onDocumentClicked);
  });

  return (
    <div ref={wrappedRef} className={show ? 'btn-group dropup show' : 'btn-group dropup'}>
      <button
        type="button"
        className="btn btn-white"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setShow(!show)}
        style={{ backgroundColor: 'f0f2f5', borderRadius: '50%' }}
      >
        {isVertical ? (
          <i className="fas fa-ellipsis-v" style={{ fontSize: 18 }} />
        ) : (
          <i className="fas fa-ellipsis-h" />
        )}
      </button>
      <div
        className={show ? 'dropdown-menu show' : 'dropdown-menu'}
        onClick={() => setShow(false)}
        style={{ marginRight, marginTop }}
      >
        {children}
      </div>
    </div>
  );
}

MoreAction.Button = function MoreActionButton(props: React.PropsWithChildren<{ onClick?: () => unknown }>) {
  return (
    <button className="dropdown-item" type="button" onClick={props.onClick}>
      {props.children}
    </button>
  );
};

MoreAction.Link = function MoreLinkButton(props: React.PropsWithChildren<{ href: string; target?: string }>) {
  return (
    <a className="dropdown-item" href={props.href} target={props.target}>
      {props.children}
    </a>
  );
};
