import React, { useState, ReactElement, ChangeEvent } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { DropdownBaseComponent } from './../DropdownBaseComponent';
import { DropdownPreview } from './../DropboxComponent';
import { HighlightText } from './../HighlightText';
import { DropdownShadow } from '../DropdownShadow';

const QUERY = gql`
  query {
    customPageList {
      id
      name
      priority
    }
  }
`;

interface Props {
  value: number;
  placeholder: string;
  onChanged: (v: number) => void;
}

export function CustomPagePicker(props: Props): React.ReactElement {
  const { data } = useQuery<Graph.Query>(QUERY);
  const [toggle, setToggle] = useState(false);
  const [search, setSearch] = useState('');

  if (!data?.customPageList) {
    return (
      <div className="lf-form-control bg-loading">
        <span>Loading</span>
      </div>
    );
  }

  const customPageList = data.customPageList;

  function preview(data: Graph.CustomPage[]): ReactElement {
    const item = data.find(x => x.id === props.value);
    const caption = item ? item.name : props.placeholder;

    return (
      <DropdownPreview>
        {item && item.priority !== 0 && (
          <span style={{ display: 'inline-block', width: 30 }}>
            {item.priority === 2 && <i className="fas fa-star" style={{ color: '#f1c40f' }}></i>}
            {item.priority === 1 && <i className="fas fa-star" style={{ color: '#95a5a6' }}></i>}
          </span>
        )}
        <span>{caption}</span>
      </DropdownPreview>
    );
  }

  function dropdown(data: Graph.CustomPage[]): ReactElement {
    return (
      <DropdownShadow>
        <div className="element-search autosuggest-search-activator">
          <input
            placeholder="Start typing to search..."
            type="search"
            autoFocus
            name="search"
            autoComplete={'off'}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              setSearch(e.target.value);
            }}
          />
        </div>

        <ul className="dropdown-container mt-3" style={{ width: 500, maxHeight: 400, overflowY: 'auto', right: 0 }}>
          {data.map(x => (
            <li
              key={x.id?.toString()}
              onClick={(): void => {
                setToggle(false);
                if (props.onChanged) props.onChanged(x.id as number);
              }}
            >
              <span style={{ display: 'inline-block', width: 30 }}>
                {x.priority === 2 && <i className="fas fa-star" style={{ color: '#f1c40f' }}></i>}
                {x.priority === 1 && <i className="fas fa-star" style={{ color: '#95a5a6' }}></i>}
              </span>
              <HighlightText text={x.name ? x.name : ''} highlight={search} />
            </li>
          ))}
        </ul>
      </DropdownShadow>
    );
  }

  return (
    <DropdownBaseComponent
      dropdownHeight={400}
      toggle={toggle}
      onToggleChanged={(t): void => {
        setToggle(t);
      }}
      preview={(): ReactElement => preview(customPageList)}
      dropdown={(): ReactElement =>
        dropdown(
          customPageList.filter(x => {
            if (!x.name) return false;
            return (
              x.name
                .toString()
                .toLowerCase()
                .indexOf(search.toString().toLowerCase()) !== -1
            );
          }),
        )
      }
    />
  );
}
