import React from 'react';
import Layout from 'src/components/layout/Layout';
import { Tab } from 'src/components/layout/tabs/Tab';
import LoginlessOrderPendingScreen from './LoginlessOrderPendingScreen';
import { LoginlessOrderRejectedScreen } from './LoginlessOrderRejectedScreen';
import { LoginlessOrderApprovedScreen } from './LoginlessOrderApprovedScreen';

export function LoginlessOrderScreen() {
  return (
    <Layout>
      <Layout.Header>Manage Loginless Order</Layout.Header>

      <Tab>
        <Tab.Item title="Pending" alias="#pending">
          <LoginlessOrderPendingScreen />
        </Tab.Item>
        <Tab.Item title="Approved" alias="#approved">
          <LoginlessOrderApprovedScreen />
        </Tab.Item>
        <Tab.Item title="Rejected" alias="#rejected">
          <LoginlessOrderRejectedScreen />
        </Tab.Item>
      </Tab>
    </Layout>
  );
}
