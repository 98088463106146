import { Graph } from '../../../generated/graph';
import { UploadInlineFile } from '../../../components/UploadInlineFile';
import { useDefaultValue } from '../../../libs/Common';

interface Props {
  value: Graph.PurchaseOrderInput;
  onChange: (v: Partial<Graph.PurchaseOrderInput>) => void;
}

export function PurchaseOrderShippingPartial({ value, onChange }: Props) {
  return (
    <fieldset>
      <legend>
        <span>Before Shipping</span>
      </legend>

      <div className="row">
        <div className="col-3">
          <div className="form-group">
            <label>Shipping Unit</label>
            <input
              className="lf-form-control"
              type="text"
              defaultValue={useDefaultValue(value.shippingQty?.toString(), '0')}
              onChange={e => onChange({ ...value, shippingQty: Number(e.currentTarget.value) })}
            />
          </div>
        </div>

        <div className="col-3">
          <div className="form-group">
            <label>Product Net Value</label>
            <input
              className="lf-form-control"
              type="number"
              defaultValue={useDefaultValue(value.shippingAmount, '0')}
            />
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label>Invoice Value</label>
            <div className="lf-input-group">
              <input
                className="lf-form-control"
                type="number"
                value={useDefaultValue(value.invoiceAmount, '0')}
                onChange={e => onChange({ ...value, invoiceAmount: e.currentTarget.value })}
              />
              <select
                className="lf-form-control"
                value={useDefaultValue(value.shippingAmountCurrency, 'USD')}
                onChange={e => onChange({ ...value, shippingAmountCurrency: e.currentTarget.value as Graph.Currency })}
              >
                <option value="USD">USD</option>
                <option value="RMB">RMB</option>
                <option value="DONG">DONG</option>
                <option value="BAHT">BAHT</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>Supplier File</label>
            <UploadInlineFile
              value={useDefaultValue(value.supplierFile, undefined)}
              onChange={e => onChange({ ...value, supplierFile: e })}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="row">
            <div className="col-6 form-group">
              <label>Invoice #</label>
              <div className="lf-input-group">
                <input
                  className="lf-form-control"
                  type="string"
                  value={useDefaultValue(value.invoiceNumber, '')}
                  onChange={e => onChange({ ...value, invoiceNumber: e.currentTarget.value })}
                />
              </div>
            </div>
            <div className="col-6 form-group">
              <label>Invoice</label>
              <UploadInlineFile
                value={useDefaultValue(value.invoiceFile, undefined)}
                onChange={e => onChange({ ...value, invoiceFile: e })}
              />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
}
