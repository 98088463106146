import React from 'react';
import styles from '../style.module.scss';
interface Props {
  toggle: boolean;
  setPopular: (popular: string) => void;
  onToggle: () => void;
  popular: string;
  qs: {
    sort: string;
  };
}

export default function ProductSort(props: Props) {
  return (
    <div className={styles.btn_Sort}>
      <div className={styles.drpSort}>
        <p onClick={() => props.onToggle()}>
          {props.popular === 'MOST_EXPENSIVE' ? 'Expensive' : props.popular?.toLowerCase()}
          {''}
          {props?.toggle ? (
            <img src="/icons/drpUp.svg" alt="" width={10} />
          ) : (
            <img src="/icons/drpDown.svg" alt="" width={10} />
          )}
        </p>
        {props.toggle && (
          <div className={styles.drpSort_Layer} onClick={props?.onToggle}>
            <div className={styles.drpSort_Layer_inner}>
              <ul>
                <li
                  id="POPULAR"
                  onClick={e => props?.setPopular(e.currentTarget.id.toString())}
                  className={props.qs.sort === 'POPULAR' ? styles.active : ''}
                >
                  <p>Popular</p>
                </li>
                <li
                  id="LATEST"
                  className={props.qs.sort === 'LATEST' ? styles.active : ''}
                  onClick={e => props?.setPopular(e.currentTarget.id.toString())}
                >
                  <p>Latest</p>
                </li>
                <li
                  id="CHEAPEST"
                  className={props.qs.sort === 'CHEAPEST' ? styles.active : ''}
                  onClick={e => props?.setPopular(e.currentTarget.id.toString())}
                >
                  <p>Cheapest</p>
                </li>
                <li
                  id="MOST_EXPENSIVE"
                  className={props.qs.sort === 'MOST_EXPENSIVE' ? styles.active : ''}
                  onClick={e => props?.setPopular(e.currentTarget.id.toString())}
                >
                  <p>Expensive</p>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
