import React, { useState } from 'react';
import { Icon, Page } from '@shopify/polaris';
import { PolarisCard } from 'src/components/polaris/PolarisCard';
import { PolarisCategory } from 'src/components/polaris/PolarisCategory';

export function PlaygroundScreen() {
  const [c, setC] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const icons = require('@shopify/polaris-icons');
  return (
    <Page>
      <PolarisCard>
        <PolarisCard.Section>
          <PolarisCategory value={c} onChange={setC} />
        </PolarisCard.Section>
        <PolarisCard.Section>
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto' }}>
              {Object.keys(icons).map(x => {
                return (
                  <div
                    key={x}
                    style={{
                      display: 'flex',
                      borderStyle: 'solid',
                      borderColor: 'gray',
                      borderBottomWidth: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <Icon source={icons[x]} color="base" />
                    </div>
                    <div>{x}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </PolarisCard.Section>
      </PolarisCard>
    </Page>
  );
}
