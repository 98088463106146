import React from 'react';
import { Link } from 'react-router-dom';

export function PackageQuickNavigation() {
  return (
    <nav className="quick">
      <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
        <li>
          <Link to="/package/scanin">
            <i className="far fa-barcode-alt"></i>
            <span>Scan In</span>
          </Link>
        </li>
        <li>
          <Link to="/package/scanout">
            <i className="far fa-barcode-alt"></i>Scan Out
          </Link>
        </li>
        <li>
          <Link to="/package/scan">
            <i className="fal fa-barcode-scan"></i>Scan Package
          </Link>
        </li>
        <li>
          <Link to="/package/progress">
            <i className="fas fa-truck-loading"></i>Tracking
          </Link>
        </li>
        <li>
          <Link to="/package/walkIn">
            <i className="fas fa-shopping-basket"></i>Package walk in
          </Link>
        </li>
        <li>
          <Link to="/package/unsettle">
            <i className="fas fa-money-check-edit-alt"></i>COD to Settle
          </Link>
        </li>
        <li>
          <Link to="/package/settlements">
            <i className="fas fa-money-check-alt"></i>Settlement Log
          </Link>
        </li>
        <li>
          <Link to="/package/success">
            <i className="fas fa-book"></i>Delivery History
          </Link>
        </li>
      </ul>
    </nav>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withPackageNavigation<T>(Component: React.ComponentType<any>) {
  return function HOC(props: T) {
    return (
      <>
        <PackageQuickNavigation />
        <Component {...props} />
      </>
    );
  };
}
