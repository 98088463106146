/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { useLazyQueryProductList } from '../Filter/useQueryProductList';
import { useMutationMassUpdateProduct } from './useMutationMassUpdateProduct';

interface Props {
  itemIds: number[];
  open: boolean;
  onClose: (open: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterValue: any;
  total: number;
}

export function RenewPublish(props: Props) {
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(0);
  const [query, { loading }] = useLazyQueryProductList({
    variables: {},
    type: 'ID',
  });
  const [massUpdateProduct, { loading: loadingUpdate }] = useMutationMassUpdateProduct();

  const handleUpdateSelected = useCallback(() => {
    massUpdateProduct({
      variables: {
        massUpdateProductId: props.itemIds.map((x: any) => Number(x)),
        data: {
          renewPublishDate: true,
        },
      },
    }).then(res => {
      if (res.data?.massUpdateProduct) {
        alert('Renew publish date was success');
      }
    });
  }, [massUpdateProduct, props]);

  const handleUpdateAll = useCallback(async () => {
    const res = await query({
      variables: {
        ...props.filterValue,
        page: {
          offset: 0,
          limit: props.total,
        },
      },
    });
    sleep(1);
    if (res.data) {
      const arr = [...(res.data.productListV2?.data || [])];
      await setTotal(Math.ceil((res.data.productListV2?.data || []).length / 100));
      sleep(1);
      if (arr.length > 0) {
        while (arr.length) {
          sleep(1000);
          const update = await massUpdateProduct({
            variables: {
              massUpdateProductId: arr.splice(0, 100).map(x => Number(x.id)),
              data: {
                renewPublishDate: true,
              },
            },
          });
          if (!loadingUpdate || update.data?.massUpdateProduct) {
            sleep(1500);
            await setSuccess(success => success + 1);
          }
        }
      }
    }
  }, [total, success, query, massUpdateProduct, props]);

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={() => {
          if (!loading || !loadingUpdate) {
            setTotal(0);
            setSuccess(0);
            props.onClose(false);
          }
        }}
        title={`Renew publish products`}
      >
        <Modal.Section>
          <p>
            It will be renew publish date of product. <strong>please click button which one you want</strong>.
          </p>
        </Modal.Section>
        <Modal.Section>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {total > 0 && (
                <small>
                  <strong>
                    {success}/{total} batch
                  </strong>
                </small>
              )}
            </div>
            <div>
              {props.itemIds.length !== props.total && (
                <span className="mr-2">
                  <Button
                    disabled={loading || loadingUpdate}
                    loading={loading || loadingUpdate}
                    size="slim"
                    onClick={handleUpdateSelected}
                  >
                    Selected ({props.itemIds.length + ''})
                  </Button>
                </span>
              )}
              <Button
                size="slim"
                primary
                onClick={handleUpdateAll}
                disabled={loading || loadingUpdate}
                loading={loading || loadingUpdate}
              >
                All filter products ({props.total + ''}).
              </Button>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
}
