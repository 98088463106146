import { Graph } from '../../../generated/graph';
import { UploadInlineFile } from '../../../components/UploadInlineFile';
import { useDefaultValue } from '../../../libs/Common';

interface Props {
  value: Graph.PurchaseOrderInput;
  onChange: (v: Partial<Graph.PurchaseOrderInput>) => void;
}

export function PurchaseOrderPaymentPartial({ value, onChange }: Props) {
  return (
    <fieldset>
      <legend>
        <span>Payment</span>
      </legend>
      <div>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label>Payment Method</label>
              <select
                value={useDefaultValue(value.paymentMethod, 'NONE')}
                onChange={e => onChange({ ...value, paymentMethod: e.currentTarget.value as Graph.PaymentMethod })}
                className="lf-form-control"
              >
                <option value="NONE">----</option>
                <option value="CASH">Cash</option>
                <option value="MONEY_TRANSFER">Money Transfer</option>
                <option value="TRADE_RECEIVABLE">Trade Receivable</option>
                <option value="BANK_TRANSFER">Bank Transfer</option>
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Invoice Date</label>
              <input
                className="lf-form-control"
                type="date"
                value={useDefaultValue(value.invoiceAt, '')}
                onChange={e => onChange({ ...value, invoiceAt: e.currentTarget.value })}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Exchange Rate</label>
              <input
                className="lf-form-control"
                type="number"
                defaultValue={useDefaultValue(value.exchangeRate, '0')}
                onChange={e => onChange({ ...value, exchangeRate: e.currentTarget.value })}
              />
            </div>
          </div>

          <div className="col-3">
            <div className="form-group">
              <label>Payment Proof</label>
              <UploadInlineFile
                value={value.paymentFile ?? undefined}
                onChange={e => onChange({ ...value, paymentFile: e })}
              />
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  );
}
