/* eslint-disable */
import React from 'react';
import { purchaseOrderCostReader } from '../../libs/PurchaseOrderCostReader';
import { MutationResult, gql } from '@apollo/client';
import { Mutation } from '@apollo/react-components';
import { Graph } from '../../generated/graph';
import { Permission } from '../../components/Permission';

const MUTATION = gql`
  mutation UpdatePurchaseCostTable($id: Int!, $order: PurchaseOrderInput!) {
    updatePurchaseOrder(id: $id, order: $order)
  }
`;

interface Props extends React.Props<{}> {
  purchaseOrder: Graph.PurchaseOrder;
}

let costTable: any = [];

export class PurchaseOrderCost extends React.Component<Props> {
  state: {
    mode: 'PREVIEW' | 'EDITOR';
    costs: string;
    formated: boolean;
    exchangeRateBias: number;
    validExchangeRate: boolean;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      mode: 'PREVIEW',
      costs: props.purchaseOrder.orderDetail as string,
      formated: true,
      exchangeRateBias: this.props.purchaseOrder.orderAmountCurrency === 'DONG' ? 1000 : 1,
      validExchangeRate:
        this.props.purchaseOrder.exchangeRate !== undefined &&
        this.props.purchaseOrder.exchangeRate !== null &&
        Number(this.props.purchaseOrder.exchangeRate) >= 1,
    };
  }

  onChangeEditor = (e: any) => {
    this.setState({ costs: e.target.value });
    costTable = purchaseOrderCostReader(
      e.target.value,
      Number(this.props.purchaseOrder.exchangeRate) / this.state.exchangeRateBias,
    );
    this.loopCheckFormat();
  };

  componentDidMount() {
    costTable = purchaseOrderCostReader(
      this.state.costs,
      Number(this.props.purchaseOrder.exchangeRate) / this.state.exchangeRateBias,
    );
    this.loopCheckFormat();
  }

  loopCheckFormat() {
    for (const table of costTable) {
      if (table.complete === false) {
        return this.setState({ formated: false });
      }
    }
    this.setState({ formated: true });
  }

  render() {
    let warningDiv = <div></div>;

    if (!this.state.validExchangeRate) {
      warningDiv = (
        <div className="alert alert-danger" role="alert">
          You need to input <strong>Exchange Rate</strong> to update the cost.
        </div>
      );
    } else if (!this.state.formated) {
      warningDiv = (
        <div className="alert alert-danger" role="alert">
          Your cost sheet is not correct!
        </div>
      );
    }

    return (
      <div>
        <div className="controls-above-table">
          <div className="text-right">
            <button
              className={this.state.mode === 'PREVIEW' ? 'btn btn-primary' : 'btn btn-white'}
              onClick={() => this.setState({ mode: 'PREVIEW' })}
            >
              Preview
            </button>
            <button
              className={this.state.mode === 'EDITOR' ? 'btn btn-primary' : 'btn btn-white'}
              onClick={() => this.setState({ mode: 'EDITOR' })}
            >
              Editor
            </button>
          </div>
        </div>
        {warningDiv}
        <div className="table-responsive">
          {this.state.mode === 'EDITOR' && this.renderEditor()}
          {this.state.mode === 'PREVIEW' && this.renderPreview()}
        </div>
        <Mutation
          mutation={MUTATION}
          onCompleted={() => {
            window.location.reload();
          }}
        >
          {this.renderMutation}
        </Mutation>
      </div>
    );
  }

  renderEditor = () => {
    return (
      <div>
        <textarea
          value={this.state.costs}
          onChange={this.onChangeEditor}
          style={{
            width: '100%',
            height: '400px',
          }}
        />
        <div className="help-block form-text text-muted form-control-feedback">
          {this.state.formated ? '' : <span className="text-danger">Editor input not complete infomation.</span>} <br />
          Required two columns (<code>Product ID</code>, <code>Cost</code>).
          <br />
          You can use space, tab or comma as column separator.
        </div>
      </div>
    );
  };

  renderPreview = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Product ID</th>
            <th>Cost</th>
            <th>Price Description</th>
          </tr>
        </thead>

        <tbody>
          {costTable.map((row: any) => {
            return (
              <tr key={row.id} className={row.complete ? '' : 'bg-danger text-light'}>
                <td>
                  <a
                    href={`https://www.l192.com/product/${row.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: row.complete ? '' : '#fff' }}
                  >
                    {row.id}
                  </a>
                </td>
                <td>$ {row.price}</td>
                <td>{row.priceDescription}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  renderMutation = (update: any, { data, loading, error }: MutationResult) => {
    const variables = {
      id: this.props.purchaseOrder.id,
      order: { orderDetail: this.state.costs },
    };

    return (
      <div className="form-buttons-w">
        <button
          onClick={() => {
            if (this.state.formated) {
              update({ variables });
            }
          }}
          disabled={loading || !this.state.validExchangeRate || !this.state.formated}
          className="btn btn-lg btn-primary"
        >
          Update Cost List
        </button>
        <Permission required="purchase_order_override_cost" silence={true}>
          <button
            className="btn btn-lg btn-danger"
            onClick={() => {
              const generated = (Math.random() * 100000) | 0;
              const msg = prompt(
                'There might make a mistake. If you are sure you want to update, please type the following code: ' +
                  generated,
              );
              if (generated.toString() === msg) {
                update({ variables });
              }
            }}
          >
            Update (Override)
          </button>
        </Permission>
      </div>
    );
  };
}
