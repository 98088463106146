import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';

let previousWsClient: SubscriptionClient | null = null;

export default function createApolloClient(token: string | null): ApolloClient<unknown> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let uri: string = (window as any).setting.endpoint as string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ws: string = (window as any).setting.ws as string;
  const uri_origin = uri;

  if (token !== null && token.length > 5) {
    uri += `?token=${token}`;
  }

  const uploadLink = createUploadLink({
    uri: uri,
  });

  const batchLink = new BatchHttpLink({
    uri: uri,
  });

  if (previousWsClient) {
    previousWsClient.close(true, true);
  }

  const wsClient = new SubscriptionClient(
    ws ? ws : uri_origin.replace('https://', 'wss://').replace('http://', 'ws://') + '/graphql',
    {
      reconnect: true,
      connectionParams: {
        authToken: token,
      },
    },
  );

  previousWsClient = wsClient;

  const wsLink = new WebSocketLink(wsClient);

  const httpLinkSplit = split(
    operation => {
      return operation.operationName === 'Upload';
    },
    uploadLink,
    batchLink,
  );

  const allLinkSplit = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLinkSplit,
  );

  const client = new ApolloClient<unknown>({
    link: allLinkSplit,
    name: 'l192-spa',
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return client;
}
