import { TextField, TextFieldProps } from '@shopify/polaris';
import React, { useEffect } from 'react';

// Polaris-TextField
interface Props {
  height?: number | string;
  fontSize?: number | string;
}

export function PolarisTextField(props: TextFieldProps & Props) {
  useEffect(() => {
    if (props.height) {
      const doc = document.getElementsByClassName('Polaris-TextField');
      for (const x of doc) {
        x.setAttribute('style', `height: ${isNaN(Number(props.height)) ? props.height : props.height + 'px'}`);
      }

      return () => {
        for (const x of doc) {
          x.removeAttribute('style');
        }
      };
    }
  }, [props.height]);

  useEffect(() => {
    if (props.fontSize) {
      const doc = document.getElementsByClassName('Polaris-TextField');

      for (const x of doc) {
        x.children[1].setAttribute(
          'style',
          `font-size: ${isNaN(Number(props.fontSize)) ? props.fontSize : props.fontSize + 'px'}`,
        );
      }

      return () => {
        for (const x of doc) {
          x.children[1].removeAttribute('style');
        }
      };
    }
  }, [props.fontSize]);

  return <TextField {...props} />;
}
