import React, { useState } from 'react';
import styles from './search.module.scss';
interface SelectedType {
  text: string;
  value: string;
}
interface Props {
  type: { text: string; value: string }[];
  drpSelect: SelectedType;
  searchTxt: string;
  onChangeType: (drpSelected: SelectedType) => void;
  onChangeSearch: (txt: string) => void;
  loading?: boolean;
}
export default function PackageSearchScreen(props: Props) {
  const [isShow, setIsshow] = useState(false);

  return (
    <div className={styles.package_search_wrap}>
      <div className={styles.package_search_wrap_inner}>
        <div className={styles.package_type_selected}>
          <div className={styles.package_type_selected_txt} onClick={() => setIsshow(!isShow)}>
            <span>{props.drpSelect?.text} &nbsp;</span>
            <i className="fas fa-caret-down"></i>
          </div>
          {isShow && (
            <ul className={styles.drpdwn}>
              {props?.type?.map((x, i) => (
                <li
                  key={i}
                  onClick={() => {
                    // setDrpSelected({ text: x?.text, value: x?.value });
                    props?.onChangeType({ text: x?.text, value: x?.value });
                    setIsshow(!isShow);
                  }}
                >
                  {x.text}
                </li>
              ))}
            </ul>
          )}
        </div>
        <input type="search" placeholder={'Search...'} onChange={e => props?.onChangeSearch(e.currentTarget.value)} />
        {!!props?.loading && (
          <span className={styles.loading}>
            <i className="fas fa-spinner fa-spin"></i>
          </span>
        )}
        {/* <button>Search</button> */}
      </div>
    </div>
  );
}
