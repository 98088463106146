/* eslint-disable @typescript-eslint/no-explicit-any */
import { Popover, Button, ChoiceList, Divider } from '@shopify/polaris';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { PolarisTextField } from 'src/components/polaris/PolarisTextField';
import { Graph } from 'src/generated/graph';

interface Props {
  sales: Graph.SalesFilter;
  setSale: (v: Graph.SalesFilter) => void;
}

export function FilterSale(props: Props) {
  const [last, setLast] = useState('3');
  const [type, setType] = useState('MM');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDataSale = useCallback(
    (endDate?: string, t?: string) => {
      const date = endDate ? new Date(endDate) : props.sales.endDate ? new Date(props.sales.endDate) : undefined;
      if (date) {
        const lastdate = new Date(date);
        const f = t ?? type;

        if (f === 'DD') {
          lastdate.setDate(lastdate.getDate() - Number(last));
        }

        if (f === 'MM') {
          lastdate.setMonth(lastdate.getMonth() - Number(last));
        }

        if (f === 'YY') {
          lastdate.setFullYear(lastdate.getFullYear() - Number(last));
        }

        props.setSale({
          ...props.sales,
          startDate: moment(lastdate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        });

        setLoading(false);
      }
    },
    [props, type, last],
  );

  useEffect(() => {
    getDataSale(new Date() + '', type);
  }, []);

  const handleChangeSaleStartDate = useCallback(
    v => {
      props.setSale({
        ...props.sales,
        startDate: moment(new Date(v)).format('YYYY-MM-DD'),
      });
    },
    [props],
  );

  const handleChangeSaleEndDate = useCallback(
    v => {
      props.setSale({
        ...props.sales,
        endDate: moment(new Date(v)).format('YYYY-MM-DD'),
      });
      setLoading(true);
      getDataSale(moment(new Date(v)).format('YYYY-MM-DD'));
    },
    [props, getDataSale],
  );

  const handleChangeType = useCallback(
    v => {
      setType(v[0]);
      setLoading(true);
      setTimeout(() => {
        getDataSale(props.sales.endDate + '', v[0]);
      }, 500);
    },
    [getDataSale],
  );

  const handleChangeLast = useCallback(
    v => {
      setLast(v);
    },
    [last],
  );

  const handleChangeGTESale = useCallback(
    v => {
      props.setSale({
        ...props.sales,
        saleAmountGte: String(v),
      });
    },
    [props],
  );

  const handleChangeLTESale = useCallback(
    v => {
      props.setSale({
        ...props.sales,
        saleAmountLte: String(v),
      });
    },
    [props],
  );

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="w-[250px]">
          <PolarisTextField
            value={String(props.sales?.startDate || '')}
            onChange={handleChangeSaleStartDate}
            autoComplete="off"
            label
            labelHidden
            prefix="Start"
            height={'30px'}
            align="right"
            type="date"
            onFocus={(event: any) => event?.target.select()}
            disabled={loading}
          />
          <br />
          <PolarisTextField
            value={String(props.sales?.endDate || '')}
            onChange={handleChangeSaleEndDate}
            autoComplete="off"
            label
            labelHidden
            prefix="End"
            height={'30px'}
            align="right"
            type="date"
            onFocus={(event: any) => event?.target.select()}
            disabled={loading}
          />
        </div>
        <div
          className="ml-2 mr-2 w-[150px]"
          onKeyDown={e => {
            if (e.keyCode === 13) {
              setLoading(true);
              getDataSale();
            }
          }}
        >
          <PolarisTextField
            value={last}
            onChange={handleChangeLast}
            autoComplete="off"
            label
            labelHidden
            prefix="Last"
            height={'30px'}
            align="right"
            type="number"
            onFocus={(event: any) => event?.target.select()}
            helpText="Press enter to update date"
            disabled={loading}
          />
        </div>
        <div>
          <Popover
            active={open}
            onClose={() => setOpen(!open)}
            activator={
              <Button disclosure size="slim" disabled={loading} onClick={() => setOpen(!open)}>
                {type === 'DD' ? 'Day' : type === 'MM' ? 'Month' : 'Year'}
              </Button>
            }
          >
            <Popover.Section>
              <ChoiceList
                choices={[
                  { label: 'Day', value: 'DD' },
                  { label: 'Month', value: 'MM' },
                  { label: 'Year', value: 'YY' },
                ]}
                selected={[type]}
                title
                titleHidden
                onChange={handleChangeType}
              />
            </Popover.Section>
          </Popover>
        </div>
      </div>
      <br />
      <Divider borderColor="border" borderWidth="1" />
      <br />
      <div>
        <PolarisTextField
          value={String(props.sales?.saleAmountGte || 0)}
          onChange={handleChangeGTESale}
          autoComplete="off"
          label
          labelHidden
          prefix="GTE"
          height={'30px'}
          align="right"
          suffix="USD"
          type="number"
          onFocus={(event: any) => event?.target.select()}
          disabled={loading}
        />
        <br />
        <PolarisTextField
          value={String(props.sales?.saleAmountLte || 0)}
          onChange={handleChangeLTESale}
          autoComplete="off"
          label
          labelHidden
          prefix="LTE"
          height={'30px'}
          align="right"
          suffix="USD"
          type="number"
          onFocus={(event: any) => event?.target.select()}
          disabled={loading}
        />
      </div>
    </div>
  );
}
