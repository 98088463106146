import React from 'react';
import { AppPageMarkup } from '../../../types/common';

interface Props {
  value?: AppPageMarkup;
  onChange: (v: AppPageMarkup) => void;
}

export function AppPageMarkupSponsored(props: Props) {
  return (
    <div>
      <div className="form-group">
        <label>Title</label>
        <input
          value={props.value?.title || ''}
          className="lf-form-control"
          placeholder="Please write block title"
          onChange={e => {
            props.onChange({ ...props.value, title: e.target.value } as AppPageMarkup);
          }}
        />
      </div>

      <div className="form-group">
        <label>Display</label>
        <br />
        <select
          className="lf-form-control"
          value={props.value?.display || ''}
          style={{ width: 200 }}
          onChange={e => {
            props.onChange({ ...props.value, display: e.target.value } as AppPageMarkup);
          }}
        >
          <option value="column">Column</option>
          <option value="slider">Slider</option>
        </select>
      </div>
    </div>
  );
}
