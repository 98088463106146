import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Layout from 'src/components/layout/Layout';
import { Table } from 'src/components/layout/tables/Table';
import XForm from 'src/components/layout/xform/XForm';
import { BlockLoading } from 'src/components/loading/BlockLoading';
import { Graph } from 'src/generated/graph';
import { DateTime } from 'src/libs/DateTime';

const QUERY_KPI_OFFLINE = gql`
  query QUERY_KPI_OFFLINE($from: String!, $to: String!) {
    reportOfflineKPI(from: $from, to: $to) {
      sales
      store
      assistedBy {
        name
        image
      }
    }
  }
`;
function renderRow(data: Graph.ReportOfflineKPIEntry[]) {
  return (
    <Table seperator={true}>
      <thead>
        <tr>
          <th style={{ width: 60 }}></th>
          <th style={{ width: 250 }}>Staff</th>
          <th style={{ width: 100 }}>Store</th>
          <th className="text-right">Sales</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.assistedBy?.id}>
            <td>
              <img src={item.assistedBy?.image?.toString()} width={50} height={50} alt="" />
            </td>
            <td>
              <strong>{item?.assistedBy?.name}</strong>
            </td>
            <td>Store {item.store}</td>
            <td className="text-right">$ {item.sales}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
function renderStore(data: Graph.ReportOfflineKPIEntry[]) {
  const uniqueStore = new Set(data.map(item => item.store));
  return [...uniqueStore].map(store => {
    return (
      <div key={store?.toString()} className="mb-4">
        {renderRow(data.filter(staff => staff.store === store))}
      </div>
    );
  });
}

function renderStaff(data: Graph.ReportOfflineKPIEntry[], breakByStore: boolean) {
  if (breakByStore) {
    return renderStore(data);
  } else {
    return renderRow(data);
  }
}

export function KPIOfflineReportScreen() {
  const [startDay, setstartDay] = useState(DateTime.firstDayOfCurrentMonth());
  const [endDay, setendDay] = useState(DateTime.today());
  const [breakdownbyStore, setBreakdownByStore] = useState(false);
  const { data } = useQuery<Graph.Query>(QUERY_KPI_OFFLINE, { variables: { from: startDay, to: endDay } });

  return (
    <Layout>
      <Layout.Header>KPI Offline Report</Layout.Header>
      <div className="mb-3">
        <XForm.Division>
          <XForm.Text type="date" label="From" onChange={e => setstartDay(e.currentTarget.value)} value={startDay} />
          <XForm.Text type="date" label="To" onChange={e => setendDay(e.currentTarget.value)} value={endDay} />
        </XForm.Division>
        <XForm.Check
          label="Breakdown by Store"
          checked={breakdownbyStore}
          onChange={e => setBreakdownByStore(e.currentTarget.checked)}
        />
      </div>
      {data?.reportOfflineKPI ? renderStaff(data.reportOfflineKPI, breakdownbyStore) : <BlockLoading />}
    </Layout>
  );
}
