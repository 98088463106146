/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Checkbox, ChoiceList, Modal } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { useMutationMassUpdateProduct } from './useMutationMassUpdateProduct';
import { PolarisTextField } from 'src/components/polaris/PolarisTextField';
import { useLazyQueryProductList } from '../Filter/useQueryProductList';

interface Props {
  itemIds: number[];
  open: boolean;
  onClose: (open: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterValue: any;
  total: number;
}

export function MassPricePopup(props: Props) {
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const [checked, setChecked] = useState(false);
  const [inputValue, setInputValue] = useState('0');
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(0);
  const [selected, setSelected] = useState<string[]>(['ADD']);

  const [query, { loading }] = useLazyQueryProductList({
    variables: {},
    type: 'ID',
  });
  const [massUpdateProduct, { loading: loadingUpdate }] = useMutationMassUpdateProduct();

  const massUpdate = useCallback(
    async (data: any) => {
      const array = [...data];
      const count_loop = Math.ceil(array.length / 100);
      setTotal(count_loop);
      let success = 0;

      if (array.length > 0) {
        while (array.length) {
          const res = await massUpdateProduct({
            variables: {
              massUpdateProductId: array.splice(0, 100).map(x => Number(x.id)),
              data: {
                price: {
                  percentage: Number(inputValue),
                  option: selected[0],
                },
              },
            },
          });
          sleep(10);
          if (!loadingUpdate && res.data?.massUpdateProduct) {
            success = success + 1;
            setSuccess(success);
          }
        }
      }

      return success === count_loop;
    },
    [success, total, massUpdateProduct, inputValue, selected],
  );

  const handleUpdate = useCallback(async () => {
    const variables = {
      massUpdateProductId: props.itemIds,
      data: {
        price: {
          percentage: Number(inputValue),
          option: selected[0],
        },
      },
    };

    if (props.itemIds.length === props.total) {
      const res = await query({
        variables: {
          ...props.filterValue,
          page: {
            offset: 0,
            limit: props.total,
          },
        },
      });
      sleep(1);
      if (res.data) {
        const update = await massUpdate(res.data?.productListV2?.data || []);
        if (update) {
          setTotal(0);
          setSuccess(0);
          props.onClose(false);
          alert('Mass update product price was completed');
        }
      }
    } else {
      if (checked) {
        const res = await query({
          variables: {
            ...props.filterValue,
            page: {
              offset: 0,
              limit: props.total,
            },
          },
        });
        sleep(1);
        if (res.data) {
          const update = await massUpdate(res.data?.productListV2?.data || []);
          if (update) {
            setTotal(0);
            setSuccess(0);
            props.onClose(false);
            alert('Mass update product price was completed');
          }
        }
      } else {
        massUpdateProduct({
          variables,
        }).then(res => {
          if (res.data?.massUpdateProduct) {
            props.onClose(false);
            alert('Mass update product price was completed');
          }
        });
      }
    }
  }, [inputValue, selected, props, massUpdateProduct, checked, massUpdate, success, total]);

  return (
    <React.Fragment>
      <Modal
        title={`Mass update price ${checked ? props.total : props.itemIds.length} products`}
        open={props.open}
        onClose={() => {
          if (!loading || !loadingUpdate) {
            props.onClose(false);
          }
        }}
      >
        <Modal.Section>
          <Checkbox
            disabled={loading || loadingUpdate}
            label={`Select all filter products (${props.total})`}
            checked={props.itemIds.length === props.total ? true : checked}
            onChange={v => props.itemIds.length !== props.total && setChecked(v)}
          />
        </Modal.Section>
        <Modal.Section>
          <PolarisTextField
            suffix="%"
            type="number"
            autoComplete="off"
            value={inputValue}
            onChange={setInputValue}
            label
            labelHidden
            maxLength={3}
            inputMode="decimal"
            max={3}
            autoFocus
            height={30}
            disabled={loading || loadingUpdate}
            error={!inputValue && 'Please input value product price.'}
          />
        </Modal.Section>
        <Modal.Section>
          <ChoiceList
            disabled={loading || loadingUpdate}
            choices={[
              {
                label: <strong>Add</strong>,
                value: 'ADD',
                helpText: (
                  <small>
                    All product selected will be increase price{' '}
                    <strong className="text-success">{`(+${inputValue}%)`}</strong> of current product price.
                  </small>
                ),
              },
              {
                label: <strong>Substrate</strong>,
                value: 'SUBTRACT',
                helpText: (
                  <small>
                    All product selected will be decrease price{' '}
                    <strong className="text-danger">{`(-${inputValue}%)`}</strong> of current product price.
                  </small>
                ),
              },
            ]}
            title
            titleHidden
            selected={selected}
            onChange={setSelected}
          />
        </Modal.Section>
        <Modal.Section>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {total > 0 && (
                <small>
                  <strong>
                    {success}/{total} batch
                  </strong>
                </small>
              )}
            </div>
            <div>
              <Button
                disabled={loading || loadingUpdate}
                loading={loading || loadingUpdate}
                size="slim"
                primary
                onClick={handleUpdate}
              >
                Update
              </Button>
            </div>
          </div>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
}
