/* eslint-disable */
import React, { ReactElement } from 'react';

type Props = {
  items: any[];
  selectedItem: any;
  onItemChanged?: (item: any) => void;
  right?: any | 0;
};

interface State {
  visible: boolean;
}

export abstract class DropboxComponent<T = any, V = any> extends React.Component<Props> {
  wrappedRef = React.createRef<HTMLDivElement>();
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  onClick_POPUP = (): void => this.setState({ visible: !this.state.visible });

  componentDidMount(): void {
    document.addEventListener('click', this.onDocumentClicked);
  }

  componentWillUnmount(): void {
    document.removeEventListener('click', this.onDocumentClicked);
  }

  onDocumentClicked = (e: MouseEvent): void => {
    if (this.wrappedRef.current && !this.wrappedRef.current.contains(e.target as Node)) {
      this.setState({ visible: false });
    }
  };

  render(): ReactElement {
    return (
      <div style={{ position: 'relative' }} ref={this.wrappedRef}>
        <div onClick={this.onClick_POPUP}>
          {this.renderPreview(this.props.selectedItem, this.props.items, this.state.visible)}
        </div>

        {this.renderOptionContainer()}
      </div>
    );
  }

  renderOptionContainer = (): ReactElement => {
    return (
      <div
        style={{
          position: 'absolute',
          zIndex: 1000,
          right: this.props.right,
          display: this.state.visible ? 'block' : 'none',
        }}
      >
        <div
          style={{
            backgroundColor: '#fff',
            marginTop: 10,
            boxShadow: '0px 15px 125px rgba(0,0,0,0.22)',
            borderRadius: '3px',
          }}
        >
          {this.renderOptions(this.props.items)}
        </div>
      </div>
    );
  };

  setSelectedItem = (data: V): void => {
    if (this.props.onItemChanged) {
      this.props.onItemChanged(data);
    }

    this.setState({ visible: false });
  };

  abstract renderPreview(value: V, data: T[], toggle: boolean): React.ReactNode;
  abstract renderOptions(data: T[]): React.ReactNode;
}

export function DropdownPreview(props: React.PropsWithChildren<unknown>): ReactElement {
  return (
    <div className="lf-form-control">
      <div>{props.children}</div>
      <div style={{ position: 'absolute', right: 10, top: 7 }}>
        <i className="fas fa-caret-down"></i>
      </div>
    </div>
  );
}
