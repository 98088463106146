import { PropsWithChildren, useRef } from 'react';
import { useUser } from 'src/contexts/AuthProvider';
import { useToken } from 'src/contexts/TokenContext';
import getMenuItems from 'src/MenuItems';
import { DesktopMenu } from '../light/MainMenu';
import { MobileMenu } from '../light/MobileMenu';
import { Topbar } from '../light/Topbar';
import { useLegacyNavigation } from './NavigationContainer';

export default function LegacyNavigationContainer(props: PropsWithChildren<unknown>) {
  const { setLegacyNavigation } = useLegacyNavigation();
  const { setToken } = useToken();
  const user = useUser();
  const menu = getMenuItems(user.superAdmin || false, user.groupID || 0);
  const windowWidth = useRef(window.innerWidth);

  return (
    <div className="all-wrapper with-side-panel solid-bg-all">
      <div className="layout-w">
        <MobileMenu items={menu} />
        <DesktopMenu items={menu}></DesktopMenu>
        <div className="content-w" style={{ width: windowWidth.current - 250 }}>
          <Topbar
            title="L192"
            name={user.name || ''}
            role={user.role || ''}
            menu={[
              {
                name: 'Setup Two Factor',
                icon: 'fas fa-qrcode',
                to: '/security/two-factor',
              },
              {
                name: 'My Online Performance',
                icon: 'far fa-chart-bar',
                to: '/online/kpi/me',
              },
              {
                name: 'Switch to new interface',
                icon: 'far fa-chart-bar',
                onClick: () => {
                  setLegacyNavigation({ legacy: false });
                },
              },
              {
                name: 'Logout',
                icon: 'os-icon os-icon-signs-11',
                onClick: () => setToken(''),
              },
            ]}
            image={user.image || ''}
          />
          {props.children}
        </div>
      </div>
    </div>
  );
}
