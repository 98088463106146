import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import styles from './Layout.module.scss';

function Layout(props: React.PropsWithChildren<{ full?: boolean }>) {
  return <div className={props.full ? styles.layout_full : styles.layout}>{props.children}</div>;
}

Layout.Header = function Header(props: React.PropsWithChildren<{ style?: CSSProperties }>) {
  return (
    <h1 className={styles.header} style={props.style}>
      {props.children}
    </h1>
  );
};

Layout.Breadcrumb = function Breadcrumb(props: { links: { name: string; to: string }[] }) {
  return (
    <ul className={styles.breadcrumb}>
      <li>
        <Link to="/">Home</Link>&nbsp;&nbsp;/&nbsp;&nbsp;
      </li>
      {props.links.map(x => (
        <li key={x.name}>
          <Link to={x.to}>{x.name}</Link>
        </li>
      ))}
    </ul>
  );
};

export default Layout;
