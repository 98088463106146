/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChoiceList, FilterInterface, IndexFilters, IndexFiltersMode, IndexFiltersProps } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { Graph } from 'src/generated/graph';

export interface State {
  roles?: [Graph.SupplierRole];
  sellerType?: [Graph.SellerType];
  name?: string;
  origin?: string;
  phoneNumber?: string;
  active?: boolean;
  recentUseDeliveryService?: boolean;
  isSeller?: boolean;
  myFollowwing?: boolean;
  isVerified?: boolean;
  subscriptionPlan?: [Graph.SellerSuscriptionPlan];
}

interface Props {
  value: State;
  setValue: (e: State) => void;
}

export function SupplierFilter({ value, setValue }: Props) {
  const [queryValue, setQueryValue] = useState('');
  const [mode, setMode] = useState(IndexFiltersMode.Filtering);

  const handleQueryChange = useCallback(
    v => {
      setQueryValue(v);
      if (v === '') {
        setValue({
          ...value,
          name: undefined,
          phoneNumber: undefined,
        });
        return;
      }
    },
    [value],
  );

  const handlePressEnter = useCallback(
    e => {
      if (e.keyCode === 13) {
        const isNumber = !isNaN(Number(queryValue));
        setValue({
          ...value,
          name: isNumber ? undefined : queryValue,
          phoneNumber: isNumber ? queryValue : undefined,
        });
      }
    },
    [queryValue],
  );

  const handleChange = useCallback(
    (key, v: any) => {
      setValue({
        ...value,
        [key]: v,
      });
    },
    [value],
  );

  const handleRemove = useCallback(
    key => {
      setValue({
        ...value,
        [key]: undefined,
      });
    },
    [value],
  );

  const status = [];

  if (!!value.active) {
    status.push('active');
  }

  if (!!value.recentUseDeliveryService) {
    status.push('recentUseDeliveryService');
  }

  if (!!value.isSeller) {
    status.push('isSeller');
  }

  if (!!value.myFollowwing) {
    status.push('myFollowwing');
  }

  if (!!value.isVerified) {
    status.push('isVerified');
  }

  const filters: FilterInterface[] = [
    {
      key: 'origin',
      label: 'Origin',
      filter: (
        <ChoiceList
          title
          titleHidden
          selected={value.origin ? [value.origin] : []}
          choices={[
            { label: 'Local', value: '0' },
            { label: 'China', value: '2' },
          ]}
          onChange={v => handleChange('origin', v[0])}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'sellerType',
      label: 'Type',
      filter: (
        <ChoiceList
          title
          titleHidden
          selected={value.sellerType ?? []}
          allowMultiple
          choices={[
            { label: 'Normal', value: 'NORMAL' },
            { label: 'IS', value: 'IS' },
            { label: 'ISD', value: 'ISD' },
          ]}
          onChange={v => handleChange('sellerType', v)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'roles',
      label: 'Role',
      filter: (
        <ChoiceList
          title
          titleHidden
          selected={value.roles ?? []}
          allowMultiple
          choices={[
            { label: 'Seller', value: 'SELLER' },
            { label: 'Store', value: 'STORE' },
            { label: 'Vendor', value: 'VENDOR' },
            { label: 'FBL Vendor', value: 'FBL_VENDOR' },
          ]}
          onChange={v => handleChange('roles', v)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'active',
      label: 'Status',
      filter: (
        <ChoiceList
          title
          titleHidden
          selected={value.active !== undefined ? [!!value.active ? 'Yes' : 'No'] : []}
          choices={[
            { label: 'Active', value: 'Yes' },
            { label: 'Not Active', value: 'No' },
          ]}
          onChange={v => handleChange('active', v[0] === 'Yes' ? true : false)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'recentUseDeliveryService',
      label: 'Delivery Service',
      filter: (
        <ChoiceList
          title
          titleHidden
          selected={
            value.recentUseDeliveryService !== undefined ? [!!value.recentUseDeliveryService ? 'Yes' : 'No'] : []
          }
          choices={[
            { label: 'Recent Used', value: 'Yes' },
            { label: 'Not Use', value: 'No' },
          ]}
          onChange={v => handleChange('recentUseDeliveryService', v[0] === 'Yes' ? true : false)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'isSeller',
      label: 'L192 Seller',
      filter: (
        <ChoiceList
          title
          titleHidden
          selected={value.isSeller !== undefined ? [!!value.isSeller ? 'Yes' : 'No'] : []}
          choices={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          onChange={v => handleChange('isSeller', v[0] === 'Yes' ? true : false)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'myFollowwing',
      label: 'My Following',
      filter: (
        <ChoiceList
          title
          titleHidden
          selected={value.myFollowwing !== undefined ? [!value.myFollowwing ? 'No' : 'Yes'] : []}
          choices={[
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ]}
          onChange={v => handleChange('myFollowwing', v[0] === 'Yes' ? true : false)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'isVerified',
      label: 'Verified',
      filter: (
        <ChoiceList
          title
          titleHidden
          selected={value.isVerified !== undefined ? [String(value.isVerified)] : []}
          choices={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          onChange={v => handleChange('isVerified', v[0] === 'true' ? true : false)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'subscriptionPlan',
      label: 'Subscription Plan',
      filter: (
        <ChoiceList
          title
          titleHidden
          allowMultiple
          selected={value.subscriptionPlan ?? []}
          choices={[
            { label: 'Pro', value: 'PRO' },
            { label: 'Boost', value: 'BOOST' },
            { label: 'Elite', value: 'ELITE' },
            { label: 'Feature', value: 'FEATURE' },
          ]}
          onChange={v => handleChange('subscriptionPlan', v)}
        />
      ),
      shortcut: true,
      pinned: true,
    },
  ];

  const appliedFilters: IndexFiltersProps['appliedFilters'] = [];

  if (value.origin && !isEmpty(value.origin)) {
    const key = 'origin';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.origin),
      onRemove: () => handleRemove(key),
    });
  }

  if (value.sellerType && !isEmpty(value.sellerType)) {
    const key = 'sellerType';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.sellerType),
      onRemove: () => handleRemove(key),
    });
  }

  if (value.roles && !isEmpty(value.roles)) {
    const key = 'roles';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.roles),
      onRemove: () => handleRemove(key),
    });
  }

  if (value.active !== undefined) {
    const key = 'active';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.active ? 'Active' : 'No Active'),
      onRemove: () => handleRemove(key),
    });
  }

  if (value.recentUseDeliveryService !== undefined) {
    const key = 'recentUseDeliveryService';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.recentUseDeliveryService ? 'Yes' : 'No'),
      onRemove: () => handleRemove(key),
    });
  }

  if (value.isSeller !== undefined) {
    const key = 'isSeller';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.isSeller ? 'Yes' : 'No'),
      onRemove: () => handleRemove(key),
    });
  }

  if (value.myFollowwing !== undefined) {
    const key = 'myFollowwing';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.myFollowwing),
      onRemove: () => handleRemove(key),
    });
  }

  if (value.isVerified !== undefined) {
    const key = 'isVerified';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.isVerified),
      onRemove: () => handleRemove(key),
    });
  }

  if (value.subscriptionPlan && !isEmpty(value.subscriptionPlan)) {
    const key = 'subscriptionPlan';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, value.subscriptionPlan),
      onRemove: () => handleRemove(key),
    });
  }

  const handelAllRemove = useCallback(() => {
    handleRemove('origin');
    handleRemove('active');
    handleRemove('recentUseDeliveryService');
    handleRemove('isSeller');
    handleRemove('myFollowwing');
    handleRemove('isVerified');
    handleRemove('subscriptionPlan');
  }, []);

  return (
    <div onKeyDown={handlePressEnter}>
      <IndexFilters
        filters={filters}
        cancelAction={{
          onAction: () => {
            setQueryValue('');
            setValue({
              ...value,
              name: undefined,
              phoneNumber: undefined,
            });
          },
          disabled: false,
          loading: false,
        }}
        queryValue={queryValue}
        mode={mode}
        onClearAll={handelAllRemove}
        onQueryChange={handleQueryChange}
        onQueryClear={() => {
          setQueryValue('');
          setValue({
            ...value,
            name: undefined,
            phoneNumber: undefined,
          });
        }}
        selected={0}
        setMode={setMode}
        tabs={[
          {
            content: 'Default',
            id: '0',
            isLocked: true,
          },
        ]}
        queryPlaceholder="Enter name or phone number..."
        borderlessQueryField={false}
        appliedFilters={appliedFilters}
      />
    </div>
  );

  function disambiguateLabel(key: string, value: any): string {
    switch (key) {
      case 'origin':
        return `Origin: ${value}`;
      case 'sellerType':
        return `Type: ${value.join(',')}`;
      case 'roles':
        return `Role: ${value.join(',')}`;
      case 'active':
        return `Status: ${!!value ? 'Active' : 'Not Active'}`;
      case 'recentUseDeliveryService':
        return `Delivery Service: ${!!value ? 'Recent Used' : 'Not Use'}`;
      case 'isSeller':
        return `L192 Seller: ${!!value ? 'Yes' : 'No'}`;
      case 'myFollowwing':
        return `My Following: ${String(value) === 'true' ? 'Yes' : 'No'}`;
      case 'isVerified':
        return `Verified: ${String(value) === 'true' ? 'Yes' : 'No'}`;
      case 'subscriptionPlan':
        return `Subscription Plan: ${value.join(',')}`;
      case 'moneySpent':
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case 'taggedWith':
        return `Tagged with ${value}`;
      case 'accountStatus':
        return (value as string[]).map(val => `Customer ${val}`).join(', ');
      default:
        return value as string;
    }
  }

  function isEmpty(value: string | any[] | number) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null || !value;
    }
  }
}
