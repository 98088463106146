import { createContext, useContext, useEffect, useState } from 'react';

interface ContextInterface {
  type: 'mini' | 'compact';
  setType: (type: 'mini' | 'compact') => void;
}

const Context = createContext<ContextInterface>({
  type: 'compact',
  setType: () => {
    return;
  },
});

export function MenuContext(props: React.PropsWithChildren<unknown>) {
  const [type, setType] = useState<'mini' | 'compact'>('compact');
  return <Context.Provider value={{ type, setType }}>{props.children}</Context.Provider>;
}

export function useMenuContext() {
  return useContext(Context);
}

export function useMiniMenu() {
  const { setType } = useContext(Context);

  useEffect(() => {
    setType('mini');
    return () => setType('compact');
  }, [setType]);
}
