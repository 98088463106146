import React, { useState } from 'react';
import { Graph } from 'src/generated/graph';

import styles from './ProductColumn.module.scss';

function RenderImage({ data }: { data: Graph.ProductRawJson }) {
  const [load, setLoad] = useState(true);
  if (data?.is_square_picture === false) {
    return (
      <div style={{ position: 'relative' }}>
        <img src={data.picture_responsive?.toString()} width={350} height={490} onLoad={() => setLoad(false)} alt="" />
        {load && (
          <div className={styles.img_load}>
            <div className={styles.img_loading + ' ph'}></div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div style={{ position: 'relative' }}>
      <img src={data.picture_responsive?.toString()} width={200} height={200} onLoad={() => setLoad(false)} alt="" />
      {load && (
        <div className={styles.img_load}>
          <div className={styles.img_loading + ' ph'}></div>
        </div>
      )}
    </div>
  );
}

export function ProductColumnItem({
  data,
  topBadgeDiscount,
}: {
  data: Graph.ProductRawJson;
  topBadgeDiscount?: boolean;
}) {
  if (!!topBadgeDiscount) {
    return (
      <div className={styles.container} style={{ position: 'relative' }}>
        <div className={styles.item}>
          <div className={styles.item_inner}>
            <RenderImage data={data} />
            <div className={styles.discount_badge}>
              {data.discount_percentage && <span>-{data?.discount_percentage?.toFixed(0)}%</span>}
            </div>
            <div className={styles.desc}>
              {data.is_ads && (
                <div className={styles.sponsored}>
                  <strong>AD</strong>
                </div>
              )}
              <div className={styles.title}>{data.title || '----'}</div>
              {data?.product_label && <span className={styles.product_label}>{data?.product_label}</span>}
              <div className={styles.list_badge}>
                <div className={styles.price}>
                  $
                  {data?.price?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
                <div className={styles.is_Choice_Badge}>
                  {data.l192_choice && <span>CHOICE</span>}
                  {data.is_brand_product && <div className={styles.badge2}>BRAND</div>}
                </div>
              </div>

              {data.stock_status_label && (
                <div className={styles.list_badge} style={{ height: '12px', marginTop: '4px' }}>
                  {data.stock_status_label && (
                    <div className={styles.stock_status_badge}>
                      {data?.stock_status_label.toUpperCase() === 'IN STOCK' ? (
                        <span style={{ color: '#00D100' }}>{data.stock_status_label.toUpperCase()}</span>
                      ) : (
                        <span>{data.stock_status_label.toUpperCase()}</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.item_inner}>
          <RenderImage data={data} />

          <div className={styles.desc}>
            {data.is_ads && (
              <div className={styles.sponsored}>
                <strong>AD</strong>
              </div>
            )}

            <div className={styles.is_Choice}>
              {data.l192_choice && <div className={styles.badge}>CHOICE</div>}
              {data.is_brand_product && <div className={styles.badge2}>BRAND</div>}
            </div>

            <div className={styles.title}>{data.title || '----'}</div>
            {data?.product_label && <span className={styles.product_label}>{data?.product_label}</span>}
            <div style={{ display: 'flex', margin: '4px 0 0 0' }}>
              <strong className={styles.price_after_Discount}>
                $
                {data?.price?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </strong>
              {data?.price && data?.price < Number(data?.price_before_discount) && (
                <span className={styles.price_before_discount}>
                  $
                  {data?.price_before_discount &&
                    data?.price_before_discount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </span>
              )}
              {data.discount_percentage && (
                <span className={styles.discount_percentage}>
                  <span>|</span>-{data?.discount_percentage?.toFixed(0)}%
                </span>
              )}
            </div>

            <div style={{ display: 'flex' }}>
              {data.stock_status_label && (
                <div style={{ height: '19px' }}>
                  <div className={styles.stock_status}>
                    {data?.stock_status_label.toUpperCase() === 'IN STOCK' ? (
                      <span style={{ color: '#00D100' }}>{data.stock_status_label.toUpperCase()}</span>
                    ) : (
                      <span>{data.stock_status_label.toUpperCase()}</span>
                    )}
                  </div>
                </div>
              )}
              {data?.rating && (
                <div style={{ height: '19px' }}>
                  <div className={styles.star_count}>
                    <span className={styles.star_icon}>
                      <img src="/icons/star-with-five-points.svg" alt="" />
                    </span>
                    <span>
                      {data?.rating.toString().includes('.') ? Number(data?.rating.toFixed(1)) : Number(data?.rating)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
