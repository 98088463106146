import React, { ReactElement } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';

interface NotificationStatus {
  logs: {
    id: number;
    message: string;
    created_at: string;
  }[];
}

const QUERY_STATUS = gql`
  query notificationStatus {
    notificationStatus
    notificationSegmentList {
      id
      name
      size
      updatedStartedAt
      updatedEndedAt
    }
  }
`;

export function NotificationStatusPartial(): React.ReactElement {
  const { loading, data } = useQuery<Graph.Query>(QUERY_STATUS);
  if (loading || !data) return <div>Loading ...</div>;

  const status: NotificationStatus = data.notificationStatus;

  return (
    <div>
      <h5 className="form-header">Segments</h5>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Segment</th>
            <th className="text-right" style={{ width: 100 }}>
              Size
            </th>
            <th style={{ width: 250 }}>Last Update</th>
          </tr>
        </thead>
        <tbody>
          {(data.notificationSegmentList || []).map(
            (x): ReactElement => (
              <tr key={x.id?.toString()}>
                <td>{x.name}</td>
                <td className="text-right">{x.size?.toLocaleString()}</td>
                <td>{x.updatedEndedAt ? x.updatedEndedAt + ' (+0 GMT)' : ''} </td>
              </tr>
            ),
          )}
        </tbody>
      </table>

      <h5 className="form-header">Logs</h5>

      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Log Message</th>
            <th style={{ width: 250 }}>Log Time</th>
          </tr>
        </thead>
        <tbody>
          {status.logs.map(
            (x): ReactElement => (
              <tr key={x.id?.toString()}>
                <td>{x.message}</td>
                <td>{x.created_at} </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  );
}
