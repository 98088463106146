import React from 'react';
import Layout from 'src/components/layout/Layout';
import { PackageDeliveryHistory } from './partial/PackageDeliveryHistory';

export function PackageSuccessScreen() {
  return (
    <Layout>
      <Layout.Header>Delivery History</Layout.Header>
      <PackageDeliveryHistory />
    </Layout>
  );
}
