import React from 'react';

export function Loading() {
  return (
    <div className="loading">
      <svg height="60" width="60">
        <image x="0" y="0" height="60" width="60" xlinkHref="/assets/loading.svg" />
      </svg>
      <div>
        <small>Please wait...</small>
      </div>
    </div>
  );
}
