import { PropsWithChildren } from 'react';
import { useUser } from 'src/contexts/AuthProvider';

interface PermissionProps {
  required?: string;
  superAdmin?: boolean;
  silence?: boolean;
}

export function Permission(props: PropsWithChildren<PermissionProps>) {
  const user = useUser();
  let allowed = false;

  if (user.superAdmin) {
    allowed = true;
  } else if (props.superAdmin === true) {
    allowed = false;
  } else if ((user.permissions || []).indexOf(props.required || '') >= 0) {
    allowed = true;
  }

  if (allowed) return <>{props.children}</>;

  if (props.silence) {
    return <></>;
  } else {
    return (
      <div>
        <h2>You do not have permission</h2>
      </div>
    );
  }
}
