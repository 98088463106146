import { DocumentNode, OperationVariables, QueryHookOptions, useQuery } from '@apollo/client';
import React from 'react';
import { Graph } from 'src/generated/graph';
import FormSelectInputV2 from './FormSelectInputV2';

interface Props extends React.PropsWithChildren<React.HTMLProps<HTMLSelectElement>> {
  label?: string;
  query: DocumentNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphOptions?: QueryHookOptions<any, OperationVariables>;
  transform: (data: Graph.Query) => { text: string; value: string }[];
}

export default function FormSelectInputFromQuery(props: Props) {
  const { query, graphOptions, transform, ...restProps } = props;
  const { data, loading } = useQuery(query, graphOptions);

  if (loading || !data) {
    return null;
  }

  return (
    <FormSelectInputV2
      {...restProps}
      items={transform(data)}
      onChange={props?.onChange}
      value={props?.value?.toString()}
    />
  );
}
