import React, { useState, ReactElement } from 'react';

interface Props<T> {
  value: T[];
  onChange: (v: T[]) => void;
  defaultNewItemValue: () => T;
  titleExtract: (v: T) => string;
  render: (value: T, change: (v: T) => void) => ReactElement;
}

function move<T>(a: T[], n: number, m: number): T[] {
  if (n < m) {
    return [...a.slice(0, n), ...a.slice(n + 1, m + 1), a[n], ...a.slice(m + 1)];
  } else {
    return [...a.slice(0, m), a[n], a[m], ...a.slice(m + 1, n), ...a.slice(n + 1)];
  }
}

export function AppPageTabEditor<T>(props: Props<T>) {
  const [selected, setSelected] = useState((props.value || []).length > 0 ? 0 : -1);

  function change(v: T) {
    props.value[selected] = v;
    props.onChange(props.value);
  }

  return (
    <div className="markup-tab-container">
      <div className="os-tabs-controls">
        <ul className="nav nav-tabs">
          {props.value.map((x, idx) => {
            return (
              <li
                key={idx}
                onClick={() => setSelected(idx)}
                className={idx === selected ? 'nav-link active' : 'nav-link'}
                draggable={true}
                onDragStart={e => {
                  e.dataTransfer.setData('text/plain', idx.toString());
                }}
                onDragOver={e => {
                  e.preventDefault();
                }}
                onDrop={e => {
                  e.preventDefault();
                  const from = Number(e.dataTransfer.getData('text/plain'));
                  props.onChange(move(props.value, from, idx));
                  setSelected(idx);
                }}
              >
                {props.titleExtract(x)}
              </li>
            );
          })}
          <li
            className="nav-link"
            onClick={() => {
              props.onChange([...props.value, props.defaultNewItemValue()]);
              if (selected < 0) {
                setSelected(0);
              }
            }}
          >
            <i className="fas fa-plus-circle"></i>
          </li>
        </ul>
      </div>

      <div className="markup-tab-body">
        {props.value && props.value[selected] && props.render(props.value[selected], change)}

        <button
          className="mt-2 btn btn-warning"
          onClick={() => {
            const value = props.value;
            value.splice(selected, 1);
            props.onChange([...value]);
          }}
        >
          Remove
        </button>
      </div>
    </div>
  );
}
