import React, { useState, useEffect, useContext, useMemo } from 'react';
import { DropdownBaseComponent } from '../DropdownBaseComponent';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { DropdownShadow } from '../DropdownShadow';
import { usePrevious } from '../../hooks/common';
import FormInputLike from '../layout/xform/FormInputLike';

const CategoryCallbackContext = React.createContext<{ value?: number; onChange?: (v: number) => void }>({});

const QUERY_CATEGORY_LIST = gql`
  query {
    categoryList {
      id
      name {
        en
      }
      parentID
    }
  }
`;

interface CategoryTreeNode {
  id: number;
  name: string;
  path: string;
  children: CategoryTreeNode[];
}

interface CategoryPrebuild {
  root: CategoryTreeNode;
  hash: { [key: number]: CategoryTreeNode };
}

function buildPath(path: string, children: CategoryTreeNode[]) {
  for (const child of children) {
    child.path = path;
    buildPath(path + child.name + ' > ', child.children);
  }
}

function buildTree(data: Graph.Category[]) {
  const hash: { [key: number]: CategoryTreeNode } = {
    0: {
      children: [],
      id: 0,
      name: '',
      path: '',
    },
  };

  // Create all the hash
  for (const item of data) {
    const node: CategoryTreeNode = {
      id: item.id,
      name: item.name?.en as string,
      path: '',
      children: [],
    };

    hash[item.id] = node;
  }

  // Attach all the children
  for (const item of data) {
    if (item.parentID) {
      hash[item.parentID].children.push(hash[item.id]);
    } else {
      hash[0].children.push(hash[item.id]);
    }
  }

  buildPath('', hash[0].children);

  return {
    root: hash[0],
    hash,
  };
}

function shouldCategoryDisplayed(data: CategoryTreeNode, keyword = ''): boolean {
  if (keyword === '') return true;
  if (data.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0) {
    return true;
  } else {
    let answer = false;
    for (const child of data.children) {
      answer = answer || shouldCategoryDisplayed(child, keyword);
    }
    return answer;
  }
}

function CategoryTreeItem({ data, depth, keyword }: { data: CategoryTreeNode; depth: number; keyword: string }) {
  const { onChange, value } = useContext(CategoryCallbackContext);

  const shouldDisplay = shouldCategoryDisplayed(data, keyword);
  if (!shouldDisplay) return null;

  const children = data.children.map(x => <CategoryTreeItem key={x.id} data={x} depth={depth + 1} keyword={keyword} />);
  let heading;

  if (depth > 0) {
    heading = (
      <div
        className={value === data.id ? 'dropdown-category-item selected' : 'dropdown-category-item'}
        style={{ padding: '5px 10px', marginLeft: depth * -25, paddingLeft: 10 + depth * 25 }}
        onClick={() => {
          if (onChange) onChange(data.id);
        }}
      >
        {keyword.length > 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: data.name.replace(new RegExp(`${keyword}`, 'ig'), (v: string) => {
                return `<mark style="padding: 0; background-color: #f1c40f; color: white">${v}</mark>`;
              }),
            }}
          />
        ) : (
          data.name
        )}
      </div>
    );
  }

  const border = depth > 0 ? { borderLeft: '1px solid #eee', paddingLeft: 15, marginLeft: 10 } : {};

  return (
    <div>
      {heading}
      {data.children.length > 0 && <div style={border}>{children}</div>}
    </div>
  );
}

interface CategoryPickerProps {
  value: number;
  label?: string;
  onChange?: (v: number) => void;
}

export function CategoryPicker(props: CategoryPickerProps) {
  const { data } = useQuery<Graph.Query>(QUERY_CATEGORY_LIST, { fetchPolicy: 'cache-first' });
  const [toggle, setToggle] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [timer] = useState<{ id?: number }>({});
  const prev = usePrevious(props);
  const [category, setCategory] = useState<CategoryPrebuild>({
    root: { children: [], id: 0, name: '', path: '' },
    hash: {},
  });

  // Build the tree
  useEffect(() => {
    if (data?.categoryList) {
      setCategory(buildTree(data.categoryList));
    }
  }, [data, setCategory]);

  const memo = useMemo(() => {
    const value = props.value;
    const { onChange } = prev.value;

    function preview() {
      if (value > 0 && category.hash[value]) {
        return (
          <FormInputLike dropdown={true} label={props.label}>
            {category.hash[value].name}
          </FormInputLike>
        );
      } else {
        return (
          <FormInputLike dropdown={true} label={props.label}>
            Please select category
          </FormInputLike>
        );
      }
    }

    function dropdown() {
      return (
        <DropdownShadow>
          <div className="element-search autosuggest-search-activator">
            <input
              placeholder="Start typing to search..."
              type="search"
              autoFocus
              name="search"
              autoComplete={'off'}
              onChange={e => {
                clearTimeout(timer.id);
                const t = e.currentTarget.value;
                timer.id = window.setTimeout(() => {
                  setKeyword(t);
                }, 500);
              }}
            />
          </div>
          <div
            className="dropdown-category dropdown-container mt-3"
            style={{ width: 500, maxHeight: 300, overflowY: 'auto', right: 0, position: 'relative' }}
          >
            <CategoryCallbackContext.Provider
              value={{
                value,
                onChange: v => {
                  if (onChange) onChange(v);
                  setToggle(false);
                },
              }}
            >
              <CategoryTreeItem data={category.root} depth={0} keyword={keyword} />
            </CategoryCallbackContext.Provider>
          </div>
        </DropdownShadow>
      );
    }

    return (
      <DropdownBaseComponent
        dropdownHeight={400}
        onToggleChanged={t => setToggle(t)}
        toggle={toggle}
        preview={preview}
        dropdown={dropdown}
      />
    );
  }, [prev, props.value, props.label, category, keyword, setToggle, toggle, timer]);

  return memo;
}
