import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Content } from '../../../components/light/Content';
import { SectionBlock } from '../../../components/light/SectionBlock';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../../generated/graph';

const QUERY = gql`
  query LuckyDraw($id: Int!) {
    luckyDraw(id: $id) {
      id
      name
      description
      startedAt
      endedAt
      cost
      cover
      thumbnail
      winner {
        id
      }
      published
    }

    luckyDrawParticipant(id: $id) {
      id
      name
      phoneNumber
    }
  }
`;

export function LuckyDrawParticipantScreen(props: RouteComponentProps<{ id: string }>): React.ReactElement {
  const { data, loading } = useQuery<Graph.Query>(QUERY, { variables: { id: Number(props.match.params.id) } });
  if (!data || loading) return <div>Loading...</div>;

  return (
    <Content>
      <SectionBlock title="Lucky Draw Participant">
        <div>
          <h5 className="form-header">{data?.luckyDraw?.name}</h5>
          <table className="table table-striped">
            <thead>
              <tr>
                <th style={{ width: 50 }}>#</th>
                <th>Customer Name</th>
                <th>Phone Number</th>
              </tr>
            </thead>
            <tbody>
              {(data?.luckyDrawParticipant || []).map((x, idx) => (
                <tr key={x.id}>
                  <td>{idx + 1}</td>
                  <td>{x.name}</td>
                  <td>{x.phoneNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SectionBlock>
    </Content>
  );
}
