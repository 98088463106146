import React, { useState, useEffect, ChangeEvent } from 'react';
import { Graph } from '../../generated/graph';
import { DropdownBaseComponent } from '../DropdownBaseComponent';
import { useQuery, gql } from '@apollo/client';
import { DropdownShadow } from '../DropdownShadow';
import { HighlightText } from '../HighlightText';
import FormInputLike from '../layout/xform/FormInputLike';
import XForm from '../layout/xform/XForm';

const QUERY_SUPPLIER = gql`
  query supplier($id: Int!) {
    supplier(id: $id) {
      name
      id
      logo
    }
  }
`;

const QUERY_SUPPLIER_LIST = gql`
  query supplierList($name: String, $recent: Boolean) {
    supplierList(name: $name, limit: 10, recentUseDeliveryService: $recent) {
      id
      name
      logo
    }
  }
`;

interface Props {
  label?: string;
  value?: number | null;
  onChange?: (v: number | null) => void;
  defaultUseRecentDelivery?: boolean;
}

export function MerchantPicker(props: Props) {
  const { value, onChange } = props;
  const [timer] = useState<{ id?: number }>({});
  const [toggle, setToggle] = useState(false);
  const [search, setSearch] = useState('');
  const [supplier, setSupplier] = useState<Graph.Supplier>({});
  const [defaultUseRecentDelivery, setDefaultUseRecentDelivery] = useState(props.defaultUseRecentDelivery || false);

  const { data: supplierData } = useQuery<Graph.Query>(QUERY_SUPPLIER, {
    variables: { id: value },
    skip: value === supplier.id || value === 0 || value === null,
  });

  const { data } = useQuery<Graph.Query>(QUERY_SUPPLIER_LIST, {
    variables: { name: search, recent: defaultUseRecentDelivery },
  });

  useEffect(() => {
    if (supplierData?.supplier) {
      setSupplier(supplierData.supplier);
    }
  }, [supplierData]);

  function preview() {
    return (
      <FormInputLike label={props.label} dropdown={true}>
        {supplier?.name ? supplier.name : 'Please pick store'}
      </FormInputLike>
    );
  }

  function list() {
    const suppliers: Graph.Supplier[] = data?.supplierList ? data?.supplierList : [];

    return (
      <ul className="dropdown-container mt-3" style={{ width: 500, right: 0 }}>
        <li
          onClick={(): void => {
            setSupplier({});
            setToggle(false);
            if (onChange) onChange(null);
          }}
          style={{ borderBottom: '1px solid #f1f1f1' }}
        >
          <i>No Store</i>
        </li>
        {suppliers.map(x => (
          <li
            key={x.id?.toString()}
            onClick={(): void => {
              setSupplier(x);
              setToggle(false);
              if (onChange) onChange(x.id as number);
            }}
            className={value === x.id ? 'selected' : ''}
          >
            <HighlightText text={x.name ? x.name : ''} highlight={search} />
          </li>
        ))}
      </ul>
    );
  }

  function dropdown() {
    return (
      <DropdownShadow>
        <div className="element-search autosuggest-search-activator">
          <input
            placeholder="Start typing to search..."
            type="search"
            autoFocus
            name="search"
            autoComplete={'off'}
            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
              const t = e.target.value;
              window.clearTimeout(timer.id);
              timer.id = window.setTimeout(() => setSearch(t), 500);
            }}
          />
        </div>
        <div className="mt-2">
          <XForm.Check
            checked={defaultUseRecentDelivery}
            label="Recent Use Delivery System"
            onChange={e => setDefaultUseRecentDelivery(e.currentTarget.checked)}
          />
        </div>
        {list()}
      </DropdownShadow>
    );
  }

  return (
    <DropdownBaseComponent
      dropdownHeight={400}
      preview={preview}
      dropdown={dropdown}
      onToggleChanged={t => setToggle(t)}
      toggle={toggle}
    />
  );
}
