/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from '@apollo/client';
import { ChoiceList, Divider, Tag } from '@shopify/polaris';
import React, { useState } from 'react';
import { PolarisTextField } from 'src/components/polaris/PolarisTextField';
import { Graph } from 'src/generated/graph';

const QUERY = gql`
  query supplierVendorList($supplierId: Int) {
    supplierVendorList(supplierId: $supplierId) {
      id
      supplier {
        id
        name
        logo
        contact
      }
      name
      contactInfo
      address
      note
      margin
    }
  }
`;

interface Props {
  selected: any[];
  setSelected: (v: any[]) => void;
  supplierId?: number;
}

export function FilterVendor(props: Props) {
  const [vendorInput, setVendorInput] = useState('');
  const { data, loading } = useQuery<Graph.Query>(QUERY, {
    variables: {
      supplierId: props.supplierId ? Number(props.supplierId) : undefined,
    },
  });

  const filterRegex = new RegExp(vendorInput, 'i');

  const vendor = data
    ? vendorInput !== ''
      ? (data.supplierVendorList
          ?.filter(x => x.name?.match(filterRegex) || x.supplier?.name?.match(filterRegex))
          .map(vendor => {
            return {
              label: (<small>{`${vendor.name} (${vendor.supplier?.name})`}</small>) as any,
              value: String(vendor.id),
            };
          }) as any[])
      : (data.supplierVendorList?.map(vendor => {
          return {
            label: (<small>{`${vendor.name} (${vendor.supplier?.name})`}</small>) as any,
            value: String(vendor.id),
          };
        }) as any[])
    : [];

  return (
    <React.Fragment>
      <PolarisTextField
        value={vendorInput}
        onChange={setVendorInput}
        height={'25x'}
        placeholder="Search vendor name"
        autoComplete="off"
        label
        labelHidden
        disabled={loading}
        readOnly={loading}
      />
      <Divider />
      {props.selected.length > 0 && (
        <React.Fragment>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {props.selected.map(x => {
              const find = data?.supplierVendorList?.find(f => Number(f.id) === Number(x));
              return (
                <div key={x} className="mr-1 mb-1">
                  <Tag
                    onRemove={() => {
                      props.setSelected(props.selected.filter(f => Number(f) !== Number(x)));
                    }}
                  >
                    <small>{find?.name}</small>
                  </Tag>
                </div>
              );
            })}
          </div>
          <Divider />
        </React.Fragment>
      )}
      <ChoiceList
        allowMultiple
        choices={vendor}
        selected={props.selected}
        onChange={props.setSelected}
        title
        titleHidden
      />
    </React.Fragment>
  );
}
