import { PaginationProps, Pagination } from '@shopify/polaris';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';

type Props = PaginationProps & { type: 'table' | 'page'; className?: string };

const PolarisPagination = (props: Props) => {
  if (props.type === 'table') {
    return (
      <div>
        <div className={`${styles.pag} ${props.className}`}>
          {props.label && <span className={styles.label}>{props.label}</span>}{' '}
          <div>
            {props.previousURL ? (
              <Link aria-disabled="true" to={props.previousURL} className={styles.pag_button}>
                <i className="os-icon os-icon-chevron-left"></i>
              </Link>
            ) : (
              <button
                disabled={!props.hasPrevious}
                className={styles.pag_button}
                onClick={props.onPrevious && props.onPrevious}
              >
                <i className="os-icon os-icon-chevron-left"></i>
              </button>
            )}
            {props.nextURL ? (
              <Link aria-disabled="true" to={props.nextURL} className={styles.pag_button}>
                <i className="os-icon os-icon-chevron-right"></i>
              </Link>
            ) : (
              <button disabled={!props.hasNext} className={styles.pag_button} onClick={props.onNext && props.onNext}>
                <i className="os-icon os-icon-chevron-right"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Pagination {...props} />
    </div>
  );
};

PolarisPagination.prototype = {
  type: PropTypes.oneOf(['table', 'page']),
};

PolarisPagination.defaultProps = {
  type: 'page',
};

export default PolarisPagination;
