/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from 'react';
import { ReactElement } from 'react';
import { useQuery, useMutation, DocumentNode } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { useHistory } from 'react-router';

export interface CreateUpdateProps<T, K = T> {
  update: (data: T) => void;
  defaultValue: K;
  isEditingMode: boolean;
}

export function CreateUpdateForm<T, K>(props: {
  update: DocumentNode;
  create: DocumentNode;
  query: DocumentNode;
  updateReturned?: string;
  createReturned?: string | ((data: Graph.Mutation) => string);
  transform?: (data: any) => T;
  id?: number | string;
  body: FunctionComponent<CreateUpdateProps<T, K>>;
}): ReactElement {
  const id = props.id ? Number(props.id) : undefined;

  const { data } = useQuery<Graph.Query>(props.query, {
    variables: { id },
    skip: id ? false : true,
  });

  const history = useHistory();

  const onUpdatedCompleted = (): void => {
    if (props.updateReturned) {
      history.push(props.updateReturned);
    }
  };

  const onCreatedCompleted = (data: Graph.Mutation): void => {
    if (props.createReturned) {
      if (typeof props.createReturned === 'string') {
        history.push(props.createReturned);
      } else {
        history.push(props.createReturned(data));
      }
    }
  };

  const [mutate, { loading, data: mutationData, error }] = useMutation(id ? props.update : props.create, {
    onCompleted: id ? onUpdatedCompleted : onCreatedCompleted,
  });

  const updateFunc = (data: T): void => {
    if (id) {
      mutate({ variables: { id, data } })
        .then()
        .catch(console.error);
    } else {
      mutate({ variables: { data } })
        .then()
        .catch(console.error);
    }
  };

  let header = <div></div>;
  let body = <div>Loading ...</div>;

  if (loading) {
    header = <div className="alert alert-info">Processing ....</div>;
  } else if (mutationData) {
    header = <div className="alert alert-success">Success!</div>;
  } else if (error) {
    header = <div className="alert alert-danger">{error.message}</div>;
  }

  if (!id) {
    body = <props.body defaultValue={{} as K} update={updateFunc} isEditingMode={false} />;
  } else if (data) {
    const dataSelection = (data as any)[Object.keys(data)[0]];
    const dataAfterTransform = { ...dataSelection, ...(props.transform ? props.transform(dataSelection) : {}) };
    body = <props.body defaultValue={dataAfterTransform} update={updateFunc} isEditingMode={true} />;
  }

  return (
    <div>
      {header}
      {body}
    </div>
  );
}
