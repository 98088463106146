/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChoiceList, FilterInterface, IndexFiltersProps, Modal, Select } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import { ProductListArguments } from './useQueryProductList';
import { PolarisTextField } from 'src/components/polaris/PolarisTextField';
import { FilterVendor } from './FilterVendor';
import { FilterBrand } from './FilterBrand';
import { FilterAdmin } from './FilterAdmin';
import moment from 'moment';
import { FilterSale } from './FilterSale';
import { SupplierPicker } from 'src/components/pickers/SupplierPicker/SupplierPicker';
import { PolarisCategory } from 'src/components/polaris/PolarisCategory';

/*
  Readme:

  Filter product using polaris IndexFilter

  How to add new filers:

    1. In variable name filters insert new filers component here.
    2. add new appliedFilter in variable appliedFilters
    3. required handleChangeFilterSomething
    4. required handleRemoverFilterSomething
    5. add new case in disambiguateLabel
*/

interface Props {
  filters: ProductListArguments;
  setFilters: (filters: ProductListArguments) => void;
}

export function FiltersForm({ filters: propsFilters, setFilters }: Props) {
  const [sale, setSale] = useState(false);
  const handleChangeStatue = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          status: v,
        },
      });
    },
    [propsFilters],
  );

  const handleChangeMark = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          mark: v,
        },
      });
    },
    [propsFilters],
  );

  // const handleChangeRole = useCallback(
  //   v => {
  //     setFilters({
  //       ...propsFilters,
  //       filter: {
  //         ...propsFilters.filter,
  //         sellerRole: v,
  //       },
  //     });
  //   },
  //   [propsFilters],
  // );

  // const handleChangeType = useCallback(
  //   v => {
  //     setFilters({
  //       ...propsFilters,
  //       filter: {
  //         ...propsFilters.filter,
  //         sellerType: v,
  //       },
  //     });
  //   },
  //   [propsFilters],
  // );

  const handleChangeVendor = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          vendor: v,
        },
      });
    },
    [propsFilters],
  );

  const handleChangeBrand = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          brand: v,
        },
      });
    },
    [propsFilters],
  );

  const handleChangeCategories = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          categories: v,
        },
      });
    },
    [propsFilters],
  );

  const handleChangeSelectedBy = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          selectedBy: v,
        },
      });
    },
    [propsFilters],
  );

  const handleChangeSupplier = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          supplierId: v,
        },
      });
    },
    [propsFilters],
  );

  const handleChangeSupplierFillter = useCallback(
    (supplier, v) => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          supplierId: supplier,
        },
        supplierFilter: v,
      });
    },
    [propsFilters],
  );

  const handleChangeGTEStock = useCallback(
    v => {
      if (!isNaN(Number(v))) {
        setFilters({
          ...propsFilters,
          filter: {
            ...propsFilters.filter,
            gteStock: Number(v),
          },
        });
      }
    },
    [propsFilters],
  );

  const handleChangeLTEStock = useCallback(
    v => {
      if (!isNaN(Number(v))) {
        setFilters({
          ...propsFilters,
          filter: {
            ...propsFilters.filter,
            lteStock: Number(v),
          },
        });
      }
    },
    [propsFilters],
  );

  const handleChangeGTEDiscount = useCallback(
    v => {
      if (!isNaN(Number(v))) {
        setFilters({
          ...propsFilters,
          filter: {
            ...propsFilters.filter,
            gteDiscount: Number(v),
          },
        });
      }
    },
    [propsFilters],
  );

  const handleChangeLTEDiscount = useCallback(
    v => {
      if (!isNaN(Number(v))) {
        setFilters({
          ...propsFilters,
          filter: {
            ...propsFilters.filter,
            lteDiscount: Number(v),
          },
        });
      }
    },
    [propsFilters],
  );

  const handleChangeGTEPrice = useCallback(
    v => {
      if (!isNaN(Number(v))) {
        setFilters({
          ...propsFilters,
          filter: {
            ...propsFilters.filter,
            gtePrice: String(v),
          },
        });
      }
    },
    [propsFilters],
  );

  const handleChangeLTEPrice = useCallback(
    v => {
      if (!isNaN(Number(v))) {
        setFilters({
          ...propsFilters,
          filter: {
            ...propsFilters.filter,
            ltePrice: String(v),
          },
        });
      }
    },
    [propsFilters],
  );

  const handleChangeStockInAfter = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          stockInAfter: moment(new Date(v)).format('YYYY-MM-DD'),
        },
      });
    },
    [propsFilters],
  );

  const handleChangeStockInBefore = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          stockInBefore: moment(new Date(v)).format('YYYY-MM-DD'),
        },
      });
    },
    [propsFilters],
  );

  const handleChangeFbLastPost = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          fbLastPostGte: Number(v),
        },
      });
    },
    [propsFilters],
  );

  const handleChangelastCampaignCreated = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          lastCampaignCreated: Number(v),
        },
      });
    },
    [propsFilters],
  );

  const handleChangeRemoted = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          isRemoted: v[0] === 'T' ? true : v[0] === 'F' ? false : undefined,
        },
      });
    },
    [propsFilters],
  );

  const handleChangeCore = useCallback(
    v => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          coreProduct: v[0] === 'T' ? true : v[0] === 'F' ? false : undefined,
        },
      });
    },
    [propsFilters],
  );

  // const handleChangeOrigin = useCallback(
  //   v => {
  //     setFilters({
  //       ...propsFilters,
  //       filter: {
  //         ...propsFilters.filter,
  //         sellerOrigin: v,
  //       },
  //     });
  //   },
  //   [propsFilters],
  // );

  const handleChangeSale = useCallback(
    sale => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          sales: sale,
        },
      });
    },
    [propsFilters],
  );

  const handleChangeScore = useCallback(
    (key, value) => {
      setFilters({
        ...propsFilters,
        filter: {
          ...propsFilters.filter,
          productScore: {
            ...propsFilters.filter?.productScore,
            [key]: value,
          },
        },
      });
    },
    [propsFilters],
  );

  const filters: FilterInterface[] = [
    {
      key: 'supplier',
      label: (
        <SupplierPicker
          selected={propsFilters.filter?.supplierId ?? []}
          setSelected={handleChangeSupplier}
          filter={propsFilters.supplierFilter}
          setFilter={handleChangeSupplierFillter}
          type="FILTER"
        />
      ) as any,
      // filter: <FilterSupplier selected={propsFilters.filter?.supplierId} setSelected={handleChangeSupplier} />,
      // filter: (
      //   <SupplierPicker
      //     selected={propsFilters.filter?.supplierId ?? []}
      //     setSelected={handleChangeSupplier}
      //     filter={propsFilters.supplierFilter}
      //     setFilter={handleChangeSupplierFillter}
      //   />
      // ),
      // onAction: () => {
      //   console.log('action supplier');
      // },
      filter: <></>,
      shortcut: true,
      pinned: true,
    },
    // {
    //   key: 'origin',
    //   label: 'Origin',
    //   filter: (
    //     <ChoiceList
    //       allowMultiple
    //       title
    //       titleHidden
    //       choices={[
    //         { label: 'Local', value: 'LOCAL' },
    //         { label: 'China', value: 'CHINA' },
    //       ]}
    //       selected={propsFilters.filter?.sellerOrigin || []}
    //       onChange={handleChangeOrigin}
    //     />
    //   ),
    //   shortcut: true,
    //   pinned: true,
    // },
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          allowMultiple
          title
          titleHidden
          choices={[
            { label: 'Active', value: 'ACTIVE' },
            { label: 'Inactive', value: 'INACTIVE' },
          ]}
          selected={propsFilters.filter?.status || []}
          onChange={handleChangeStatue}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'mark',
      label: 'Mark',
      filter: (
        <ChoiceList
          allowMultiple
          title
          titleHidden
          choices={[
            { label: 'Available', value: 'AVAILABLE' },
            { label: 'Out of Stock', value: 'OUT_OF_STOCK' },
            { label: 'Discontinue', value: 'DISCONTINUE' },
          ]}
          selected={propsFilters.filter?.mark || []}
          onChange={handleChangeMark}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    // {
    //   key: 'seller_type',
    //   label: 'Seller Type',
    //   filter: (
    //     <ChoiceList
    //       allowMultiple
    //       title
    //       titleHidden
    //       choices={[
    //         { label: 'Normal', value: 'NORMAL' },
    //         { label: 'IS', value: 'IS' },
    //         { label: 'ISD', value: 'ISD' },
    //       ]}
    //       selected={propsFilters.filter?.sellerType || []}
    //       onChange={handleChangeType}
    //     />
    //   ),
    //   shortcut: true,
    //   pinned: true,
    // },
    {
      key: 'core',
      label: 'Core Product',
      filter: (
        <ChoiceList
          allowMultiple={false}
          title
          titleHidden
          choices={[
            { label: 'No Filter', value: '' },
            { label: 'Yes', value: 'T' },
            { label: 'No', value: 'F' },
          ]}
          selected={
            propsFilters.filter?.coreProduct === true
              ? ['T']
              : propsFilters.filter?.coreProduct === false
              ? ['F']
              : ['']
          }
          onChange={handleChangeCore}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    // {
    //   key: 'seller_role',
    //   label: 'Seller Role',
    //   filter: (
    //     <ChoiceList
    //       allowMultiple
    //       title
    //       titleHidden
    //       choices={[
    //         { label: 'Seller', value: 'SELLER' },
    //         { label: 'Store', value: 'STORE' },
    //         { label: 'Vendor', value: 'VENDOR' },
    //         { label: 'FBL Vendor', value: 'FBL_VENDOR' },
    //       ]}
    //       selected={propsFilters.filter?.sellerRole || []}
    //       onChange={handleChangeRole}
    //     />
    //   ),
    //   shortcut: true,
    //   pinned: true,
    // },
    {
      key: 'vendor',
      label: 'Vendor',
      filter: (
        <FilterVendor
          selected={propsFilters.filter?.vendor || []}
          setSelected={handleChangeVendor}
          supplierId={propsFilters.filter?.supplierId ? Number(propsFilters.filter?.supplierId) : undefined}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'brand',
      label: 'Brand',
      filter: (
        <FilterBrand
          selected={propsFilters.filter?.brand || []}
          setSelected={handleChangeBrand}
          supplierId={propsFilters.filter?.supplierId ? Number(propsFilters.filter?.supplierId) : undefined}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'category',
      label: 'Category',
      // filter: <FilterCategory selected={propsFilters.filter?.categories || []} setSelected={handleChangeCategories} />,
      filter: <PolarisCategory value={propsFilters.filter?.categories || []} onChange={handleChangeCategories} />,
      shortcut: true,
      pinned: true,
    },
    {
      key: 'selected_by',
      label: 'Selected By',
      filter: <FilterAdmin selected={propsFilters.filter?.selectedBy || []} setSelected={handleChangeSelectedBy} />,
      shortcut: true,
      pinned: true,
    },
    {
      key: 'remote',
      label: 'Remote',
      filter: (
        <ChoiceList
          allowMultiple={false}
          title
          titleHidden
          choices={[
            { label: 'No Filter', value: '' },
            { label: 'Yes', value: 'T' },
            { label: 'No', value: 'F' },
          ]}
          selected={
            propsFilters.filter?.isRemoted === true ? ['T'] : propsFilters.filter?.isRemoted === false ? ['F'] : ['']
          }
          onChange={handleChangeRemoted}
        />
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'stock',
      label: 'Stock',
      filter: (
        <div>
          <PolarisTextField
            value={String(propsFilters.filter?.gteStock || 0)}
            onChange={handleChangeGTEStock}
            autoComplete="off"
            label
            labelHidden
            prefix="GTE"
            height={'30px'}
            align="right"
            suffix="UNIT"
            type="number"
            onFocus={(event: any) => event?.target.select()}
          />
          <br />
          <PolarisTextField
            value={String(propsFilters.filter?.lteStock || 0)}
            onChange={handleChangeLTEStock}
            autoComplete="off"
            label
            labelHidden
            prefix="LTE"
            height={'30px'}
            align="right"
            suffix="UNIT"
            type="number"
            onFocus={(event: any) => event?.target.select()}
          />
        </div>
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'discount',
      label: 'Discount',
      filter: (
        <div>
          <PolarisTextField
            value={String(propsFilters.filter?.gteDiscount || 0)}
            onChange={handleChangeGTEDiscount}
            autoComplete="off"
            label
            labelHidden
            prefix="GTE"
            height={'30px'}
            align="right"
            suffix="%"
            type="number"
            onFocus={(event: any) => event?.target.select()}
          />
          <br />
          <PolarisTextField
            value={String(propsFilters.filter?.lteDiscount || 0)}
            onChange={handleChangeLTEDiscount}
            autoComplete="off"
            label
            labelHidden
            prefix="LTE"
            height={'30px'}
            align="right"
            suffix="%"
            type="number"
            onFocus={(event: any) => event?.target.select()}
          />
        </div>
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'price',
      label: 'Price',
      filter: (
        <div>
          <PolarisTextField
            value={String(propsFilters.filter?.gtePrice || 0)}
            onChange={handleChangeGTEPrice}
            autoComplete="off"
            label
            labelHidden
            prefix="GTE"
            height={'30px'}
            align="right"
            suffix="USD"
            type="number"
            onFocus={(event: any) => event?.target.select()}
          />
          <br />
          <PolarisTextField
            value={String(propsFilters.filter?.ltePrice || 0)}
            onChange={handleChangeLTEPrice}
            autoComplete="off"
            label
            labelHidden
            prefix="LTE"
            height={'30px'}
            align="right"
            suffix="USD"
            type="number"
            onFocus={(event: any) => event?.target.select()}
          />
        </div>
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'stockIn',
      label: 'Stock In',
      filter: (
        <div>
          <PolarisTextField
            value={String(propsFilters.filter?.stockInAfter || '')}
            onChange={handleChangeStockInAfter}
            autoComplete="off"
            label
            labelHidden
            prefix="After"
            height={'30px'}
            align="right"
            type="date"
            onFocus={(event: any) => event?.target.select()}
          />
          <br />
          <PolarisTextField
            value={String(propsFilters.filter?.stockInBefore || '')}
            onChange={handleChangeStockInBefore}
            autoComplete="off"
            label
            labelHidden
            prefix="Before"
            height={'30px'}
            align="right"
            type="date"
            onFocus={(event: any) => event?.target.select()}
          />
        </div>
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'fbLastPostGte',
      label: 'Facebook Last Post',
      filter: (
        <div>
          <PolarisTextField
            value={String(propsFilters.filter?.fbLastPostGte || '')}
            onChange={handleChangeFbLastPost}
            autoComplete="off"
            label
            labelHidden
            prefix="GTE"
            height={'30px'}
            align="right"
            type="number"
            onFocus={(event: any) => event?.target.select()}
            suffix="Day"
          />
        </div>
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'lastCampaignCreated',
      label: 'Last Campaign Created',
      filter: (
        <div>
          <PolarisTextField
            value={String(propsFilters.filter?.lastCampaignCreated || '')}
            onChange={handleChangelastCampaignCreated}
            autoComplete="off"
            label
            labelHidden
            prefix="GTE"
            height={'30px'}
            align="right"
            type="number"
            onFocus={(event: any) => event?.target.select()}
            suffix="Day"
          />
        </div>
      ),
      shortcut: true,
      pinned: true,
    },
    {
      key: 'sales',
      label: (
        <Modal
          title="Filter Product List by Sales"
          open={sale}
          onClose={() => setSale(!sale)}
          activator={<div onClick={() => setSale(!sale)}>Select Filter Sale</div>}
        >
          <Modal.Section>
            <FilterSale sales={propsFilters.filter?.sales ?? {}} setSale={handleChangeSale} />
          </Modal.Section>
        </Modal>
      ) as any,
      shortcut: true,
      pinned: true,
      filter: <></>,
    },
    {
      key: 'productScore',
      label: 'Score',
      shortcut: true,
      pinned: true,
      filter: (
        <div>
          <div className="flex items-center mb-2">
            <div className="w-[100px]">
              <label htmlFor="" className="mr-2">
                Title
              </label>
            </div>
            <Select
              label
              labelHidden
              options={[
                { label: 'Select Status', value: '' },
                { label: 'Complete', value: 'COMPLETE' },
                { label: 'Incomplete', value: 'INCOMPLETE' },
              ]}
              value={propsFilters.filter?.productScore ? propsFilters.filter.productScore.title || '' : ''}
              onChange={v => handleChangeScore('title', v === '' ? undefined : v)}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className="w-[100px]">
              <label htmlFor="" className="mr-2">
                Description
              </label>
            </div>
            <Select
              label
              labelHidden
              options={[
                { label: 'Select Status', value: '' },
                { label: 'Complete', value: 'COMPLETE' },
                { label: 'Incomplete', value: 'INCOMPLETE' },
              ]}
              value={propsFilters.filter?.productScore ? propsFilters.filter.productScore.description || '' : ''}
              onChange={v => handleChangeScore('description', v === '' ? undefined : v)}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className="w-[100px]">
              <label htmlFor="" className="mr-2">
                Picture
              </label>
            </div>
            <Select
              label
              labelHidden
              options={[
                { label: 'Select Status', value: '' },
                { label: 'Complete', value: 'COMPLETE' },
                { label: 'Incomplete', value: 'INCOMPLETE' },
              ]}
              value={propsFilters.filter?.productScore ? propsFilters.filter.productScore.picture || '' : ''}
              onChange={v => handleChangeScore('picture', v === '' ? undefined : v)}
            />
          </div>
          <div className="flex items-center mb-2">
            <div className="w-[100px]">
              <label htmlFor="" className="mr-2">
                Video
              </label>
            </div>
            <Select
              label
              labelHidden
              options={[
                { label: 'Select Status', value: '' },
                { label: 'Complete', value: 'COMPLETE' },
                { label: 'Incomplete', value: 'INCOMPLETE' },
              ]}
              value={propsFilters.filter?.productScore ? propsFilters.filter.productScore.video || '' : ''}
              onChange={v => handleChangeScore('video', v === '' ? undefined : v)}
            />
          </div>
        </div>
      ),
    },
  ];

  const handleRemoverMark = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        mark: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverStatus = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        status: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverType = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        sellerType: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverRole = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        sellerRole: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverVendor = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        vendor: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverBrand = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        brand: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverFbPost = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        fbLastPostGte: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverLastCampaignCreated = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        lastCampaignCreated: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverCategories = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        categories: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverSelectedBy = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        selectedBy: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverSupplier = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        supplierId: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverRemote = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        isRemoted: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverCore = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        coreProduct: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverStock = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        gteStock: undefined,
        lteStock: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverDiscount = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        gteDiscount: undefined,
        lteDiscount: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverPrice = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        gtePrice: undefined,
        ltePrice: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverStockIn = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        stockInAfter: undefined,
        stockInBefore: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverOrigin = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        sellerOrigin: [],
      },
    });
  }, [propsFilters]);

  const handleRemoverSale = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        sales: undefined,
      },
    });
  }, [propsFilters]);

  const handleRemoverScore = useCallback(() => {
    setFilters({
      ...propsFilters,
      filter: {
        ...propsFilters.filter,
        productScore: undefined,
      },
    });
  }, [propsFilters]);

  const appliedFilters: IndexFiltersProps['appliedFilters'] = [];

  if ((propsFilters.filter?.mark || []).length > 0 && !isEmpty(propsFilters.filter?.mark || [])) {
    const key = 'mark';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.mark || []),
      onRemove: handleRemoverMark,
    });
  }

  if ((propsFilters.filter?.status || []).length > 0 && !isEmpty(propsFilters.filter?.status || [])) {
    const key = 'status';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.status || []),
      onRemove: handleRemoverStatus,
    });
  }

  if ((propsFilters.filter?.sellerType || []).length > 0 && !isEmpty(propsFilters.filter?.sellerType || [])) {
    const key = 'seller_type';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.sellerType || []),
      onRemove: handleRemoverType,
    });
  }

  if ((propsFilters.filter?.sellerRole || []).length > 0 && !isEmpty(propsFilters.filter?.sellerRole || [])) {
    const key = 'seller_role';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.sellerRole || []),
      onRemove: handleRemoverRole,
    });
  }

  if ((propsFilters.filter?.vendor || []).length > 0 && !isEmpty(propsFilters.filter?.vendor || [])) {
    const key = 'vendor';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.vendor || []),
      onRemove: handleRemoverVendor,
    });
  }

  if ((propsFilters.filter?.brand || []).length > 0 && !isEmpty(propsFilters.filter?.brand || [])) {
    const key = 'brand';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.brand || []),
      onRemove: handleRemoverBrand,
    });
  }

  if ((propsFilters.filter?.categories || []).length > 0 && !isEmpty(propsFilters.filter?.categories || [])) {
    const key = 'category';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.categories || []),
      onRemove: handleRemoverCategories,
    });
  }

  if ((propsFilters.filter?.selectedBy || []).length > 0 && !isEmpty(propsFilters.filter?.selectedBy || [])) {
    const key = 'selected_by';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.selectedBy || []),
      onRemove: handleRemoverSelectedBy,
    });
  }

  if ((propsFilters.filter?.supplierId || []).length > 0 && !isEmpty(propsFilters.filter?.supplierId || [])) {
    const key = 'supplier';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, propsFilters.filter?.supplierId || []),
      onRemove: handleRemoverSupplier,
    });
  }

  if (propsFilters.filter?.isRemoted !== undefined) {
    const key = 'remote';
    appliedFilters.push({
      key,
      label: disambiguateLabel(
        key,
        propsFilters.filter?.isRemoted !== undefined ? String(propsFilters.filter?.isRemoted) : '',
      ),
      onRemove: handleRemoverRemote,
    });
  }

  if (propsFilters.filter?.coreProduct !== undefined) {
    const key = 'core';
    appliedFilters.push({
      key,
      label: disambiguateLabel(
        key,
        propsFilters.filter?.coreProduct !== undefined ? String(propsFilters.filter?.coreProduct) : '',
      ),
      onRemove: handleRemoverCore,
    });
  }

  if (propsFilters.filter?.gteStock) {
    appliedFilters.push({
      key: 'stock',
      label: disambiguateLabel('stock', [
        String(propsFilters.filter?.gteStock || 0),
        String(propsFilters.filter?.lteStock || 0),
      ]),
      onRemove: handleRemoverStock,
    });
  }

  if (propsFilters.filter?.gteDiscount) {
    appliedFilters.push({
      key: 'discount',
      label: disambiguateLabel('discount', [
        String(propsFilters.filter?.gteDiscount || 0),
        String(propsFilters.filter?.lteDiscount || 0),
      ]),
      onRemove: handleRemoverDiscount,
    });
  }

  if (propsFilters.filter?.gtePrice) {
    appliedFilters.push({
      key: 'price',
      label: disambiguateLabel('price', [
        String(propsFilters.filter?.gtePrice || 0),
        String(propsFilters.filter?.ltePrice || 0),
      ]),
      onRemove: handleRemoverPrice,
    });
  }

  if (propsFilters.filter?.stockInAfter || propsFilters.filter?.stockInBefore) {
    appliedFilters.push({
      key: 'stock_in',
      label: disambiguateLabel('stock_in', [
        String(propsFilters.filter?.stockInAfter),
        String(propsFilters.filter?.stockInBefore),
      ]),
      onRemove: handleRemoverStockIn,
    });
  }

  if ((propsFilters.filter?.sellerOrigin || []).length > 0 && !isEmpty(propsFilters.filter?.sellerOrigin || [])) {
    appliedFilters.push({
      key: 'origin',
      label: disambiguateLabel('origin', propsFilters.filter?.sellerOrigin || []),
      onRemove: handleRemoverOrigin,
    });
  }

  if (!!propsFilters.filter?.sales) {
    appliedFilters.push({
      key: 'sales',
      label: disambiguateLabel('sales', propsFilters.filter.sales),
      onRemove: handleRemoverSale,
    });
  }

  if (propsFilters.filter?.fbLastPostGte) {
    appliedFilters.push({
      key: 'fbLastPostGte',
      label: disambiguateLabel('fbLastPostGte', propsFilters.filter?.fbLastPostGte),
      onRemove: handleRemoverFbPost,
    });
  }

  if (propsFilters.filter?.lastCampaignCreated) {
    appliedFilters.push({
      key: 'lastCampaignCreated',
      label: disambiguateLabel('lastCampaignCreated', propsFilters.filter?.lastCampaignCreated),
      onRemove: handleRemoverLastCampaignCreated,
    });
  }

  if (!!propsFilters.filter?.productScore) {
    appliedFilters.push({
      key: 'productScore',
      label: disambiguateLabel('productScore', propsFilters.filter?.productScore),
      onRemove: handleRemoverScore,
    });
  }

  return {
    filters,
    appliedFilters,
  };

  function disambiguateLabel(key: string, value: string | any): string {
    switch (key) {
      case 'mark':
        return `Mark: ${(value as any[]).join(',')}`;
      case 'status':
        return `Status: ${(value as any[]).join(',')}`;
      case 'seller_type':
        return `Seller Type: ${(value as any[]).join(',')}`;
      case 'seller_role':
        return `Seller Role: ${(value as any[]).join(',')}`;
      case 'vendor':
        return `Vendor: ${(value as any[]).join(',')}`;
      case 'brand':
        return `Brand: ${(value as any[]).join(',')}`;
      case 'category':
        return `Category: ${(value as any[]).length}`;
      case 'origin':
        return `Origin: ${(value as any[]).join(',')}`;
      case 'supplier':
        return (
          <SupplierPicker
            selected={propsFilters.filter?.supplierId ?? []}
            setSelected={handleChangeSupplier}
            filter={propsFilters.supplierFilter}
            setFilter={handleChangeSupplierFillter}
            type="FILTER"
          />
        ) as any;
      case 'remote':
        return `Remoted ${value === 'true' ? ': Yes' : value === 'false' ? ': No' : ''}`;
      case 'core':
        return `Core Product: ${value === 'true' ? ': Yes' : value === 'false' ? ': No' : ''}`;
      case 'stock':
        return `Stock GTE: ${value[0]} | LTE: ${value[1]}`;
      case 'discount':
        return `Discount GTE: ${value[0]} | LTE: ${value[1]}`;
      case 'price':
        return `Price GTE: ${value[0]} | LTE: ${value[1]}`;
      case 'stock_in':
        return `Stock After: ${value[0]} | Before: ${value[1]}`;
      case 'selected_by':
        return `Selected By: ${(value as any[]).join(',')}`;
      case 'sales':
        return (
          <Modal
            title="Filter Product List by Sales"
            open={sale}
            onClose={() => setSale(!sale)}
            activator={
              <div onClick={() => setSale(true)}>{`Sales from ${value.startDate} -> ${value.endDate}, ${
                value.saleAmountGte ? `GTE: ${value.saleAmountGte}` : ''
              } ${value.saleAmountLte ? `| LTE: ${value.saleAmountLte}` : ''}`}</div>
            }
          >
            <Modal.Section>
              <FilterSale sales={propsFilters.filter?.sales ?? {}} setSale={handleChangeSale} />
            </Modal.Section>
          </Modal>
        ) as any;
      case 'fbLastPostGte':
        return `Facebook Last Post ${value ? `GTE: ${value}` : ''}`;
      case 'lastCampaignCreated':
        return `Last Campaign Created ${value ? `GTE: ${value}` : ''}`;
      case 'productScore':
        const d = [];
        if (value.title) {
          d.push(`Title: ${value.title}`);
        }
        if (value.description) {
          d.push(`Description: ${value.description}`);
        }
        if (value.picture) {
          d.push(`Picture: ${value.picture}`);
        }
        if (value.video) {
          d.push(`Video: ${value.video}`);
        }
        return `Score ${d.join('|')}`;
      case 'taggedWith':
        return `Tagged with ${value}`;
      case 'accountStatus':
        return (value as string[]).map(val => `Customer ${val}`).join(', ');
      default:
        return value as string;
    }
  }

  function isEmpty(value: string | any[]) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }
}
