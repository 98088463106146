import { gql } from '@apollo/client';

export const QUERY_ADMIN_LIST = gql`
  query adminList {
    adminList {
      id
      name
      image
      active
      groupID
      storeId
      warehouseIds
      role
      position
      balance
      balanceHold
      depositAmount
      deliveryCreditLimit
    }
  }
`;
