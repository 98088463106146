/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Avatar, Button, Modal, Thumbnail, Text, Tooltip, Layout, Spinner } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Graph } from 'src/generated/graph';
import { State, SupplierFilter } from './SupplierFilter';
import PolarisPagination from 'src/components/polaris/PolarisPagination';
// import { SelectedSupplierList } from './SelectedSupplierList';
// import { CancelMinor } from '@shopify/polaris-icons';

const QUERYSUPPLIER = gql`
  query supplierList(
    $offset: Int!
    $limit: Int!
    $roles: [SupplierRole]
    $sellerType: [SellerType]
    $name: String
    $origin: Int
    $phoneNumber: String
    $active: Boolean
    $reviewStatus: [SupplierReviewStatus]
    $recentUseDeliveryService: Boolean
    $isVerified: Boolean = false
    $myFollowwing: Boolean = false
    $isSeller: Boolean
    $subscriptionPlan: [SellerSuscriptionPlan]
  ) {
    supplierList(
      offset: $offset
      limit: $limit
      roles: $roles
      sellerType: $sellerType
      name: $name
      origin: $origin
      phoneNumber: $phoneNumber
      active: $active
      reviewStatus: $reviewStatus
      recentUseDeliveryService: $recentUseDeliveryService
      isVerified: $isVerified
      myFollowwing: $myFollowwing
      isSeller: $isSeller
      subscriptionPlan: $subscriptionPlan
    ) {
      id
      name
      logo
      role
      origin
      sellerType
      initial
      address
      isSeller
      subscription {
        id
        planName
        expiredDate
        fee
        interval
      }
    }
  }
`;

interface Props {
  type?: 'BUTTON' | 'FILTER';
  selected: number[];
  setSelected: (v: number[]) => void;
  filter?: State;
  setFilter: (supplier: number[], v: State) => void;
  onQuery?: (supplier: number[]) => void;
  firstDefault?: boolean;
}

export function useQueryAllSupplier(filter: State) {
  return useLazyQuery<Graph.Query>(
    gql`
      query supplierList(
        $offset: Int!
        $limit: Int!
        $roles: [SupplierRole]
        $sellerType: [SellerType]
        $name: String
        $origin: Int
        $phoneNumber: String
        $active: Boolean
        $reviewStatus: [SupplierReviewStatus]
        $recentUseDeliveryService: Boolean
        $isVerified: Boolean = false
        $myFollowwing: Boolean = false
        $isSeller: Boolean
        $subscriptionPlan: [SellerSuscriptionPlan]
      ) {
        supplierList(
          offset: $offset
          limit: $limit
          roles: $roles
          sellerType: $sellerType
          name: $name
          origin: $origin
          phoneNumber: $phoneNumber
          active: $active
          reviewStatus: $reviewStatus
          recentUseDeliveryService: $recentUseDeliveryService
          isVerified: $isVerified
          myFollowwing: $myFollowwing
          isSeller: $isSeller
          subscriptionPlan: $subscriptionPlan
        ) {
          id
        }
      }
    `,
    {
      variables:
        filter && filter.origin
          ? {
              offset: 0,
              limit: 10000,
              ...filter,
              origin: Number(filter.origin),
            }
          : {
              offset: 0,
              limit: 10000,
              ...filter,
            },
    },
  );
}

export function SupplierPicker(props: Props) {
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [firstDefault, setFirstDefault] = useState(props.firstDefault || false);
  const [filter, setFilter] = useState<State>(
    props.filter
      ? props.filter
      : {
          myFollowwing: false,
          isVerified: false,
        },
  );
  const { data, loading } = useQuery<Graph.Query>(QUERYSUPPLIER, {
    fetchPolicy: 'no-cache',
    variables:
      filter && filter.origin
        ? {
            offset: offset * 25,
            limit: 25 * (offset + 1),
            ...filter,
            origin: Number(filter.origin),
          }
        : {
            offset: offset * 25,
            limit: 25 * (offset + 1),
            ...filter,
          },
  });
  const [allSupplier] = useQueryAllSupplier(filter);
  const toggleActive = useCallback(() => setOpen(!open), [open]);

  useEffect(() => {
    if (!!firstDefault) {
      if (props.onQuery) {
        allSupplier().then(res => {
          props.onQuery && props.onQuery(res.data?.supplierList?.map(x => Number(x.id)) ?? []);
        });
      }
      setFirstDefault(false);
    }
  }, [firstDefault]);

  const activator = () => {
    if (props.type === 'FILTER') {
      return (
        <div onClick={toggleActive} className="w-[100%]">
          <div>{props.selected ? props.selected.length + ' sellers' : 'Seller Picker'}</div>
        </div>
      );
    }

    return (
      <Button size="slim" onClick={toggleActive}>
        Seller Picker {props.selected && props.selected.length > 0 ? `(${props.selected.length})` : ''}
      </Button>
    );
  };

  return (
    <Modal
      open={open}
      fullScreen
      onClose={toggleActive}
      title="Seller Picker"
      activator={activator()}
      primaryAction={{
        content: 'OK',
        onAction: () => {
          allSupplier().then(res => {
            props.setFilter(res.data?.supplierList?.map(x => Number(x.id)) ?? [], filter);
            toggleActive();
          });
        },
      }}
    >
      <Modal.Section flush>
        <div>
          <SupplierFilter value={filter} setValue={setFilter} />
        </div>
      </Modal.Section>
      <Modal.Section flush>
        <div className="flex flex-row items-center w-full">
          <div className="ml-3 pr-2"></div>
          <div className="w-full">
            <PolarisPagination
              type="table"
              hasNext={(data?.supplierList?.length ?? 0) >= 25}
              hasPrevious={offset > 0}
              label={`${offset * 25 + 1} - ${25 * (offset + 1)} of supplier`}
              onNext={() => setOffset(offset + 1)}
              onPrevious={() => setOffset(offset - 1)}
            />
          </div>
        </div>
      </Modal.Section>
      <Modal.Section flush>
        <div style={{ height: 500 }}>
          <Layout>
            <Layout.Section fullWidth>
              {loading && (
                <div className="flex flex-row justify-between items-center border-b-[0.025rem] border-b-[#DDE0E4]">
                  <div className="flex flex-row items-center p-3 w-[250px]">
                    <Spinner size="small" />
                  </div>
                </div>
              )}
              {data &&
                data?.supplierList?.map(x => {
                  return (
                    <div
                      key={x.id}
                      className="cursor-pointer flex flex-row justify-between items-center border-b-[0.025rem] border-r-[0.025rem] border-b-[#DDE0E4]"
                      onClick={() => {
                        props.setFilter([Number(x.id)], filter);
                        toggleActive();
                      }}
                    >
                      <div className="flex flex-row items-center p-3 w-[250px]">
                        <div className="pr-3">
                          {x.logo ? (
                            <Thumbnail alt="" source={x.logo ?? ''} size="small" />
                          ) : (
                            <Avatar initials={x.initial ?? ''} size="medium" shape="square" />
                          )}
                        </div>
                        <div>
                          <small>{x.name}</small>
                          <br />
                          {x.subscription && (
                            <Tooltip
                              content={
                                <div>
                                  <b className="text-success">Fee: ${x.subscription.fee}</b>
                                  <br />
                                  <small className="text-danger">Expire date: {x.subscription.expiredDate}</small>
                                  <br />
                                  <small>{x.subscription.interval}</small>
                                </div>
                              }
                            >
                              <div
                                className={`${
                                  x.subscription.planName === 'BOOST'
                                    ? 'bg-red-500'
                                    : x.subscription.planName === 'PRO'
                                    ? 'bg-green-500'
                                    : 'bg-neutral-500'
                                } text-white rounded-full w-[50px] text-center border-dotted border-[0.025rem]`}
                              >
                                <small>{x.subscription?.planName}</small>
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div className="w-[200px]">
                        <small>{x.address}</small>
                        <br />
                        <small className="text-red-500">{x.origin}</small>
                      </div>
                      <div className="w-[200px]">
                        <small>Type: {x.sellerType}</small>
                        <br />
                        <small>Role: {x.role}</small>
                        {x.isSeller && (
                          <Text as={'p'} variant={'headingXs'} color="success">
                            L192&apos;s seller
                          </Text>
                        )}
                      </div>
                    </div>
                  );
                })}
            </Layout.Section>
          </Layout>
        </div>
      </Modal.Section>
    </Modal>
  );
}
