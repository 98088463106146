import React, { ReactElement, useState } from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { gql } from '@apollo/client';
import { CategoryPicker } from '../../components/pickers/CategoryPicker';
import OffsetPagination from 'src/components/layout/Pagination/OffsetPagination';

interface ProductLiveItem {
  id: number;
  code: string;
  stock: number;
  live_time: number;
  sold: number;
  picture: string;
}

const QUERY = gql`
  query ReportLiveProductList($offset: Int!, $limit: Int!, $categoryId: Int, $live: Int) {
    reportLiveProductList(offset: $offset, limit: $limit, categoryId: $categoryId, live: $live)
  }
`;

function LiveProductListTableBody(data: ProductLiveItem[]): ReactElement {
  return (
    <tbody>
      {data.map(x => (
        <tr key={x.id.toString()}>
          <td>
            <img src={x.picture} style={{ width: 73, height: 100 }} alt="" />
          </td>
          <td>{x.code}</td>
          <td>{x.stock}</td>
          <td>{x.live_time}</td>
          <td>{x.sold}</td>
        </tr>
      ))}
    </tbody>
  );
}

function LiveProductListTable(data: ProductLiveItem[]): ReactElement {
  return (
    <table className="table table-bordered table-lg table-v2 table-striped">
      <thead>
        <tr>
          <th style={{ width: 150 }}></th>
          <th>Code</th>
          <th style={{ width: 100 }}>Stock</th>
          <th style={{ width: 100 }}>Live</th>
          <th style={{ width: 100 }}>Sold</th>
        </tr>
      </thead>
      {LiveProductListTableBody(data)}
    </table>
  );
}

export function ReportLiveProductListScreen(): React.ReactElement {
  const [categoryId, setCategoryId] = useState(0);
  const inputRef = React.createRef<HTMLInputElement>();
  const [live, setLive] = useState(0);
  function LiveProductFilter(): ReactElement {
    function onSubmit(): void {
      setLive(Number(inputRef.current?.value));
    }

    return (
      <div style={{ width: 600 }}>
        <div className="form-group">
          <CategoryPicker
            value={categoryId}
            onChange={(t): void => {
              setCategoryId(t);
            }}
          />
        </div>

        <div className="lf-input-group" style={{ width: 350 }}>
          <div className="lf-form-control" style={{ width: 150 }}>
            Number of Live
          </div>
          <input className="lf-form-control" type="number" ref={inputRef} defaultValue={'0'} style={{ width: 75 }} />
          <button className="lf-btn btn-primary" style={{ width: 150 }} onClick={onSubmit}>
            Filter
          </button>
        </div>
      </div>
    );
  }

  return (
    <Content>
      <SectionBlock title="Live Product">
        <div className="mb-3">{LiveProductFilter()}</div>
        <OffsetPagination
          query={QUERY}
          body={LiveProductListTable}
          variables={{ categoryId: categoryId, live: live, offset: 0 }}
        />
      </SectionBlock>
    </Content>
  );
}
