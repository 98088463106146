import React from 'react';
import { Content } from '../light/Content';
import { SectionBlock } from '../light/SectionBlock';
import { BlockLoading } from './BlockLoading';

interface Props {
  title: string;
  width?: number;
}

export function SectionLoading(props: Props) {
  return (
    <Content>
      <SectionBlock title={props.title} width={props.width}>
        <BlockLoading />
      </SectionBlock>
    </Content>
  );
}
