/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon } from '@shopify/polaris';
import {
  CancelMajor,
  CircleDisableMinor,
  QuestionMarkMinor,
  RiskMinor,
  StatusActiveMajor,
} from '@shopify/polaris-icons';
import React, { CSSProperties, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';

interface Props {
  position?: 'top-star' | 'top-end' | 'bottom-start' | 'bottom-end';
  status?: 'success' | 'error' | 'warning' | 'info';
  content?: string;
  index?: number;
  onClose?: () => void;
}

export function CustomToast(props: Props) {
  let style: CSSProperties = { top: '3.5rem', right: '.5rem' };
  let classname = 'bg-secondary text-white';
  let icon = StatusActiveMajor;

  switch (props.position) {
    case 'top-end':
      style = {
        top: `${3.5 * (props.index || 1)}rem`,
        right: '.5rem',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        zIndex: 999,
        fill: '#f3f3f3',
        borderRadius: 7,
      };
      break;
    case 'top-star':
      style = {
        top: `${3.5 * (props.index || 1)}rem`,
        left: '.5rem',
      };
      break;
    case 'bottom-start':
      style = {
        bottom: `${1.5 * (props.index || 1)}rem`,
        left: '.5rem',
      };
      break;
    case 'bottom-end':
      style = {
        bottom: `${1.5 * (props.index || 1)}rem`,
        right: '.5rem',
      };
      break;
  }

  switch (props.status) {
    case 'success':
      classname = 'bg-success text-white';
      icon = StatusActiveMajor;
      break;
    case 'error':
      classname = 'bg-danger text-white';
      icon = CircleDisableMinor;
      break;
    case 'warning':
      classname = 'bg-waring text-white';
      icon = RiskMinor;
      break;
    case 'info':
      classname = 'bg-info text-white';
      icon = QuestionMarkMinor;
      break;
  }

  return (
    <div className={`rounded-md fixed flex-wrap items-center toast flex z-[999] ${classname}`} style={style}>
      <div className="p-3">
        <Icon source={icon as any} />
      </div>
      <div
        style={{ marginLeft: 3, paddingTop: 3, paddingBottom: 3, paddingRight: 3 }}
        className="ml-3 pt-3 pb-3 pr-2 max-w-md"
      >
        <i>{props.content}</i>
      </div>
      <div
        style={{ padding: 3, cursor: 'pointer' }}
        className="p-3 cursor-pointer rounded-e-md h-[100%]"
        onClick={props.onClose && props.onClose}
      >
        <Icon source={CancelMajor as any} />
      </div>
    </div>
  );
}

interface PropsContext {
  content: string;
  status?: 'success' | 'error' | 'warning' | 'info';
}

const CustomContext = React.createContext<{
  toasts: PropsContext[];
  setToasts: (e: PropsContext[]) => void;
  onToast: (e: PropsContext) => void;
}>({
  toasts: [],
  setToasts: () => {
    //...
  },
  onToast: () => {
    //...
  },
});

export function useCustomToast() {
  return useContext(CustomContext);
}

export function CustomToastMultiple(props: PropsWithChildren<unknown>) {
  const ref = useRef<HTMLDivElement>(null);
  const [item, setItem] = useState<PropsContext[]>([]);

  useEffect(() => {
    if (item.length > 0) {
      setTimeout(() => {
        handleRemove(-1);
      }, 3500);
    }
  }, [item]);

  const handleAdd = useCallback(
    async (e: PropsContext | null) => {
      const x = [...item];

      if (e) {
        await x.push(e);
      } else {
        await x.push({ content: '', status: 'success' });
      }

      if (x.length > 7) {
        await x.splice(0, 1);
      }

      await setItem(prev => [...prev, ...x]);
    },
    [item],
  );

  const handleRemove = (index: number) => {
    const x = [...item];
    if (index >= 0) {
      x.splice(index, 1);
      setItem(item.filter((_, i) => i !== index));
    } else {
      setItem([]);
    }
  };

  return (
    <CustomContext.Provider value={{ toasts: item, setToasts: setItem, onToast: e => handleAdd(e) }}>
      <div ref={ref} style={{ display: 'none' }} onClick={() => handleAdd(null)}>
        add
      </div>
      {item.map((x, i) => {
        return (
          <CustomToast
            position="top-end"
            status={x.status as any}
            content={x.content}
            key={i}
            index={i + 1}
            onClose={() => handleRemove(i)}
          />
        );
      })}
      {props.children}
    </CustomContext.Provider>
  );
}
