import { gql, useSubscription } from '@apollo/client';
import React from 'react';
import { Graph } from 'src/generated/graph';
import MessagePopup from '../popup_msg/general_event_pop';

const GENERAL_EVENT_ADDED = gql`
  subscription GeneralEventAdded($type: String) {
    generalEventAdded(type: $type) {
      type
      targetGroupId
      targetAdminId
      payload
    }
  }
`;

export function GeneralEventAdd(props: React.PropsWithChildren<unknown>) {
  const { data } = useSubscription<Graph.Subscription | undefined>(GENERAL_EVENT_ADDED);

  return (
    <div style={{ position: 'relative' }}>
      {data?.generalEventAdded &&
        [data?.generalEventAdded].map((x, i) => (
          <MessagePopup
            dataMsg={x}
            id={i + Math.round(new Date().getTime() / 1000)}
            key={i + Math.round(new Date().getTime() / 1000)}
          />
        ))}

      {props.children}
    </div>
  );
}
