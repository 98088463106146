import { ReactElement } from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { useQueryString } from '../../libs/QueryString';
import { useHistory } from 'react-router';

interface GraphSearchResultItem {
  id: number;
  picture: string;
  title: string;
  code: string;
  price: number;
}

interface GraphSearchResult {
  items: GraphSearchResultItem[];
}

const QUERY = gql`
  query AppSearchPreview($filter: JSON) {
    productSearchLegacy(filter: $filter)
  }
`;

function AppSearchPreviewRow(props: { item: GraphSearchResultItem }): ReactElement {
  const { item } = props;

  return (
    <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '10px 0' }}>
      <img src={item.picture} style={{ width: 70, height: 98, marginRight: 10, flexShrink: 0 }} alt="" />
      <div>
        <div>{item.title}</div>
        <div style={{ marginTop: 5 }}>
          <strong>{item.code}</strong>
        </div>
        <div style={{ marginTop: 5 }}>
          <strong>${item.price.toLocaleString(undefined, { maximumFractionDigits: 2 })}</strong>
        </div>
      </div>
    </div>
  );
}

function AppSearchPreview(props: { keyword: string }): ReactElement {
  const { loading, data } = useQuery<Graph.Query>(QUERY, {
    variables: {
      filter: {
        keyword: props.keyword,
        sort: 'popular',
      },
    },
  });

  if (loading || !data) {
    return (
      <div>
        <div className="ph-big bg-loading mb-2"></div>
        <div className="ph-big bg-loading mb-2"></div>
        <div className="ph-big bg-loading mb-2"></div>
      </div>
    );
  }

  return (
    <div>
      {(data.productSearchLegacy as GraphSearchResult).items.map(x => (
        <AppSearchPreviewRow key={x.id} item={x} />
      ))}
    </div>
  );
}

export function AppSearchPreviewScreen(): ReactElement {
  const history = useHistory();
  const { keyword } = useQueryString<{ keyword: string }>({
    keyword: 'diaper',
  });

  return (
    <Content>
      <SectionBlock title="App Search Preview" width={350}>
        <form
          className="mb-4"
          action=""
          method="GET"
          onSubmit={(e): void => {
            e.preventDefault();
            const query = new URLSearchParams();
            query.set('keyword', e.currentTarget.keyword.value);
            history.push('?' + query.toString());
          }}
        >
          <input name="keyword" className="lf-form-control" placeholder="Search..." defaultValue={keyword} />
        </form>

        <AppSearchPreview key={keyword} keyword={keyword} />
      </SectionBlock>
    </Content>
  );
}
