import React from 'react';

type Props = {
  isHidden: boolean;
  type?: string | null;
  onClickClose: () => void;
  message: string;
};

export class AlertComponent extends React.Component<Props> {
  render() {
    return (
      <div
        className={`alert alert-small-${this.props.isHidden ? 'right' : 'left'} alert-${
          this.props.type == null ? 'danger' : this.props.type
        } alert-dismissible`}
        role="alert"
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          onClick={this.props.onClickClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <strong>{this.props.type === 'danger' ? 'Oh snap' : 'Well done'}!</strong> {this.props.message}
      </div>
    );
  }
}
