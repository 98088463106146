import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import { InventoryRoutes } from './InventoryRoutes';
import withTracker from '../components/GoogleAnalytics';
import { OtherRoutes } from './OtherRoutes';
import { MarketRoutes } from './MarketRoutes';
import { SupplierRoutes } from './SupplierRoutes';
import { AdminGroupRoutes } from './AdminGroupRoutes';
import { ReportRoutes } from './ReportRoutes';
import { DeliveryRoutes } from './DeliveryRoutes';

import GeneratedRoutes from 'src/generated/route';

const allRoutes = [
  ...InventoryRoutes,
  ...OtherRoutes,
  ...MarketRoutes,
  ...SupplierRoutes,
  ...AdminGroupRoutes,
  ...ReportRoutes,
  ...DeliveryRoutes,
  ...GeneratedRoutes,
];

export function createRoutes() {
  return allRoutes.map(x => {
    return <Route {...x} key={x.path} component={withTracker(x.component as FunctionComponent)} />;
  });
}
