import { Graph } from '../../../generated/graph';
import { UploadInlineFile } from '../../../components/UploadInlineFile';
import { useDefaultValue } from '../../../libs/Common';

interface Props {
  value: Graph.PurchaseOrderInput;
  onChange: (v: Partial<Graph.PurchaseOrderInput>) => void;
}

export function PurchaseOrderBeforeArrivedPartial({ value, onChange }: Props) {
  return (
    <fieldset>
      <legend>
        <span>Before Arrive</span>
      </legend>
      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label>Shipping Invoice #</label>
            <div className="lf-input-group">
              <input
                className="lf-form-control"
                type="string"
                value={useDefaultValue(value.shippingNumber, '')}
                onChange={e => onChange({ ...value, shippingNumber: e.currentTarget.value })}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label>Volume (CBM)</label>
            <div className="lf-input-group">
              <input
                className="lf-form-control"
                type="string"
                value={useDefaultValue(value.volumne, '')}
                onChange={e => onChange({ ...value, volumne: e.currentTarget.value })}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label>Shipping Invoice</label>
            <UploadInlineFile
              value={useDefaultValue(value.shippingFile, undefined)}
              onChange={e => onChange({ ...value, shippingFile: e })}
            />
          </div>
        </div>
      </div>{' '}
    </fieldset>
  );
}
