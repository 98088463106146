import { PropsWithChildren, useContext, createContext, useState, useCallback, useEffect } from 'react';
import cookie from 'js-cookie';

const TokenContext = createContext<{ token: string; setToken: (token: string) => void }>({
  token: '',
  setToken: () => {
    throw 'Not implemented';
  },
});

export function useToken() {
  return useContext(TokenContext);
}

export function TokenProvider({ children }: PropsWithChildren<unknown>) {
  const [token, setToken] = useState(localStorage.getItem('token') || cookie.get('lf_admin_token') || '');
  const setTokenCallback = useCallback(
    newToken => {
      setToken(newToken);
      localStorage.setItem('token', newToken);
      const now = new Date();
      const time = now.getTime();
      const expireTime = time + 1000 * 36000;
      now.setTime(expireTime);
      cookie.set('lf_admin_token', newToken, {
        domain: window.location.hostname === 'localhost' ? window.location.hostname : '.l192.com',
        path: '/',
        expires: now,
      });
    },
    [setToken],
  );

  useEffect(() => {
    if (!!token && !cookie.get('lf_admin_token')) {
      const now = new Date();
      const time = now.getTime();
      const expireTime = time + 1000 * 36000;
      now.setTime(expireTime);
      cookie.set('lf_admin_token', token, {
        domain: window.location.hostname === 'localhost' ? window.location.hostname : '.l192.com',
        path: '/',
        expires: now,
      });
    }
  }, []);

  return <TokenContext.Provider value={{ token, setToken: setTokenCallback }}>{children}</TokenContext.Provider>;
}
