import React, { useEffect, useState } from 'react';
import styles from './tasks.module.scss';
import { useQuery } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { QUERY_PACKAGE_LIST } from '../query';
import { Link } from 'react-router-dom';
import { BlockLoading } from '../../../components/loading/BlockLoading';
import { Table } from 'src/components/layout/tables/Table';
import Button from 'src/components/layout/xform/Button';
import Formatter from 'src/libs/Formatter';
import { Checkbox } from 'src/components/form/FormComponent';
import { useQueryString } from 'src/libs/QueryString';
import { useHistory, useLocation } from 'react-router';

function Schedule({ items }: { items: Graph.Package[] }) {
  if (items.length > 0) {
    return (
      <div className="mb-3">
        <Table stripe={true} border={true}>
          <thead>
            <tr>
              <th style={{ width: 50 }}>Type</th>
              <th style={{ width: 180 }}> Date</th>
              <th style={{ width: 120 }}>Services</th>
              <th>Package</th>
              <th style={{ width: 200 }}>Merchant</th>
              <th style={{ width: 120 }}>Claimed By</th>
              <th style={{ width: 200 }}>Customer</th>
              <th style={{ width: 220 }}>Route</th>
            </tr>
          </thead>

          <tbody>
            {items.map(item => (
              <tr
                key={item.id}
                style={{
                  backgroundColor: item.isFinal ? (item.barcode ? '#8ce396' : '#ee8f8f') : '#fff',
                  borderBottom: '1px solid #eeeeee',
                }}
              >
                <td className="text-center">
                  <i
                    className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
                    style={{ fontSize: 18, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
                  ></i>
                </td>
                <td>
                  {!!item.barcode &&
                  !item.requestPickupAt &&
                  item?.claimPickupBy?.groupID !== 5 &&
                  item?.type !== 'INTERNAL' &&
                  item?.supplier ? (
                    <h5 style={{ color: '#e22f2f', fontWeight: 'bold' }}>Seller Drop</h5>
                  ) : (
                    ''
                  )}
                  {item?.requestPickupAt ? (
                    <div>
                      <strong>Requested At</strong>
                      <br /> {item?.requestPickupAt}
                    </div>
                  ) : (
                    ''
                  )}
                  {item?.appointedAt ? (
                    <div>
                      <strong>Appointed At</strong>
                      <br /> {item?.appointedAt}
                    </div>
                  ) : (
                    ''
                  )}
                </td>
                <td>{item.type === 'THIRD_PARTY' ? 'Third Party' : 'L192'}</td>
                <td>
                  <div className={styles.badge_wrap}>
                    <div>
                      <Link className="h4" to={`/package/detail/${item.id}`} target="_blank">
                        {item.id.toString().padStart(8, '0')}
                      </Link>
                    </div>
                    {Number(item?.attempt) > 0 && (
                      <div>
                        <span
                          style={{
                            fontSize: 10,
                            background: '#e22f2f',
                            display: 'inline-block',
                            margin: '6px 0 0 5px',
                            padding: '2px 5px',
                          }}
                        >
                          Attempt ({item?.attempt})
                        </span>
                      </div>
                    )}
                    {item?.useInsurance === true && (
                      <div>
                        <span
                          style={{
                            fontSize: 10,
                            background: '#F94892',
                            display: 'inline-block',
                            margin: '6px 0 0 4px',
                            padding: '3px 5px 1px 5px',
                            color: '#fff',
                          }}
                        >
                          {Formatter.beautifyUglyName('Insurance')}
                        </span>
                      </div>
                    )}
                    {item?.isPrepayment === true && (
                      <div>
                        <span
                          style={{
                            fontSize: 10,
                            background: '#009432',
                            display: 'inline-block',
                            margin: '6px 0 0 5px',
                            padding: '2px 5px',
                          }}
                        >
                          Pre Payment
                        </span>
                      </div>
                    )}
                    {!!item?.isDropbox && (
                      <div>
                        <span
                          style={{
                            fontSize: 10,
                            background: '#eb3b5a',
                            display: 'inline-block',
                            margin: '6px 0 0 4px',
                            padding: '3px 5px 0px 5px',
                            color: '#fff',
                          }}
                        >
                          CTD
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    Cash to collect: <strong>${item.cash?.toFixed(2)}</strong> | Barcode:{' '}
                    <strong>{item.barcode}</strong>
                    {item?.barcodeInitial && (
                      <strong style={{ color: '#0052cc' }}>
                        &nbsp; <i>({item?.barcodeInitial})</i>
                      </strong>
                    )}
                  </div>
                  {!!item?.hasReturningItem && (
                    <div>
                      <span
                        style={{
                          fontSize: 13,

                          display: 'inline-block',

                          fontWeight: 'bold',
                          color: 'RED',
                        }}
                      >
                        <i className="fas fa-box-alt"></i> CUSTOMER WILL RETURN A PRODUCT
                      </span>
                    </div>
                  )}
                  {!!item?.returningFromPackageId && (
                    <div>
                      <span
                        style={{
                          fontSize: 13,
                          display: 'inline-block',

                          fontWeight: 'bold',
                          color: '#1C6DD0',
                        }}
                      >
                        <i className="fas fa-hand-holding-box"></i> RETURN FROM PACKAGE #{item?.returningFromPackageId}
                      </span>
                    </div>
                  )}
                </td>
                <td>
                  <strong>{item.supplier?.name}</strong>
                </td>
                <td>
                  <strong>{item.claimDeliveryBy?.name}</strong>
                </td>
                <td>
                  {item.customer && (
                    <div>
                      <strong>{item.customer.name}</strong>
                      <br />
                      <span>{item.customer.phoneNumber}</span>
                    </div>
                  )}
                </td>
                <td>
                  <strong style={{ fontSize: 14 }}>
                    {item.currentWarehouse?.shortName}
                    &nbsp;&nbsp;
                    <i className="fas fa-arrow-right" />
                    &nbsp;&nbsp; {item.nextWarehouse?.shortName ?? 'Customer'}
                  </strong>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
  return null;
}

function PackageButtonFilter({ items }: { items: Graph.Package[] }) {
  const [hubName, setHubName] = useState('All');
  const hubs: { title: string; total: number; isCtd?: number | undefined }[] = [
    { title: 'All', total: items.length, isCtd: items.filter(x => !!x.isDropbox).length },
  ];
  items.map(x => {
    const title = x?.currentWarehouse?.shortName || 'Little Fashion';
    const index = hubs.findIndex(h => h.title === title);

    if (index < 0) {
      hubs.push({ title, total: 1, isCtd: 0 });
    } else {
      const hub = hubs[index];
      hubs[index] = { title, total: hub.total + 1, isCtd: Number(hub.isCtd) + Number(x.isDropbox || 0) };
    }
    return null;
  });
  return (
    <>
      <div className={styles.btn_filter_wrap}>
        {hubs.map((h, i) => (
          <div key={i}>
            <Button
              theme={hubName === h.title ? 'primary' : 'secondary'}
              value={h.title}
              onClick={() => setHubName(h.title)}
            >
              {h.title} ({h.total})
              {Number(h.isCtd) > 0 && (
                <span style={{ color: '#eb3b5a', fontSize: '12px' }}> &nbsp;CTD : {h.isCtd}</span>
              )}
            </Button>
          </div>
        ))}
      </div>

      <Schedule
        items={items.filter(
          x =>
            hubName === 'All' ||
            (hubName === 'Little Fashion' && x.currentWarehouse === null) ||
            x.currentWarehouse?.shortName === hubName,
        )}
      />

      {/* {renderPackage(
        items.filter(
          x =>
            hubName === 'All' ||
            (hubName === 'Little Fashion' && x.currentWarehouse === null) ||
            x.currentWarehouse?.shortName === hubName,
        ),
      )} */}
    </>
  );
}

export function PackageAbandonedListScreen() {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const qs = useQueryString<{
    nobarcode: '1' | '0';
    onlyfinal: '1' | '0';
    noClaimed: '1' | '0';
    claimed: '1' | '0';
    thirdParty: '1' | '0';
    internal: '1' | '0';
    isSellerDrop: '1' | '0';
  }>({
    nobarcode: '1',
    onlyfinal: '1',
    noClaimed: '0',
    claimed: '0',
    thirdParty: '0',
    internal: '0',
    isSellerDrop: '0',
  });
  const [onlyNoBarcode, setOnlyNoBarcode] = useState(qs.nobarcode === '1');
  const [onlyIsFinal, setOnlyIsFinal] = useState(qs.onlyfinal === '1');
  const [noClaimed, setNoClaimed] = useState(qs.noClaimed === '1');
  const [claimed, setClaimed] = useState(qs.claimed === '1');
  const [onlyThirdParty, setOnlyThirdParty] = useState(qs.thirdParty === '1');
  const [onlyInternal, setOnlyInternal] = useState(qs.internal === '1');
  const [onlySellerDrop, setOnlySellerDrop] = useState(qs.isSellerDrop === '1');
  const query = `${pathname}?nobarcode=${onlyNoBarcode ? '1' : '0'}&onlyfinal=${onlyIsFinal ? '1' : '0'}&noClaimed=${
    noClaimed ? '1' : '0'
  }&claimed=${claimed ? '1' : '0'}&thirdParty=${onlyThirdParty ? '1' : '0'}&internal=${
    onlyInternal ? '1' : '0'
  }&isSellerDrop=${onlySellerDrop ? '1' : '0'}`;

  useEffect(() => {
    history.replace(query + '#abandoned');
  }, [search, query, history]);

  const { data } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, { variables: { status: ['ABANDONED'] } });
  if (!data?.packageList) return <BlockLoading />;
  return (
    <>
      <div className={styles.CheckboxWrap}>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#e22f2f',
          }}
        >
          <div>
            <Checkbox
              label=" Need to package first"
              checked={onlyNoBarcode}
              onChange={e => {
                setOnlyNoBarcode(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#009432',
          }}
        >
          <div>
            <Checkbox
              label="Ready to deliver to customer"
              checked={onlyIsFinal}
              onChange={e => {
                setOnlyIsFinal(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Claimed"
              checked={claimed}
              onChange={e => {
                setClaimed(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Unclaimed"
              checked={noClaimed}
              onChange={e => {
                setNoClaimed(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Third Party"
              checked={onlyThirdParty}
              onChange={e => {
                setOnlyThirdParty(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Internal Package"
              checked={onlyInternal}
              onChange={e => {
                setOnlyInternal(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Seller Drop"
              checked={onlySellerDrop}
              onChange={e => {
                setOnlySellerDrop(e.target.checked);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div>
        <PackageButtonFilter
          items={data?.packageList.filter(x => {
            const sellerDrop =
              !onlySellerDrop &&
              !!x.barcode &&
              !x.requestPickupAt &&
              x?.claimPickupBy?.groupID !== 5 &&
              x?.type !== 'INTERNAL' &&
              x?.supplier;
            const a =
              (!onlySellerDrop &&
                x.barcode === null &&
                ((onlyThirdParty && x.type === 'THIRD_PARTY') || (!onlyThirdParty && x.type !== 'THIRD_PARTY'))) ||
              (onlyNoBarcode && sellerDrop);
            const b =
              (onlyIsFinal &&
                !onlySellerDrop &&
                x.isFinal &&
                x.barcode !== null &&
                ((onlyThirdParty && x.type === 'THIRD_PARTY') || (!onlyThirdParty && x.type !== 'THIRD_PARTY'))) ||
              (onlyIsFinal && sellerDrop);
            const d = noClaimed && x.claimDeliveryBy === null;
            const e = !noClaimed && x;
            const f = claimed && x.claimDeliveryBy !== null;
            const g = !claimed && x;
            const h = onlyInternal && x.type === 'INTERNAL';
            const i = !onlyInternal && x;
            const j =
              onlySellerDrop &&
              !!x.barcode &&
              !x.requestPickupAt &&
              x?.claimPickupBy?.groupID !== 5 &&
              x?.type !== 'INTERNAL' &&
              x?.supplier;
            return ((a || b || j) && (d || f)) || ((a || b || j) && e && g && i) || h;
          })}
        />
      </div>
    </>
  );
}
