/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from '@apollo/client';
import { Avatar, ChoiceList, Divider, Select } from '@shopify/polaris';
import React, { useState } from 'react';
import { PolarisTextField } from 'src/components/polaris/PolarisTextField';
import { Graph } from 'src/generated/graph';

export const QUERY = gql`
  query adminList {
    adminList(active: true) {
      id
      name
      role
      groupID
      isProductTeam
      zones
      warehouseIds
      image
    }
    adminGroupList {
      id
      name
    }
  }
`;

interface Props {
  selected: any[];
  setSelected: (v: any[]) => void;
}

export function FilterAdmin(props: Props) {
  const [searchInput, setSearchInput] = useState('');
  const [selectGroup, setSelectGroup] = useState('0');
  const { data, loading } = useQuery<Graph.Query>(QUERY, {
    variables: { status: ['ARRIVED'] },
    fetchPolicy: 'cache-and-network',
  });

  const filterRegex = new RegExp(searchInput, 'i');

  const groups = data
    ? data?.adminGroupList?.map(x => {
        return {
          label: x.name || '',
          value: String(x.id || '0'),
        };
      })
    : [];

  const optionsGroup = [...[{ label: 'All Group', value: '0' }], ...(groups as any[])];

  const labelAdmin = (value: Graph.Admin) => {
    const adminGroup = data?.adminGroupList?.find(f => f.id === value.groupID);
    return (
      <div key={`${value.name} ${adminGroup?.name}`} style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar source={value.image || ''} size="small" />
        <div className="ml-2">
          <small>
            <strong>{value.name}</strong>
          </small>
          <br />
          <small>{adminGroup?.name}</small>
        </div>
      </div>
    );
  };

  const admin = data
    ? Number(selectGroup) > 0
      ? data?.adminList
          ?.filter(x => x.groupID === Number(selectGroup))
          .map(x => ({ label: labelAdmin(x), value: x.id }))
      : data?.adminList?.map(x => ({ label: labelAdmin(x), value: x.id }))
    : [];

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <div className="mr-2">
          <PolarisTextField
            value={searchInput}
            onChange={v => {
              setSearchInput(v);
            }}
            height={'25x'}
            placeholder="Search admin name..."
            autoComplete="off"
            label
            labelHidden
            disabled={loading}
            readOnly={loading}
          />
        </div>
        <div style={{ width: '100px' }}>
          <Select
            value={selectGroup}
            onChange={v => setSelectGroup(v)}
            label
            labelHidden
            options={optionsGroup}
            disabled={loading}
          />
        </div>
      </div>
      <Divider />
      <ChoiceList
        onChange={props.setSelected}
        allowMultiple={true}
        choices={(searchInput !== '' ? admin?.filter(x => x.label.key?.toString().match(filterRegex)) : admin) as any}
        selected={props.selected}
        title
        titleHidden
      />
    </React.Fragment>
  );
}
