/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal } from '@shopify/polaris';
import React, { ReactElement } from 'react';

interface IButton {
  title: string;
  onPress?: (value?: any) => void;
  class?: any;
  invisible?: boolean;
  submit?: boolean;
}

interface IState {
  visible?: boolean;
  title?: string;
  message?: string;
  buttons: IButton[];
  body?: React.ComponentType[];
  fullscreen?: boolean;
}

interface IProps {
  visible?: boolean;
  title?: string;
  message?: string;
  buttons?: IButton[];
  body?: ReactElement[];
  fullscreen?: boolean;
}

interface ModalProps {
  state: any;
  setModal: (ref: any) => void;
  dialog: (p: IProps) => void;
}

export const PolarisConfirm: ModalProps = {
  state: {
    modal: {},
  },
  setModal: (ref: any) => (PolarisConfirm.state.modal = ref),
  dialog: (p: IProps) => {
    PolarisConfirm.state.modal.onShow(p);
  },
};

export class PolarisModal extends React.Component<{}, IState> {
  pressRef: any | null = null;
  formRef: any | null = null;

  state: IState = {
    visible: false,
    title: '',
    message: '',
    buttons: [],
    body: [],
  };

  onShow = (p: IState) => {
    this.setState({
      ...p,
      visible: true,
    });
  };

  onPress = (press?: (value?: any) => void) => {
    if (!!press) {
      this.pressRef = press;
    }
    this.onClose();
  };

  onClose = () => {
    this.setState(
      {
        title: '',
        message: '',
        buttons: [],
        body: [],
        visible: false,
      },
      () => {
        if (!!this.pressRef) {
          this.pressRef();
          this.pressRef = null;
        }
      },
    );
  };

  render() {
    const primary = this.state.buttons.find(x => x.class === 'primary' && !x.submit);
    const submit = this.state.buttons.find(x => x.submit === true);
    return (
      <Modal
        open={!!this.state.visible}
        onClose={this.onClose}
        title={this.state.title}
        primaryAction={
          !submit
            ? {
                content: primary?.title,
                onAction: () => {
                  this.onPress(primary?.onPress);
                },
              }
            : undefined
        }
        secondaryActions={
          !submit
            ? this.state.buttons
                .filter(x => x.class !== 'primary')
                .map(x => {
                  return {
                    content: x.title,
                    onAction: () => this.onPress(x.onPress),
                    destructive: x.class === 'danger',
                  };
                })
            : undefined
        }
        fullScreen={!!this.state.fullscreen}
      >
        <form
          ref={this.formRef}
          onSubmit={(e: any) => {
            e.preventDefault();
            if (submit) {
              const keys = Object.keys(e.target.elements).filter(x => isNaN(Number(x)));
              const input: any = keys
                .map(x => `${x}@${e.target[x].value}`)
                .reduce((a: any, b: any) => {
                  const str: any = b.split('@');
                  a[str[0]] = str[1];
                  return a;
                }, {});
              if (submit) {
                submit.onPress && this.onPress(submit.onPress(input) as any);
              }
            }
          }}
        >
          {this.state.body &&
            this.state.body.map((x, i) => {
              return <Modal.Section key={i}>{x}</Modal.Section>;
            })}
          {submit ? (
            <Modal.Section>
              {this.state.buttons
                .filter(x => x.class !== 'primary')
                .map((x, i) => {
                  return (
                    <Button key={i} destructive={x.class === 'danger'} onClick={() => this.onPress(x.onPress)}>
                      {x.title}
                    </Button>
                  );
                })}
              <Button submit primary={submit.class === 'primary'}>
                {submit.title}
              </Button>
            </Modal.Section>
          ) : (
            <></>
          )}
        </form>
      </Modal>
    );
  }
}
