import React, { useState, ReactElement, ChangeEvent, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { DropdownBaseComponent } from './../DropdownBaseComponent';
import { DropdownPreview } from './../DropboxComponent';
import { HighlightText } from './../HighlightText';
import { DropdownShadow } from '../DropdownShadow';
import { usePrevious } from '../../hooks/common';

const QUERY = gql`
  query {
    productGroupList {
      id
      name
    }
  }
`;

interface Props {
  value: number;
  placeholder?: string;
  onChange?: (v: number) => void;
}

export function ProductGroupPicker(props: Props): React.ReactElement {
  const { data } = useQuery<Graph.Query>(QUERY, { fetchPolicy: 'cache-first' });
  const prev = usePrevious(props);
  const [toggle, setToggle] = useState(false);
  const [search, setSearch] = useState('');

  const cache = useMemo(() => {
    if (!data?.productGroupList) {
      return (
        <div className="lf-form-control bg-loading">
          <span>Loading</span>
        </div>
      );
    }

    const productGroupList = data.productGroupList;

    function preview(data: Graph.ProductGroup[]): ReactElement {
      const item = data.find(x => x.id === props.value);
      const caption = item ? item.name : prev.value.placeholder;

      return (
        <DropdownPreview>
          <span>{caption || 'Please select product group'}</span>
        </DropdownPreview>
      );
    }

    function dropdown(data: Graph.ProductGroup[]): ReactElement {
      return (
        <DropdownShadow>
          <div className="element-search autosuggest-search-activator">
            <input
              placeholder="Start typing to search..."
              type="search"
              autoFocus
              name="search"
              autoComplete={'off'}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                setSearch(e.target.value);
              }}
            />
          </div>

          <ul className="dropdown-container mt-3" style={{ width: 500, maxHeight: 400, overflowY: 'auto', right: 0 }}>
            {data.map(x => (
              <li
                key={x.id?.toString()}
                onClick={(): void => {
                  setToggle(false);
                  if (prev.value.onChange) prev.value.onChange(x.id as number);
                }}
              >
                <HighlightText text={x.name ? x.name : ''} highlight={search} />
              </li>
            ))}
          </ul>
        </DropdownShadow>
      );
    }

    return (
      <DropdownBaseComponent
        dropdownHeight={400}
        toggle={toggle}
        onToggleChanged={(t): void => {
          setToggle(t);
        }}
        preview={(): ReactElement => preview(productGroupList)}
        dropdown={(): ReactElement =>
          dropdown(
            productGroupList.filter(x => {
              if (!x.name) return false;
              return (
                x.name
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toString().toLowerCase()) !== -1
              );
            }),
          )
        }
      />
    );
  }, [prev, props.value, data, search, setSearch, toggle, setToggle]);

  return cache;
}
