import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Graph } from 'src/generated/graph';

const QUERY = gql`
  query categoryList {
    categoryList {
      id
      name {
        en
      }
      parentID
      subCategory
    }
  }
`;

export function useQueryCategoryList(options?: QueryHookOptions<Graph.Query>) {
  return useQuery(QUERY, options);
}
