import React, { useState } from 'react';
import { UploadFileBase } from 'src/components/UploadFileBase';
import FormInputLike from './FormInputLike';

interface Props {
  label?: string;
  value?: string;
  onChange?: (v: string) => void;
}

export default function FormUpload(props: Props) {
  const [uploading, setUploading] = useState(false);

  let display = (
    <UploadFileBase
      value={props.value}
      onStart={() => setUploading(true)}
      onChange={v => {
        if (props.onChange) props.onChange(v);
        setUploading(false);
      }}
    >
      <FormInputLike label={props.label}>
        <i className="fas fa-cloud-upload-alt" style={{ color: '#95a5a6' }} />
        &nbsp; Click to upload
      </FormInputLike>
    </UploadFileBase>
  );

  if (uploading) {
    display = (
      <FormInputLike label={props.label}>
        <i className="fas fa-spinner fa-spin"></i> Uploading
      </FormInputLike>
    );
  } else if (props.value) {
    display = (
      <FormInputLike label={props.label}>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <i className="far fa-image" style={{ color: '#95a5a6' }} />
            &nbsp;
            <a href={props.value} target="_blank" rel="noopener noreferrer">
              Attachment
            </a>
          </div>
          <div style={{ flexGrow: 0 }}>
            <UploadFileBase
              value={props.value}
              onStart={() => setUploading(true)}
              onChange={v => {
                if (props.onChange) props.onChange(v);
                setUploading(false);
              }}
            >
              <i className="fas fa-cloud-upload-alt" style={{ color: '#95a5a6' }} />
            </UploadFileBase>
          </div>
        </div>
      </FormInputLike>
    );
  }

  return display;
}
