/* eslint-disable */
import React from 'react';
import { gql, OperationVariables } from '@apollo/client';
import { Loading } from '../../components/Loading';
import { Query, QueryResult } from '@apollo/react-components';
import { Graph } from '../../generated/graph';

const QUERY = gql`
  query getPurchaseOrder($id: Int!) {
    purchaseOrder(id: $id) {
      id
      name
      volume
      transactionList {
        id
        productID
        skuID
        size
        color
        qty
        cost
        transactionType
        createdAt
        product {
          dimensionSize
        }
      }
    }
  }
`;

type Props = {
  id: number;
};

type SummaryRowType = {
  productID: number;
  size: string;
  color: string;
  cost: number;
  qty: number;
  product: Graph.BasicProduct;
};

type StateType = {
  viewMode: 'Detail' | 'Summary';
};

export class PurchaseOrderTransactionScreen extends React.Component<Props> {
  state: StateType = {
    viewMode: 'Detail',
  };

  public render() {
    return (
      <Query query={QUERY} variables={({ id: this.props.id } as unknown) as OperationVariables}>
        {this.renderQuery}
      </Query>
    );
  }

  private renderQuery = ({ loading, data }: QueryResult<Graph.Query>) => {
    if (loading) return <Loading />;
    if (!data?.purchaseOrder?.transactionList) return <Loading />;

    return (
      <div>
        <div className="controls-above-table text-right">
          <button
            onClick={this.switchView}
            className={'btn ' + (this.state.viewMode === 'Detail' ? 'btn-primary' : 'btn-white')}
          >
            Detail
          </button>
          <button
            onClick={this.switchView}
            className={'btn ' + (this.state.viewMode === 'Summary' ? 'btn-primary' : 'btn-white')}
          >
            Summary
          </button>
        </div>
        {this.state.viewMode === 'Detail'
          ? this.renderDetailTable(data.purchaseOrder?.transactionList)
          : this.renderSummaryTable(data.purchaseOrder?.transactionList)}
      </div>
    );
  };

  private switchView = () => {
    this.setState({ viewMode: this.state.viewMode === 'Detail' ? 'Summary' : 'Detail' });
  };

  private renderDetailTable(data: Graph.StockTransaction[]) {
    let map = undefined;
    let totalVolume = 0;

    if (data !== null) {
      map = data.map(row => (
        <tr key={row.id}>
          <td>{row.id}</td>
          <td>{row.productID}</td>
          <td>{row.skuID}</td>
          <td>{row.size}</td>
          <td>{row.color}</td>
          <td>{row.qty}</td>
          <td>{row.cost}</td>
          <td>{row.createdAt}</td>
        </tr>
      ));
    } else {
      map = (
        <tr>
          <td colSpan={5} style={{ textAlign: 'center' }}>
            <i>No transaction</i>
          </td>
        </tr>
      );
    }

    return (
      <table className="table table-lightborder">
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Product ID</th>
            <th>SKU ID</th>
            <th>Size</th>
            <th>Color</th>
            <th>Qty</th>
            <th>Cost</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>{map}</tbody>
      </table>
    );
  }

  private renderSummaryTable(data: Graph.StockTransaction[]) {
    if (data === null) {
      return 'There is no summary';
    }

    const summary = this.buildSummary(data);

    let map = undefined;
    let totalQty = 0;
    let totalCost = 0;
    let totalVolume = 0;

    if (summary !== null) {
      map = summary.map((row, inx) => {
        totalQty += row.qty;
        totalCost += row.qty * row.cost;
        totalVolume += (Number(row.product.dimensionSize) / 1000000) * Number(row.qty);

        return (
          <tr key={inx}>
            <td>{row.productID}</td>
            <td>{row.size}</td>
            <td>{row.color}</td>
            <td>{row.qty}</td>
            <td>{row.cost}</td>
            <td>{Number(row.product.dimensionSize) / 1000000}</td>
          </tr>
        );
      });
    } else {
      map = (
        <tr>
          <td colSpan={5} style={{ textAlign: 'center' }}>
            <i>No transaction</i>
          </td>
        </tr>
      );
    }

    return (
      <table className="table table-lightborder">
        <thead>
          <tr>
            <th>Product ID</th>
            <th>Size</th>
            <th>Color</th>
            <th>Qty</th>
            <th>Cost</th>
            <th>Volume (CBM)</th>
          </tr>
        </thead>

        <tfoot>
          <tr>
            <td colSpan={2}></td>
            <td>
              <strong>Total Qty</strong>
            </td>
            <td colSpan={3}>
              <strong>{totalQty}</strong>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td colSpan={2}>
              <strong>Total Cost</strong>
            </td>
            <td colSpan={2}>
              <strong>${totalCost.toFixed(2)}</strong>
            </td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td colSpan={3}>
              <strong>Total Volume (CBM)</strong>
            </td>
            <td>
              <strong>{totalVolume.toFixed(6)}</strong>
            </td>
          </tr>
        </tfoot>

        <tbody>{map}</tbody>
      </table>
    );
  }

  private buildSummary(rows: Graph.StockTransaction[]): SummaryRowType[] {
    const result: SummaryRowType[] = [];
    const hash: { [key: string]: SummaryRowType } = {};

    for (const row of rows) {
      const key = row.productID! + row.size! + row.color + row.cost;
      if (hash[key]) {
        hash[key].qty += row.qty!;
      } else {
        hash[key] = {
          productID: row.productID!,
          color: row.color!,
          size: row.size!,
          qty: row.qty!,
          cost: parseFloat(row.cost!),
          product: row.product || {},
        };
      }
    }

    for (const row of Object.values(hash)) {
      result.push(row);
    }

    return result;
  }
}
