import { CSSProperties } from 'react';
import styles from './styles.module.scss';

interface Props {
  show?: boolean;
  onClose?: () => void;
}

export default function Modal(props: React.PropsWithChildren<Props>) {
  if (!props.show) return <></>;

  return (
    <div
      className={styles.modal_container}
      onClick={() => {
        if (props.onClose) props.onClose();
      }}
    >
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  );
}

Modal.Header = function ModalHeader(props: React.PropsWithChildren<unknown>) {
  return (
    <div className={styles.header}>
      <h5>{props.children}</h5>
    </div>
  );
};

Modal.Body = function ModalHeader(props: React.PropsWithChildren<{ style?: CSSProperties }>) {
  return (
    <div className={styles.modal} onClick={e => e.stopPropagation()} style={props.style}>
      {props.children}
    </div>
  );
};

Modal.Footer = function ModalFooter(props: React.PropsWithChildren<unknown>) {
  return <div className={styles.footer}>{props.children}</div>;
};
