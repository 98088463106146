/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import React, { useCallback } from 'react';
import { Graph } from 'src/generated/graph';
import downloadExcelFile from 'src/libs/downloadExcelFile';
import { ProductListArguments, useLazyQueryProductList } from '../Filter/useQueryProductList';
import { MenuGroupDescriptor } from '@shopify/polaris';
import { PolarisConfirm } from 'src/components/polaris/PolarisModal';

export function actionGroupDownload(
  filterValue: ProductListArguments,
  offset: number,
  limit: number,
  selected: any[],
  total: number,
) {
  const [productQuery, { loading }] = useLazyQueryProductList({ variables: {}, type: 'EXCEL' });

  const handleDownloadExcel = useCallback((data: Graph.ProductV2[]) => {
    const items = data.map(x => {
      const discount = Number(x.price) * (Number(x.discount) / 100);
      return {
        Code: x.code,
        Supplier: x.supplier?.name,
        Brand: x.brand?.name,
        Category: x.category?.name?.en,
        Status: x.mark,
        Weight: x.weight + ' gram',
        'Original Price ($)': x.price,
        'Sale Price ($)': `${(Number(x.price) - (discount || 0)).toFixed(2)}`,
        Stock: x.stockQty || '0',
        Restock: '0',
      };
    });
    const headers = Object.keys(items[0]);
    downloadExcelFile(
      `product_list_${items.length}_${moment(new Date()).format('YYYYMMDD')}.xlsx`,
      `product_list_${items.length}_${moment(new Date()).format('YYYYMMDD')}`,
      [headers, ...items.map((row: any) => headers.map(h => (row[h] ? row[h].toString() : '')))],
    );
  }, []);

  return [
    {
      title: `Download Excel ${loading ? '(Please wait a moment...)' : ''}`,
      disabled: loading,
      actions:
        selected.length === total
          ? [
              {
                content: `Download all of filter products`,
                disabled: loading,
                onAction: () => {
                  const variables = {
                    ...filterValue,
                    page: {
                      limit: total > 3000 ? 3000 : total,
                      offset: 0,
                    },
                  };

                  if (total > 3000) {
                    PolarisConfirm.dialog({
                      title: 'Download excel',
                      body: [
                        <div key={1}>
                          You can only download less than 3000 records in order to keep our system run properly.
                        </div>,
                      ],
                      buttons: [
                        {
                          title: 'Download first 3000 records',
                          class: 'primary',
                          onPress: () => {
                            productQuery({
                              variables,
                              onCompleted: res => {
                                handleDownloadExcel(res.productListV2?.data as any);
                              },
                            });
                          },
                        },
                        {
                          title: 'Cancel',
                          class: 'secondary',
                        },
                      ],
                    });
                  } else {
                    productQuery({
                      variables,
                      onCompleted: res => {
                        handleDownloadExcel(res.productListV2?.data as any);
                      },
                    });
                  }
                },
              },
            ]
          : [
              {
                content: `Download all of filter products`,
                disabled: loading,
                onAction: () => {
                  const variables = {
                    ...filterValue,
                    page: {
                      limit: total > 3000 ? 3000 : total,
                      offset: 0,
                    },
                  };

                  if (total > 3000) {
                    PolarisConfirm.dialog({
                      title: 'Download excel',
                      body: [
                        <div key={1}>
                          You can only download less than 3000 records in order to keep our system run properly.
                        </div>,
                      ],
                      buttons: [
                        {
                          title: 'Download first 3000 records',
                          class: 'primary',
                          onPress: () => {
                            productQuery({
                              variables,
                              onCompleted: res => {
                                handleDownloadExcel(res.productListV2?.data as any);
                              },
                            });
                          },
                        },
                        {
                          title: 'Cancel',
                          class: 'secondary',
                        },
                      ],
                    });
                  } else {
                    productQuery({
                      variables,
                      onCompleted: res => {
                        handleDownloadExcel(res.productListV2?.data as any);
                      },
                    });
                  }
                },
              },
              {
                content: `Download by selected ${selected.length} products`,
                disabled: selected.length === 0 || loading,
                onAction: () => {
                  productQuery({
                    variables: {
                      ...filterValue,
                      page: {
                        limit: selected.length,
                        offset: 0,
                      },
                      orderBy: {
                        field: 'ID',
                        value: selected.map(x => Number(x)),
                      },
                    },
                    onCompleted: res => {
                      handleDownloadExcel(res.productListV2?.data as any);
                    },
                  });
                },
              },
            ],
    },
  ] as MenuGroupDescriptor[];
}
