/* eslint-disable */
import React from 'react';

type Props = {
  items: any[];
  onAppend: any;
  onRemove: any;
  label: string;
  itemBack: string[];
};

export class TagComplete extends React.Component<Props> {
  state: {
    text: string;
    tags: any[];
    suggest: any[];
    show: boolean;
  } = {
    text: '',
    tags: this.props.itemBack,
    suggest: this.props.items,
    show: false,
  };

  onChangeText = (e: any) => {
    this.setState({ text: e.target.value });
  };

  onKeyDownText = (e: any) => {
    if (e.key === 'Enter') {
      if (this.state.text !== '') {
        this.resultComplete(this.state.text);
      }
    }
    if (e.key === 'Backspace') {
      if (this.state.text === '' && this.state.tags.length !== 0) {
        const index = this.state.tags.length - 1;
        const name = this.state.tags[index];
        this.setState({ text: name });
        this.RemoveTag(index);
      }
    }
  };

  resultComplete(data: string) {
    const record = this.state.suggest.find(r => r.name === data);

    this.setState({ tags: [...this.state.tags, data], text: '' });

    this.props.onAppend(record == null ? data : record.name);
  }

  RemoveTag = (i: number) => {
    const tags = this.state.tags;

    tags.splice(i, 1);

    this.setState({ tags }, () => {
      this.props.onRemove(tags);
    });
  };

  render() {
    const data = this.state.suggest.filter(x => x.indexOf(this.state.text) >= 0);

    return (
      <div className="form-group tag">
        <div className="lf-form-control">
          {this.renderInput()}
          {this.renderSuggest(data)}
        </div>
      </div>
    );
  }

  renderInput() {
    return (
      <div className="inline-tag">
        {this.renderTags()}
        <input
          type="text"
          style={{ background: 'inherit' }}
          value={this.state.text}
          onChange={this.onChangeText}
          onKeyDown={this.onKeyDownText}
          onFocus={() => this.setState({ show: !this.state.show })}
          onBlur={() => {
            setTimeout(() => {
              this.setState({ show: false });
            }, 500);
          }}
          placeholder={'Enter ' + this.props.label}
        />
      </div>
    );
  }

  renderTags() {
    return (
      <ul className="tags">
        {this.state.tags.map((e, i) => (
          <li key={i}>
            <span className="remove" onClick={() => this.RemoveTag(i)}>
              ×
            </span>{' '}
            {e}
          </li>
        ))}
      </ul>
    );
  }

  renderSuggest(data: any) {
    return (
      <ul className="suggest" hidden={!this.state.show}>
        {data.map((e: any, i: any) => (
          <li key={i} onClick={() => this.resultComplete(e)}>
            {e}
          </li>
        ))}
      </ul>
    );
  }
}
