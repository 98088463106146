import { PropsWithChildren, createContext, useContext } from 'react';
import InitialLoading from 'src/components/InitialLoading';
import { Graph } from 'src/generated/graph';
import useQueryAdmin from 'src/hooks/useQueryAdmin';
import { LoginScreen } from 'src/screens/LoginScreen';
import { useToken } from './TokenContext';

const AuthContext = createContext<Graph.Admin>({ id: 0 });

export function useUser() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: PropsWithChildren<unknown>) {
  const { data, loading } = useQueryAdmin();
  const { setToken } = useToken();

  if (loading) {
    return <InitialLoading />;
  }

  if (!data?.admin) {
    return <LoginScreen saveToken={setToken} />;
  }

  return <AuthContext.Provider value={data.admin}>{children}</AuthContext.Provider>;
}
