import { NotificationScreen } from '../screens/Marketing/NotificationScreen';
import { NotificationListScreen } from '../screens/Marketing/NotificationListScreen';
import { LuckyDrawListScreen } from '../screens/Marketing/LuckyDraw/LuckyDrawListScreen';
import { LuckyDrawEditScreen } from '../screens/Marketing/LuckyDraw/LuckyDrawEditScreen';
import { LuckyDrawParticipantScreen } from '../screens/Marketing/LuckyDraw/LuckyDrawParticipantScreen';
import { NotificationSegmentScreen } from '../screens/Marketing/NotificationSegmentScreen';
import { DiscountCampaignListScreen } from '../screens/Marketing/Discount/DiscountCampaignListScreen';
import { DiscountCampaignScreen } from '../screens/Marketing/Discount/DiscountCampaignScreen';
import { LuckyDrawScreen } from 'src/screens/Marketing/LuckyDraw/LuckyDrawScreen';

export const MarketRoutes = [
  { path: '/notification/schedule/create', exact: true, component: NotificationScreen },
  { path: '/notification/schedule/:id', exact: true, component: NotificationScreen },
  { path: '/notification/list', exact: true, component: NotificationListScreen },
  { path: '/notification/segment/:id', exact: true, component: NotificationSegmentScreen },
  { path: '/marketing/lucky_draw/list', exact: true, component: LuckyDrawListScreen },
  { path: '/marketing/lucky_draw/create', exact: true, component: LuckyDrawEditScreen },
  { path: '/marketing/lucky_draw/:id', exact: true, component: LuckyDrawScreen },
  { path: '/marketing/lucky_draw/edit/:id', exact: true, component: LuckyDrawEditScreen },
  { path: '/marketing/lucky_draw/participant/:id', exact: true, component: LuckyDrawParticipantScreen },
  { path: '/discount_campaigns', exact: true, component: DiscountCampaignListScreen },
  { path: '/discount_campaign/create', exact: true, component: DiscountCampaignScreen },
  { path: '/discount_campaign/edit/:id', exact: true, component: DiscountCampaignScreen },
];
