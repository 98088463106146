import React from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { TableComponent } from '../../components/TableComponent';
import { Field } from '../../libs/Field';

const query = gql`
  query adminGroupList($page: Int!, $size: Int!) {
    adminGroupList(page: $page, size: $size) {
      id
      name
      description
      dashboard
      permissions {
        id
        name
        description
        group
        allowed
      }
    }
  }
`;

export class AdminGroupListScreen extends React.Component {
  render() {
    return (
      <Content>
        <SectionBlock title="Admin Group">
          {this.renderButton()}
          <TableComponent
            name="adminGroupList"
            field={
              new Field({
                query: query,
                columns: [
                  { name: 'Group Name', field: 'name', type: 'link', to: '/admingroup/edit/' },
                  { name: 'Description', field: 'description' },
                  { name: 'Dashboard', field: 'dashboard' },
                ],
              })
            }
          />
        </SectionBlock>
      </Content>
    );
  }

  renderButton() {
    return (
      <div className="controls-above-table">
        <div className="row">
          <div className="col-sm-6">
            <Link className="btn btn btn-secondary" to="/admingroup/create">
              Create Admin Group
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
