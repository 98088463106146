/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { useQuery, WatchQueryFetchPolicy, DocumentNode } from '@apollo/client';
import { Graph } from 'src/generated/graph';
import { rebuildQueryString, useQueryStringContext } from 'src/libs/QueryString';
import { BlockLoading } from 'src/components/loading/BlockLoading';
import { LinkButton } from '../xform/LinkButton';
import XForm from '../xform/XForm';
import { useHistory } from 'react-router';

interface Props<T> {
  size?: number;
  body: (data: T[]) => ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables?: any;
  query: DocumentNode;
  fetchPolicy?: WatchQueryFetchPolicy;
  showTotal?: boolean;
  showTop?: boolean;
  padding?: boolean;
}

export default function OffsetPagination<T>(props: Props<T>): ReactElement {
  const { push } = useHistory();
  const qs = useQueryStringContext<{ page: string }>({ page: '1' });
  const page = Number(qs.page);
  const size = props.size || 10;
  const [pageInput, setPageInput] = React.useState(page + '' || '1');

  const { data, loading } = useQuery<Graph.Query>(props.query, {
    variables: { ...(props.variables || {}), offset: (page - 1) * size, limit: size + 1 },
    fetchPolicy: props.fetchPolicy ? props.fetchPolicy : 'cache-and-network',
    onCompleted: () => {
      if (data && !!props.showTotal) {
        const bodyData = (data as any)[Object.keys(data)[0]];
        if (bodyData.length > 0) {
          const url = rebuildQueryString({ record: (bodyData[0] as any).totalRow });
          push(url);
        }
      }
    },
  });

  React.useEffect(() => {
    if (qs.page !== pageInput) {
      setPageInput(qs.page + '' || '1');
    }
  }, [qs.page, pageInput]);

  let body = undefined;
  let bodyData: T[] = [];

  if (data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bodyData = (data as any)[Object.keys(data)[0]];

    if (Object.keys(bodyData)[1] === 'data' || Object.keys(bodyData)[1] === 'nodes') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      body = props.body((bodyData as any)[Object.keys(bodyData)[1]]);
    } else {
      body = props.body(bodyData.slice(0, props.size));
    }
  } else {
    body = <BlockLoading />;
  }

  return (
    <>
      {props.showTop && (
        <XForm.Footer>
          <div
            style={{ display: 'flex', justifyContent: 'space-between' }}
            className={props.padding ? 'pl-3 pr-3' : ''}
          >
            {props.showTotal && (
              <small>
                Showing {page === 1 ? 1 : size * (page - 1)} to {size * page} of{' '}
                {bodyData.length > 0 && (bodyData[0] as any).totalRow} entries
              </small>
            )}
            <span>
              <LinkButton to={rebuildQueryString({ page: page - 1 })} disabled={Number(qs.page) === 1}>
                Back
              </LinkButton>
              {props.showTotal && (
                <span>
                  <input
                    type="text"
                    value={pageInput}
                    onChange={e => setPageInput(e.currentTarget.value)}
                    className="input_page"
                    onKeyDown={e => {
                      if (e.keyCode === 13 && !isNaN(Number(e.currentTarget.value))) {
                        if (!e.currentTarget.value) {
                          const url = rebuildQueryString({ page: 1 });
                          push(url);
                          return;
                        }
                        const url = rebuildQueryString({ page: Number(e.currentTarget.value) });
                        push(url);
                      }
                    }}
                  />
                </span>
              )}
              <LinkButton to={rebuildQueryString({ page: page + 1 })} disabled={loading || bodyData.length < size}>
                Next
              </LinkButton>
            </span>
          </div>
        </XForm.Footer>
      )}
      {body}

      <XForm.Footer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className={props.padding ? 'pl-3 pr-3' : ''}>
          {props.showTotal && (
            <small>
              Showing {page === 1 ? 1 : size * (page - 1)} to {size * page} of{' '}
              {bodyData.length > 0 && (bodyData[0] as any).totalRow} entries
            </small>
          )}
          <span>
            <LinkButton to={rebuildQueryString({ page: page - 1 })} disabled={Number(qs.page) === 1}>
              Back
            </LinkButton>
            {props.showTotal && (
              <span>
                <input
                  type="text"
                  value={pageInput}
                  onChange={e => setPageInput(e.currentTarget.value)}
                  className="input_page"
                  onKeyDown={e => {
                    if (e.keyCode === 13 && !isNaN(Number(e.currentTarget.value))) {
                      if (!e.currentTarget.value) {
                        const url = rebuildQueryString({ page: 1 });
                        push(url);
                        return;
                      }
                      const url = rebuildQueryString({ page: Number(e.currentTarget.value) });
                      push(url);
                    }
                  }}
                />
              </span>
            )}
            <LinkButton to={rebuildQueryString({ page: page + 1 })} disabled={loading || bodyData.length < size}>
              Next
            </LinkButton>
          </span>
        </div>
      </XForm.Footer>
    </>
  );
}
