import styles from './Loading.module.scss';
import React from 'react';
interface Props {
  topPlaceHolder?: boolean;
}
export function Loading(props: Props) {
  return (
    <>
      {props?.topPlaceHolder && (
        <div className={styles.header}>
          <div></div>
        </div>
      )}
      <div className={styles.container}>
        <div>
          <div className={styles.item}>
            <div className={styles.item_inner}>
              <div className={styles.img}></div>
              <div className={styles.title}></div>
              <div className={styles.txt}></div>
            </div>
            <div className={styles.item_inner}>
              <div className={styles.img}></div>
              <div className={styles.title}></div>
              <div className={styles.txt}></div>
            </div>
            <div className={styles.item_inner}>
              <div className={styles.img}></div>
              <div className={styles.title}></div>
              <div className={styles.txt}></div>
            </div>
            <div className={styles.item_inner}>
              <div className={styles.img}></div>
              <div className={styles.title}></div>
              <div className={styles.txt}></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
