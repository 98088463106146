import React from 'react';
import { CategoryPicker } from '../../../components/pickers/CategoryPicker';
import { AppDataSource } from '../../../types/common';
import { ProductGroupPicker } from '../../../components/pickers/ProductGroupPicker';
import { usePrevious } from '../../../hooks/common';
import { MerchantPicker } from '../../../components/pickers/MerchantPicker';
import { BrandPicker } from '../../../components/pickers/BrandPicker';
import { Checkbox } from '../../../components/form/FormComponent';

interface Props {
  value?: AppDataSource;
  onChange?: (v: AppDataSource) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deepChange<T>(prev: any, change: any): T {
  if (typeof prev !== 'object') return change;

  const tmp = { ...prev };
  for (const key of Object.keys(change)) {
    tmp[key] = deepChange(prev[key], change[key]);
  }
  return tmp;
}

export function AppPageDataSource(props: Props) {
  const previous = usePrevious(props);
  const { value, onChange } = previous.value;

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-group">
          <label>Category</label>
          <CategoryPicker
            value={value?.filter?.category || 0}
            onChange={(v: number) => {
              if (previous.value.onChange) {
                previous.value.onChange(deepChange(previous.value.value, { filter: { category: v } }));
              }
            }}
          />
        </div>

        <div className="form-group">
          <label>Product Group</label>
          <ProductGroupPicker
            value={value?.filter?.product_group || 0}
            onChange={(v: number) => {
              if (previous.value.onChange) {
                previous.value.onChange(deepChange(previous.value.value, { filter: { product_group: v } }));
              }
            }}
          />
        </div>

        <div className="form-group">
          <label>Store</label>
          <MerchantPicker
            value={value?.filter?.supplier || 0}
            onChange={v => {
              if (previous.value.onChange) {
                previous.value.onChange(deepChange(previous.value.value, { filter: { supplier: v } }));
              }
            }}
          />
        </div>

        <div className="form-group">
          <Checkbox
            label="Show Only Discount"
            checked={props.value?.filter?.discount}
            onChange={e => {
              if (previous.value.onChange) {
                previous.value.onChange(deepChange(previous.value.value, { filter: { discount: e.target.checked } }));
              }
            }}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <label>Brand</label>
          <BrandPicker
            value={value?.filter?.brand || 0}
            onChange={v => {
              if (previous.value.onChange) {
                previous.value.onChange(deepChange(previous.value.value, { filter: { brand: v } }));
              }
            }}
          />
        </div>

        <div className="form-group">
          <label>Sort</label>
          <select
            className="lf-form-control"
            value={value?.sort || 'new'}
            onChange={e => {
              if (onChange) {
                onChange(deepChange(value, { sort: e.target.value }));
              }
            }}
          >
            <option value="new">Latest Upload</option>
            <option value="latest_stock">Latest Stock</option>
            <option value="hot">Hot</option>
            <option value="cheapest">Cheapest</option>
            <option value="pricest">Pricest</option>
          </select>
        </div>

        <div className="form-group">
          <label>Limit</label>
          <div>
            <input
              value={value?.limit?.sample || '0'}
              className="lf-form-control lf-inline mr-2"
              onChange={e => {
                if (props.onChange) {
                  props.onChange(deepChange(value, { limit: { sample: Number(e.target.value) } }));
                }
              }}
              style={{ width: 100 }}
            />
            <input
              value={props.value?.limit?.population || '0'}
              className="lf-form-control lf-inline"
              onChange={e => {
                if (props.onChange) {
                  props.onChange(deepChange(value, { limit: { population: Number(e.target.value) } }));
                }
              }}
              style={{ width: 100 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
