import React, { useEffect, useMemo, useState } from 'react';
import styles from './tasks.module.scss';
import { useQuery } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { QUERY_PACKAGE_LIST, QUERY_WAREHOUSE_LIST_FILTER } from '../query';
import { Link } from 'react-router-dom';
import { BlockLoading } from '../../../components/loading/BlockLoading';
import { useHistory, useLocation } from 'react-router';
import { Table } from 'src/components/layout/tables/Table';
import XForm from 'src/components/layout/xform/XForm';
import moment from 'moment';
import { LinkButton } from 'src/components/layout/xform/LinkButton';
import Formatter from 'src/libs/Formatter';

interface Schedule {
  title: string;
  items: Graph.Package[];
}

function Schedule(props: Schedule) {
  const [toggle, setToggle] = useState(false);
  if (props.items.length > 0) {
    return (
      <div className="mb-3">
        <Table stripe={true} border={true}>
          <thead>
            <tr style={{ background: '#ced6e0' }} onClick={() => setToggle(!toggle)}>
              <th colSpan={7}>
                {props.title}
                <span style={{ fontWeight: 'lighter' }}> ({props.items.length})</span>
              </th>
              <th style={{ textAlign: 'right' }}>
                <XForm.Button>
                  {toggle ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}
                </XForm.Button>
              </th>
            </tr>
            {toggle && (
              <tr>
                <th style={{ width: 75 }}>Type</th>
                <th style={{ width: 180 }}> Date</th>
                <th style={{ width: 150 }}>Services</th>
                <th>Package</th>
                <th style={{ width: 200 }}>Merchant</th>
                <th style={{ width: 120 }}>Claimed By</th>
                <th style={{ width: 200 }}>Customer</th>
                <th style={{ width: 200 }}>Route</th>
              </tr>
            )}
          </thead>
          {toggle && (
            <tbody>
              {props.items.map(item => (
                <tr
                  key={item.id}
                  style={{
                    borderBottom: '1px solid #eeeeee',
                  }}
                >
                  <td className="text-center">
                    <i
                      className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
                      style={{ fontSize: 18, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
                    ></i>
                  </td>
                  <td>
                    {item?.requestPickupAt ? (
                      <div>
                        <strong>Requested At</strong>
                        <br /> {item?.requestPickupAt}
                      </div>
                    ) : (
                      ''
                    )}
                    {item?.appointedAt ? (
                      <div>
                        <strong>Appointed At</strong>
                        <br /> {item?.appointedAt}
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{item.type === 'THIRD_PARTY' ? 'Third Party' : 'L192'}</td>
                  <td>
                    <div className={styles.badge_wrap}>
                      <div>
                        <Link className="h4" to={`/package/detail/${item.id}`} target="_blank">
                          {item.id.toString().padStart(8, '0')}
                        </Link>
                      </div>
                      {item.type === 'PICKUP' && (
                        <div style={{ marginBottom: 2 }}>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#1d82fb',
                              display: 'inline-block',
                              margin: '6px 0 0 5px',
                              color: '#fff',
                            }}
                          >
                            {Formatter.beautifyUglyName('Customer Pickup')}
                          </span>
                        </div>
                      )}
                      {Number(item?.attempt) > 0 && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#e22f2f',
                              display: 'inline-block',
                              margin: '6px 0 0 5px',
                              padding: '2px 5px',
                            }}
                          >
                            Attempt ({item?.attempt})
                          </span>
                        </div>
                      )}
                      {item?.useInsurance === true && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#F94892',
                              display: 'inline-block',
                              margin: '6px 0 0 4px',
                              padding: '3px 5px 1px 5px',
                              color: '#fff',
                            }}
                          >
                            {Formatter.beautifyUglyName('Insurance')}
                          </span>
                        </div>
                      )}
                      {item?.isPrepayment === true && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#009432',
                              display: 'inline-block',
                              margin: '6px 0 0 5px',
                              padding: '2px 5px',
                            }}
                          >
                            Pre Payment
                          </span>
                        </div>
                      )}
                      {!!item?.isDropbox && (
                        <div>
                          <span
                            style={{
                              fontSize: 10,
                              background: '#eb3b5a',
                              display: 'inline-block',
                              margin: '6px 0 0 4px',
                              padding: '3px 5px 0px 5px',
                              color: '#fff',
                            }}
                          >
                            CTD
                          </span>
                        </div>
                      )}
                    </div>
                    <small>
                      Cash to collect:{' '}
                      <strong style={{ fontSize: '14px', color: '#e22f2f' }}>${item.cash?.toFixed(2)}</strong> |
                      Barcode: <strong>{item.barcode}</strong>
                      {item?.barcodeInitial && (
                        <strong style={{ color: '#0052cc' }}>
                          &nbsp; <i>({item?.barcodeInitial})</i>
                        </strong>
                      )}
                    </small>
                    {!!item?.hasReturningItem && (
                      <div>
                        <span
                          style={{
                            fontSize: 13,

                            display: 'inline-block',

                            fontWeight: 'bold',
                            color: 'RED',
                          }}
                        >
                          <i className="fas fa-box-alt"></i> CUSTOMER WILL RETURN A PRODUCT
                        </span>
                      </div>
                    )}
                    {!!item?.returningFromPackageId && (
                      <div>
                        <span
                          style={{
                            fontSize: 13,
                            display: 'inline-block',

                            fontWeight: 'bold',
                            color: '#1C6DD0',
                          }}
                        >
                          <i className="fas fa-hand-holding-box"></i> RETURN FROM PACKAGE #
                          {item?.returningFromPackageId}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    <strong>{item.supplier?.name}</strong>
                  </td>
                  <td>
                    <strong>{item.claimDeliveryBy?.name}</strong>
                  </td>
                  <td>
                    {item.customer && (
                      <div>
                        <strong>{item.customer.name}</strong>
                        <br />
                        <span>{item.customer.phoneNumber}</span>
                      </div>
                    )}
                  </td>
                  <td>
                    <strong style={{ fontSize: 14 }}>
                      {item.currentWarehouse?.shortName}
                      &nbsp;&nbsp;
                      <i className="fas fa-arrow-right" />
                      &nbsp;&nbsp; {item.nextWarehouse?.shortName ?? 'Customer'}
                    </strong>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </div>
    );
  }
  return null;
}

function renderPackage(items: Graph.Package[]) {
  const today = moment(Date.now()).format('YYYY-MM-DD');
  const yesterdays: Graph.Package[] = [];
  const tomorrow: Graph.Package[] = [];
  const morning: Graph.Package[] = [];
  const afternoon: Graph.Package[] = [];
  const evening: Graph.Package[] = [];

  items.map(x => {
    if (x.appointedAt && !moment(x.appointedAt).isBefore(moment(`${today} 00:00:00`))) {
      if (moment(x.appointedAt).isAfter(moment(`${today} 23:59:59`))) {
        tomorrow.push(x);
      } else if (moment(x.appointedAt).isBefore(`${today} 12:00:00`)) {
        morning.push(x);
      } else if (moment(x.appointedAt).isBefore(`${today} 17:00:00`)) {
        afternoon.push(x);
      } else {
        evening.push(x);
      }
    } else {
      yesterdays.push(x);
    }
    return null;
  });
  const list = [
    { title: 'Yesterday', items: yesterdays },
    { title: '00AM-12PM', items: morning },
    { title: '12PM-5PM', items: afternoon },
    { title: '05PM-12AM', items: evening },
    { title: 'Tomorrow', items: tomorrow },
  ];
  return list.map((l, i) => <Schedule {...l} key={i} />);
}

function PackageButtonFilter({ items }: { items: Graph.Package[] }) {
  const { data: warehouseList } = useQuery<Graph.Query>(QUERY_WAREHOUSE_LIST_FILTER);

  const [tabName, setTabName] = useState<{ current: string; next: string }>({ current: 'All', next: '' });
  const tabs = useMemo(
    () =>
      items
        .reduce(
          (a: { current: string; next: string; total: number }[], b) => {
            const x = [...a];
            const current = b?.currentWarehouse?.shortName || '';
            const next = b.isFinal ? '' : b?.nextWarehouse?.shortName || '';
            const index = x.findIndex(h => h.current === current && h.next === next);
            if (index < 0) {
              x.push({ current, next, total: 1 });
            } else {
              const hub = x[index];
              x[index] = { current, next, total: hub.total + 1 };
            }
            return x;
          },
          [{ current: 'All', next: '', total: items.length }],
        )
        .sort((a, b) => (a.current > b.current ? 1 : -1)),
    [items],
  );
  return (
    <>
      <div className="mb-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className={styles.group_by_hub_wrap}>
          <button
            className={`btn ${tabName.current === 'All' && tabName.next === '' ? 'btn-primary' : 'btn-default'}`}
            style={{ margin: '5px 10px 5px 0', width: '100%' }}
            value={'All'}
            onClick={() => setTabName({ current: 'All', next: '' })}
          >
            All ({tabs?.find(tab => tab.current === 'All')?.total || 0})
          </button>
          <ul>
            {warehouseList?.warehouseList
              ?.filter(wh => wh.shortName !== 'STM' && wh.shortName !== 'WH5' && wh.shortName !== 'SSK')
              .map((x, i) => {
                return (
                  <li key={i}>
                    <button
                      className={`btn btn-sm ${
                        !tabs?.find(tab => tab.current === x.shortName && tab.next === '')?.total
                          ? 'btn-success'
                          : x.shortName === tabName.current && tabName.next === ''
                          ? 'btn-primary'
                          : 'btn-default'
                      }`}
                      value={x.shortName + ''}
                      onClick={() => setTabName({ current: x.shortName + '', next: '' })}
                    >
                      {x?.shortName}({tabs?.find(tab => tab.current === x.shortName && tab.next === '')?.total || 0})
                    </button>
                    <ul>
                      {x.connectedHubs?.map((y, key) => {
                        const subtab = `${x.shortName}${y.shortName}`;
                        return (
                          <li key={key}>
                            <button
                              className={`btn btn-sm ${
                                !tabs?.find(tab => tab.current === x.shortName && tab.next === y.shortName)?.total
                                  ? 'btn-success'
                                  : x.shortName === tabName.current && y.shortName === tabName.next
                                  ? 'btn-primary'
                                  : 'btn-default'
                              }`}
                              value={subtab}
                              onClick={() => setTabName({ current: x.shortName + '', next: y.shortName + '' })}
                            >
                              {x.shortName + '->' + y.shortName} (
                              {tabs?.find(tab => tab.current === x.shortName && tab.next === y.shortName)?.total || 0})
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
          </ul>
        </div>
        {/* {tabs.map((t, i) => {
          const tab = !!t.next ? `${t.current}${t.next}` : t.current;
          return (
            <div key={i}>
              <Button
                theme={t.current === tabName.current && t.next === tabName.next ? 'primary' : 'secondary'}
                style={{ margin: '5px 10px 5px 0' }}
                value={tab}
                onClick={() => setTabName(t)}
              >
                {t.current}
                {t.next !== '' ? (
                  <>
                    &nbsp;
                    <i className="fas fa-arrow-right" />
                    &nbsp;
                  </>
                ) : (
                  ''
                )}
                {t.next}({t.total})
              </Button>
            </div>
          );
        })} */}
      </div>
      {renderPackage(
        items.filter(x => {
          return (
            tabName.current === 'All' ||
            (x.currentWarehouse?.shortName === tabName.current && x.nextWarehouse?.shortName === tabName.next) ||
            (x.currentWarehouse?.shortName === tabName.current &&
              (!x.nextWarehouse?.shortName || x.isFinal) &&
              tabName.next === '')
          );
        }),
      )}
    </>
  );
}

export function PackageWaitTransitListScreen() {
  const history = useHistory();
  const { pathname, search } = useLocation();

  useEffect(() => {
    history.replace(pathname + '#wait');
  }, [search, pathname, history]);

  const { data } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, {
    variables: { reachFinal: false, status: ['ARRIVED'] },
  });
  if (!data?.packageList) return <BlockLoading />;
  return (
    <>
      <div className={styles.CheckboxWrap}>
        <div>
          <LinkButton to={'map'} style={{ borderRadius: '15px', height: 28 }}>
            Map
          </LinkButton>
        </div>
      </div>

      <hr />

      <div>
        <PackageButtonFilter items={data?.packageList} />
      </div>
    </>
  );
}
