/* eslint-disable */
import React from 'react';
import { gql, ApolloError, OperationVariables } from '@apollo/client';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { MutationFunction, QueryResult } from '@apollo/client';
import { Mutation, Query } from '@apollo/react-components';
import { AlertComponent } from '../../components/AlertComponent';
import { Loading } from '../../components/Loading';

type Props = {
  match: {
    params: {
      id: string;
    };
  };
  history: any;
};

const QUERYSLOT = gql`
  query inventorySlot($id: Int!) {
    inventorySlot(id: $id) {
      id
      name
      warehouse
      used
    }
  }
`;

const QUERYCREATE = gql`
  mutation createInventorySlot($data: InventorySlotInput) {
    createInventorySlot(data: $data)
  }
`;

const QUERYUPDATE = gql`
  mutation updateInventorySlot($data: InventorySlotInput) {
    updateInventorySlot(data: $data)
  }
`;

export class EditInventoryScreen extends React.Component<Props> {
  state: {
    id: number;
    name: string;
    warehouse: number;
    used: number;
    isHidden: boolean;
    messages: string;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      id: 0 | Number(props.match.params.id),
      name: '',
      warehouse: 0,
      used: 0,
      isHidden: true,
      messages: '',
    };
  }

  onInputChanged = (event: any) => {
    const name = event.target.name;
    const val = event.target.value;

    this.setState({ [name]: val });
  };

  onInputChangedNumber = (event: any) => {
    const name = event.target.name;
    const val = event.target.value;
    this.setState({ [name]: Number(val) });
  };

  onUpdateCompleted = (data: any) => {
    if (data.updateInventorySlot || data.createInventorySlot === true) {
      window.location.pathname = '/inventory/list';
    }
  };

  onUpdateError = (error: ApolloError) => {
    this.setState({
      isHidden: false,
      messages: error.message,
    });
  };

  onSubmit = (event: any, update: MutationFunction) => {
    event.preventDefault();

    update({
      variables: { data: this.onGetData() },
    });
  };

  onGetData() {
    if (this.state.id === 0) {
      return {
        name: this.state.name,
        used: Number(this.state.used),
        warehouse: Number(this.state.warehouse),
      };
    } else {
      return {
        id: Number(this.props.match.params.id),
        name: this.state.name,
        used: Number(this.state.used),
        warehouse: Number(this.state.warehouse),
      };
    }
  }

  onQueryCompleted = (data: any) => {
    this.setState({
      id: 0 | Number(data.inventorySlot.id),
      name: data.inventorySlot.name,
      warehouse: data.inventorySlot.warehouse,
      used: data.inventorySlot.used,
    });
  };

  render() {
    return (
      <Content>
        {this.renderAlert()}
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <SectionBlock title={`${this.state.id !== 0 ? 'Edit' : 'Create'} Inventory`}>
              <Query
                query={QUERYSLOT}
                variables={({ id: Number(this.props.match.params.id) } as unknown) as OperationVariables}
                onCompleted={this.onQueryCompleted}
                skip={this.state.id === 0}
              >
                {this.renderForm}
              </Query>
            </SectionBlock>
          </div>
        </div>
      </Content>
    );
  }

  renderAlert() {
    return (
      <AlertComponent
        isHidden={this.state.isHidden}
        message={this.state.messages}
        onClickClose={() => this.setState({ isHidden: true })}
      />
    );
  }

  renderForm = ({ data, loading }: QueryResult) => {
    if (loading || (data === undefined && this.state.id !== 0))
      return (
        <div>
          <Loading />
        </div>
      );
    return (
      <Mutation
        mutation={this.props.match.params.id === undefined ? QUERYCREATE : QUERYUPDATE}
        onCompleted={this.onUpdateCompleted}
        onError={this.onUpdateError}
      >
        {this.renderMutation}
      </Mutation>
    );
  };

  renderMutation = (update: MutationFunction) => {
    return (
      <form onSubmit={e => this.onSubmit(e, update)}>
        <div className="form-group">
          <label>Inventory Name</label>
          <input
            className="form-control"
            autoFocus
            type="text"
            value={this.state.name}
            name="name"
            placeholder="Name"
            onChange={this.onInputChanged}
            required
          />
        </div>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Warehouse</label>
              <input
                className="form-control"
                type="number"
                value={this.state.warehouse}
                name="warehouse"
                placeholder="Warehouse"
                onChange={this.onInputChangedNumber}
                required
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label>Used</label>
              <input
                className="form-control"
                type="number"
                value={this.state.used}
                name="used"
                placeholder="Used"
                onChange={this.onInputChangedNumber}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-buttons-w">
          <button className="btn btn-primary" type="submit">
            {this.state.id !== 0 ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    );
  };
}
