import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import XForm from 'src/components/layout/xform/XForm';

const QUERY_NOTIFICATION_SCHEDULE_LIST = gql`
  query NotificationScheduleList(
    $offset: Int
    $limit: Int
    $notificationScheduleListId: Int
    $type: KindOfNotification
  ) {
    notificationScheduleList(offset: $offset, limit: $limit, id: $notificationScheduleListId, type: $type) {
      id
      segmentId
      segmentName
      phoneNumber
      data
      createdAt
      updatedAt
      createdBy {
        name
      }
      updatedBy {
        name
      }
      sent
      deletedBy {
        name
      }
      deletedAt
      scheduleAt
      repeatedValue
      repeatedType
    }
  }
`;

export function NotificationSchedulePartial(): React.ReactElement {
  const [listType, setListType] = useState('NORMAL');
  const { loading, data } = useQuery<Graph.Query>(QUERY_NOTIFICATION_SCHEDULE_LIST, {
    variables: { offset: 0, limit: 100, type: listType },
  });

  if (loading || !data) return <div>Loading ...</div>;

  return (
    <div>
      <div className="controls-above-table" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Link to="/notification/schedule/create" className="btn btn-primary">
            Create Notification
          </Link>
          <a
            href="https://adm.l192.com/training/29"
            target={'_blank'}
            style={{
              fontSize: '2rem',
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: '10px',
              marginBottom: '4px',
            }}
            rel="noreferrer"
          >
            <strong style={{ fontSize: '2rem' }}>?</strong>
          </a>
        </div>

        <div>
          <XForm.Select
            label=""
            onChange={e => setListType(e.currentTarget.value as 'ALL' | 'NORMAL' | 'REPEATED')}
            value={listType}
            items={[
              { text: 'All notifications', value: 'ALL' },
              { text: 'Normal only', value: 'NORMAL' },
              { text: 'Repeated only', value: 'REPEATED' },
            ]}
          />
        </div>
      </div>
      <div>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Content</th>
              <th>Receivers</th>
              <th style={{ width: 150 }}>Repeat Every</th>
              <th style={{ width: 150 }}>Landing Page</th>
              <th style={{ width: 60 }}>Notified(n)</th>
              <th style={{ width: 130 }}>Created</th>
              <th style={{ width: 130 }}>Scheduled at</th>
              <th style={{ width: 130 }}>Updated</th>
            </tr>
          </thead>
          <tbody>
            {(data.notificationScheduleList || []).map(
              (x): ReactElement => (
                <tr key={x.id?.toString()}>
                  <td>
                    <div>
                      <Link key={x.id} to={`/notification/schedule/${x.id}`}>
                        <strong>{x.data.heading ? x.data.heading : x.id}</strong>
                      </Link>
                    </div>
                    <div>{x.data.title}</div>
                  </td>

                  <td>
                    {x.segmentName
                      ? x.segmentName
                      : x.phoneNumber?.splice(0, Math.min(3, x.phoneNumber.length)).join(', ')}
                  </td>
                  <td>{x.repeatedValue ? x.repeatedValue + ' ' + x.repeatedType : ''}</td>
                  <td>
                    <div>{x.data.data.screen ? 'Screen: ' + x.data.data.screen : ''}</div>
                    <div>{x.data.data.payload ? 'payload: ' + JSON.stringify(x.data.data.payload) : ''}</div>
                  </td>

                  <td>{x.sent}</td>

                  <td>
                    <div> {x.createdBy ? 'by ' + x.createdBy.name : ''}</div>
                    <div>{x.createdAt ? 'at ' + x.createdAt : ''}</div>
                  </td>

                  <td>
                    <div>{x.scheduleAt ? x.scheduleAt : ''}</div>
                  </td>

                  <td>
                    <div> {x.updatedBy ? 'by ' + x.updatedBy.name : ''}</div>
                    <div>{x.updatedAt ? 'at ' + x.updatedAt : ''}</div>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
