import React from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { ResponsiveLine } from '@nivo/line';
import { DateTime } from '../../libs/DateTime';

const QUERY = gql`
  query reportMerchantTotalSales($start: String!, $end: String!) {
    reportMerchantTotalSales(start: $start, end: $end) {
      sales {
        date
        total
        delivered
        ready
        waiting
        waitingApprove
        returned
        prepared
        arrivedAtAgent
        merchantShipping
        merchantCancelled
      }
    }
  }
`;

function MerchantSalesChart({ sales }: { sales: Graph.ReportMerchantTotalSaleItem[] }) {
  const chartData = [
    {
      id: 'Delivered',
      data: sales.map(x => ({
        x: x.date,
        y: Number(x.delivered),
      })),
    },
    {
      id: 'Ready',
      data: sales.map(x => ({
        x: x.date,
        y: Number(x.ready),
      })),
    },
    {
      id: 'Arrived at Agent',
      data: sales.map(x => ({
        x: x.date,
        y: Number(x.arrivedAtAgent),
      })),
    },
    {
      id: 'Merchant Shipping',
      data: sales.map(x => ({
        x: x.date,
        y: Number(x.merchantShipping),
      })),
    },
    {
      id: 'Prepared',
      data: sales.map(x => ({
        x: x.date,
        y: Number(x.prepared),
      })),
    },
    {
      id: 'Waiting',
      data: sales.map(x => ({
        x: x.date,
        y: Number(x.waiting) + Number(x.waitingApprove),
      })),
    },
  ];

  return (
    <div style={{ height: 300 }}>
      <ResponsiveLine
        margin={{ top: 40, right: 20, bottom: 20, left: 50 }}
        data={chartData}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{
          type: 'linear',
          stacked: true,
          min: 'auto',
          max: 'auto',
        }}
        axisBottom={{
          format: '%b %d',
          tickValues: 'every 2 days',
        }}
        curve="monotoneX"
        colors={[
          'rgb(179, 226, 205)',
          'rgb(253, 205, 172)',
          'rgb(244, 202, 228)',
          'rgb(255, 242, 174)',
          'rgb(203, 213, 232)',
          'rgb(204, 204, 204)',
        ]}
        enablePointLabel={false}
        enableArea={true}
        areaOpacity={1}
        pointSize={5}
        pointBorderWidth={1}
        pointBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.3]],
        }}
        useMesh={true}
        enableSlices={'x'}
      />
    </div>
  );
}

export function MerchantTotalSalesScreen() {
  const { data } = useQuery<Graph.Query>(QUERY, {
    variables: {
      start: DateTime.last(30, 'day'),
      end: DateTime.last(-1, 'day'),
    },
  });

  if (!data?.reportMerchantTotalSales) return <div>Loading...</div>;

  return (
    <Content>
      <SectionBlock title="Merchant Sales Report" width={1050}>
        <div>
          <MerchantSalesChart sales={data.reportMerchantTotalSales?.sales as Graph.ReportMerchantTotalSaleItem[]} />
        </div>
        <div className="mt-5">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th></th>
                <th colSpan={3}>L192 Side</th>
                <th colSpan={1}>Agent Side</th>
                <th colSpan={3}>Merchant Side</th>
                <th colSpan={2}></th>
              </tr>
              <tr>
                <th>Date</th>
                <th style={{ width: 80 }}>Delivered</th>
                <th style={{ width: 80 }}>Ready</th>
                <th style={{ width: 80 }}>
                  Waiting
                  <br />
                  (Unapproved)
                </th>

                <th style={{ width: 80 }}>Arrived At Agent</th>

                <th style={{ width: 80 }}>Prepared</th>
                <th style={{ width: 80 }}>Merchant Shipping</th>
                <th style={{ width: 80 }}>
                  Waiting
                  <br />
                  (approved)
                </th>
                <th style={{ width: 80 }}></th>
                <th style={{ width: 150 }}>Conversion</th>
              </tr>
            </thead>
            <tbody>
              {(data.reportMerchantTotalSales?.sales || []).map(x => (
                <tr key={x.date?.toString()}>
                  <td>{x.date}</td>
                  <td className="text-right">{x.delivered}</td>
                  <td className="text-right">{x.ready}</td>
                  <td className="text-right">{x.waiting}</td>
                  <td className="text-right">{x.arrivedAtAgent}</td>
                  <td className="text-right">{x.prepared}</td>
                  <td className="text-right">{x.merchantShipping}</td>
                  <td className="text-right">{x.waitingApprove}</td>
                  <td className="text-right">{x.total}</td>
                  <td>
                    <div className="os-progress-bar primary">
                      <div className="bar-level-1" style={{ width: '100%' }}>
                        <div
                          className="bar-level-3"
                          style={{
                            width:
                              Number(x.total) > 0
                                ? `${Math.min(100, (Number(x.delivered) * 100) / Number(x.total))}%`
                                : 0,
                          }}
                        ></div>
                      </div>
                      <div className="bar-labels">
                        <div className="bar-label-left">
                          <span>Conversion</span>
                          <span className="positive">
                            {Number(x.total) > 0
                              ? Math.min(100, (Number(x.delivered) * 100) / Number(x.total)).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) + '%'
                              : '0.00'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SectionBlock>
    </Content>
  );
}
