import FormTextInput from './FormTextInput';
import FormDivision from './FormDivision';
import FormButtonFooter from './FormButtonFooter';
import FormButton from './Button';
import FormCheckbox from './FormCheckbox';
import FormTextArea from './FormTextArea';
import FormSelectInput from './FormSelectInput';
import FormController from './FormController';
import FormInline from './FormInline';
import FormSelectInputFromQuery from './FormSelectInputFromQuery';
import FormRadio from './FormRadio';
import FormInputGroup from './FormInputGroup';
import FormUpload from './FormUpload';
import FormGroup from './FormGroup';
import FormFilterContainer from './FormFilterContainer';
import FormButtonList from './FormButtonList';
import FormSelectInputRaw from './FormSelectInputRaw';
import FormSelectInputFromQueryV2 from './FormSelectInputFromQueryV2';

function XForm(props: React.PropsWithChildren<React.HTMLProps<HTMLFormElement>>) {
  return <form {...props}>{props.children}</form>;
}

XForm.Text = FormTextInput;
XForm.Division = FormDivision;
XForm.Footer = FormButtonFooter;
XForm.Button = FormButton;
XForm.ButtonList = FormButtonList;
XForm.Group = FormGroup;
XForm.Check = FormCheckbox;
XForm.TextArea = FormTextArea;
XForm.Select = FormSelectInput;
XForm.SelectRaw = FormSelectInputRaw;
XForm.SelectFromQuery = FormSelectInputFromQuery;
XForm.SelectFromQueryV2 = FormSelectInputFromQueryV2;
XForm.Controller = FormController;
XForm.Inline = FormInline;
XForm.Radio = FormRadio;
XForm.InputGroup = FormInputGroup;
XForm.Upload = FormUpload;
XForm.FilterContainer = FormFilterContainer;

export default XForm;
