/* eslint-disable */
import React, { FormEvent } from 'react';
import { Content } from '../../components/light/Content';
import { SectionBlock } from '../../components/light/SectionBlock';
import { gql, ApolloClient } from '@apollo/client';
import { Permission } from '../../components/Permission';
import { ApolloConsumer } from '@apollo/client';
import { Query } from '@apollo/react-components';
import { Loading } from '../../components/Loading';
import moment from 'moment';
import 'moment-timezone';
import queryString from 'query-string';
import downloadExcelFile from 'src/libs/downloadExcelFile';

const QUERY = gql`
  query reportSale($year: Int!, $month: Int!) {
    reportSale(year: $year, month: $month)
  }
`;
const QUERY_INVOICE_LIST = gql`
  query reportInvoiceList($year: Int!, $month: Int!) {
    reportInvoiceList(year: $year, month: $month)
  }
`;
const QUERY_SALE_LIST = gql`
  query reportSaleList($year: Int!, $month: Int!) {
    reportSaleList(year: $year, month: $month)
  }
`;

const QUERY_SALE_LIST_BY_SHOP = gql`
  query reportSaleList($shopID: Int!, $year: Int!, $month: Int!) {
    reportSaleList(shopID: $shopID, year: $year, month: $month)
  }
`;
const QUERY_INVOICE_LIST_BY_SHOP = gql`
  query reportInvoiceList($shopID: Int!, $year: Int!, $month: Int!) {
    reportInvoiceList(shopID: $shopID, year: $year, month: $month)
  }
`;
const QUERY_INVENTORY_SALE_LIST = gql`
  query reportInventorySaleList($year: Int!, $month: Int!) {
    reportInventorySaleList(year: $year, month: $month)
  }
`;
const QUERY_OTHER_SALE_LIST = gql`
  query reportOtherSaleList($year: Int!, $month: Int!) {
    reportOtherSaleList(year: $year, month: $month)
  }
`;

const QUERY_DELIVERY_FEE_LIST = gql`
  query reportDeliveryFeeList($year: Int!, $month: Int!) {
    reportDeliveryFeeList(year: $year, month: $month)
  }
`;
const QUERY_FBL_SALE_LIST = gql`
  query reportFBLSaleList($year: Int!, $month: Int!) {
    reportFBLSaleList(year: $year, month: $month)
  }
`;
const QUERY_SERVICE_FEE_LIST = gql`
  query reportServiceFeeList($year: Int!, $month: Int!) {
    reportServiceFeeList(year: $year, month: $month)
  }
`;

type Props = {
  location: {
    search: any;
  };
  history: any;
};

export class SaleReportScreen extends React.Component<Props> {
  state: {
    year: number;
    month: number;

    inputYear: string;
    inputMonth: string;

    isGeneratingReport: boolean;
  };

  graphs: any = [];
  queryUrl: any = queryString.parse(this.props.location.search);

  constructor(props: Props) {
    super(props);

    const currentDate = new Date();
    const previousDate = new Date();
    previousDate.setMonth(previousDate.getMonth() - 1);

    const currentDateYear = this.props.location.search === '' ? currentDate.getFullYear() : Number(this.queryUrl.year);
    const currentDateMonth =
      this.props.location.search === '' ? currentDate.getMonth() + 1 : Number(this.queryUrl.month);

    this.state = {
      year: currentDateYear,
      month: currentDateMonth,

      inputYear: currentDateYear.toString(),
      inputMonth: currentDateMonth.toString().padStart(2, '0'),

      isGeneratingReport: false,
    };
  }

  onClickReport = (e: FormEvent) => {
    e.preventDefault();
    this.setState({
      year: Number(this.state.inputYear),
      month: Number(this.state.inputMonth),
    });

    this.props.history.push(`/report/sale?year=${this.state.inputYear}&month=${this.state.inputMonth}`);
  };

  onChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Content>
        <Permission superAdmin={true}>
          <SectionBlock title="Sale Report">
            {this.renderInput()}
            <div className="row">
              <div className="col-sm-12">{this.renderQuery()}</div>
            </div>
          </SectionBlock>
        </Permission>
      </Content>
    );
  }

  renderInput() {
    return (
      <form className="controls-above-table" onSubmit={this.onClickReport}>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-inline justify-content-sm-start">
              <input
                type="text"
                placeholder="Year"
                className="form-control form-control-sm rounded bright"
                style={{ marginRight: 7, width: 75 }}
                value={this.state.inputYear}
                onChange={this.onChange}
                name="inputYear"
              />
              <input
                type="text"
                placeholder="Month"
                className="form-control form-control-sm rounded bright"
                style={{ marginRight: 7, width: 75 }}
                value={this.state.inputMonth}
                onChange={this.onChange}
                name="inputMonth"
              />
              <button className="btn btn btn-secondary" type="submit">
                Report
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }

  renderQuery = () => {
    return (
      <Query query={QUERY} variables={{ year: this.state.year, month: this.state.month }}>
        {({ loading, data }: any) => {
          return this.renderTable(loading, data);
        }}
      </Query>
    );
  };

  renderTable = (loading: boolean, data: any) => {
    if (loading)
      return (
        <div>
          <Loading />
        </div>
      );
    let othersale_desc = 'Sale without COGS and fees and commissions';
    if (this.state.year > 2020 || (this.state.year === 2020 && this.state.month >= 3)) {
      othersale_desc = 'SALE FROM FBL VENDORS; COGS FROM FBL VENDOR POs\n(COGS VALUE)';
    }
    const { rows, summary } = this.transformDataToTable(data.reportSale);
    let alert = <div></div>;
    if (this.state.isGeneratingReport) {
      alert = <div className="alert alert-success">Generating report. Please wait....</div>;
    }

    return (
      <div style={{ position: 'relative', overflow: 'hidden', maxWidth: '100%' }}>
        {alert}
        <table className="table table-lightborder">
          <thead>
            <tr>
              <th style={{ width: '150px' }}>Sale Channels</th>
              <th className="text-right" style={{ width: '250px' }}>
                Inventory Sale (with COGS)
              </th>
              <th className="text-right" style={{ width: '250px' }}>
                FBL Transaction Fees
                <br />
                <small>
                  Sale without COGS, Pickup fee + handling fee + delivery fee + commission <br />
                  <br /> (Merchandise Net Value)
                </small>
              </th>

              <th className="text-right" style={{ width: '250px' }}>
                FBL Promotion
                <br />
                <small>Promotion for FBL Sale</small>
              </th>

              <th className="text-right" style={{ width: '250px' }}>
                FBL Non-transaction Fees
                <br />
                <small>Shipment processing fee, removal fee, monthly storage fee, product entry fee</small>
              </th>
              <th className="text-right" style={{ width: '250px' }}>
                Other Sale
                <br />
                <small>{othersale_desc}</small>
              </th>
              <th className="text-right" style={{ width: '250px' }}>
                General Customer Delivery Fee
              </th>
              <th className="text-right" style={{ width: '200px' }}>
                COGS
              </th>
              <th>Download Invoice List</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableBody(rows)}
            <tr style={{ fontWeight: 'bold' }}>
              <td>Total</td>
              {this.renderCell(summary, 'inventorySale')}
              <td className="align-top text-right">
                <div>{`${summary.FBLCommission.toLocaleString('en-US', { minimumFractionDigits: 2 })}`}</div>
                <small>({`${summary.FBLNetSale.toLocaleString('en-US', { minimumFractionDigits: 2 })}`})</small>
              </td>
              {this.renderCell(summary, 'FBLPromoDiscount')}
              {this.renderCell(summary, 'fee')}
              <td className="align-top text-right">
                <div>{`${summary.otherSale.toLocaleString('en-US', { minimumFractionDigits: 2 })}`}</div>
                {summary.otherSaleCOGS > 0 ? (
                  <small>({`${summary.otherSaleCOGS.toLocaleString('en-US', { minimumFractionDigits: 2 })}`})</small>
                ) : null}
              </td>
              {this.renderCell(summary, 'customerDeliveryFee')}
              {this.renderCell(summary, 'COGS')}
              <td>
                {this.state.year < 2020 || (Number(this.state.year) === 2020 && this.state.month <= 7) ? (
                  <ApolloConsumer>
                    {client => {
                      return (
                        <button className="btn btn-primary" onClick={this.exportCSV(client, null)}>
                          Invoice List
                        </button>
                      );
                    }}
                  </ApolloConsumer>
                ) : null}
                <br />
                <br />
                {this.state.year < 2020 || (Number(this.state.year) === 2020 && this.state.month <= 7) ? (
                  <ApolloConsumer>
                    {client => {
                      return (
                        <button className="btn btn-danger" onClick={this.exportSaleCSV(client, null)}>
                          Sale Listing
                        </button>
                      );
                    }}
                  </ApolloConsumer>
                ) : null}
              </td>
            </tr>
            <tr>
              <td></td>
              <td style={{ position: 'relative', height: '50px' }}>
                {Number(this.state.year) > 2020 || (Number(this.state.year) === 2020 && this.state.month > 7) ? (
                  <ApolloConsumer>
                    {client => {
                      return (
                        <button
                          className="btn btn-primary"
                          style={{ position: 'absolute', right: 0, top: '10px' }}
                          onClick={this.exportInventorySale(client)}
                        >
                          Download
                        </button>
                      );
                    }}
                  </ApolloConsumer>
                ) : null}
              </td>
              <td style={{ position: 'relative', height: '50px' }}>
                {this.state.year > 2020 || (Number(this.state.year) === 2020 && this.state.month > 7) ? (
                  <ApolloConsumer>
                    {client => {
                      return (
                        <button
                          className="btn btn-primary"
                          style={{ position: 'absolute', right: 0, top: '10px' }}
                          onClick={this.exportFBLSale(client)}
                        >
                          Download
                        </button>
                      );
                    }}
                  </ApolloConsumer>
                ) : null}
              </td>
              <td></td>
              <td style={{ position: 'relative', height: '50px' }}>
                {this.state.year > 2020 || (Number(this.state.year) === 2020 && this.state.month > 7) ? (
                  <ApolloConsumer>
                    {client => {
                      return (
                        <button
                          className="btn btn-primary"
                          style={{ position: 'absolute', right: 0, top: '10px' }}
                          onClick={this.exportServiceFee(client)}
                        >
                          Download
                        </button>
                      );
                    }}
                  </ApolloConsumer>
                ) : null}
              </td>

              <td style={{ position: 'relative', height: '50px' }}>
                {this.state.year > 2020 || (Number(this.state.year) === 2020 && this.state.month > 7) ? (
                  <ApolloConsumer>
                    {client => {
                      return (
                        <button
                          className="btn btn-primary"
                          style={{ position: 'absolute', right: 0, top: '10px' }}
                          onClick={this.exportOtherSale(client)}
                        >
                          Download
                        </button>
                      );
                    }}
                  </ApolloConsumer>
                ) : null}
              </td>

              <td style={{ position: 'relative', height: '50px' }}>
                {this.state.year > 2020 || (Number(this.state.year) === 2020 && this.state.month > 7) ? (
                  <ApolloConsumer>
                    {client => {
                      return (
                        <button
                          className="btn btn-primary"
                          style={{ position: 'absolute', right: 0, top: '10px' }}
                          onClick={this.exportDeliveryFeeList(client)}
                        >
                          Download
                        </button>
                      );
                    }}
                  </ApolloConsumer>
                ) : null}
              </td>
              <td></td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    );
  };

  transformDataToTable(data: any) {
    const rows = [];

    const summary = {
      inventorySale: 0,
      FBLNetSale: 0,
      FBLCommission: 0,
      FBLPromoDiscount: 0,
      otherSale: 0,
      otherSaleCOGS: 0,
      customerDeliveryFee: 0,
      COGS: 0,
      fee: 0,
    };

    for (const row of data) {
      rows.push({
        shopID: row.shopID,
        inventorySale: Number(row.inventorySale),
        FBLNetSale: Number(row.FBLNetSale),
        FBLCommission: Number(row.FBLCommission),
        FBLPromoDiscount: Number(row.FBLPromoDiscount),
        otherSale: Number(row.otherSale),
        otherSaleCOGS: Number(row.otherSaleCOGS),
        customerDeliveryFee: Number(row.customerDeliveryFee),
        COGS: Number(row.COGS),
        fee: Number(row.fee),
      });

      summary.inventorySale += Number(row.inventorySale);
      summary.FBLNetSale += Number(row.FBLNetSale);
      summary.FBLCommission += Number(row.FBLCommission);
      summary.FBLPromoDiscount += Number(row.FBLPromoDiscount);
      summary.otherSale += Number(row.otherSale);
      summary.otherSaleCOGS += Number(row.otherSaleCOGS);
      summary.customerDeliveryFee += Number(row.customerDeliveryFee);
      summary.COGS += Number(row.COGS);
      summary.fee += Number(row.fee);
    }

    // console.log(rows);
    return { rows, summary };
  }

  renderTableBody(rows: any[]) {
    return rows.map((x, idx) => {
      return (
        <tr key={idx}>
          <td>{`${x.shopID === 0 ? 'Online' : `Shop ${x.shopID}`}`}</td>
          {this.renderCell(x, 'inventorySale', { backgroundColor: '#f1e48f' })}
          <td className="align-top text-right">
            <div>{`${x.FBLCommission.toLocaleString('en-US', { minimumFractionDigits: 2 })}`}</div>
            <small>({`${x.FBLNetSale.toLocaleString('en-US', { minimumFractionDigits: 2 })}`})</small>
          </td>
          {this.renderCell(x, 'FBLPromoDiscount')}
          {this.renderCell(x, 'fee')}
          <td className="align-top text-right">
            <div>{`${x.otherSale.toLocaleString('en-US', { minimumFractionDigits: 2 })}`}</div>
            {x.otherSaleCOGS > 0 ? (
              <small>({`${x.otherSaleCOGS.toLocaleString('en-US', { minimumFractionDigits: 2 })}`})</small>
            ) : null}
          </td>
          {this.renderCell(x, 'customerDeliveryFee')}
          {this.renderCell(x, 'COGS')}
          <td>
            {this.state.year < 2020 || (Number(this.state.year) === 2020 && this.state.month <= 7) ? (
              <ApolloConsumer>
                {client => {
                  return (
                    <button className="btn btn-primary" onClick={this.exportCSV(client, x.shopID)}>
                      Invoice List
                    </button>
                  );
                }}
              </ApolloConsumer>
            ) : null}
            <br />
            <br />
            {this.state.year < 2020 || (Number(this.state.year) === 2020 && this.state.month <= 7) ? (
              <ApolloConsumer>
                {client => {
                  return (
                    <button className="btn btn-danger" onClick={this.exportSaleCSV(client, x.shopID)}>
                      Sale Listing
                    </button>
                  );
                }}
              </ApolloConsumer>
            ) : null}
          </td>
        </tr>
      );
    });
  }

  renderCell(value: any, field: string, style = {}) {
    return (
      <td className="align-top text-right" style={style}>
        <div>{value[field].toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
      </td>
    );
  }

  exportCSV = (client: ApolloClient<{}>, shopID: any) => {
    return async () => {
      this.setState({ isGeneratingReport: true });

      let result = null;
      if (shopID === null) {
        result = await client.query({
          query: QUERY_INVOICE_LIST,
          variables: {
            year: this.state.year,
            month: this.state.month,
          },
        });
      } else {
        result = await client.query({
          query: QUERY_INVOICE_LIST_BY_SHOP,
          variables: {
            year: this.state.year,
            month: this.state.month,
            shopID: shopID,
          },
        });
      }
      const rows = result.data.reportInvoiceList.map((o: any) => {
        o.invoice_date = moment.tz(o.invoice_date, 'Asia/Bangkok').format('YYYY/MM/DD');
        return o;
      });

      this.setState({ isGeneratingReport: false });
      this.generateCSV(rows);
    };
  };
  exportInventorySale = (client: ApolloClient<{}>) => {
    return async () => {
      this.setState({ isGeneratingReport: true });

      client
        .query({
          query: QUERY_INVENTORY_SALE_LIST,
          variables: {
            year: this.state.year,
            month: this.state.month,
          },
        })
        .then(data => {
          const rows = data.data.reportInventorySaleList.map((o: any) => {
            return {
              ...o,
              invoice_date: moment.tz(o.invoice_date, 'Asia/Bangkok').format('YYYY/MM/DD'),
            };
          });
          this.setState({ isGeneratingReport: false });
          const headers = Object.keys(rows[0]);
          downloadExcelFile(
            `${this.state.year}-${this.state.month} Inventory Sales.xlsx`,
            `${this.state.year}-${this.state.month} Inventory Sales`,
            [headers, ...rows.map((row: any) => headers.map(h => (row[h] ? row[h].toString() : '')))],
          );
        });
      //this.generateCSV(rows, `Inventory Sale ${this.state.year}-${this.state.month}`);
    };
  };
  exportOtherSale = (client: ApolloClient<{}>) => {
    return async () => {
      this.setState({ isGeneratingReport: true });
      client
        .query({
          query: QUERY_OTHER_SALE_LIST,
          variables: {
            year: this.state.year,
            month: this.state.month,
          },
        })
        .then(data => {
          const rows = data.data.reportOtherSaleList.map((o: any) => {
            return {
              ...o,
              invoice_date: moment.tz(o.invoice_date, 'Asia/Bangkok').format('YYYY/MM/DD'),
            };
          });
          this.setState({ isGeneratingReport: false });
          const headers = Object.keys(rows[0]);
          downloadExcelFile(
            `${this.state.year}-${this.state.month} Other Sales.xlsx`,
            `${this.state.year}-${this.state.month} Other Sales`,
            [headers, ...rows.map((row: any) => headers.map(h => (row[h] ? row[h].toString() : '')))],
          );
        });

      //this.generateCSV(rows, `Other Sale ${this.state.year}-${this.state.month}`);
    };
  };
  exportDeliveryFeeList = (client: ApolloClient<{}>) => {
    return async () => {
      this.setState({ isGeneratingReport: true });

      client
        .query({
          query: QUERY_DELIVERY_FEE_LIST,
          variables: {
            year: this.state.year,
            month: this.state.month,
          },
        })
        .then(data => {
          const rows = data.data.reportDeliveryFeeList.map((o: any) => {
            return {
              ...o,
              invoice_date: moment.tz(o.invoice_date, 'Asia/Bangkok').format('YYYY/MM/DD'),
              completed_at: moment.tz(o.completed_at, 'Asia/Bangkok').format('YYYY/MM/DD HH:mm'),
            };
          });
          this.setState({ isGeneratingReport: false });
          const headers = Object.keys(rows[0]);
          downloadExcelFile(
            `${this.state.year}-${this.state.month} FBL delivery.xlsx`,
            `${this.state.year}-${this.state.month} FBL delivery`,
            [headers, ...rows.map((row: any) => headers.map(h => (row[h] ? row[h].toString() : '')))],
          );
          //this.generateCSV(rows, `Delivery Fee ${this.state.year}-${this.state.month}`);
        });
    };
  };
  exportFBLSale = (client: ApolloClient<{}>) => {
    return async () => {
      this.setState({ isGeneratingReport: true });
      client
        .query({
          query: QUERY_FBL_SALE_LIST,
          variables: {
            year: this.state.year,
            month: this.state.month,
          },
        })
        .then(data => {
          const rows = data.data.reportFBLSaleList.map((o: any) => {
            return {
              ...o,
              invoice_date: moment.tz(o.invoice_date, 'Asia/Bangkok').format('YYYY/MM/DD'),
            };
          });

          this.setState({ isGeneratingReport: false });
          const headers = Object.keys(rows[0]);
          downloadExcelFile(
            `${this.state.year}-${this.state.month} FBL fees.xlsx`,
            `${this.state.year}-${this.state.month} FBL fees`,
            [headers, ...rows.map((row: any) => headers.map(h => (row[h] ? row[h].toString() : '')))],
          );

          //this.generateCSV(rows, `FBL Sale ${this.state.year}-${this.state.month}`);
        });
    };
  };
  exportServiceFee = (client: ApolloClient<{}>) => {
    return async () => {
      this.setState({ isGeneratingReport: true });

      client
        .query({
          query: QUERY_SERVICE_FEE_LIST,
          variables: {
            year: this.state.year,
            month: this.state.month,
          },
        })
        .then(data => {
          const rows = data.data.reportServiceFeeList.map((o: any) => {
            return {
              ...o,
              invoice_date: moment.tz(o.invoice_date, 'Asia/Bangkok').format('YYYY/MM/DD'),
            };
          });

          this.setState({ isGeneratingReport: false });
          const headers = Object.keys(rows[0]);
          downloadExcelFile(
            `${this.state.year}-${this.state.month} Warehousing.xlsx`,
            `${this.state.year}-${this.state.month} Warehousing`,
            [headers, ...rows.map((row: any) => headers.map(h => (row[h] ? row[h].toString() : '')))],
          );
          //this.generateCSV(rows, `Service Fee Sale ${this.state.year}-${this.state.month}`);
        });
    };
  };
  exportSaleCSV = (client: ApolloClient<{}>, shopID: any) => {
    return async () => {
      this.setState({ isGeneratingReport: true });

      client
        .query({
          query: QUERY_SALE_LIST_BY_SHOP,
          variables: {
            year: this.state.year,
            month: this.state.month,
            shopID: shopID ? shopID : undefined,
          },
        })
        .then(data => {
          const rows = data.data.reportSaleList.map((o: any) => {
            o.invoice_date = moment.tz(o.invoice_date, 'Asia/Bangkok').format('YYYY/MM/DD');
            return o;
          });

          this.setState({ isGeneratingReport: false });
          const headers = Object.keys(rows[0]);
          downloadExcelFile(
            `Sales list ${this.state.year}-${this.state.month}.xlsx`,
            `Sales list ${this.state.year}-${this.state.month}`,
            [headers, ...rows.map((row: any) => headers.map(h => (row[h] ? row[h].toString() : '')))],
          );
          //this.generateCSV(rows);
        });
    };
  };
  createHeadersFormat(headers: any[], formatter: any[]) {
    // Construct the header column format
    const headersFormatter = headers.map(header => {
      // Check if there is any custom formatter
      const format = formatter === null ? undefined : formatter.find(x => x.column === header);

      return {
        column: header,
        format: format
          ? format.format
          : {
              type: 'TEXT',
            },
      };
    });

    return headersFormatter;
  }

  generateCSV(data: any[], title = 'salereport') {
    const headers = Object.keys(data[0]);

    const rows: string[][] = [];
    rows.push(headers);

    for (const val of data) {
      rows.push(headers.map(header => this.fixComma(val[header])));
    }
    const csvData = new Blob(['\ufeff', rows.map(x => x.join(',')).join('\n')], {
      type: 'text/csv;charset=utf-8-bom,',
    });

    const link = document.createElement('a');
    link.setAttribute('href', URL.createObjectURL(csvData));
    link.setAttribute('download', `${title}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
    link.remove();
  }

  fixComma(value: any) {
    if (typeof value == 'string' && value.includes(',')) {
      value = `"${value}"`;
    } else if (typeof value == 'string' && value.includes('\n')) {
      value = `"${value}"`;
    }
    return value;
  }
}
