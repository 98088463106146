import { Graph } from '../../../generated/graph';
import { UploadInlineFile } from '../../../components/UploadInlineFile';
import { PurchaseOrderTags } from './PurchaseOrderTags';

interface Props {
  value: Graph.PurchaseOrderInput;
  onChange: (v: Partial<Graph.PurchaseOrderInput>) => void;
}

export function PurchaseOrderBasicPartial({ value, onChange }: Props) {
  return (
    <fieldset>
      <legend>
        <span>Basic Information</span>
      </legend>

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>Purchase Order</label>
            <input
              className="lf-form-control"
              type="text"
              value={value.name ?? ''}
              onChange={e => onChange({ ...value, name: e.currentTarget.value })}
            />
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label>Tags</label>
            <PurchaseOrderTags
              value={value.tags ?? []}
              onChanged={e => {
                onChange({ ...value, tags: e });
              }}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-3">
          <div className="form-group">
            <label>PO Type</label>
            <select
              className="lf-form-control"
              value={value.type ?? ''}
              onChange={e => {
                onChange({ ...value, type: e.currentTarget.value as Graph.PurchaseOrderType });
              }}
            >
              <option value="NORMAL">Normal PO</option>
              <option value="FBL_VENDOR">FBL Vendor PO</option>
              <option value="INTERNAL_SELLER">Internal Seller</option>
              <option value="INTERNAL_SELLER_DEPOSIT">Internal Seller (Deposit)</option>
            </select>
          </div>
        </div>

        <div className="col-2">
          <div className="form-group">
            <label>Order Unit</label>
            <input
              className="lf-form-control"
              type="text"
              defaultValue={value.orderQty?.toString() || '0'}
              onChange={e => onChange({ ...value, orderQty: Number(e.currentTarget.value) })}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label>Order Amount</label>
            <div className="lf-input-group">
              <input
                className="lf-form-control"
                type="number"
                value={value.orderAmount ?? '0'}
                onChange={e => onChange({ ...value, orderAmount: e.currentTarget.value })}
              />
              <select
                className="lf-form-control"
                value={value.orderAmountCurrency ?? 'USD'}
                onChange={e => onChange({ ...value, orderAmountCurrency: e.currentTarget.value as Graph.Currency })}
              >
                <option value="USD">USD</option>
                <option value="RMB">RMB</option>
                <option value="DONG">DONG</option>
                <option value="BAHT">BAHT</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-3">
          <div className="form-group">
            <label>Order File</label>
            <UploadInlineFile
              value={value.orderFile ?? undefined}
              onChange={e => onChange({ ...value, orderFile: e })}
            />
          </div>
        </div>
      </div>
    </fieldset>
  );
}
