import { ReactElement } from 'react';
import { AppPageMarkupEditor } from './page/AppPageMarkupEditor';
import { useQuery, useMutation, gql } from '@apollo/client';
import { RouteComponentProps, useHistory } from 'react-router';
import { SectionLoading } from '../../components/loading/SectionLoading';
import { Graph } from '../../generated/graph';

const QUERY = gql`
  query customPage($id: Int!) {
    customPage(id: $id) {
      id
      content
      name
      priority
    }
  }
`;

const MUTATION_CREATE = gql`
  mutation createCustomPage($data: CustomPageInput!) {
    createCustomPage(data: $data)
  }
`;

const MUTATION_UPDATE = gql`
  mutation updateCustomPage($id: Int!, $data: CustomPageInput!) {
    updateCustomPage(id: $id, data: $data)
  }
`;

export function AppPageMarkupScreen(props: RouteComponentProps<{ id: string }>): ReactElement {
  const history = useHistory();
  const { data, loading } = useQuery<Graph.Query>(QUERY, {
    variables: { id: Number(props.match.params.id), skip: props.match.params.id === undefined },
  });
  const [create, { loading: loading1 }] = useMutation(MUTATION_CREATE);
  const [update, { loading: loading2 }] = useMutation(MUTATION_UPDATE);

  if (loading) {
    return <SectionLoading title="Custom Page" />;
  }

  const onSaved = async (v: Graph.CustomPageInput) => {
    if (props.match.params.id) {
      await update({ variables: { id: Number(props.match.params.id), data: v } });
    } else {
      await create({ variables: { id: Number(props.match.params.id), data: v } });
      history.push('/app/pages');
    }
  };

  return (
    <AppPageMarkupEditor
      value={
        (data?.customPage
          ? JSON.parse(JSON.stringify(data.customPage))
          : { content: [], name: '' }) as Graph.CustomPageInput
      }
      id={data?.customPage?.id || null}
      loading={loading1 || loading2}
      onSaved={onSaved}
    />
  );
}
