import React from 'react';

type InlineTabProps = {
  defaultTabIndex?: number;
  lazy?: boolean;
  disableHashed?: boolean;
  children: React.ReactElement<InlineTabContentProps> | React.ReactElement<InlineTabContentProps>[];
};

type InlineTabContentProps = {
  children?: JSX.Element[] | JSX.Element;
  alias?: string;
  name: string;
  visible?: boolean;
  link?: string;
  onFocus?: () => void;
};

export class InlineTab extends React.Component<InlineTabProps> {
  state: {
    selectedIndex: number;
    alias: string;
  };

  // Key track of the tab that used to load before
  loadedTabs: boolean[] = [];

  constructor(props: InlineTabProps) {
    super(props);

    // Find the default tabindex
    let defaultTabIndex = props.defaultTabIndex ? props.defaultTabIndex : 0;

    // Initial which tab that used to load before
    const children = React.Children.toArray(props.children) as React.ReactElement<InlineTabContentProps>[];
    if (props.lazy === undefined || props.lazy === false) {
      this.loadedTabs = new Array(children.length).fill(true);
    } else {
      this.loadedTabs = new Array(children.length).fill(false);
    }

    if (window.location.hash === '') {
      this.state = { selectedIndex: defaultTabIndex, alias: children[defaultTabIndex].props.alias || '' };
    } else {
      children.map((data, i: number) => {
        if ('#' + data.props.alias === window.location.hash) {
          defaultTabIndex = i;
          this.loadedTabs[i] = true;
        }
        return true;
      });

      this.state = {
        selectedIndex: defaultTabIndex,
        alias: window.location.hash,
      };
    }

    this.loadedTabs[defaultTabIndex] = true;
  }

  componentDidMount() {
    if (!this.props.disableHashed) {
      window.addEventListener('hashchange', this.onHashChanged, false);
    }
  }

  componentWillUnmount() {
    if (!this.props.disableHashed) {
      window.removeEventListener('hashchange', this.onHashChanged);
    }
  }

  onHashChanged = () => {
    const children = React.Children.toArray(this.props.children) as React.ReactElement<InlineTabContentProps>[];
    let found = false;

    for (let i = 0; i < children.length; i++) {
      if ('#' + children[i].props.alias === window.location.hash) {
        this.loadedTabs[i] = true;
        this.setState({ selectedIndex: i });
        found = true;
      } else {
        this.loadedTabs[i] = false;
      }
    }

    if (found === false) {
      this.loadedTabs[0] = true;
      this.setState({ selectedIndex: 0 });
    }
  };

  renderTabHeader = (children: React.ReactElement<InlineTabContentProps>[]) => {
    const headers = [];
    for (let i = 0; i < children.length; i++) {
      headers.push({
        name: children[i].props.name,
        alias: '#' + children[i].props.alias,
        link: children[i].props.link,
      });
    }

    const headerNodes = headers.map((headerName, index) => {
      let tabHeaderClassName = 'nav-link';
      if (index === this.state.selectedIndex) {
        tabHeaderClassName += ' active show';
      }

      return (
        <li
          key={headerName.name}
          className={tabHeaderClassName}
          onClick={() => {
            if (headerName.link) {
              window.location.href = headerName.link;
            } else {
              if (!this.props.disableHashed) {
                window.location.hash = children[index].props?.alias || '';
              }

              this.loadedTabs[index] = true;
              this.setState({ selectedIndex: index, alias: headerName.alias }, () => {
                if (children[index].props.onFocus) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  children[index].props.onFocus!();
                }
              });
            }
          }}
        >
          <div>{headerName.name}</div>
        </li>
      );
    });

    return (
      <div className="os-tabs-controls" style={{ backgroundColor: '#fff' }}>
        <ul className="nav nav-tabs">{headerNodes}</ul>
      </div>
    );
  };

  renderTabContent(children: React.ReactElement<InlineTabContentProps>[]) {
    return children.map((child, index) => {
      const props = { ...child.props, visible: index === this.state.selectedIndex };
      if (this.loadedTabs[index]) return React.cloneElement(child, props);
      else return <div key={child.props.name}></div>;
    });
  }

  render() {
    const children = React.Children.toArray(this.props.children) as React.ReactElement<InlineTabContentProps>[];

    return (
      <div className="steps-w">
        {this.renderTabHeader(children)}
        {this.renderTabContent(children)}
      </div>
    );
  }
}

export class InlineTabContent extends React.Component<InlineTabContentProps> {
  render() {
    return <div className={this.props.visible ? '' : 'd-none'}>{this.props.children}</div>;
  }
}
