export default function InitialLoading() {
  return (
    <div className={`fs-loading-wrap`}>
      <div style={{ textAlign: 'center' }}>
        <img src="/assets/loading2.gif" alt="" />
        <p>Loading the application</p>
      </div>
    </div>
  );
}
