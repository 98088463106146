export const navigationForOffline = () => {
  return [
    {
      title: 'Sales',
      items: [
        {
          label: 'Point of Sales',
          url: '/store/dashboard',
          icon: 'fas fa-laptop',
        },
      ],
    },
    {
      title: 'Inventory',
      items: [
        {
          label: 'Create Barcode',
          url: '/barcode/create',
          icon: 'fa fa-barcode',
        },
        {
          label: 'Finding',
          url: '/inventory/finding',
          icon: 'os-icon os-icon-search',
        },
        {
          label: 'Recycle',
          url: '/inventory/recycle',
          icon: 'os-icon os-icon-trash-2',
        },
        {
          label: 'Transfer',
          url: '/inventory/transfer',
          icon: 'os-icon os-icon-truck',
        },
      ],
    },
  ];
};
