import React, { useState, ReactElement } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Graph } from '../../generated/graph';
import { DropdownBaseComponent } from './../DropdownBaseComponent';
import { DropdownPreview } from './../DropboxComponent';
import { DropdownShadow } from '../DropdownShadow';

const QUERY = gql`
  query {
    luckyDrawList {
      id
      name
    }
  }
`;

interface Props {
  value: number;
  placeholder: string;
  onChanged: (v: number) => void;
}

export function LuckyDrawPicker(props: Props): React.ReactElement {
  const { data } = useQuery<Graph.Query>(QUERY);
  const [toggle, setToggle] = useState(false);

  if (!data?.luckyDrawList) {
    return (
      <div className="lf-form-control bg-loading">
        <span>Loading</span>
      </div>
    );
  }

  const luckyDrawList = data.luckyDrawList;

  function preview(data: Graph.LuckyDraw[]): ReactElement {
    const item = data.find(x => x.id === props.value);
    const caption = item ? item.name : props.placeholder;

    return (
      <DropdownPreview>
        <span>{caption}</span>
      </DropdownPreview>
    );
  }

  function dropdown(data: Graph.LuckyDraw[]): ReactElement {
    return (
      <DropdownShadow>
        <ul className="dropdown-container" style={{ width: 500, maxHeight: 400, overflowY: 'auto', right: 0 }}>
          {data.map(x => (
            <li
              key={x.id?.toString()}
              onClick={(): void => {
                setToggle(false);
                if (props.onChanged) props.onChanged(x.id as number);
              }}
            >
              <span>{x.name}</span>
            </li>
          ))}
        </ul>
      </DropdownShadow>
    );
  }

  return (
    <DropdownBaseComponent
      dropdownHeight={400}
      toggle={toggle}
      onToggleChanged={(t): void => {
        setToggle(t);
      }}
      preview={(): ReactElement => preview(luckyDrawList)}
      dropdown={(): ReactElement => dropdown(luckyDrawList)}
    />
  );
}
