import React from 'react';
import { AppPageMarkup, AppMarkupBanner } from '../../../types/common';
import { NavigationBuilder } from '../../../components/NavigationBuilder';
import { UploadImage } from '../../../components/UploadImage';
import { AppPageTabEditor } from './AppPageTabEditor';

interface Props {
  value: AppPageMarkup;
  onChange: (v: AppPageMarkup) => void;
}

function AppPageMarkupBanner(
  value: AppMarkupBanner,
  change: (v: AppMarkupBanner) => void,
  width: number,
  height: number,
) {
  return (
    <>
      <div className="form-group">
        <label>Title</label>
        <input
          value={value.text || ''}
          className="lf-form-control"
          placeholder="Please write block title"
          onChange={e => {
            change({ ...value, text: e.target.value } as AppMarkupBanner);
          }}
        />
      </div>
      <div className="form-group">
        <UploadImage
          value={value?.picture}
          width={width}
          height={height}
          onChange={v => {
            change({ ...value, picture: v } as AppMarkupBanner);
          }}
        />
      </div>
      <NavigationBuilder
        value={value.navigate}
        onChange={v => {
          change({ ...value, navigate: v } as AppMarkupBanner);
        }}
      />
    </>
  );
}

export function AppPageMarkupBannerList(props: Props) {
  return (
    <div>
      <div className="form-group">
        <label>Title</label>
        <input
          value={props.value?.title || ''}
          className="lf-form-control"
          placeholder="Please write block title"
          onChange={e => {
            props.onChange({ ...props.value, title: e.target.value } as AppPageMarkup);
          }}
        />
      </div>

      <div className="form-group">
        <label>Size</label>
        <br />
        <input
          value={props.value?.width || '0'}
          style={{ width: 50 }}
          className="lf-inline lf-form-control mr-1"
          placeholder="Width"
          onChange={e => {
            props.onChange({ ...props.value, width: Number(e.target.value) } as AppPageMarkup);
          }}
        />
        &nbsp; x &nbsp;
        <input
          value={props.value?.height || '0'}
          style={{ width: 50 }}
          className="lf-inline lf-form-control"
          placeholder="Height"
          onChange={e => {
            props.onChange({ ...props.value, height: Number(e.target.value) } as AppPageMarkup);
          }}
        />
      </div>

      <AppPageTabEditor
        value={props.value.items || []}
        onChange={v => {
          if (props.onChange) {
            props.onChange({ ...props.value, items: v });
          }
        }}
        titleExtract={v => v.text || 'Untitled'}
        defaultNewItemValue={() => ({
          navigate: {},
          picture: '',
          text: 'Untitled',
        })}
        render={(v, change) => AppPageMarkupBanner(v, change, props.value.width || 0, props.value.height || 0)}
      />
    </div>
  );
}
