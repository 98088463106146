import { ApolloClient, gql } from '@apollo/client';
import { InventoryStockInProductType } from '../components/InventoryStockInProduct';
import { Graph } from 'src/generated/graph';

interface InternalJSON {
  z: number;
  x: string;
  s: number;
}

export class CachedProductBarcodeQuery {
  cached: { [key: string]: InventoryStockInProductType };
  apollo: ApolloClient<unknown>;

  constructor(apollo: ApolloClient<unknown>) {
    this.apollo = apollo;
    this.cached = {};
  }

  async get(barcode: string): Promise<InventoryStockInProductType | null> {
    const { data } = await this.apollo.query<Graph.Query>({
      query: gql`
        query getProduct($barcode: String) {
          getProductUnitByBarcode(barcode: $barcode) {
            skuID
            itemID
            color
            size
            code
          }
        }
      `,
      variables: { barcode },
      fetchPolicy: 'cache-first',
    });

    if (!data || !data.getProductUnitByBarcode) return null;

    return {
      code: data.getProductUnitByBarcode.code || '',
      color: data.getProductUnitByBarcode.color || '',
      size: data.getProductUnitByBarcode.size || '',
      cost: '',
      costDescription: '',
      itemID: (data.getProductUnitByBarcode.itemID || '').toString(),
      qty: 1,
      skuID: data.getProductUnitByBarcode.skuID.toString(),
    };
  }

  async getBySkuCode(id: number, shipmentId?: number): Promise<InventoryStockInProductType | null> {
    const { data } = await this.apollo.query<Graph.Query>({
      query: gql`
        query getProduct($id: Int!) {
          sku(id: $id) {
            id
            productID
            color
            size
            product {
              id
              code
            }
          }
        }
      `,
      variables: { id },
      fetchPolicy: 'cache-first',
    });

    if (!data || !data.sku) return null;

    return {
      code: data.sku.product?.code || '',
      color: data.sku.color || '',
      size: data.sku.size || '',
      cost: '',
      costDescription: '',
      itemID: (data.sku.productID || '').toString(),
      qty: 1,
      skuID: (data.sku.id || '').toString(),
      shipmentId: shipmentId,
    };
  }

  async scan(barcode: string): Promise<InventoryStockInProductType | null> {
    // Check barcode type
    const code = barcode.trim();
    let json: InternalJSON | undefined = undefined;

    if (code.substr(0, 1) === '{') {
      try {
        json = JSON.parse(code);
        if (json) {
          return await this.getBySkuCode(json.z, json && json.s ? json.s : undefined);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      return await this.get(code);
    }

    return null;
  }
}
