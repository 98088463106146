import Layout from 'src/components/layout/Layout';
import { DeliveryHubScreen } from './partial/DeliveryHubScreen';
import { PackageDraftListScreen } from './partial/PackageDraftListScreen';
import { PackagePickupListScreen } from './partial/PackagePickupListScreen';
import { PackageRequestListScreen } from './partial/PackageRequestListScreen';
import { PackageTransitListScreen } from './partial/PackageTransitListScreen';
import { PackageArrivedListScreen } from './partial/PackageArrivedListScreen';
import { InlineTab, InlineTabContent } from '../../components/light/InlineTab';
import { PackageInternalListScreen } from './partial/PackageInternalListScreen';
import { PackageAbandonedListScreen } from './partial/PackageAbandonedListScreen';
import { PackageCustomerPickupListScreen } from './partial/PackageCustomerPickup';
import { PackageDeliveringListScreen } from './partial/PackageDeliveringListScreen';
import { PackageWaitTransitListScreen } from './partial/PackageWaitTransitListScreen';
import { ClaimPackageScreen } from './partial/ClaimPackageScreen';

export function PackageProgressScreen() {
  return (
    <Layout full={true}>
      <Layout.Header>Delivery Stages</Layout.Header>
      <InlineTab lazy={true}>
        <InlineTabContent name="Draft" alias="draft">
          <PackageDraftListScreen />
        </InlineTabContent>
        <InlineTabContent name="Pickup Requests" alias="request">
          <PackageRequestListScreen />
        </InlineTabContent>
        <InlineTabContent name="With Pickup Staffs" alias="pickup">
          <PackagePickupListScreen />
        </InlineTabContent>
        <InlineTabContent name="Wait for Transit" alias="wait">
          <PackageWaitTransitListScreen />
        </InlineTabContent>
        <InlineTabContent name="In Transit" alias="transit">
          <PackageTransitListScreen />
        </InlineTabContent>
        <InlineTabContent name="Arrived@Hub" alias="arrived">
          <PackageArrivedListScreen />
        </InlineTabContent>
        <InlineTabContent name="Delivering" alias="delivering">
          <PackageDeliveringListScreen />
        </InlineTabContent>
        <InlineTabContent name="Package Internal" alias="internal">
          <PackageInternalListScreen />
        </InlineTabContent>
        <InlineTabContent name="Abandoned" alias="abandoned">
          <PackageAbandonedListScreen />
        </InlineTabContent>
        <InlineTabContent name="Customer Pickup" alias="customer_pickup">
          <PackageCustomerPickupListScreen />
        </InlineTabContent>
        <InlineTabContent name="Hubs Settings" alias="hubs">
          <DeliveryHubScreen />
        </InlineTabContent>
        <InlineTabContent name="Claimed Packages" alias="claim_packages">
          <ClaimPackageScreen />
        </InlineTabContent>
      </InlineTab>
    </Layout>
  );
}
