/* eslint-disable */
import React from 'react';
import { MenuItem } from './MainMenu';
import { Link } from 'react-router-dom';

type Props = {
  items: MenuItem[];
};

export class MobileMenu extends React.Component<Props> {
  state = {
    hamburger: false,
    activeItem: {},
  };

  timerMenuLeavingID?: NodeJS.Timeout;

  onClick = (subs: number, item: any) => {
    if (subs === 0) {
      this.setState({ hamburger: !this.state.hamburger });
    } else {
      this.setState({ activeItem: item });
    }
  };

  render() {
    const menuVisibleStyle = this.state.hamburger ? { display: 'block' } : {};

    return (
      <div className="menu-mobile menu-activated-on-click color-scheme-dark">
        <div className="mm-logo-buttons-w">
          <a href="/#" className="mm-logo">
            <img src={require('../../img/logo.png')} alt="" />
            <span>L192</span>
          </a>
          <div className="mm-buttons">
            <div className="mobile-menu-trigger" onClick={() => this.setState({ hamburger: !this.state.hamburger })}>
              <div className="os-icon os-icon-hamburger-menu-1"></div>
            </div>
          </div>
        </div>
        <div className="menu-and-user" style={menuVisibleStyle}>
          <ul className="main-menu">{this.props.items.map(item => this.renderMenu(item))}</ul>
        </div>
      </div>
    );
  }

  private renderSubmenuItem(item: MenuItem) {
    return (
      <li key={item.name}>
        <Link
          to={{
            pathname: item.to,
            state: {
              refresh: Date.now(),
            },
          }}
          onClick={() => {
            this.setState({ activeItem: undefined, hamburger: !this.state.hamburger });
          }}
        >
          {item.name}
        </Link>
      </li>
    );
  }

  private renderSubmenuItems(items: MenuItem[], index: number) {
    return (
      <ul key={index} className="sub-menu">
        {items.map(subItem => this.renderSubmenuItem(subItem))}
      </ul>
    );
  }

  private renderMenu(item: MenuItem) {
    const classNameList: string[] = [];
    let subs: React.ReactNode;

    if (item.subs && item.subs.length > 0) {
      classNameList.push('has-sub-menu');
    }

    if (item.subs !== undefined) {
      if (item.subs!.length !== 0) {
        if (Array.isArray(item.subs![0])) {
          subs = (item.subs! as MenuItem[][]).map((x, index) => this.renderSubmenuItems(x, index));
        } else {
          subs = this.renderSubmenuItems(item.subs! as MenuItem[], 0);
        }
      }
    }

    if (this.state.activeItem === item) {
      classNameList.push('active');
    }

    if (!item.header) {
      return (
        <li key={item.name} className={classNameList.join(' ')}>
          <Link
            to={item.to}
            onClick={() => {
              this.onClick((item.subs || []).length, item);
            }}
          >
            <div className="icon-w">
              <div className={item.icon}></div>
            </div>
            <span>{item.name}</span>
          </Link>
          <div className="sub-menu-w">
            <div className="sub-menu-i">{subs}</div>
          </div>
        </li>
      );
    }
  }
}
