/* eslint-disable */
import React from 'react';
import { ApolloClient, gql } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { ApolloConsumer } from '@apollo/client';

interface Props extends RouteComponentProps {
  apollo: ApolloClient<any>;
}

export class CreatePurchaseOrderScreen extends React.Component<Props> {
  state: {
    name: string;
    isLoading: boolean;
    tags: any[];
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      isLoading: false,
      tags: [],
    };
  }

  renderApollo() {
    return <ApolloConsumer>{this.renderContent}</ApolloConsumer>;
  }

  renderContent = (apollo: ApolloClient<{}>) => {
    return (
      <SectionBlock title="Create Purchase Order">
        <div className="form-group">
          <label>Purchase Order Name</label>
          <input
            className="form-control"
            autoFocus
            type="text"
            value={this.state.name}
            placeholder="Name"
            onChange={this.onNameInputChanged}
          />
        </div>

        <div className="form-buttons-w">
          <button className="btn btn-primary" onClick={this.onCreateClicked(apollo)}>
            Create
          </button>
        </div>
      </SectionBlock>
    );
  };

  render() {
    return (
      <Content>
        <div className="row">
          <div className="col-lg-6 col-sm-12">{this.renderApollo()}</div>
        </div>
      </Content>
    );
  }

  onNameInputChanged = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ name: e.currentTarget.value });
  };

  onCreateClicked = (apollo: ApolloClient<{}>) => {
    return async () => {
      this.setState({ isLoading: true });

      const t = await apollo.mutate({
        mutation: gql`
          mutation createPurchaseOrder($name: String) {
            createPurchaseOrder(name: $name)
          }
        `,
        variables: {
          name: this.state.name,
        },
      });

      this.setState({ isLoading: false }, () => {
        this.props.history.push('/purchase/edit/' + t.data.createPurchaseOrder);
      });
    };
  };
}
