/* eslint-disable */
import React from 'react';
import { ApolloClient, gql } from '@apollo/client';
import { InventoryStockInProductType } from '../components/InventoryStockInProduct';
import { ScanningPurchaseOrder } from '../components/ScanningPurchaseOrder';
import { ScanningInventorySlot, InventorySlot } from '../components/ScanningInventorySlot';
import { BreadCrumbArray } from '../components/light/BreadcrumbArray';
import { Content } from '../components/light/Content';
import { PurchaseOrder } from '../types/PurchaseOrderType';
import { ScanningTable } from '../components/ScanningTable';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  apollo: ApolloClient<{}>;
  type: 'STOCK_IN_FBL' | 'STOCK_IN' | 'RECYCLE' | 'TRANSFER' | 'ADJUST' | 'FIXED';
}

type InventoryMovementScreenState = {
  purchaseOrder: PurchaseOrder | null;
  inventorySlotTo: InventorySlot | null;
  inventorySlotFrom: InventorySlot | null;
  products: InventoryStockInProductType[];
  loading: boolean;
  type: 'STOCK_IN_FBL' | 'STOCK_IN' | 'RECYCLE' | 'TRANSFER' | 'ADJUST' | 'FIXED';
};

export class InventoryMovementScreen extends React.Component<Props> {
  public state: InventoryMovementScreenState;
  breadcrumbItem: string[] = [];

  constructor(props: Props) {
    super(props);
    this.state = getResetState(props.type);
  }

  public render() {
    let screen;

    if (this.state.type === 'STOCK_IN') screen = this.renderStockIn();
    if (this.state.type === 'FIXED') screen = this.renderFixed();
    if (this.state.type === 'STOCK_IN_FBL') screen = this.renderStockInFBL();
    if (this.state.type === 'RECYCLE') screen = this.renderRecycle();
    if (this.state.type === 'TRANSFER') screen = this.renderTransfer();
    if (this.state.type === 'ADJUST') screen = this.renderAdjust();

    return <Content breadcrumb={<BreadCrumbArray items={this.renderBreadCrumb()} />}>{screen}</Content>;
  }

  private renderAdjust() {
    return this.renderScreenIndependency([this.askForInventorySlotTo, this.askForProductsWithNoCost]);
  }

  private renderStockIn() {
    return this.renderScreenIndependency([
      this.askForPurchaseOrder,
      this.askForInventorySlotTo,
      this.askForProductsWithCost,
    ]);
  }

  private renderFixed() {
    return this.renderScreenIndependency([this.askForInventorySlotTo, this.askForProductsWithNoCost]);
  }

  private renderStockInFBL() {
    return this.renderScreenIndependency([this.askForInventorySlotTo, this.askForProductsWithNoCost]);
  }

  private renderTransfer() {
    return this.renderScreenIndependency([
      this.askForInventorySlotFrom,
      this.askForInventorySlotTo,
      this.askForProductsWithNoCost,
    ]);
  }

  private renderRecycle() {
    return this.renderScreenIndependency([this.askForInventorySlotTo, this.askForProductsWithNoCost]);
  }

  private renderScreenIndependency = (screenFns: (() => React.ReactNode)[]) => {
    for (const fns of screenFns) {
      const result = fns();
      if (result) return result;
    }

    return undefined;
  };

  private askForPurchaseOrder = (): React.ReactNode => {
    if (this.state.purchaseOrder !== null) return undefined;

    return <ScanningPurchaseOrder apollo={this.props.apollo} onValidPurchaseOrder={this.onValidPurchaseOrder} />;
  };

  private askForInventorySlotTo = (): React.ReactNode => {
    if (this.state.inventorySlotTo !== null) return undefined;

    return (
      <ScanningInventorySlot
        key="inventory_slot_to"
        title="To inventory slot"
        apollo={this.props.apollo}
        onValidInventorySlot={this.onValidInventorySlotTo}
      />
    );
  };

  private askForInventorySlotFrom = (): React.ReactNode => {
    if (this.state.inventorySlotFrom !== null) return undefined;

    return (
      <ScanningInventorySlot
        key="inventory_slot_from"
        title="From inventory slot"
        apollo={this.props.apollo}
        onValidInventorySlot={this.onValidInventorySlotFrom}
      />
    );
  };

  private askForProductsWithCost = (): React.ReactNode => {
    return (
      <ScanningTable
        title={this.state.type}
        requiredCost={true}
        items={this.state.products}
        apollo={this.props.apollo}
        purchaseOrder={this.state.purchaseOrder as PurchaseOrder}
        onAddTransaction={this.addStockTransaction}
        onValueChanged={this.onProductItemsChanged}
      />
    );
  };

  private askForProductsWithNoCost = (): React.ReactNode => {
    return (
      <ScanningTable
        title={this.state.type}
        requiredCost={false}
        items={this.state.products}
        apollo={this.props.apollo}
        purchaseOrder={this.state.purchaseOrder as PurchaseOrder}
        onAddTransaction={this.addStockTransaction}
        onValueChanged={this.onProductItemsChanged}
      />
    );
  };

  private renderBreadCrumb = () => {
    // Constructing the breadcrumb list
    this.breadcrumbItem = [`[${this.state.type}]`];
    if (this.state.purchaseOrder !== null) this.breadcrumbItem.push(`[ORDER: ${this.state.purchaseOrder.name}]`);
    if (this.state.inventorySlotTo !== null) this.breadcrumbItem.push(`[IN: ${this.state.inventorySlotTo.name}]`);
    if (this.state.inventorySlotFrom !== null) this.breadcrumbItem.push(`[ OUT: ${this.state.inventorySlotFrom.name}]`);

    return this.breadcrumbItem;
  };

  private onValidPurchaseOrder = (order: PurchaseOrder) => {
    this.setState({ purchaseOrder: order });
  };

  private onValidInventorySlotTo = (slot: InventorySlot) => {
    this.setState({ inventorySlotTo: slot });
  };

  private onValidInventorySlotFrom = (slot: InventorySlot) => {
    console.log(slot);
    this.setState({ inventorySlotFrom: slot });
  };

  private onProductItemsChanged = (items: InventoryStockInProductType[]) => {
    this.setState({ products: items });
  };

  private addStockTransaction = async () => {
    const products = this.state.products;

    // check if all cost input has been filled
    if (this.state.type === 'STOCK_IN') {
      if (
        products.filter(obj => {
          return obj.cost.trim() === '';
        }).length > 0
      ) {
        alert('Please update all item cost');
        return;
      }
    }

    if (products.length === 0) {
      alert('No items');
      return;
    }

    // Prepare batch of transaction
    let transactions;
    if (this.state.type === 'STOCK_IN') {
      transactions = this.prepareStockInData(products);
    } else if (this.state.type === 'STOCK_IN_FBL') {
      transactions = this.prepareStockInFBLData(products);
    } else if (this.state.type === 'RECYCLE') {
      transactions = this.prepareRecylceData(products);
    } else if (this.state.type === 'TRANSFER') {
      transactions = this.prepareTransferData(products);
    } else if (this.state.type === 'ADJUST') {
      transactions = this.prepareAdjustData(products);
    } else if (this.state.type === 'FIXED') {
      transactions = this.prepareFixedData(products);
    }

    if (window.confirm('Are you sure you want to add this transaction')) {
      this.setState({ loading: true });

      await this.props.apollo.mutate({
        mutation: gql`
          mutation addStockTransaction($transactions: [StockTransactionInput]) {
            addStockTransaction(transactions: $transactions)
          }
        `,
        variables: { transactions: transactions },
      });

      this.setState({ loading: false });
      this.resetState();
    }
  };

  private prepareRecylceData(items: InventoryStockInProductType[]) {
    if (this.state.inventorySlotTo === null) return [];

    return items.map(item => ({
      type: 'RECYCLE',
      qty: item.qty,
      slotID: this.state.inventorySlotTo!.id,
      skuID: parseInt(item.skuID),
    }));
  }

  private prepareAdjustData(items: InventoryStockInProductType[]) {
    if (this.state.inventorySlotTo === null) return [];

    return items.map(item => ({
      type: 'ADJUST',
      qty: item.qty,
      slotID: this.state.inventorySlotTo!.id,
      skuID: parseInt(item.skuID),
    }));
  }

  private prepareFixedData(items: InventoryStockInProductType[]) {
    if (this.state.inventorySlotTo === null) return [];

    return items.map(item => ({
      type: 'FIXED',
      qty: item.qty,
      slotID: this.state.inventorySlotTo!.id,
      skuID: parseInt(item.skuID),
    }));
  }

  private prepareStockInFBLData(items: InventoryStockInProductType[]) {
    if (this.state.inventorySlotTo === null) return [];

    return items.map(item => ({
      type: 'STOCK_IN',
      qty: item.qty,
      slotID: this.state.inventorySlotTo!.id,
      skuID: parseInt(item.skuID),
      shipmentId: item.shipmentId,
    }));
  }

  private prepareStockInData(items: InventoryStockInProductType[]) {
    if (this.state.inventorySlotTo === null) return [];
    if (this.state.purchaseOrder === null) return [];

    return items.map(item => ({
      type: 'STOCK_IN',
      qty: item.qty,
      slotID: this.state.inventorySlotTo!.id,
      skuID: parseInt(item.skuID),
      purchaseOrderID: this.state.purchaseOrder!.id,
      cost: item.cost,
      costDescription: item.costDescription,
    }));
  }

  private prepareTransferData(items: InventoryStockInProductType[]) {
    if (this.state.inventorySlotTo === null) return [];
    if (this.state.inventorySlotFrom === null) return [];

    return items.map(item => ({
      type: 'TRANSFER',
      qty: item.qty,
      slotID: this.state.inventorySlotTo!.id,
      fromSlotID: this.state.inventorySlotFrom!.id,
      skuID: parseInt(item.skuID),
    }));
  }

  private resetState = () => {
    this.setState(getResetState(this.state.type));
  };
}

/**
 * Get state that will reset the stock movement operation
 *
 * @param refresh Refresh timestamp. It is used to compare with previous timestamp to
 *                determine when to refresh the page
 * @param type    Stock movement type
 */
function getResetState(
  type: 'STOCK_IN_FBL' | 'STOCK_IN' | 'TRANSFER' | 'RECYCLE' | 'ADJUST' | 'FIXED',
): InventoryMovementScreenState {
  return {
    purchaseOrder: null,
    inventorySlotTo: null,
    inventorySlotFrom: null,
    products: [],
    loading: false,
    type: type,
  };
}
