/* eslint-disable */
import React from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { QueryResult, MutationResult, MutationFunction, gql } from '@apollo/client';
import { Query, Mutation } from '@apollo/react-components';
import { Graph } from '../../generated/graph';
import { AlertComponent } from '../../components/AlertComponent';

const NOTE_TEMPLATE_LIST = gql`
  query customerNoteTemplateList {
    customerNoteTemplateList {
      id
      content
      isMoneyNote
    }
  }
`;

const NOTE_TEMPLATE_UPDATE = gql`
  mutation updateCustomerNoteTemplate($id: Int, $data: CustomerNoteTemplateInput) {
    updateCustomerNoteTemplate(id: $id, data: $data)
  }
`;

const NOTE_TEMPLATE_CREATE = gql`
  mutation createCustomerNoteTemplate($data: CustomerNoteTemplateInput) {
    createCustomerNoteTemplate(data: $data)
  }
`;

interface State {
  editingID: number;
  content: string;
  isMoneyNote: boolean;
  isAlert: boolean;
  message: string;
}

export class NoteTemplateListScreen extends React.Component {
  state: State = {
    editingID: -1,
    content: '',
    isMoneyNote: false,
    isAlert: false,
    message: '',
  };

  onCreate = (update: MutationFunction) => {
    update({
      variables: {
        data: {
          content: this.state.content,
          isMoneyNote: this.state.isMoneyNote,
        },
      },
    });
  };

  renderUpdateMutation = (update: MutationFunction) => {
    return (
      <tr>
        <td>
          <input
            className="form-control"
            type="text"
            name="content"
            value={this.state.content}
            onChange={this.onChangeInput}
          />
        </td>
        <td>
          <input type="checkbox" name="isMoneyNote" checked={this.state.isMoneyNote} onChange={this.onChangeInput} />
        </td>
        <td>
          <button onClick={() => this.onCreate(update)} className="btn btn-default">
            {' '}
            Save
          </button>
        </td>
      </tr>
    );
  };

  onCreateCompleted = (data: any) => {
    if (data.createCustomerNoteTemplate) {
      this.setState({
        isAlert: true,
        message: 'Created Successfully',
        content: '',
        isMoneyNote: false,
      });
    }
  };

  onUpdate = (e: any, update: MutationFunction, id: number) => {
    e.preventDefault();
    const content: any = document.getElementsByName(`content-${id}`);
    const isMoneyNote: any = document.getElementsByName(`isMoneyNote-${id}`);
    update({
      variables: {
        id: id,
        data: {
          content: content[0].value,
          isMoneyNote: isMoneyNote[0].checked,
        },
      },
    });
    this.setState({
      editingID: -1,
    });
  };

  onUpdateCompleted = (data: any) => {
    if (data.updateCustomerNoteTemplate === true) {
      this.setState({
        isAlert: true,
        message: 'Update successfully!',
      });
    }
  };

  renderMutationUpdate = (data: Graph.CustomerNoteTemplate, update: MutationFunction, { loading }: MutationResult) => {
    return (
      <tr key={'' + data.id} id={'' + data.id}>
        <td>
          <input className="form-control" type="text" defaultValue={data.content!} name={'content-' + data.id} />
        </td>
        <td>
          <input type="checkbox" defaultChecked={data.isMoneyNote!} name={'isMoneyNote-' + data.id} />
        </td>
        <td style={{ width: 250 }}>
          <button className="btn btn-primary" onClick={e => this.onUpdate(e, update, Number(data.id))}>
            Save
          </button>
          <button className="btn btn-default" onClick={() => this.setState({ editingID: -1 })}>
            Close
          </button>
        </td>
      </tr>
    );
  };

  renderRow = (data: Graph.CustomerNoteTemplate) => {
    if (this.state.editingID === data.id) {
      return (
        <Mutation
          mutation={NOTE_TEMPLATE_UPDATE}
          onCompleted={this.onUpdateCompleted}
          refetchQueries={['customerNoteTemplateList']}
          key={data.id}
        >
          {(update: MutationFunction, result: MutationResult) => this.renderMutationUpdate(data, update, result)}
        </Mutation>
      );
    }

    return (
      <tr key={'' + data.id}>
        <td>{data.content}</td>
        <td>{data.isMoneyNote ? 'True' : 'False'}</td>
        <td style={{ width: 250 }}>
          <button className="btn btn-default" onClick={() => this.setState({ editingID: data.id })}>
            Edit
          </button>
        </td>
      </tr>
    );
  };

  onChangeInput = (e: any) => {
    const target = e.target;
    const value = e.target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  renderNoteTemplateList = ({ loading, data }: QueryResult<Graph.Query>) => {
    if (loading || data === undefined) return <div>Loading</div>;
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Content</th>
            <th>Is Money Note</th>
          </tr>
        </thead>
        <tbody>
          <Mutation
            mutation={NOTE_TEMPLATE_CREATE}
            refetchQueries={['customerNoteTemplateList']}
            onCompleted={this.onCreateCompleted}
          >
            {(update: MutationFunction) => this.renderUpdateMutation(update)}
          </Mutation>
          {(data.customerNoteTemplateList || []).slice(0).map(this.renderRow)}
        </tbody>
      </table>
    );
  };

  render() {
    return (
      <Content>
        <SectionBlock title="Note Template">
          <AlertComponent
            message={this.state.message}
            type={this.state.isAlert ? 'success' : ''}
            isHidden={!this.state.isAlert}
            onClickClose={() => this.setState({ isAlert: false })}
          />
          <Query query={NOTE_TEMPLATE_LIST}>{this.renderNoteTemplateList}</Query>
        </SectionBlock>
      </Content>
    );
  }
}
