import React, { useEffect, useState } from 'react';
import styles from './tasks.module.scss';
import { useQuery } from '@apollo/client';
import { Graph } from '../../../generated/graph';
import { Link } from 'react-router-dom';
import { QUERY_PACKAGE_LIST } from '../query';
import { BlockLoading } from '../../../components/loading/BlockLoading';
import XForm from 'src/components/layout/xform/XForm';
import { Table } from 'src/components/layout/tables/Table';
import Button from 'src/components/layout/xform/Button';
import Formatter from 'src/libs/Formatter';
import { Checkbox } from 'src/components/form/FormComponent';
import { useHistory, useLocation } from 'react-router';
import { useQueryString } from 'src/libs/QueryString';
import { LinkButton } from 'src/components/layout/xform/LinkButton';
import { DateTime } from 'src/libs/DateTime';
import Badge from 'src/components/badge/Badge';

function renderPackage(item: Graph.Package, idx: number) {
  return (
    <tr key={item.id}>
      <td>{idx + 1}</td>
      <td className="text-center">
        <i
          className={item.isReturning ? 'fas fa-arrow-left' : 'fas fa-arrow-right'}
          style={{ fontSize: 18, color: item.isReturning ? '#e74c3c' : '#27ae60' }}
        ></i>
      </td>
      <td>
        {item.type === 'ORDER_PICKUP' && (
          <div style={{ marginBottom: 2 }}>
            <span style={{ fontSize: 10, background: '#f1c40f', display: 'inline-block', padding: '2px 5px' }}>
              {Formatter.beautifyUglyName(item.type)}
            </span>
          </div>
        )}
        {item.type === 'PICKUP' && (
          <div style={{ marginBottom: 2 }}>
            <span
              style={{
                fontSize: 10,
                background: '#1d82fb',
                display: 'inline-block',
                padding: '2px 5px',
                color: '#fff',
              }}
            >
              {Formatter.beautifyUglyName('Customer Pickup')}
            </span>
          </div>
        )}
        <Link to={`/package/detail/${item.id}`} style={{ fontSize: 16 }}>
          <strong>{item.id.toString().padStart(8, '0')}</strong>
        </Link>
        {!!item?.hasReturningItem && (
          <div>
            <span
              style={{
                fontSize: 13,

                display: 'inline-block',

                fontWeight: 'bold',
                color: 'RED',
              }}
            >
              <i className="fas fa-box-alt"></i> CUSTOMER WILL RETURN A PRODUCT
            </span>
          </div>
        )}
        {!!item?.returningFromPackageId && (
          <div>
            <span
              style={{
                fontSize: 13,
                display: 'inline-block',

                fontWeight: 'bold',
                color: '#1C6DD0',
              }}
            >
              <i className="fas fa-hand-holding-box"></i> RETURN FROM PACKAGE #{item?.returningFromPackageId}
            </span>
          </div>
        )}
      </td>
      <td>
        <div style={{ display: 'flex' }}>
          {item?.useInsurance === true && (
            <div>
              <span
                style={{
                  fontSize: 10,
                  background: '#F94892',
                  display: 'inline-block',
                  margin: '6px 0 0 4px',
                  padding: '3px 5px 1px 5px',
                  color: '#fff',
                }}
              >
                {Formatter.beautifyUglyName('Insurance')}
              </span>
            </div>
          )}
        </div>
        <strong>{item.supplier?.name}</strong>
        <br />
        <span>{item.supplier?.contact}</span>
      </td>
      <td>{item?.requestPickupAt}</td>
      <td>
        {item.customer && (
          <div>
            <strong>{item.customer.name}</strong>
            <br />
            <span>{item.customer.phoneNumber}</span>
          </div>
        )}
      </td>
      <td>
        {item.claimPickupBy && (
          <div>
            <strong>{item.claimPickupBy.name}</strong>
            <br />
            <span>{item.claimPickupAt}</span>
          </div>
        )}
      </td>
      <td>{item.appointedAt?.toString()}</td>
      <td>
        <strong style={{ fontSize: 13 }}>{item.nextWarehouse?.shortName}</strong>
      </td>
    </tr>
  );
}
function PackageList({ items }: { items: Graph.Package[] }) {
  const [toggle, setToggle] = useState(false);

  const currentSupplier = items[0].supplier;
  const isNewSupplier = DateTime.diff(DateTime.now(), currentSupplier?.firstCreatedPackageAt || '') < 3;

  return (
    <Table stripe={true} border={true}>
      <thead>
        <tr style={{ background: '#ced6e0' }} onClick={() => setToggle(!toggle)}>
          <th colSpan={8}>
            {items[0].supplier?.name || 'Little Fashion'}
            <span style={{ fontWeight: 'lighter' }}> ({items.length})</span>

            {isNewSupplier && (
              <>
                &nbsp;<Badge type="danger">New</Badge>
              </>
            )}
          </th>
          <th style={{ textAlign: 'right' }}>
            <XForm.Button>{toggle ? <i className="fas fa-minus"></i> : <i className="fas fa-plus"></i>}</XForm.Button>
          </th>
        </tr>
        {toggle && (
          <tr>
            <th style={{ width: 40 }}>#</th>
            <th style={{ width: 50 }}>Type</th>
            <th style={{ width: 300 }}>Package ID</th>
            <th>Merchant</th>
            <th style={{ width: 180 }}>Request Pickup At</th>
            <th style={{ width: 150 }}>Customer</th>
            <th style={{ width: 200 }}>Claimed By</th>
            <th style={{ width: 150 }}>Appointment</th>
            <th style={{ width: 150 }}>Pickup To</th>
          </tr>
        )}
      </thead>
      {toggle && <tbody>{items.map(renderPackage)}</tbody>}
    </Table>
  );
}
function renderPackageBreakdownBySupplier(items: Graph.Package[]) {
  const uniqueSuppliers = new Set(items.map(x => (x.supplier ? x.supplier.id : null)));
  const suppliersList = [...uniqueSuppliers];

  return suppliersList.map((id, i) => {
    const orders = items.filter(x => {
      if (!id) return x.supplier === null;
      return x.supplier?.id === id;
    });

    return (
      <div key={i} className="mb-3">
        <PackageList items={orders} />
      </div>
    );
  });
}

function PackageButtonFilter({ items }: { items: Graph.Package[] }) {
  const [hubName, setHubName] = useState('All');

  const hubs: { title: string; total: number }[] = [{ title: 'All', total: items.length }];
  items.map(x => {
    const title = x?.nextWarehouse?.shortName || 'Little Fashion';
    const index = hubs.findIndex(h => h.title === title);
    if (index < 0) {
      hubs.push({ title, total: 1 });
    } else {
      const hub = hubs[index];
      hubs[index] = { title, total: hub.total + 1 };
    }
    return null;
  });

  return (
    <>
      <div className={styles.btn_filter_wrap}>
        {hubs.map((h, i) => (
          <div key={i}>
            <Button
              theme={hubName === h.title ? 'primary' : 'secondary'}
              value={h.title}
              onClick={() => setHubName(h.title)}
            >
              {h.title} ({h.total})
            </Button>
          </div>
        ))}
      </div>
      {renderPackageBreakdownBySupplier(
        items.filter(
          x =>
            hubName === 'All' ||
            (hubName === 'Little Fashion' && x.nextWarehouse === null) ||
            x.nextWarehouse?.shortName === hubName,
        ),
      )}
    </>
  );
}

export function PackagePickupListScreen() {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const qs = useQueryString<{
    returned: '1' | '0';
  }>({
    returned: '0',
  });
  const { data } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, { variables: { status: ['PICKUP'] } });

  const [returned, setReturned] = useState(qs.returned === '1');

  const query = `${pathname}?returned=${returned ? '1' : '0'}`;

  useEffect(() => {
    history.replace(query + '#pickup');
  }, [search, query, history]);

  if (!data?.packageList) return <BlockLoading />;
  return (
    <>
      <div className={styles.CheckboxWrap}>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#e22f2f',
          }}
        >
          <div>
            <Checkbox
              label="Returning"
              checked={returned}
              onChange={e => {
                setReturned(e.target.checked);
              }}
            />
          </div>
        </div>
        <div>
          <LinkButton to={'map/pickup'} style={{ borderRadius: '15px', height: 28 }}>
            Map
          </LinkButton>
        </div>
      </div>
      <hr />
      <div>
        <PackageButtonFilter
          items={data.packageList.filter(x => {
            const a = returned && x.isReturning;
            const b = !returned && x;
            return a || b;
          })}
        />
      </div>
    </>
  );
}
