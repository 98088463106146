import { DocumentNode, OperationVariables, QueryHookOptions, useQuery } from '@apollo/client';
import React from 'react';
import { Graph } from 'src/generated/graph';
import FormSelectInput from './FormSelectInput';
import styles from './form.module.scss';

interface Props extends React.PropsWithChildren<React.HTMLProps<HTMLSelectElement>> {
  label?: string;
  query: DocumentNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphOptions?: QueryHookOptions<any, OperationVariables>;
  transform: (data: Graph.Query) => { text: string; value: string }[];
}

export default React.forwardRef(function FormSelectInputFromQuery(props: Props, ref: React.Ref<HTMLSelectElement>) {
  const { query, graphOptions, transform, ...restProps } = props;
  const { data, loading } = useQuery(query, graphOptions);

  if (loading || !data) {
    if (props.label) {
      return (
        <div className={styles.form_group}>
          <label className={styles.label}>{props.label}</label>
          <div className={styles.input}>
            <i className="fas fa-spinner fa-spin"></i>&nbsp;Loading
          </div>
        </div>
      );
    }

    return (
      <div className={styles.input}>
        <i className="fas fa-spinner fa-spin"></i>&nbsp;Loading
      </div>
    );
  }

  return <FormSelectInput {...restProps} ref={ref} items={transform(data)} />;
});
