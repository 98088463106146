import React from 'react';
import { AppPageDataSource } from './AppPageDataSource';
import { NavigationBuilder } from '../../../components/NavigationBuilder';
import { AppPageMarkup } from '../../../types/common';

interface Props {
  value?: AppPageMarkup;
  onChange: (v: AppPageMarkup) => void;
}

export function AppPageMarkupGenricProductList(props: Props) {
  return (
    <div>
      <div className="form-group">
        <label>Title</label>
        <input
          value={props.value?.title || ''}
          className="lf-form-control"
          placeholder="Please write block title"
          onChange={e => {
            props.onChange({ ...props.value, title: e.target.value } as AppPageMarkup);
          }}
        />
      </div>
      <div className="form-group">
        <fieldset>
          <legend>
            <span>Data Source</span>
          </legend>
        </fieldset>

        <AppPageDataSource
          value={props.value?.datasource}
          onChange={t => {
            props.onChange({ ...props.value, datasource: t } as AppPageMarkup);
          }}
        />
      </div>
      <div className="form-group">
        <fieldset>
          <legend>
            <span>Navigation</span>
          </legend>
        </fieldset>

        <NavigationBuilder
          value={props.value?.navigation}
          onChange={t => props.onChange({ ...props.value, navigation: t } as AppPageMarkup)}
        />
      </div>
    </div>
  );
}
