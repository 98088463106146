import React from 'react';
import { Control, Controller } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function FormController(props: { control: Control; as: React.FunctionComponent<any>; name: string }) {
  const Component = props.as;

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ value, onChange }) => {
        return <Component value={value} onChange={onChange} />;
      }}
    />
  );
}
