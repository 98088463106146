import styles from './tasks.module.scss';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { QUERY_PACKAGE_LIST } from '../query';
import { Graph } from '../../../generated/graph';
import { useHistory, useLocation } from 'react-router';
import { useQueryString } from '../../../libs/QueryString';
import { Checkbox } from '../../../components/form/FormComponent';
import { LinkButton } from 'src/components/layout/xform/LinkButton';
import PackageArrivedHubButtonFilter from './PackageArrivedListTable';
import { BlockLoading } from '../../../components/loading/BlockLoading';

export function PackageArrivedListScreen() {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const qs = useQueryString<{
    claimed: '1' | '0';
    internal: '1' | '0';
    nobarcode: '1' | '0';
    onlyfinal: '1' | '0';
    noClaimed: '1' | '0';
    thirdParty: '1' | '0';
    isSellerDrop: '1' | '0';
    isL192Packages: '1' | '0';
    isSellerPackages: '1' | '0';
    isReturningPackage: '1' | '0';
  }>({
    claimed: '0',
    internal: '0',
    nobarcode: '1',
    onlyfinal: '1',
    noClaimed: '0',
    thirdParty: '0',
    isSellerDrop: '0',
    isL192Packages: '0',
    isSellerPackages: '0',
    isReturningPackage: '0',
  });

  const [claimed, setClaimed] = useState(qs.claimed === '1');
  const [noClaimed, setNoClaimed] = useState(qs.noClaimed === '1');
  const [onlyIsFinal, setOnlyIsFinal] = useState(qs.onlyfinal === '1');
  const [onlyInternal, setOnlyInternal] = useState(qs.internal === '1');
  const [onlyNoBarcode, setOnlyNoBarcode] = useState(qs.nobarcode === '1');
  const [onlyThirdParty, setOnlyThirdParty] = useState(qs.thirdParty === '1');
  const [onlySellerDrop, setOnlySellerDrop] = useState(qs.isSellerDrop === '1');
  const [onlyL192Packages, setOnlyL192Packages] = useState(qs.isL192Packages === '1');
  const [onlySellerPackages, setOnlySellerPackages] = useState(qs.isSellerPackages === '1');
  const [onlyReturnPackage, setOnlyReturnPackage] = useState(qs.isReturningPackage === '1');
  const query = `${pathname}?nobarcode=${onlyNoBarcode ? '1' : '0'}&onlyfinal=${onlyIsFinal ? '1' : '0'}&noClaimed=${
    noClaimed ? '1' : '0'
  }&claimed=${claimed ? '1' : '0'}&thirdParty=${onlyThirdParty ? '1' : '0'}&internal=${
    onlyInternal ? '1' : '0'
  }&isSellerDrop=${onlySellerDrop ? '1' : '0'}&isReturningPackage=${onlyReturnPackage ? '1' : '0'}&isL192Packages=${
    onlyL192Packages ? '1' : '0'
  }&isSellerPackages=${onlySellerPackages ? '1' : '0'}`;

  useEffect(() => {
    history.replace(query + '#arrived');
  }, [search, query, history]);

  const { data } = useQuery<Graph.Query>(QUERY_PACKAGE_LIST, { variables: { status: ['ARRIVED'] } });
  const dataFilter = data?.packageList?.filter(y => {
    if (onlyL192Packages && onlyThirdParty) {
      return onlyL192Packages && y?.supplier === null && onlyThirdParty && y.type === 'THIRD_PARTY';
    } else if (onlyL192Packages) {
      return onlyL192Packages && y?.supplier === null;
    }
    if (onlySellerPackages && onlyThirdParty) {
      return onlySellerPackages && y?.supplier !== null && onlyThirdParty && y.type === 'THIRD_PARTY';
    } else if (onlySellerPackages) {
      return onlySellerPackages && y?.supplier !== null;
    }
    if (onlyReturnPackage) return onlyReturnPackage && y.isReturning === true;
    if (onlySellerDrop) {
      return (
        onlySellerDrop &&
        !!y.barcode &&
        !y.requestPickupAt &&
        y?.claimPickupBy?.groupID !== 5 &&
        y?.type !== 'INTERNAL' &&
        y?.supplier
      );
    }
    if (onlyThirdParty) {
      return onlyThirdParty && y.type === 'THIRD_PARTY';
    } else {
      return y.type !== 'THIRD_PARTY';
    }
  });
  if (!data?.packageList) return <BlockLoading />;
  return (
    <>
      <div className={styles.CheckboxWrap}>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#e22f2f',
          }}
        >
          <div>
            <Checkbox
              label="Need to package first"
              checked={onlyNoBarcode}
              onChange={e => {
                setOnlyNoBarcode(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#009432',
          }}
        >
          <div>
            <Checkbox
              label="Ready to deliver to customer"
              checked={onlyIsFinal}
              onChange={e => {
                setOnlyIsFinal(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Claimed"
              checked={claimed}
              onChange={e => {
                setClaimed(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Unclaimed"
              checked={noClaimed}
              onChange={e => {
                setNoClaimed(e.target.checked);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Third Party"
              checked={onlyThirdParty}
              onChange={e => {
                setOnlyThirdParty(e.target.checked);
                setOnlyReturnPackage(false);
                setOnlySellerDrop(false);
                setOnlyInternal(false);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Internal Package"
              checked={onlyInternal}
              onChange={e => {
                setOnlyInternal(e.target.checked);
                setOnlyReturnPackage(false);
                setOnlySellerDrop(false);
                setOnlyThirdParty(false);
              }}
            />
          </div>
        </div>

        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Seller Drop"
              checked={onlySellerDrop}
              onChange={e => {
                setOnlySellerDrop(e.target.checked);
                setOnlyThirdParty(false);
                setOnlyReturnPackage(false);
                setOnlyInternal(false);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #555',
            color: '#3E4B5B',
          }}
        >
          <div>
            <Checkbox
              label="Only Returning Packages"
              checked={onlyReturnPackage}
              onChange={e => {
                setOnlyReturnPackage(e.target.checked);
                setOnlyThirdParty(false);
                setOnlySellerDrop(false);
                setOnlyInternal(false);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #1155c9',
            color: '#1155c9',
          }}
        >
          <div>
            <Checkbox
              label="Only L192 Packages"
              checked={onlyL192Packages}
              onChange={e => {
                setOnlyL192Packages(e.target.checked);
                setOnlySellerPackages(false);
              }}
            />
          </div>
        </div>
        <div
          className={styles.CheckboxWrapCheck}
          style={{
            backgroundColor: '#fff',
            border: '1px solid #1155c9',
            color: '#1155c9',
          }}
        >
          <div>
            <Checkbox
              label="Only Seller Packages"
              checked={onlySellerPackages}
              onChange={e => {
                setOnlySellerPackages(e.target.checked);
                setOnlyL192Packages(false);
              }}
            />
          </div>
        </div>
        <div>
          <LinkButton to={'map'} style={{ borderRadius: '15px', height: 28 }}>
            Map
          </LinkButton>
        </div>
      </div>

      <hr />

      <div>
        <PackageArrivedHubButtonFilter
          onlyThirdParty={onlyThirdParty}
          items={dataFilter?.filter(x => {
            const a = onlyNoBarcode && x?.barcode === null;
            const b = onlyIsFinal && x.isFinal && x.barcode !== null;
            const d = noClaimed && x.claimDeliveryBy === null;
            const e = !noClaimed && x;
            const f = claimed && x.claimDeliveryBy !== null;
            const g = !claimed && x;
            const h = onlyInternal && (x.type === 'INTERNAL' || (!!x?.createdBy && x?.createdBy?.id === 107));
            const i = !onlyInternal && x;

            return ((a || b) && (d || f)) || ((a || b) && e && g && i) || h;
          })}
        />
      </div>
    </>
  );
}
