/* eslint-disable */
import React from 'react';
import { SectionBlock } from '../../components/light/SectionBlock';
import { Content } from '../../components/light/Content';
import { gql } from '@apollo/client';
import {
  Query,
  Mutation,
  QueryResult,
  MutationFunction,
  MutationResult,
  OperationVariables,
} from '@apollo/react-components';
import { Graph } from '../../generated/graph';
import { RouterProps } from 'react-router';
import queryString from 'query-string';

const QUERY_PRODUCT = gql`
  query getProductByCode($code: String!) {
    productByCode(code: $code) {
      id
      code
      size
      color
    }
  }
`;

const MUTATE_CREATE_BARCODE = gql`
  mutation createProductBarcode($id: Int!, $color: String!, $size: String!) {
    createBarcode(productID: $id, color: $color, size: $size) {
      id
    }
  }
`;

type State = {
  code?: string;
  color?: string;
  size?: string;
};

export class CreateBarcodeScreen extends React.Component<RouterProps> {
  state: State = {};

  constructor(props: RouterProps) {
    super(props);

    const query = queryString.parse(props.history.location.search);
    const defaultValues = {
      product: '',
      size: '',
      color: '',
      ...query,
    };

    this.state = {
      code: defaultValues.product,
      color: defaultValues.color,
      size: defaultValues.size,
    };
  }

  render() {
    return (
      <Content>
        <SectionBlock title="Create Barcode" body={false}>
          <Query
            query={QUERY_PRODUCT}
            fetchPolicy={'network-only'}
            skip={this.state.code === undefined || this.state.code === ''}
            variables={({ code: this.state.code } as unknown) as OperationVariables}
          >
            {this.renderProductQuery}
          </Query>
        </SectionBlock>
      </Content>
    );
  }

  renderProductQueryLoading = () => {
    return (
      <div>
        <hr />
        <div className="mt-2">Loading ...</div>
      </div>
    );
  };

  renderProductError = () => {
    return (
      <div>
        <hr />
        <div className="mt-2">
          <i>Cannot find this product. Please input the correct product code</i>
        </div>
      </div>
    );
  };

  onCreateBarcodeComplete = (data: Graph.Mutation) => {
    if (data && data.createBarcode && data.createBarcode !== null) {
      this.props.history.push(`/sku/${data.createBarcode.id}`);
    }
  };

  renderOptions = (product: Graph.Product) => {
    const inputColor = React.createRef<HTMLSelectElement>();
    const inputSize = React.createRef<HTMLSelectElement>();

    return (
      <Mutation mutation={MUTATE_CREATE_BARCODE} onCompleted={this.onCreateBarcodeComplete}>
        {(update: MutationFunction, { loading }: MutationResult<Graph.Mutation>) => {
          return (
            <div>
              <hr />

              <div className="form-group">
                <label>Color</label>
                <select
                  ref={inputColor}
                  className="lf-form-control"
                  disabled={loading}
                  defaultValue={this.state.color}
                  style={{ textTransform: 'capitalize' }}
                >
                  {(product.color || []).map(x => (
                    <option style={{ textTransform: 'capitalize' }} key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>Size</label>
                <select
                  ref={inputSize}
                  className="lf-form-control"
                  disabled={loading}
                  defaultValue={this.state.size}
                  style={{ textTransform: 'capitalize' }}
                >
                  {(product.size || []).map(x => (
                    <option style={{ textTransform: 'capitalize' }} key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </div>

              {loading && (
                <div className="form-group">
                  <i>Creating barcode. Please wait...</i>
                </div>
              )}

              <div className="form-buttons-w">
                <button
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={() =>
                    update({
                      variables: {
                        id: product.id,
                        size: inputSize.current!.value,
                        color: inputColor.current!.value,
                      },
                    })
                  }
                >
                  Create Barcode
                </button>
              </div>
            </div>
          );
        }}
      </Mutation>
    );
  };

  renderProductQuery = ({ loading, data }: QueryResult<Graph.Query>) => {
    let extra = <div></div>;

    if (loading) {
      extra = this.renderProductQueryLoading();
    } else if (data !== undefined) {
      if (data.productByCode === null) extra = this.renderProductError();
      else if (this.state.code !== undefined) extra = this.renderOptions(data.productByCode!);
    }

    return (
      <div className="bg-white p-4" style={{ maxWidth: 400 }}>
        <div className="form-group">
          <label>Product Code</label>
          <input
            disabled={loading}
            autoFocus
            type="text"
            className="lf-form-control"
            placeholder="Product Code"
            defaultValue={this.state.code}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // Do the search when enter
              if (e.key === 'Enter') {
                this.setState({ code: e.currentTarget.value.trim() });
              }
            }}
            onChange={() => {
              if (this.state.code !== undefined) {
                this.setState({ code: undefined });
              }
            }}
          />
        </div>

        {extra}
      </div>
    );
  };
}
